import axiosInstance from '../axios'

export const NEW_SEARCH_FEATURE_STORAGE_KEY = 'show_search_tool_tip'

export function searchInventoryVehicles(
    auctionCode,
    saleYear,
    saleNumber,
    laneNumber,
    sellerSearchType,
    sellerType
) {
    return axiosInstance.post('/vehicle/inventory-search', {
        auctionCode,
        laneNumber,
        saleYear,
        saleNumber,
        sellerType,
        sellerSearchType
    })
}

export function inventoryBulkUpdate(requestBody) {
    return axiosInstance.post('/vehicle/inventory-bulk-update', requestBody)
}

export function saveDraft(requestBody) {
    return axiosInstance.post('/vehicle/save-draft', requestBody)
}

export function deleteDraftBySbluList(requestBody) {
    return axiosInstance.post('/vehicle/delete-draft-vehicles', requestBody)
}

export function setShowNewSearchFeatureBoxToStorage(key, value) {
    localStorage.setItem(key, value)
}
export function getShowNewSearchFeatureBoxToStorage(key, value) {
    return localStorage.getItem(key) !== 'NO';
}

export function deleteShowNewSearchFeatureBoxFromStorage(key, value) {
    localStorage.removeItem(key)
}