import React, {useMemo} from "react";
import {isEmpty} from "lodash";
import {UnAssignVehicleList} from "./UnAssignVehicleList";
import {useTranslation} from "react-i18next";

export function UnAssignVehicleListWrapper({
    auctionCode,
    inventory,
    setInventory,
    scrollToItem
}) {

    const { t } = useTranslation()
    const mainUnassignVehicleList = useMemo(() => (
        <div className="assign-inventory-draggable right-panel table-bordered unassigned-inventory">
            {isEmpty(inventory) && (
                <div className="text-center">
                    {t(
                        'LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SELECT_INSTRUCTION'
                    )}
                </div>
            )}
            <UnAssignVehicleList
                auctionCode={auctionCode}
                vehicles={inventory}
                setVehicles={setInventory}
                scrollToItem={scrollToItem}
            />
        </div>
    ), [auctionCode, inventory, setInventory, scrollToItem])

    return (
        <>
            {mainUnassignVehicleList}
        </>
    )
}