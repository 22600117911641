import { useMessages } from "../../hooks/common/UseMessages";
import { useTranslation } from "react-i18next";
import NotificationMessages from "../common/notification-messages/NotificationMessages";
import React, { useEffect, useMemo } from "react";
import { MessageTypeConstant } from "../../utils/constant";
import { AuctionCodeErrorMessage } from "../common/error/AuctionCodeErrorNotificationMessage";
import {isProduction, openSnowModal} from "../../utils/utils";
import {useSelector} from "react-redux";
import {NotificationMessage} from '../common/notification-message/NotificationMessage'

export default function LaneManagementMessages({
    errorEmptyAuctionCode,
    errorInvalidAuctionCode,
    errorNotExistsAuctionCode,
    errorNotConfigSaleYear,
    errorNotConfigSaleNumber,
    errorWhenSaveDraftVehicle,
    errorWhenClearMappedLane,
    isGetPreRegistError,
    isAddRequestError,
    errorMessage,
    saveAsDraftMessage,
    saveMappedLaneError,
   onSuccessMessageToggleCallBack
}) {
    const { messages, clearMessages, putMessage } = useMessages()
    const { t } = useTranslation()
    const laneManagementData = useSelector(state => state.laneManagementContext.laneManagementData)

    const processErrorMsg = useMemo(() => (
        <>
            {t('MESSAGE.COMMON.PROCESS_ERROR.PRE_TEXT')}
            <a href="#" onClick={openSnowModal}>
                {t('MESSAGE.COMMON.PROCESS_ERROR.LINK_TEXT')}
            </a>
        </>
    ), [])

    const generalErrorMessage = useMemo(() => (
        <>
            {t(
                errorMessage
            )}
            {(
                errorMessage === 'MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.DELETE_RUN_REQUEST_ERROR.PRE_TEXT' ||
                errorMessage === 'MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.EDIT_RUN_REQUEST_ERROR.PRE_TEXT') && (
                <a href="#" onClick={openSnowModal}>
                    {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.DELETE_RUN_REQUEST_ERROR.LINK_TEXT')}
                </a>
            )}
        </>
    ),[errorMessage])

    const saveMappedLaneErrorMessage400 = useMemo(() => (
        <>
            {saveMappedLaneError?.message} {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.SAVE_MAPPED_LANE_ERROR.BAD_REQUEST.PRE_TEXT')}
            <a href="#" onClick={openSnowModal}>
                {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.SAVE_MAPPED_LANE_ERROR.BAD_REQUEST.LINK_TEXT')}
            </a>
        </>
    ), [saveMappedLaneError])

    const messageConditionMapping = useMemo(() =>
        [
            {
                condition: () => !isProduction(),
                message: t('MESSAGE.COMMON.INFO_MESSAGE_FOR_TESTING'),
                type: MessageTypeConstant.INFO
            },
            {
                condition: () => !!errorEmptyAuctionCode,
                type: MessageTypeConstant.ERROR,
                message: errorEmptyAuctionCode
            },
            {
                condition: () => !!errorInvalidAuctionCode,
                type: MessageTypeConstant.ERROR,
                message: errorInvalidAuctionCode
            },
            {
                condition: () => !!errorNotExistsAuctionCode,
                type: MessageTypeConstant.ERROR,
                message: <AuctionCodeErrorMessage auctionConnectionError={errorNotExistsAuctionCode} />
            },
            {
                condition: () => !!errorNotConfigSaleYear,
                type: MessageTypeConstant.ERROR,
                message: errorNotConfigSaleYear
            },
            {
                condition: () => !!errorNotConfigSaleNumber,
                type: MessageTypeConstant.ERROR,
                message: errorNotConfigSaleNumber
            },
            {
                condition: () => !!errorWhenSaveDraftVehicle,
                type: MessageTypeConstant.ERROR,
                message: errorWhenSaveDraftVehicle
            },
            {
                condition: () => !!errorWhenClearMappedLane,
                type: MessageTypeConstant.ERROR,
                message: errorWhenClearMappedLane
            },
            {
                condition: () => !!isGetPreRegistError,
                type: MessageTypeConstant.ERROR,
                message: processErrorMsg
            },
            {
                condition: () => !!isAddRequestError,
                type: MessageTypeConstant.ERROR,
                message: processErrorMsg
            },
            {
                condition: () => !!errorMessage,
                type: MessageTypeConstant.ERROR,
                message: generalErrorMessage
            },
            {
                condition: () => !!saveAsDraftMessage,
                type: MessageTypeConstant.SUCCESS,
                message: saveAsDraftMessage
            },
            {
                condition: () => !!saveMappedLaneError && saveMappedLaneError.status === 400,
                type: MessageTypeConstant.ERROR,
                message: saveMappedLaneErrorMessage400
            },
            {
                condition: () => !!saveMappedLaneError && saveMappedLaneError.status !== 400,
                type: MessageTypeConstant.ERROR,
                message: saveMappedLaneError?.message
            }
        ], [errorEmptyAuctionCode, errorInvalidAuctionCode, errorNotExistsAuctionCode,
                    errorNotConfigSaleYear, errorNotConfigSaleNumber, errorWhenSaveDraftVehicle,
                    errorWhenClearMappedLane, isGetPreRegistError, isAddRequestError, processErrorMsg,
                    errorMessage, saveAsDraftMessage, saveMappedLaneError
                ])

    useEffect(() => {
        clearMessages()
        messageConditionMapping.forEach(({condition, message, type}) => {
            if (condition() === true) {
                putMessage(message, type)
            }
        })

        // Observe from redux
        if (laneManagementData.message) {
            putMessage(laneManagementData.message.content, laneManagementData.message.type)
        }

    }, [messageConditionMapping, laneManagementData.message])

    return (
        <NotificationMessages messages={messages} onSuccessMessageToggleCallBack={onSuccessMessageToggleCallBack} />
    )
}