import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ToggleFunction } from "../../utils/constant";
import { isEnableToggleFlag } from "../../utils/utils";

export const useToggleAS400 = (
    auctionCode
) => {
    const toggleConfigs = useSelector(states => states.session.toggleConfigs)
    const [isEnableUpdateAS400Flag, setIsEnableUpdateAS400Flag] = useState(false)
    useEffect(() => {
        if (auctionCode) {
            const toggleConfigValue = toggleConfigs[ToggleFunction.AUCTION_LIST_ALLOWED_DB_WRITE]
            const isEnableUpdateAS400Flag = isEnableToggleFlag(toggleConfigValue, auctionCode)
            setIsEnableUpdateAS400Flag(isEnableUpdateAS400Flag)
        }
    }, [auctionCode, toggleConfigs])

    return {isEnableUpdateAS400Flag}
}