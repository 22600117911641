import {LANE_MANAGEMENT_ACTION_TYPE} from '../utils/constant';

const initialState = {
    laneManagementData: {
        message: null,
        revertedMessage: null,
    },
    laneManagementSearchData: {
        saleYear: null,
        saleNumber: null,
        laneNumber: null,
        sellerNumber: null
    }
}

export const laneManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case LANE_MANAGEMENT_ACTION_TYPE.MESSAGES:
            return {
             ...state,
                laneManagementData: {
                    ...state.message,
                    message: action.message
                }
            };
        case LANE_MANAGEMENT_ACTION_TYPE.REVERTED_MESSAGE:
            return {
            ...state,
                laneManagementData: {
                   ...state.revertedMessage,
                    revertedMessage: action.revertedMessage
                }
            };
        default:
            return state;
    }
}

export const laneManagementSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_LANE_MANAGEMENT_SEARCH_CONTEXT":
            return{
                ...state,
                laneManagementSearchData: action.laneManagementSearchData
            }
        default:
            return state;
    }
}
