import {randomInteger} from '../../utils/utils';
import {inventoryQuickSearch} from '../../actions/AssignInventoryAction';
import {useDispatch} from 'react-redux';

export default function usePushQuickSearchValue() {
    const dispatch = useDispatch()
    const pushQuickSearchValue = (value) => {
        const quickSearch = {
            quickSearchValue: value,
            id: randomInteger()
        }
        dispatch(inventoryQuickSearch(quickSearch))
    }

    return {pushQuickSearchValue}
}