import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import './search-form.scss'
import './datepicker.scss'
import { Button, Col, Input, Row, Space } from '@prism/library'
import { loadCriteriaSearchData } from '../../utils/data-source/request-history-datasource'
import {handleErrorException, handleEventOnKeyDownForNumber, isBlank} from '../../utils/utils'
import { Dropdown } from '../common/custom/prism/dropdown/Dropdown'
import {ConstraintConstant, EMPTY_STRING} from '../../utils/constant'
import { SearchSellerPanel } from '../common/vehicle-info/SearchSellerPanel'
import { NotificationMessage } from '../common/notification-message/NotificationMessage'
import { isValidNumber } from '../../utils/validation-utils'
import { isNil, isEqual, first } from 'lodash'
import SearchHistoryDatePicker from "./SearchHistoryDatePicker";
import { useDispatch } from "react-redux";
import { notifySearchHistoryClearAll } from "../../actions/ResetStateObservingAction";

export function SearchForm({ auctionCode, search, clearAll, saleNumber, laneNumber }) {
    const { t } = useTranslation()

    const LANE_NUMBERS_PATTERN = /^[0-9,]*$/
    const MAX_SALE_NUMBER = 999
    const SALE_NUMBER_FROM = "FROM"
    const SALE_NUMBER_TO = "TO"

    const [auctionCodeOptions, setAuctionCodeOptions] = useState([])
    const [selectedAuctionCode, setSelectedAuctionCode] = useState({})
    const [auctionCodes, setAuctionCodes] = useState([])

    const [saleYearOptions, setSaleYearOptions] = useState([])
    const [selectedSaleYear, setSelectedSaleYear] = useState({name: new Date().getFullYear(), value: new Date().getFullYear()})

    //Set defaultRequestCreationDateFrom is thirty days ago
    const [defaultRequestCreationDateFrom, setDefaultRequestCreationDateFrom] = useState(() => {
        let date = new Date()
        date.setDate(date.getDate() - 30)
        return date
    })
    const [defaultRequestCreationDateTo, setDefaultRequestCreationDateTo] = useState(new Date())

    //#region Variables for Search Seller Popup
    const [searchSellerOpen, setSearchSellerOpen] = useState(false)
    const [customers, setCustomers] = useState(null)
    const [searchData, setSearchData] = useState(null)
    const dispatch = useDispatch();

    //#endregion

    const [criteriaSearch, setCriteriaSearch] = useState({
        auctionCode: selectedAuctionCode?.value,
        sellerNumber: null,
        creationDateFrom: defaultRequestCreationDateFrom,
        creationDateTo: defaultRequestCreationDateTo,
        saleYear: selectedSaleYear.value,
        saleNumberFrom: null,
        saleNumberTo: null,
        laneNumbers: null
    })

    const [error, setError] = useState(null)

    const creationDateFromRef = useRef(null)
    const creationDateToRef = useRef(null)
    const sellerNumberRef = useRef(null)
    const saleNumberFromRef = useRef(null)
    const saleNumberToRef = useRef(null)
    const laneNumbersRef = useRef(null)
    const searchFormRef = useRef(null)

    useEffect(() => {
        getCriteriaSearchData()
    }, [])

    function getCriteriaSearchData() {
        loadCriteriaSearchData().then((response) => {
            const auctionCodeResponses = response.data.auctionCodes
            setAuctionCodes(auctionCodeResponses)
            const auctionCodes = auctionCodeResponses.map(buildDropDowItem)
            setAuctionCodeOptions(auctionCodes)
            const selectedAuction = buildDefaultSelectedAuctionCode(auctionCodeResponses, auctionCode)
            setSelectedAuctionCode(auctionCodes.find(auctionCodeOption => auctionCodeOption.key === selectedAuction))
            updateCriteriaSearch('auctionCode', selectedAuction)

            const years = response.data.years.map(buildDropDowItem)
            setSaleYearOptions(years)
            const currentYear = new Date().getFullYear()
            const selectedItem = years.find(p => p.value === currentYear)
            setSelectedSaleYear(selectedItem)
        }).catch((ex) => {
            handleErrorException(ex)
        })
    }

    function buildDropDowItem(value) {
        return {key: value, name: value, value: value}
    }

    function buildDefaultSelectedAuctionCode(auctionCodes, auctionCode) {
        if (auctionCodes.includes(auctionCode)) {
            return auctionCode
        } else {
            return auctionCodes[0]
        }
    }

    function updateCriteriaSearch(attribute, value) {
        criteriaSearch[attribute] = value
        setCriteriaSearch((oldCriteriaSearch) => {
            const newCriteriaSearch = {...oldCriteriaSearch}
            return newCriteriaSearch
        })
    }

    function updateSellerNumber(value) {
        updateCriteriaSearch('sellerNumber', value)
    }

    function validateSearchForm() {
        const creationDateFrom = new Date(creationDateFromRef.current.querySelector('input').value)
        const creationDateTo = new Date(creationDateToRef.current.querySelector('input').value)
        const today = new Date()

        if (criteriaSearch.sellerNumber && !isValidNumber(criteriaSearch.sellerNumber)) {
            let err = {
                message: t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.SELLER_NUMBER_ERROR_FORMAT')
            }
            setError(err)
            return
        }

        if (!!criteriaSearch.saleNumberFrom) {
            if(!isValidNumber(criteriaSearch.saleNumberFrom) || Number(criteriaSearch.saleNumberFrom) < 1 || Number(criteriaSearch.saleNumberFrom) > MAX_SALE_NUMBER) {
                let err = {
                    message: t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.SALE_NUMBER_ERROR_FORMAT')
                }
                setError(err)
                return
            }
        }

        if (!!criteriaSearch.saleNumberTo) {
            if(!isValidNumber(criteriaSearch.saleNumberTo) || Number(criteriaSearch.saleNumberTo) < 1 || Number(criteriaSearch.saleNumberTo) > MAX_SALE_NUMBER) {
                let err = {
                    message: t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.SALE_NUMBER_ERROR_FORMAT')
                }
                setError(err)
                return
            }
        }

        if(parseInt(criteriaSearch.saleNumberFrom) > parseInt(criteriaSearch.saleNumberTo)) {
            let err = {
                message: t('MESSAGE.COMMON.ERROR.SEARCH_REQUEST_HISTORY.SALE_NUMBER_VALIDATION_ERROR')
            }
            setError(err)
            return
        }

        if (criteriaSearch.laneNumbers && !LANE_NUMBERS_PATTERN.test(criteriaSearch.laneNumbers)) {
            let err = {
                message: t('MESSAGE.COMMON.ERROR.SEARCH_REQUEST_HISTORY.LANE_NUMBERS_ERROR_FORMAT')
            }
            setError(err)
            return
        }

        if (creationDateFrom > today) {
            let err = {
                message: t('MESSAGE.COMMON.ERROR.SEARCH_REQUEST_HISTORY.DATE_FROM_AFTER_TODAY_ERROR')
            }
            setError(err)
            return
        } else if (creationDateTo > today) {
            let err = {
                message: t('MESSAGE.COMMON.ERROR.SEARCH_REQUEST_HISTORY.DATE_TO_AFTER_TODAY_ERROR')
            }
            setError(err)
            return
        }

        if(creationDateFrom > creationDateTo) {
            let err = {
                message: t('MESSAGE.COMMON.ERROR.SEARCH_REQUEST_HISTORY.DATE_FROM_TO_ERROR')
            }
            setError(err)
            return
        }

        setError(null)
    }

    useEffect(() => {
        validateSearchForm()
    }, [criteriaSearch])

    function doSearch() {
        if (isNil(error)) {
            criteriaSearch['creationDateFrom'] = creationDateFromRef.current.querySelector('input').value
            criteriaSearch['creationDateTo'] = creationDateToRef.current.querySelector('input').value
            setCriteriaSearch(criteriaSearch)
            search(criteriaSearch)
        }
    }

    function doSearchOnEnter(searchData) {
        if (isNil(error)) {
            search(searchData)
        }
    }
    function handlePressEnterForSearching(event) {
        if(event.key === 'Enter') {
            doSearchOnEnter(buildSearch());
        }
    }

    function handleEventInputSaleNumber(type) {
        //Check input saleNumberTo value changed
        if (isEqual(type, SALE_NUMBER_TO)) {
            if (criteriaSearch.saleNumberTo && !criteriaSearch.saleNumberFrom) {
                updateCriteriaSearch('saleNumberFrom', 1)
                return
            }
            //When input blank for saleNumberTo if saleNumberFrom not blank => update saleNumberTo with MAX_SALE_NUMBER
            if (criteriaSearch.saleNumberFrom && !criteriaSearch.saleNumberTo) {
                updateCriteriaSearch('saleNumberTo', MAX_SALE_NUMBER)
                return
            }
        }

        //When input blank to saleNumberFrom also clear saleNumberTo
        if (isBlank(criteriaSearch.saleNumberFrom)) {
            updateCriteriaSearch('saleNumberTo', EMPTY_STRING)
            return
        }

        //Check input saleNumberFrom value changed
        if (isEqual(type, SALE_NUMBER_FROM)) {
            if (criteriaSearch.saleNumberFrom && !criteriaSearch.saleNumberTo) {
                updateCriteriaSearch('saleNumberTo', MAX_SALE_NUMBER)
                return
            }
        }
    }

    function doClearAll() {
        const today = new Date()
        let thirtyDaysAgo = new Date()
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

        const defaultSelectedAuctionCode = buildDefaultSelectedAuctionCode(auctionCodes, auctionCode)
        //Reset react state
        const defaultCriteria = {
            auctionCode: defaultSelectedAuctionCode,
            sellerNumber: '',
            creationDateFrom: thirtyDaysAgo,
            creationDateTo: today,
            saleYear: today.getFullYear(),
            saleNumberFrom: '',
            saleNumberTo: '',
            laneNumbers: ''
        }
        setCriteriaSearch(defaultCriteria)

        //Reset UI
        setSelectedAuctionCode({name: defaultSelectedAuctionCode, value: defaultSelectedAuctionCode})
        setSelectedSaleYear({name: today.getFullYear(), value: today.getFullYear()})

        const creationDateFrom = String(thirtyDaysAgo.getMonth() + 1).padStart(2, '0')
                                    + '/' + String(thirtyDaysAgo.getDate()).padStart(2, '0')
                                    + '/' + thirtyDaysAgo.getFullYear()
        const creationDateTo = String(today.getMonth() + 1).padStart(2, '0')
                                    + '/' + String(today.getDate()).padStart(2, '0')
                                    + '/' + today.getFullYear()
        creationDateFromRef.current.querySelector('input').value = creationDateFrom
        creationDateToRef.current.querySelector('input').value = creationDateTo
        dispatch(notifySearchHistoryClearAll())

        //Reset table data
        clearAll()
    }

    function buildSearch() {
        const creationDateFrom = creationDateFromRef.current.querySelector('input').value
        const creationDateTo = creationDateToRef.current.querySelector('input').value
        const auctionCode = searchFormRef.current.querySelector('.auction-dropdown')
            .querySelector('button').textContent
        const saleYear = searchFormRef.current.querySelector('.saleyear-dropdown')
            .querySelector('button').textContent

        const searchData = {
            auctionCode: auctionCode,
            sellerNumber: sellerNumberRef.current.value,
            creationDateFrom: creationDateFrom,
            creationDateTo: creationDateTo,
            saleYear: saleYear,
            saleNumberFrom: saleNumberFromRef.current.value,
            saleNumberTo: saleNumberToRef.current.value,
            laneNumbers: laneNumbersRef.current.value
        }
        return searchData
    }

    const requestCreationDate = useMemo(() =>
            <>

                <span className='m-g-from request-creation-date-lbl gray-lbl'>{t('LABEL.REQUEST_HISTORY.SEARCH_FORM.FROM')}</span>
                <div ref={creationDateFromRef}>
                    <SearchHistoryDatePicker
                        classNames={"search-history-form-calendar"}
                        defaultRequestCreationDateTo={defaultRequestCreationDateFrom}
                        handlePressEnterForSearching={handlePressEnterForSearching}
                        validateSearchForm={validateSearchForm}
                    />
                </div>
                <span className='request-creation-date-lbl gray-lbl'>{t('LABEL.REQUEST_HISTORY.SEARCH_FORM.TO')}</span>
                <div ref={creationDateToRef}>
                    <SearchHistoryDatePicker
                        classNames={"search-history-form-calendar"}
                        defaultRequestCreationDateTo={defaultRequestCreationDateTo}
                        handlePressEnterForSearching={handlePressEnterForSearching}
                        validateSearchForm={validateSearchForm}
                    />
                </div>
            </>
    ,[])

    function resetOnSeeAllRequest(saleNumber,laneNumber) {
        const today = new Date()
        //Reset react state
        const defaultCriteria = {
            auctionCode: auctionCode,
            sellerNumber: '',
            creationDateFrom: '',
            creationDateTo: '',
            saleYear: today.getFullYear(),
            saleNumberFrom: saleNumber,
            saleNumberTo: saleNumber,
            laneNumbers: laneNumber
        }
        setCriteriaSearch(defaultCriteria)

        //Reset UI
        setSelectedAuctionCode({name: auctionCode, value: auctionCode})
        setSelectedSaleYear({name: today.getFullYear(), value: today.getFullYear()})


        creationDateFromRef.current.querySelector('input').value = ''
        creationDateToRef.current.querySelector('input').value = ''

        //Reset table data
        clearAll()
        //search again
        search(defaultCriteria)
    }

    useEffect(() => {
        if(saleNumber && laneNumber){
            resetOnSeeAllRequest(saleNumber,laneNumber)
        }
    }, [saleNumber,laneNumber])
    return (
        <>
            <div ref={searchFormRef} className='search-history-form d-flex'>
                <div className='search-history-form-row-wrapper'>
                {!!error && !!error.message && (
                    <Row className="p-0">
                        <Col md={12} className="error-message-align">
                            <NotificationMessage type={'error'} isOpen={true}>
                                {error.message}
                            </NotificationMessage>
                        </Col>
                    </Row>
                )}
                    <Row className='search-history-form-row m-0'>
                        <Space className='search-history-form-group'>
                            <span className='search-history-form-lbl pl-0'>{t('LABEL.MENU_BAR.AUCTION.NAME')}</span>
                            <div className='search-dropdown auction-dropdown'>
                                <Dropdown
                                    mauto="auction-code"
                                    selectedOption={selectedAuctionCode}
                                    disabled={false}
                                    options={auctionCodeOptions}
                                    onChange={(event) => {
                                        updateCriteriaSearch('auctionCode', event.value)
                                        setSelectedAuctionCode(event)
                                    }}
                                />
                            </div>
                        </Space>
                        <Space className='search-history-form-group padding-right-align justify-content-center'>
                            <span
                                className='search-history-form-lbl'>{t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.OPTION.SELLER_NUMBER')}</span>
                            <Input
                                innerRef={sellerNumberRef}
                                className='search-history-form-input seller-number-input'
                                type="text"
                                placeholder={t('LABEL.REQUEST_HISTORY.SEARCH_FORM.SELLER_NUMBER_PLACE_HOLDER')}
                                maxLength={
                                    ConstraintConstant.SELLER_NUMBER_MAX_LENGTH
                                }
                                value={criteriaSearch.sellerNumber}
                                onKeyDown={(event) => {
                                    handleEventOnKeyDownForNumber(event)
                                    handlePressEnterForSearching(event)
                                }}
                                onChange={(event) => {
                                    updateCriteriaSearch('sellerNumber', event.target.value)
                                }}/>
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => {
                                    setSearchSellerOpen(true)
                                }}>
                                <i className="icon prism-icon-search"/>
                            </Button>
                        </Space>
                        <Space className='search-history-form-group right-align-col justify-content-end'>
                            <span
                                className='search-history-form-lbl pl-right-col'>{t('LABEL.REQUEST_HISTORY.SEARCH_FORM.LANE_NUMBERS')}</span>
                            <Input
                                innerRef={laneNumbersRef}
                                className='lane-number-input'
                                type="text"
                                value={criteriaSearch.laneNumbers}
                                maxLength={
                                    ConstraintConstant.LANE_NUMBER_MAX_LENGTH
                                }
                                onKeyDown={(event) => {
                                    handleEventOnKeyDownForNumber(event, [188]) //Key code [comma: 188]
                                    handlePressEnterForSearching(event)
                                }}
                                onChange={(event) => {
                                    updateCriteriaSearch('laneNumbers', event.target.value)
                                }}
                                placeholder={t('LABEL.REQUEST_HISTORY.SEARCH_FORM.LANE_NUMBERS_PLACE_HOLDER')}/>
                        </Space>

                    </Row>
                    <Row className='search-history-form-row m-0'>
                        <Space className='search-history-form-group'>
                            <span
                                className='search-history-form-lbl pl-0'>{t('LABEL.REQUEST_HISTORY.SEARCH_FORM.SALE_YEAR')}</span>
                            <div className='pl-1 search-dropdown saleyear-dropdown'>
                                <Dropdown
                                    selectedOption={selectedSaleYear}
                                    disabled={false}
                                    options={saleYearOptions}
                                    onChange={(event) => {
                                        updateCriteriaSearch('saleYear', event.value)
                                        setSelectedSaleYear(event)
                                    }}
                                />
                            </div>
                        </Space>
                        <Space className='search-history-form-group padding-right-align justify-content-center'>
                            <span
                                className='search-history-form-lbl request-sale-number-lbl'>{t('LABEL.REQUEST_HISTORY.SEARCH_FORM.SALE_NUMBER')}</span>
                            <div className='m-w-70 pl-1'>
                                <span
                                    className='dis-in-block pr-1 gray-lbl'>{t('LABEL.REQUEST_HISTORY.SEARCH_FORM.FROM')}</span>
                                <Input
                                    innerRef={saleNumberFromRef}
                                    id="sale-number-from"
                                    className='sale-number-input dis-in-block pr-1'
                                    type="text"
                                    maxLength={ConstraintConstant.SALE_NUMBER_MAX_LENGTH}
                                    value={criteriaSearch.saleNumberFrom}
                                    onKeyDown={(event) => {
                                        handleEventOnKeyDownForNumber(event)
                                        handlePressEnterForSearching(event)
                                    }}
                                    onBlur={() => {
                                        handleEventInputSaleNumber(SALE_NUMBER_FROM)
                                    }}
                                    onChange={(event) => {
                                        updateCriteriaSearch('saleNumberFrom', event.target.value)
                                    }}
                                    placeholder={'#'}/>
                                <span
                                    className='dis-in-block pl-1 pr-1 gray-lbl'>{t('LABEL.REQUEST_HISTORY.SEARCH_FORM.TO')}</span>
                                <Input
                                    innerRef={saleNumberToRef}
                                    id="sale-number-to"
                                    className='sale-number-input dis-in-block'
                                    type="text"
                                    maxLength={ConstraintConstant.SALE_NUMBER_MAX_LENGTH}
                                    value={criteriaSearch.saleNumberTo}
                                    onKeyDown={(event) => {
                                        handleEventOnKeyDownForNumber(event)
                                        handlePressEnterForSearching(event)
                                    }}
                                    onBlur={() => {
                                        handleEventInputSaleNumber(SALE_NUMBER_TO)
                                    }}
                                    onChange={(event) => {
                                        updateCriteriaSearch('saleNumberTo', event.target.value)
                                    }}
                                    placeholder={'#'}/>
                            </div>
                        </Space>
                        <Space className='search-history-form-group right-align-col justify-content-end'>
                            <span
                                className='request-creation-date-lbl pr-0 pl-right-col'>{t('LABEL.REQUEST_HISTORY.SEARCH_FORM.CREATION_DATE')}</span>
                            {requestCreationDate}
                        </Space>
                    </Row>
                    <Row className='search-history-form-row m-0 justify-content-end'>
                        <Space className='btm-align-btn'>
                            <Button
                                variant="textonly"
                                className='search-history-btn'
                                onClick={doClearAll}
                                mauto="maBtnReset">
                                {t('LABEL.REQUEST_HISTORY.SEARCH_FORM.CLEAR_ALL_BUTTON')}
                            </Button>
                            <Button
                                color="primary"
                                onClick={doSearch}
                                className='search-history-btn'
                                disabled={!!error}
                                mauto="maBtnSearch">
                                {t('LABEL.REQUEST_HISTORY.SEARCH_FORM.SEARCH_BUTTON')}
                            </Button>
                        </Space>
                        {/* </Row>
                        </Col> */}
                    </Row>
                </div>
            </div>

            {!!searchSellerOpen && (
                <SearchSellerPanel
                    auctionCode={auctionCode}
                    searchSellerOpen={searchSellerOpen}
                    setSellerNumber={updateSellerNumber}
                    customers={customers}
                    setCustomers={setCustomers}
                    searchData={searchData}
                    setSearchData={setSearchData}
                    isVehicleDetailParticular={false}
                    setSearchSellerOpen={setSearchSellerOpen}
                    setHasDataChanged={() => {
                    }}
                />
            )}
        </>
    )
}
