import React from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'

import i18n from './utils/locale/i18n'
import App from './App'
import { UserProvider } from './utils/data-source/context/userContext'
import SpinnerInterceptor from "./utils/data-source/interceptors/SpinnerInterceptor"

import './index.css'
import '@prism/styles/prism.comps.sandbox.css'
import '@prism/styles/prism.comps.min.css'
import '@prism/styles/icon-font.min.css'
import { Provider } from 'react-redux'
import configureStore from './store'
import { HashRouter } from "react-router-dom"

document.body.classList.add('prism-overridden')
const root = ReactDOM.createRoot(document.getElementById('root'))
const store = configureStore();

root.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <UserProvider>
                <SpinnerInterceptor />
                <HashRouter>
                    <App />
                </HashRouter>
            </UserProvider>
        </I18nextProvider>
    </Provider>
)

