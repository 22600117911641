import React from 'react'

import './vehicle-image-icon.scss'
import classNames from 'classnames'

export function VehicleImageIcon({ src, className }) {
    return (
        <i
            style={{ backgroundImage: 'url("' + src + '")' }}
            className={classNames(className, 'vehicle-image-icon')}></i>
    )
}
