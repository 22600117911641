import React from 'react'
import { Button } from '@prism/library'
import { isEqual } from 'lodash'
import classNames from 'classnames'

export function PaginationWithDiv({
    isFirstLastPage,
    isFirst,
    isLast,
    pageNumbers,
    numberOfTotalPage,
    activePageNumber,
    isEnabledPageDetails,
    pageDetails,
    updateActivePageNumber
}) {
    const FIRST_PAGE_NUMBER = 1
    return (
        <>
            <div className="table-pagination text-center mt-1">
                <div className="pagination-buttons">
                    <Button
                        type="button"
                        variant="textonly"
                        disabled={isFirstLastPage || isFirst}
                        className="pagination-button icon-button"
                        onClick={() => {
                            updateActivePageNumber(FIRST_PAGE_NUMBER)
                        }}>
                        <span className="icon prism-icon-back-to-start" />
                    </Button>
                    <Button
                        type="button"
                        variant="textonly"
                        disabled={isFirstLastPage || isFirst}
                        className="pagination-button icon-button"
                        onClick={() => {
                            updateActivePageNumber(
                                isEqual(FIRST_PAGE_NUMBER, activePageNumber)
                                    ? activePageNumber
                                    : activePageNumber - 1
                            )
                        }}>
                        <span className="icon prism-icon-chevron-left" />
                    </Button>
                    {isFirstLastPage && (
                        <Button
                            type="button"
                            variant="textonly"
                            className="pagination-button activated"
                            onClick={() => {
                                updateActivePageNumber(FIRST_PAGE_NUMBER)
                            }}>
                            <span>{FIRST_PAGE_NUMBER}</span>
                        </Button>
                    )}
                    {!isFirstLastPage && (
                        <>
                            {!!pageNumbers &&
                            pageNumbers.map((pageNumber) => {
                                return (
                                    <Button
                                        key={pageNumber}
                                        type="button"
                                        variant="textonly"
                                        className={classNames(
                                            'pagination-button number-button',
                                            {
                                                activated:
                                                    pageNumber === activePageNumber
                                            }
                                        )}
                                        onClick={() => {
                                            updateActivePageNumber(pageNumber)
                                        }}>
                                        <span>{pageNumber}</span>
                                    </Button>
                                )
                            })}
                        </>
                    )}
                    <Button
                        type="button"
                        variant="textonly"
                        disabled={isFirstLastPage || isLast}
                        className="pagination-button icon-button"
                        onClick={() => {
                            updateActivePageNumber(
                                isEqual(numberOfTotalPage, activePageNumber)
                                    ? activePageNumber
                                    : activePageNumber + 1
                            )
                        }}>
                        <span className="icon prism-icon-chevron-right" />
                    </Button>
                    <Button
                        type="button"
                        variant="textonly"
                        disabled={isFirstLastPage || isLast}
                        className="pagination-button icon-button"
                        onClick={() => {
                            updateActivePageNumber(numberOfTotalPage)
                        }}>
                        <span className="icon prism-icon-back-to-start go-to-last-icon" />
                    </Button>
                </div>
                {
                    !!isEnabledPageDetails
                    && (
                        <div className="pagination-page-details mt-1">{pageDetails}</div>
                    )
                }
            </div>
        </>
    )
}