import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {close, open} from '../../actions/VehicleNotesModalAction';

export default function useVehicleNotesModal() {
    const dispatch = useDispatch()

    const openVehicleNotesModal = useCallback((auctionCode, workOrderNumber) => {
        dispatch(open(auctionCode, workOrderNumber))
    }, [])

    const closeVehicleNotesModal = useCallback(() => {
        dispatch(close())
    }, [])

    return {
        openVehicleNotesModal,
        closeVehicleNotesModal
    }
}