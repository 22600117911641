import ErrorComponent from "./ErrorComponent"
import { useTranslation } from "react-i18next"

export function ForbiddenComponent() {
    const { t } = useTranslation()

    const backToHome = () => {
        window.location.href = process.env.REACT_APP_WORKBENCH_APPLICATION
    }

    return (
        <ErrorComponent statusCode="403" label={t('LABEL.COMMON.FORBIDDEN')} onClick={backToHome}>
            <div className="error-content">{t('LABEL.COMMON.NOT_PERMISSION')}</div>
        </ErrorComponent>
    )
}