import React from 'react'
import { Button } from '@prism/library'

import { t } from 'i18next'

export function AdvanceFilterButton({ margin, handleOnClick, disabled = false}) {
    return (
        <>
            <Button
                disabled={disabled}
                className={`btn btn-outline ${margin}`}
                mauto="maButtonAdvanceFilter"
                onClick={() => {
                    handleOnClick()
                }}>
                <i className="icon prism-icon__demo icon prism-icon-filter ml-1 mx-1"></i>
                {t('LABEL.REMAINING_INVENTORY.ADVANCE_FILTER')}
            </Button>
        </>
    )
}
