import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'
import {Button, Col, Container, Row} from '@prism/library'
import {
    getSaleOverviews
} from '../../utils/data-source/sale-datasource'
import { NoDataFound } from '../common/data-list-status/NoDataFound'
import { SalePicker } from '../common/sale-picker/SalePicker'
import { ResultTable } from './ResultTable'
import SaleOverviewMessages from "./SaleOverviewMessages";
import './sale-overview.scss'

export function SaleOverview({
    auctionCodeFromState,
    saleYearFromState,
    saleNumberFromState,
    auctionCodeFromCookie,
    saleYears,
    auctionConnectionError,
    auctionEmptyError,
    updateSaleYearToState,
    updateSaleNumberToState,
    isAuctionCodeChange,
    setIsAuctionCodeChange
}) {
    const { t } = useTranslation()
    const [saleOverviews, setSaleOverviews] = useState([])
    const [loadSaleOverviewsStatus, setLoadSaleOverviewsStatus] = useState({
        loading: true,
        loaded: false
    })

    const [activePageNumber, setActivePageNumber] = useState()
    const [totalSaleOverviewRecord, setTotalSaleOverviewRecord] = useState()
    const [errorNotConfigSaleNumber, setErrorNotConfigSaleNumber] = useState(null)

    const auctionCode = useMemo(
        () => auctionCodeFromState || auctionCodeFromCookie,
        [auctionCodeFromState, auctionCodeFromCookie]
    )

    const updateSaleOverviews = useCallback(
        (auctionCode, saleYear, saleNumber, pageNumber, pageSize) => {
            setLoadSaleOverviewsStatus({
                loading: true,
                loaded: false
            })
            return getSaleOverviews(
                auctionCode,
                saleYear,
                saleNumber,
                pageNumber,
                pageSize
            )
                .then((response) => {
                    setSaleOverviews(response.data?.saleOverviews)
                    setTotalSaleOverviewRecord(
                        response.data?.pagination?.totalRecord
                    )
                    if (
                        !isNil(response.data) &&
                        !isEmpty(response.data) &&
                        !isNil(response.data.saleOverviews) &&
                        !isEmpty(response.data.saleOverviews)
                    ) {
                        setErrorNotConfigSaleNumber(null)
                    } else {
                        setErrorNotConfigSaleNumber(
                            t('MESSAGE.COMMON.NEXT_AVAILABLE_ERROR_MESSAGE', {
                                auctionCode: auctionCode,
                                saleNumber: saleNumber
                            })
                        )
                    }
                })
                .catch((ex) => {})
                .finally(() => {
                    setLoadSaleOverviewsStatus({
                        loading: false,
                        loaded: true
                    })
                })
        },
        []
    )

    const cleanSaleOverviews = useCallback(() => {
        setSaleOverviews([])
        setLoadSaleOverviewsStatus({
            loading: false,
            loaded: false
        })
        setTotalSaleOverviewRecord(0)
    }, [])

    const resetSaleOverviewsPagination = useCallback(() => {
        setActivePageNumber(null)
    }, [])

    useEffect(() => {
        if (!saleYears || saleYears.length === 0) {
            cleanSaleOverviews()
        }
    }, [saleYears])

    useEffect(() => {
        if (!isNil(auctionCode) || isEmpty(auctionCode)) {
            cleanSaleOverviews()
        }
    }, [auctionCode])
    useEffect(() => {
        if (isAuctionCodeChange) {
            setIsAuctionCodeChange(!isAuctionCodeChange)
        }
    }, [])
    return (
        <div className='sale-overview-wrapper'>
            <SalePicker
                auctionCodeFromState={auctionCodeFromState}
                auctionCodeFromCookie={auctionCodeFromCookie}
                saleYears={saleYears}
                updateSaleYearToState={updateSaleYearToState}
                updateSaleNumberToState={updateSaleNumberToState}
                updateSaleOverviews={updateSaleOverviews}
                cleanSaleOverviews={cleanSaleOverviews}
                resetSaleOverviewsPagination={resetSaleOverviewsPagination}
                isAuctionCodeChange={isAuctionCodeChange}
            />

            {/* Sale overview messages */}
            <SaleOverviewMessages
                auctionCode={auctionCode}
                errorNotConfigSaleNumber={errorNotConfigSaleNumber}
                auctionConnectionError={auctionConnectionError}
            />

            <Container fluid className="mt-lg-1 sale-overview">
                <Row className="p-0">
                    <Col md={12} className="px-0">
                        {!!saleOverviews && !isEmpty(saleOverviews) && (
                            <ResultTable
                                saleOverviews={saleOverviews}
                                activePageNumber={activePageNumber}
                                totalSaleOverviewRecord={
                                    totalSaleOverviewRecord
                                }
                                auctionCodeFromState={auctionCodeFromState}
                                auctionCodeFromCookie={auctionCodeFromCookie}
                                saleYearFromState={saleYearFromState}
                                saleNumberFromState={saleNumberFromState}
                                updateSaleOverviews={updateSaleOverviews}
                                updateActivePageNumber={(pageNumber) => {
                                    setActivePageNumber(pageNumber)
                                }}
                            />
                        )}
                        {(!saleOverviews || isEmpty(saleOverviews)) &&
                            !!loadSaleOverviewsStatus.loaded && !errorNotConfigSaleNumber && <NoDataFound />}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
