import {useEffect, useRef, useState} from 'react';
import {EMPTY_STRING, QUICK_SEARCH} from '../../utils/constant';
import {isEmpty} from 'lodash';
import {useSelector} from 'react-redux';

export default function useQuickSearch({
    leftList,
    rightList,
    columns = []
}) {
    const quickSearchContext = useSelector(states => states.inventoryQuickSearchContext.quickSearchContext)
    const currentQuickSearchValue = useRef('');
    const [quickSearchResult, setQuickSearchResult] = useState([])
    const [scrollToLeftItem, setScrollToLeftItem] = useState(QUICK_SEARCH.CLEAR_QUICK_SEARCH_INDEX)
    const [scrollToRightItem, setScrollToRightItem] = useState(QUICK_SEARCH.CLEAR_QUICK_SEARCH_INDEX)
    const currentSearchIndex = useRef(0)

    useEffect(() => {
        if (currentQuickSearchValue.current !== quickSearchContext.quickSearchValue) {
            CollectQuickSearchResult()
        }

        // Trigger find next index
        setQuickSearchResult((prev) => [...prev])
    }, [quickSearchContext.id]);

    useEffect(() => {
        if (currentQuickSearchValue.current === quickSearchContext.quickSearchValue) {
            findNextIndex()
        }
    }, [quickSearchResult]);

    function CollectQuickSearchResult() {
        if (isEmpty(quickSearchContext.quickSearchValue)) {
            currentQuickSearchValue.current = EMPTY_STRING
            setQuickSearchResult((prev) => [])
            return
        }

        currentSearchIndex.current = 0
        currentQuickSearchValue.current = quickSearchContext.quickSearchValue;
        setQuickSearchResult((prev) => [])
        search(false, rightList, quickSearchContext.quickSearchValue)
        search(true, leftList, quickSearchContext.quickSearchValue)
    }

    function findNextIndex() {
        if (isEmpty(quickSearchResult)) {
            setScrollToRightItem(QUICK_SEARCH.CLEAR_QUICK_SEARCH_INDEX)
            setScrollToLeftItem(QUICK_SEARCH.CLEAR_QUICK_SEARCH_INDEX)
            return
        }

        // Right search first then left search
        const searchResult = quickSearchResult[currentSearchIndex.current]
        if (searchResult.left) {
            setScrollToLeftItem(searchResult.index)
            setScrollToRightItem(QUICK_SEARCH.CLEAR_QUICK_SEARCH_INDEX)
        } else {
            setScrollToRightItem(searchResult.index)
            setScrollToLeftItem(QUICK_SEARCH.CLEAR_QUICK_SEARCH_INDEX)
        }

        currentSearchIndex.current++
        if (currentSearchIndex.current === quickSearchResult.length) {
            currentSearchIndex.current = 0
        }
    }

    const search = (left, vehicles, textSearch) => {
        setQuickSearchResult((prev) => {
            let newQuickSearchResult = [...prev]
            vehicles.forEach((item, index) => {
                const anyMatch = columns.some((column) => item[column]
                    && item[column].toString().toUpperCase().includes(textSearch.toUpperCase()))
                if (anyMatch === true) {
                    newQuickSearchResult = [...newQuickSearchResult, {left: left, index: index}]
                }
            })

            return newQuickSearchResult
        })
    }

    return {
        scrollToLeftItem,
        scrollToRightItem,
    }
}