import React, { useEffect } from 'react'
import { useLogout } from './WorkbenchHeaderFooterDataSources'
import {APP_CONSTANT, PathUrlConstant} from '../../utils/constant'
import { useSelector } from "react-redux"
import { isProduction } from '../../utils/utils'

function joinArray(array) {
    return (array || []).join(',')
}

export function InitWorkbenchHeaderFooter() {
    const userInfo = useSelector(states => states.session.userInfo)
    const logout = useLogout()

    useEffect(() => {
        if (window.EmployeeWorkbench) {
            window.EmployeeWorkbench.injectHeader(
                //Do not config useInactivityTimeout attribute, if not the logout function will be broken
                window.EmployeeWorkbench.buildContext()
                    .addRequiredValues(
                        'workbench-header-injection',
                        userInfo.firstName,
                        userInfo.lastName,
                        userInfo.email,
                        joinArray(userInfo.employeeWorkbenchApplications),
                        isProduction() ? APP_CONSTANT.APP_CODE.toUpperCase() : APP_CONSTANT.APP_CODE.toUpperCase()+"NONPROD",
                        userInfo.actualEmployeeHomeAuctionCode || '',
                        joinArray(userInfo.employeeAdditionalLocations),
                        'workbench-footer-injection',
                        userInfo.login,
                        userInfo.role,
                        '',
                        userInfo.signInDateTime
                    )
                    .addPreLogoutCallback(() => logout())
                    .addAnalyticsAttributes('SSTPlusPage', 'SSTPlusPage', userInfo.login)
                    .disableAnalytics(true)
                    .addHeaderHelpIcon(true, PathUrlConstant.TrainingDocs, PathUrlConstant.ReleaseNotes, true)
                    .getContext()
            )
        }
    }, [userInfo, logout])
    return <></>
}
