const initialState = {
    preRegistVehicleData: null
}

export const preRegistReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_VEHICLE_AFTER_UPDATE_PRE_REGIST":
            return{
                ...state,
                preRegistVehicleData: action.preRegistVehicleData
            }
        default:
            return state;
    }
}