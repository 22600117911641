import React, {useEffect, useMemo, useState, useCallback, useRef} from 'react'
import { useTranslation } from 'react-i18next'
import {isEmpty, isEqual, isNil} from 'lodash'
import { Col, Container, Row } from '@prism/library'
import { SellerSearchTypeConstant } from '../../utils/constant'
import { handleErrorException } from '../../utils/utils'
import {
    getCurrentSale,
} from '../../utils/data-source/sale-datasource'
import { getRemainingInventory, filterRemainingInventory } from '../../utils/data-source/remaining-inventory-datasource'
import { NoDataFound } from '../common/data-list-status/NoDataFound'
import { ShowInventoryPicker } from '../common/inventory-picker/ShowInventoryPicker'
import { AdvancedFilters } from '../common/inventory-picker/AdvancedFilters'
import { ResultTable } from './ResultTable'
import { useDispatch, useSelector } from "react-redux";
import { updateVehicleSuccessfulMessage } from "../../actions/RemainingInventoryAction";
import RemainingInventoryMessages from "./RemainingInventoryMessages";
import './remaining-inventory-list.scss'
import useVehiclesCache from '../../hooks/remaining-inventory/UseVehiclesCache';

export function RemainingInventoryList({
    auctionCodeFromState,
    auctionCodeFromCookie,
    auctionConnectionError,
    isAuctionCodeChange,
    setIsAuctionCodeChange
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const auctionCode = useMemo(
        () => auctionCodeFromState || auctionCodeFromCookie,
        [auctionCodeFromState, auctionCodeFromCookie]
    )
    const [remainingInventory, setRemainingInventory] = useState([])

    const [loadRemainingInventoryStatus, setLoadRemainingInventoryStatus] =
        useState({
            loading: true,
            loaded: false
        })

    const [loadVehiclesError, setLoadVehiclesError] = useState(null)

    const [isProcessError, setIsProcessError] = useState(null)

    const [submitCount, setSubmitCount] = useState(0)

    const [sellerSearchType, setSellerSearchType] = useState(
        SellerSearchTypeConstant.SELLER_TYPE
    )

    const [sellerType, setSellerType] = useState(
        SellerSearchTypeConstant.ALL_DEALDER
    )

    const [currentSaleYear, setCurrentSaleYear] = useState()
    const [currentSaleNumber, setCurrentSaleNumber] = useState()

    const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false)
    const [isAdvancedFilters, setIsAdvancedFilters] = useState(false)
    const [isOnchangeAdvanceFilter, setIsOnchangeAdvanceFilter] = useState(false)
    const [isEnableFilterByGroup, setIsEnableFilterByGroup] = useState(true)
    const [searchKeyGroupCode, setSearchKeyGroupCode] = useState(SellerSearchTypeConstant.ALL_DEALDER)
    const vehiclesCache = useVehiclesCache();

    const initFilterValue = {
        noSale: false,
        ifSale: false,
        title: null,
        greaterLessSelect: "greater_than",
        gtLtNumberSelect: null,
        olderNewerSelect: "older_than",
        numberOfDaysSelect: null,
        withDisp: null,
        withOutDisp: null,
        withBusinessUnits: null,
        withOutBusinessUnits: null
    }
    const [filter, setFilter] = useState(initFilterValue)

    const [filterObject, setFilterObject] = useState(null)

    const cleanAllErrors = useCallback(() => {
        setIsProcessError(null)
        setLoadVehiclesError(null)
    }, [])

    const { auctionCodeChange } = useSelector(state => state.resetStateObservingContext.menu)

    // Store list of messages
    const [messageUpdateVehicleSuccessful, setMessageUpdateVehicleSuccessful] = useState([])

    const cleanRemainingInventory = () => {
        setRemainingInventory([])
        window.clearCachedVehicles()
        setLoadRemainingInventoryStatus({
            loading: false,
            loaded: false
        })
        setIsProcessError(null)
        dispatch(updateVehicleSuccessfulMessage(null))
    }

    useMemo(() => {
        getCurrentSale(auctionCode)
            .then((response) => {
                const { data } = response
                setCurrentSaleYear(data.saleYear)
                setCurrentSaleNumber(data.saleNumber)
            })
            .catch((ex) => {
                setIsProcessError(handleErrorException(ex))
            })
    }, [])

    function getRemainingInventoryWithPagination() {
        cleanAllErrors()
        // invalid auction code
        if (auctionConnectionError?.isValidAuction === false) {
            return
        }

        if (!isAdvancedFilters) {
            if (
                sellerSearchType !== SellerSearchTypeConstant.ALL_DEALDER &&
                sellerSearchType !== SellerSearchTypeConstant.ALL_COMMERCIAL &&
                isEmpty(sellerType)
            ) {
                return
            }

            setLoadRemainingInventoryStatus({
                loading: true,
                loaded: false
            })

            cleanRemainingInventory()
            getRemainingInventory(
                auctionCode,
                sellerSearchType,
                sellerType
            )
                .then((response) => {
                    setRemainingInventory(response.data)
                    setIsProcessError(null)
                })
                .catch((ex) => {
                    setIsProcessError(handleErrorException(ex))
                })
                .finally(() => {
                    setLoadRemainingInventoryStatus({
                        loading: false,
                        loaded: true
                    })
                    setSearchKeyGroupCode(sellerType)
                })
        } else {
            setIsAdvancedFiltersOpen(false)
            cleanRemainingInventory()

            if (!isNil(filterObject)) {
                const filter = buildFilterParams()
                filterRemainingInventory(filter)
                    .then((response) => {
                        if (isAuctionCodeChange) {
                            setIsAuctionCodeChange(false)
                        }
                        setRemainingInventory(response.data)
                        setIsProcessError(false)
                    })
                    .catch((ex) => {
                        setIsProcessError(handleErrorException(ex))
                    })
                    .finally(() => {
                        setLoadRemainingInventoryStatus({
                            loading: false,
                            loaded: true
                        })
                    })
            }
        }
    }

    useEffect(() => {
        getRemainingInventoryWithPagination();
    }, [sellerSearchType, sellerType, submitCount, filterObject])

    useEffect(() => {
        dispatch(updateVehicleSuccessfulMessage(null))
        if (sellerType === SellerSearchTypeConstant.ALL_DEALDER && isAuctionCodeChange) {
            getRemainingInventoryWithPagination()
        }
    }, [auctionCodeChange]);

    useEffect(() => {
        if (isAuctionCodeChange) {
            cleanAllErrors()
            cleanRemainingInventory()
            setIsAuctionCodeChange(false)
            getCurrentSale(auctionCode)
            .then((response) => {
                const { data } = response
                setCurrentSaleYear(data.saleYear)
                setCurrentSaleNumber(data.saleNumber)
            })
            .catch((ex) => {
                setIsProcessError(handleErrorException(ex))
            })
        }
    }, [isAuctionCodeChange])


    function handleShowInventoryGroup(sellerSearchType, sellerType) {
        setSellerSearchType(sellerSearchType)
        setSellerType(sellerType)
        setIsAdvancedFilters(false)
        cleanRemainingInventory()
        setIsAdvancedFiltersOpen(false)
    }

    function clearAdvanceFilter(){
        setIsAdvancedFilters(false)
        setFilter(initFilterValue)
        setFilterObject(initFilterValue)
        setIsOnchangeAdvanceFilter(false)
        cleanRemainingInventory()
        dispatch(updateVehicleSuccessfulMessage(null))
    }


    function buildFilterParams() {
        if (!isNil(filterObject)) {
            return {
                ...(filterObject.noSale && {noSale: filterObject.noSale}),
                ...(!!filterObject.ifSale && {ifSale: filterObject.ifSale}),
                ...(!!filterObject.title && {title: filterObject.title}),
                ...(!!filterObject.gtLtNumberSelect && {gtLtNumberSelect: filterObject.gtLtNumberSelect}),
                ...(!!filterObject.numberOfDaysSelect && {numberOfDaysSelect: filterObject.numberOfDaysSelect}),
                ...(!!filterObject.withDisp && {withDisp: filterObject.withDisp}),
                ...(!!filterObject.withOutDisp && {withOutDisp: filterObject.withOutDisp}),
                ...(!!filterObject.withBusinessUnits && {withBusinessUnits: filterObject.withBusinessUnits}),
                ...(!!filterObject.withOutBusinessUnits && {withOutBusinessUnits: filterObject.withOutBusinessUnits}),
                greaterLessSelect: filterObject.greaterLessSelect,
                olderNewerSelect: filterObject.olderNewerSelect,
                auctionCode: auctionCode,
                sellerSearchType: sellerSearchType,
                sellerType: searchKeyGroupCode,
            }
        }
    }

    function buildFiltersBaseOnLastAction() {
        if (isAdvancedFilters && !isNil(filterObject)) {
            return {
                isAdvancedFilters: isAdvancedFilters,
                ...buildFilterParams()
            }
        }
        return {}
    }

    //#region handle cache vehicles
    window.cacheVehicles = function(key, vehicles) {
        vehiclesCache.put(key, vehicles)
    }

    window.getCachedVehicles = function(key) {
        return vehiclesCache.get(key)
    }

    window.clearCachedVehicles = function() {
        return vehiclesCache.clear()
    }
    //#endregion

    return (
        <>
            <div className='remaining-inventory-list-wrapper'>
            <AdvancedFilters
                isAdvancedFiltersOpen={isAdvancedFiltersOpen}
                setIsAdvancedFilters={setIsAdvancedFilters}
                setIsAdvancedFiltersOpen={setIsAdvancedFiltersOpen}
                setFilterObject={(value) => setFilterObject(value)}
                filter={filter}
                setFilter={setFilter}
                increaseSubmitCount={() => {
                    setSubmitCount(submitCount + 1)
                }}
                isOnchangeAdvanceFilter={isOnchangeAdvanceFilter}
                setIsOnchangeAdvanceFilter={setIsOnchangeAdvanceFilter}
                isEnableFilterByGroup={isEnableFilterByGroup}
                clearAdvanceFilter={clearAdvanceFilter}
            />
            <ShowInventoryPicker
                updateInventoryGroup={handleShowInventoryGroup}
                cleanRemainingInventory={cleanRemainingInventory}
                increaseSubmitCount={() => {
                    setSubmitCount(submitCount + 1)
                }}
                setIsAdvancedFiltersOpen={()=>{setIsAdvancedFiltersOpen(true)}}
                setIsEnableFilterByGroup={setIsEnableFilterByGroup}
                setSearchKeyGroupCode={setSearchKeyGroupCode}
                isAuctionCodeChange={isAuctionCodeChange}
                advancedFiltersDisabled={auctionConnectionError?.isValidAuction !== true}
                showByInventoryDisabled={auctionConnectionError?.isValidAuction !== true}
            />

            {/* Remaining inventory messages */}
            <RemainingInventoryMessages
                auctionConnectionError={auctionConnectionError}
                isProcessError={isProcessError}
                auctionCode={auctionCode}
                loadVehiclesError={loadVehiclesError}
            />

            <Container fluid className="mt-2">
                <Row>
                    <Col md={12}>
                        {!!remainingInventory &&
                            !!remainingInventory.remainingInventoryDTOs &&
                            !isEmpty(
                                remainingInventory.remainingInventoryDTOs
                            ) && (
                                <ResultTable
                                    auctionCode={auctionCode}
                                    remainingInventory={remainingInventory}
                                    submitCount={submitCount}
                                    setLoadVehiclesError={(value) => { setLoadVehiclesError(value) }}
                                    setMessageUpdateVehicleSuccessful={setMessageUpdateVehicleSuccessful}
                                    getAdvancedFilterParams={buildFiltersBaseOnLastAction()}
                                    isAuctionCodeChange={isAuctionCodeChange}
                                    setIsAuctionCodeChange={setIsAuctionCodeChange}
                                />
                            )}
                        {(!remainingInventory ||
                            !remainingInventory.remainingInventoryDTOs ||
                            isEmpty(
                                remainingInventory.remainingInventoryDTOs
                            ))
                            && !!loadRemainingInventoryStatus.loaded
                            && !isProcessError
                            && auctionConnectionError?.isValidAuction === true && (
                                <NoDataFound />
                            )}
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    )
}
