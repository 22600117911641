import axios from 'axios'

axios.defaults.withCredentials = true

function isOktaRedirect(response) {
    return (
        response?.headers?.location &&
        response.headers.location.includes('/oauth2/authorization/okta')
    )
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT + '/api',
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
})

axiosInstance.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        if (error.response?.status === 401 && isOktaRedirect(error.response)) {
            window.location.href = error.response.headers.location
        }
        return Promise.reject(error)
    }
)

export default axiosInstance
