import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { Button, Col, Container, Row, Space } from '@prism/library'
import { isEmpty, isNil, includes } from 'lodash'

import { ConstraintConstant } from '../../../utils/constant'
import {
    getCurrentSale,
    getSaleNumbersBySaleYearAndAuctionCode
} from '../../../utils/data-source/sale-datasource'
import { Dropdown } from '../custom/prism/dropdown/Dropdown'

import './sale-picker.scss'

export function SalePicker({
    auctionCodeFromState,
    auctionCodeFromCookie,
    saleYears,
    updateSaleYearToState,
    updateSaleNumberToState,
    updateSaleOverviews,
    cleanSaleOverviews,
    resetSaleOverviewsPagination,
    isAuctionCodeChange
}) {
    const { t } = useTranslation()
    const buildDropdownSelectionData = useCallback((data, disable) => {
        const name = data
        const value = data === t('LABEL.SALE_PICKER.SELECT') ? null : data
        return {
            name: name,
            value: value,
            disable: disable,
            key: uuid()
        }
    }, [])

    const buildDropdownOptionsData = useCallback((data) => {
        const defaultDropdownOption = {
            name: t('LABEL.SALE_PICKER.SELECT'),
            value: null,
            disable: false,
            key: uuid()
        }
        let optionsData = [defaultDropdownOption]
        if (isNil(data) || isEmpty(data)) {
            return optionsData
        }
        data.forEach((item) => {
            let optionData = {
                name: item,
                value: item,
                disable: false,
                key: uuid()
            }
            optionsData.push(optionData)
        })
        return optionsData
    }, [])

    const auctionCode = useMemo(
        () => auctionCodeFromState || auctionCodeFromCookie,
        [auctionCodeFromState, auctionCodeFromCookie]
    )

    const [saleYearOptions, setSaleYears] = useState(
        buildDropdownOptionsData([])
    )
    const [saleNumberOptions, setSaleNumbers] = useState(
        buildDropdownOptionsData([])
    )

    const [selectedSaleYear, setSelectedSaleYear] = useState(
        buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), false)
    )
    const [selectedSaleNumber, setSelectedSaleNumber] = useState(
        buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), false)
    )

    const [isLoadingSaleNumber, setIsLoadingSaleNumber] = useState(false)

    const [isLoadingSaleYear, setIsLoadingSaleYear] = useState(false)

    useEffect(() => {
        if (auctionCode) {
            if (isNil(saleYears) || isEmpty(saleYears)) {
                setSaleYears(buildDropdownOptionsData([]))
                setSelectedSaleYear(
                    buildDropdownSelectionData(
                        t('LABEL.SALE_PICKER.SELECT'),
                        true
                    )
                )
                updateSaleYearToState(null)
                setSaleNumbers(buildDropdownOptionsData([]))
                setSelectedSaleNumber(
                    buildDropdownSelectionData(
                        t('LABEL.SALE_PICKER.SELECT'),
                        true
                    )
                )
                updateSaleNumberToState(null)
            } else {
                setSaleYears(buildDropdownOptionsData(saleYears))
                if(isAuctionCodeChange){
                    setSelectedSaleYear(
                        buildDropdownSelectionData(
                            t('LABEL.SALE_PICKER.SELECT'),
                            false
                        )
                    )
                  
                    setSaleNumbers(buildDropdownOptionsData([]))
                    setSelectedSaleNumber(
                        buildDropdownSelectionData(
                            t('LABEL.SALE_PICKER.SELECT'),
                            true
                        )
                    )
                    cleanSaleOverviews()
                    resetSaleOverviewsPagination()
                }else if (auctionCode) {setSelectedSaleYear(
                    buildDropdownSelectionData(
                        t('LABEL.SALE_PICKER.SELECT'),
                        true
                    )
                )
                updateSaleYearToState(null)
                setSaleNumbers(buildDropdownOptionsData([]))
                setSelectedSaleNumber(
                    buildDropdownSelectionData(
                        t('LABEL.SALE_PICKER.SELECT'),
                        true
                    )
                )
                updateSaleNumberToState(null)
                getCurrentSale(auctionCode)
                    .then((response) => {
                        const { data } = response
                        let currentSaleYear = data.saleYear
                        let currentSaleNumber = data.saleNumber
                        getSaleNumbersBySaleYearAndAuctionCode(
                            currentSaleYear,
                            auctionCodeFromCookie
                        ).then((response) => {
                            const { data } = response
                            if (!includes(data, currentSaleNumber)) {
                                for (let i = 0; i < data.length; i++) {
                                    let value = data[i]
                                    if (currentSaleNumber <= value) {
                                        currentSaleNumber = value
                                        break
                                    }
                                }
                            }
                            setSelectedSaleYear(
                                buildDropdownSelectionData(
                                    currentSaleYear,
                                    false
                                )
                            )

                            updateSaleYearToState(currentSaleYear)
                            if (!isEmpty(data) && !isNil(data)) {
                                setSaleNumbers(
                                    buildDropdownOptionsData(data)
                                )
                                setSelectedSaleNumber(
                                    buildDropdownSelectionData(
                                        currentSaleNumber,
                                        false
                                    )
                                )
                                updateSaleNumberToState(currentSaleNumber)

                                updateSaleOverviews(
                                    auctionCode,
                                    currentSaleYear,
                                    currentSaleNumber,
                                    1,
                                    ConstraintConstant.PAGE_SIZE
                                )
                            }
                        })
                    })
                    .catch(() => {})
            }
            }
        } else {
            setSaleYears(buildDropdownOptionsData([]))
            setSelectedSaleYear(
                buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), true)
            )
            setSaleNumbers(buildDropdownOptionsData([]))
            setSelectedSaleNumber(
                buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), true)
            )
        }
    }, [auctionCode, saleYears, isAuctionCodeChange])


    function changeSaleYear(selectedData) {
        if (selectedData.value === selectedSaleYear.value) {
            return
        }
        if (!!selectedData.value) {
            setIsLoadingSaleYear(true)
        }
        setIsLoadingSaleNumber(true)
        setSelectedSaleYear(selectedData)
        updateSaleYearToState(selectedData.value)
        if (!!selectedData.value) {
            getSaleNumbersBySaleYearAndAuctionCode(
                selectedData.name,
                auctionCode
            )
                .then((response) => {
                    const { data } = response
                    if (isNil(data) || isEmpty(data)) {
                        setSaleNumbers(buildDropdownOptionsData([]))
                        setSelectedSaleNumber(
                            buildDropdownSelectionData(
                                t('LABEL.SALE_PICKER.SELECT'),
                                true
                            )
                        )
                        updateSaleNumberToState(null)
                    } else {
                        setSaleNumbers(buildDropdownOptionsData(data))
                        setSelectedSaleNumber(
                            buildDropdownSelectionData(
                                t('LABEL.SALE_PICKER.SELECT'),
                                false
                            )
                        )
                        updateSaleNumberToState(null)
                    }
                })
                .catch(() => {})
                .finally(() => {
                    setIsLoadingSaleNumber(false)
                    setIsLoadingSaleYear(false)
                })
        } else {
            setSaleNumbers(buildDropdownOptionsData([]))
            setSelectedSaleNumber(
                buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), true)
            )
            setIsLoadingSaleNumber(false)
            setIsLoadingSaleYear(false)
        }
        cleanSaleOverviews()
        resetSaleOverviewsPagination()
    }

    function changeSaleNumber(selectedData) {
        setSelectedSaleNumber(selectedData)
        updateSaleNumberToState(selectedData.value)
        if (!!selectedData.value) {
            updateSaleOverviews(
                auctionCode,
                selectedSaleYear.value,
                selectedData.value,
                1,
                ConstraintConstant.PAGE_SIZE
            ).then(() => {
                resetSaleOverviewsPagination()
            })
        } else {
            cleanSaleOverviews()
            resetSaleOverviewsPagination()
        }
    }

    // Make sure selected option is one from dropdown's options
    function findSelectedOption(options, selectedOption) {
        if (!options || options.length <= 1) {
            return selectedOption
        }

        return options.find(option => option.value === selectedOption.value)
    }

    return (
        <Container fluid className="sale-picker-container py-1 mb-1">
            <Row>
                <Col md={6}>
                    <Space>
                        <Space size={2} className="sale-picker-group ml-2">
                            <span
                                mauto="maLabelSaleYear"
                                className="sale-overview-header-label">
                                {t('LABEL.SALE_PICKER.SALE_YEAR')}
                            </span>
                            <Dropdown
                                mauto="maDropdownSaleYear"
                                options={saleYearOptions}
                                onChange={changeSaleYear}
                                selectedOption={findSelectedOption(saleYearOptions, selectedSaleYear)}
                                disabled={isLoadingSaleYear}
                            />
                        </Space>
                        <Space size={2} className="sale-picker-group ml-2">
                            <span
                                mauto="maLabelSaleNumber"
                                className="sale-overview-header-label">
                                {t('LABEL.SALE_PICKER.SALE_NUMBER')}
                            </span>
                            <Dropdown
                                mauto="maDropdownSaleNumber"
                                options={saleNumberOptions}
                                onChange={changeSaleNumber}
                                selectedOption={findSelectedOption(saleNumberOptions, selectedSaleNumber)}
                                disabled={isLoadingSaleNumber}
                            />
                        </Space>
                    </Space>
                </Col>
            </Row>
        </Container>
    )
}
