import DefaultModal from '../common/modal/DefaultModal';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ClearMappedLaneConfirmModal({
    setIsClearConfirmOpen,
    isClearConfirmOpen,
    handleClearMappedLane
}) {
    const { t } = useTranslation();
    return (
        <DefaultModal data-test-id="clear-mapped-lane-modal"
            header={t('LABEL.LANE_MANAGEMENT.CLEAR_CONFIRM_DIALOG.TITLE')}
            message={t('LABEL.LANE_MANAGEMENT.CLEAR_CONFIRM_DIALOG.MESSAGE')}
            onYesClick={handleClearMappedLane}
            onNoClick={() => setIsClearConfirmOpen(false)}
            isOpen={isClearConfirmOpen}
        />
    )
}