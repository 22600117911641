import React, { useCallback, useEffect, useState } from 'react'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import { includes, isNil } from 'lodash'
import { APP_CONSTANT, PathUrlConstant } from './utils/constant'
import {
    deleteAuctionCodeFromCookie,
    getAuctionCodeFromCookie,
    setAuctionCodeToCookie
} from './utils/data-source/auction-code-datasource'
import { getSaleYears } from './utils/data-source/sale-datasource'
import { MenuBar } from './component/common/menu-bar/MenuBar'
import { SaleOverview } from './component/sale-overview/SaleOverview'
import { LaneManagement } from './component/lane-management/LaneManagement'
import { AssignInventory } from './component/assign-inventory/AssignInventory'
import { RequestHistory } from './component/request-history/RequestHistory'
import { RemainingInventoryList } from './component/remaining-inventory/RemainingInventoryList'
import { useUserContext } from './utils/data-source/context/userContext'
import { handleErrorException } from './utils/utils'
import './app.scss'
import { useDispatch, useSelector } from "react-redux";
import { ForbiddenComponent } from "./component/common/error/ForbiddenComponent"
import { notifyAuctionCodeChanged } from "./actions/ResetStateObservingAction";
import { WorkbenchHeaderFooter } from './component/header/WorkbenchHeaderFooter'
import DefaultModal from './component/common/modal/DefaultModal';
import ShareComponent from './component/common/share-component/ShareComponent';

function App() {
    const {isAuthorized} = useUserContext()
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const auctionCodeFromCookie = getAuctionCodeFromCookie()
    const [auctionCodeFromState, setAuctionCodeFromState] = useState(null)
    const [saleYearFromState, setSaleYearFromState] = useState(null)
    const [saleNumberFromState, setSaleNumberFromState] = useState(null)
    const [saleYears, setSaleYears] = useState([])

    const [auctionSubmitCount, setAuctionSubmitCount] = useState(0)
    const [auctionConnectionError, setAuctionConnectionError] = useState({})
    const [auctionEmptyError, setAuctionEmptyError] = useState()
    const [isAuctionCodeChange, setIsAuctionCodeChange] = useState(null)

    const allowedApps = useSelector(states => states.session.allowedApps)
    const [isAllowAccess, setIsAllowAccess] = useState(false)

    const cleanAllErrors = useCallback(() => {
        setAuctionConnectionError({})
        setAuctionEmptyError(false)
    }, [])

    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [disableTab, setDisableTab] = useState(true)
    const tabs = [
        `/${PathUrlConstant.SaleOverview}`,
        `/${PathUrlConstant.LaneManagement}`,
        `/${PathUrlConstant.AssignInventory}`,
        `/${PathUrlConstant.RemainingInventory}`,
        `/${PathUrlConstant.RequestHistory}`
    ]
    const defaultPathWithSaleOverview = [`/${PathUrlConstant.SaleOverview}`, "*"]

    useEffect(() => {
        if (!!isAuthorized && !includes(allowedApps, APP_CONSTANT.APP_CODE)) {
            navigate(`/${PathUrlConstant.Forbidden}`)
        }
        const path = window.location.href.split("#")
        const currentTab = path.length > 1 ? path[1] : '';
        const tabIndex = tabs.findIndex(tab => {
            return currentTab.startsWith(tab)
        });
        if (tabIndex !== -1) {
            setActiveTabIndex(tabIndex)
        } else {
            setActiveTabIndex(0)
        }
    }, [window.location.href])

    useEffect(() => {
        const auctionCode = auctionCodeFromState || auctionCodeFromCookie
        const isAllowApp = includes(allowedApps, APP_CONSTANT.APP_CODE)
        setIsAllowAccess(isAllowApp)
        if (!!auctionCode && !!isAllowApp) {
            setDisableTab(false)
            getSaleYears(auctionCode)
                .then((response) => {
                    const saleYears = response.data?.saleYears
                    setSaleYears(saleYears)
                    setAuctionConnectionError({isValidAuction: true})
                })
                .catch((ex) => {
                    setSaleYears([])
                    setAuctionConnectionError(handleErrorException(ex))
                })
            setAuctionEmptyError(false)
        } else {
            if (auctionSubmitCount > 0) {
                setAuctionEmptyError(true)
            }
        }
    }, [auctionCodeFromState, auctionCodeFromCookie, auctionSubmitCount])

    function checkAuctionAndReturnSpecificRoute(component){
        return !!auctionCodeFromState || !!auctionCodeFromCookie ? component: <Navigate replace={true} to={'/'}/> ;
    }

    return (
        <>
            <WorkbenchHeaderFooter />
            {!!isAuthorized && isAllowAccess ? (
                <div>
                    <MenuBar
                        auctionCodeFromState={auctionCodeFromState}
                        auctionCodeFromCookie={auctionCodeFromCookie}
                        onChangeAuctionCode={(auctionCode) => {
                            setAuctionSubmitCount(auctionSubmitCount + 1)
                            setAuctionCodeFromState(auctionCode)
                            if (isNil(auctionCode)) {
                                deleteAuctionCodeFromCookie()
                            } else {
                                setAuctionCodeToCookie(auctionCode)
                            }
                            cleanAllErrors()
                            dispatch(notifyAuctionCodeChanged())
                        }}
                        isAuctionCodeChange={isAuctionCodeChange}
                        setIsAuctionCodeChange={setIsAuctionCodeChange}
                        activeTabIndex={activeTabIndex}
                        disableTab={disableTab}
                        saleYears={saleYears}
                        auctionConnectionError={auctionConnectionError}
                    />
                    <div>
                        <Routes>
                            {defaultPathWithSaleOverview.map((path) =>
                                    <Route
                                        path={path}
                                        element={
                                            checkAuctionAndReturnSpecificRoute(<SaleOverview
                                                auctionCodeFromState={auctionCodeFromState}
                                                saleYearFromState={saleYearFromState}
                                                saleNumberFromState={saleNumberFromState}
                                                auctionCodeFromCookie={auctionCodeFromCookie}
                                                saleYears={saleYears}
                                                auctionConnectionError={auctionConnectionError}
                                                auctionEmptyError={auctionEmptyError}
                                                updateSaleYearToState={(saleYear) => {
                                                    setSaleYearFromState(saleYear)
                                                }}
                                                updateSaleNumberToState={(saleNumber) => {
                                                    setSaleNumberFromState(
                                                        saleNumber
                                                    )
                                                }}
                                                isAuctionCodeChange={isAuctionCodeChange}
                                                setIsAuctionCodeChange={setIsAuctionCodeChange}
                                            />)
                                        }
                                    />
                                )
                            }

                            <Route
                                path={`/${PathUrlConstant.LaneManagement}`}
                                element={
                                    checkAuctionAndReturnSpecificRoute(<LaneManagement
                                        auctionCodeFromState={auctionCodeFromState}
                                        auctionCodeFromCookie={
                                            auctionCodeFromCookie
                                        }
                                        saleYears={saleYears}
                                    />)
                                }
                            />
                            <Route
                                path={`/${PathUrlConstant.AssignInventory}`}
                                element={
                                    checkAuctionAndReturnSpecificRoute(<AssignInventory
                                        auctionCodeFromState={auctionCodeFromState}
                                        auctionCodeFromCookie={
                                            auctionCodeFromCookie
                                        }
                                        saleYears={saleYears}
                                        auctionConnectionError={auctionConnectionError}
                                    />)
                            }
                            />
                            <Route
                                path={`/${PathUrlConstant.RequestHistory}`}
                                element={
                                    checkAuctionAndReturnSpecificRoute(<RequestHistory
                                        auctionCodeFromState={auctionCodeFromState}
                                        auctionCodeFromCookie={auctionCodeFromCookie}
                                    />)
                                }
                            />
                            <Route
                                path={`/${PathUrlConstant.RemainingInventory}`}
                                element={
                                    checkAuctionAndReturnSpecificRoute(<RemainingInventoryList
                                        auctionCodeFromState={auctionCodeFromState}
                                        auctionCodeFromCookie={auctionCodeFromCookie}
                                        auctionConnectionError={auctionConnectionError}
                                        isAuctionCodeChange={isAuctionCodeChange}
                                        setIsAuctionCodeChange={setIsAuctionCodeChange}
                                    />)
                                }
                            />
                            <Route
                                path={`/${PathUrlConstant.Forbidden}`}
                                element={<ForbiddenComponent/>}
                            />
                        </Routes>
                    </div>

                </div>
            ) : (
                <div>
                    <Routes>
                        <Route
                            path={`/${PathUrlConstant.Forbidden}`}
                            element={<ForbiddenComponent/>}
                        />
                    </Routes>
                </div>

            )}
            <ShareComponent/>
        </>
    )
}

export default App
