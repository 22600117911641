import { REMAINING_INVENTORY_ACTION_TYPE } from '../utils/constant';

export function updateVehicleSuccessfulMessage(data) {
    return {
        type: REMAINING_INVENTORY_ACTION_TYPE.MESSAGE_VEHICLE_UPDATED,
        messageVehicleUpdated: data
    }
}

export function pushUpdatedVehicle(vehicle) {
    return {
        type: REMAINING_INVENTORY_ACTION_TYPE.UPDATED_VEHICLE,
        updatedVehicle: vehicle
    }
}

export function pushGetVehicleError(error) {
    return {
        type: REMAINING_INVENTORY_ACTION_TYPE.GET_VEHICLE_ERROR,
        getVehicleError: error
    }
}
