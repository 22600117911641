import cookies from 'browser-cookies'

export const AUCTION_CODE_COOKIE_NAME = 'auction_code'
const ONE_YEAR = 365


//Need to set Expire Like Permanent
export function setAuctionCodeToCookie(auctionCode) {
    cookies.set(AUCTION_CODE_COOKIE_NAME, auctionCode, {expires: ONE_YEAR})
}

export function getAuctionCodeFromCookie() {
    return cookies.get(AUCTION_CODE_COOKIE_NAME) || null
}

export function deleteAuctionCodeFromCookie() {
    cookies.erase(AUCTION_CODE_COOKIE_NAME)
}
