import cookies from 'browser-cookies'
import { local } from '../../store/ngStorage'
import { APP_CONSTANT } from '../../utils/constant'
import { useLoadScript } from '../../utils/load-script-utils'

export function useLoadHeaderScript() {
    return useLoadScript(
        'csa-workbench-header-script',
        APP_CONSTANT.WORKBENCH_HEADER_SCRIPT
    )
}

export function useLoadFooterScript() {
    return useLoadScript(
        'csa-workbench-footer-script',
        APP_CONSTANT.WORKBENCH_FOOTER_SCRIPT
    )
}

export function useLogout() {
    const cookiesToBeDeleted = ['x-auth-token', 'auth_tkt']
    cookiesToBeDeleted.forEach((cookieName) => {
        cookies.erase(cookieName)
    })

    console.info('Ready to clear localStorageService')
    if (local.keys()) {
        local.keys().forEach((key) => {
            console.info('Removing key ' + key)
            local.remove(key)
        })
    }
}
