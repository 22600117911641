import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {concat, find, first, isEmpty, isEqual, isNil, toInteger, toString} from 'lodash'
import classNames from 'classnames'
import {Button, Col, Container, Form, Input, Row, Space} from '@prism/library'
import { Dropdown } from '../common/custom/prism/dropdown/Dropdown'
import { ConstraintConstant, EMPTY_STRING, SellerSearchTypeConstant } from '../../utils/constant'
import {
    getCurrentSale,
    getLaneNumbersBySaleYearAndSaleNumberAndAuctionCode,
    getSaleSummary,
    getSaleNumbersBySaleYearAndAuctionCode
} from '../../utils/data-source/sale-datasource'
import { searchInventoryVehicles } from '../../utils/data-source/assign-inventory-datasource'
import {
    handleErrorException,
    handleEventOnKeyDownForNumber,
    isBlank
} from '../../utils/utils'
import { useSelector, useDispatch } from 'react-redux'
import { vehicleAdvancedFilters } from './VehicleAdvancedFilterStrategies'
import { SearchSellerPanel } from '../common/vehicle-info/SearchSellerPanel'

import './assign-inventory-search-bar.scss'
import { getAsgIvtAdvancedFilter } from '../../actions/AsgIvtAdvancedFilterAction'
import {
    getAssignInventorySearchContext,
    getSaleYearLane,
    resetAssignInventorySearchContext,
    resetSaleYearLane
} from '../../actions/AssignInventoryAction'
import { resetLaneManagementSearchContext } from '../../actions/LaneManagementAction'
import { AuctionCodeErrorMessage } from '../common/error/AuctionCodeErrorNotificationMessage'
import { notifyInventorySearchChanged } from '../../actions/ResetStateObservingAction'
import {useLocation} from 'react-router-dom';
import {QUERY_PARAMS} from '../../utils/constant/AssignInventorySearchBarConstant';

export function AssignInventorySearchBar({ 
    auctionCodeFromState,
    auctionCodeFromCookie,
    saleYears,
    setDraftVehicles,
    setLaneInventory,
    inventory,
    setInventory,
    setRunRanges,
    setLaneNumber,
    setErrorMessage,
    setSearchError,
    setOnlyNumberError,
    setCharLimitType,
    setIsCharLimitError,
    setIsInvalidCodeError,
    setIsEmptySearch,
    setBadRequestError,
    setBadURI,
    setBadPayload,
    vehicles,
    setVehicles,
    handleSubmitCount,
    setLaneSummary,
    setIsGetPreRegistError,
    auctionConnectionError
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [touched, setTouched] = useState(false)
    const [initialized, setInitialized] = useState(true)
    const [shownSearchSellerPanel, setShownSearchSellerPanel] = useState(false)

    const [saleNumbers, setSaleNumbers] = useState([])
    const [laneNumbers, setLaneNumbers] = useState([])

    const [currentSaleYear, setCurrentSaleYear] = useState()
    const [currentSaleNumber, setCurrentSaleNumber] = useState()
    const [saleYearOptions, setSaleYearOptions] = useState([])
    const [saleNumberOptions, setSaleNumberOptions] = useState([])
    const [laneNumberOptions, setLaneNumberOptions] = useState([])
    const [showByOptions, setShowByOptions] = useState([])

    const [selectedSaleYearOption, setSelectedSaleYearOption] = useState({})
    const [selectedSaleNumberOption, setSelectedSaleNumberOption] = useState({})
    const [selectedLaneNumberOption, setSelectedLaneNumberOption] = useState({})
    const [selectedShowByOption, setSelectedShowByOption] = useState({})
    const [enteredShowByValue, setEnteredShowByValue] = useState(EMPTY_STRING)
    const [sellerSearchCustomers, setSellerSearchCustomers] = useState(null)
    const [sellerSearchData, setSellerSearchData] = useState(null)

    const [errorEmptyAuctionCode, setErrorEmptyAuctionCode] = useState(null)
    const [errorInvalidAuctionCode, setErrorInvalidAuctionCode] = useState(null)
    const [errorNotExistsAuctionCode, setErrorNotExistsAuctionCode] = useState(null)
    const [errorNotConfigSaleYear, setErrorNotConfigSaleYear] = useState(null)
    const [errorNotConfigSaleNumber, setErrorNotConfigSaleNumber] = useState(null)
    const location = useLocation()
    const queryParams =  new URLSearchParams(location.search)

    const generalMaxLength = 2
    const drivableFiltersMaxLength = 4
    const vehicleTypeFiltersMaxLength = 17

    const advancedFilter = useSelector(states => states.asgIvtAdvancedFilter.advancedFilter)

    let searchContext = useSelector(states => states.asgIvtSearchContext.searchContext)
    let laneManagementSearchData = useSelector(states => states.laneManagementSearchContext.laneManagementSearchData)

    const [disableSaleYear, setDisableSaleYear] = useState(false)
    const [isLinked, setIsLinked] = useState(false)

    useEffect(() => {
        if (initialized) {
            // Lane management query parameters
            const saleYearParam = queryParams.get(QUERY_PARAMS.SALE_YEAR);
            const saleNumberParam = queryParams.get(QUERY_PARAMS.SALE_NUMBER);
            const laneNumberParam = queryParams.get(QUERY_PARAMS.LANE_NUMBER);
            const sellerNumberParam = queryParams.get(QUERY_PARAMS.SELLER_NUMBER);
            if (saleYearParam && saleNumberParam && laneNumberParam && sellerNumberParam) {
                laneManagementSearchData.saleYear = toInteger(saleYearParam)
                laneManagementSearchData.saleNumber = toInteger(saleNumberParam)
                laneManagementSearchData.laneNumber = toInteger(laneNumberParam)
                laneManagementSearchData.sellerNumber = sellerNumberParam
            }
        }

        if (searchContext && searchContext.searchKeywords === null) {
            // Remaining inventory query parameters
            const searchKeywordsParam = queryParams.get(QUERY_PARAMS.SEARCH_KEYWORDS)
            if (searchKeywordsParam) {
                searchContext.searchKeywords = searchKeywordsParam
            }
        }
    }, [searchContext, laneManagementSearchData])

    // Reset when auction code changes
    useEffect(() => {
        setTouched(false)
        setInitialized(true)
        setShownSearchSellerPanel(false)

        setSaleNumbers([])
        setLaneNumbers([])

        setErrorEmptyAuctionCode(null)
        setErrorInvalidAuctionCode(null)
        setErrorNotExistsAuctionCode(null)
        setErrorNotConfigSaleYear(null)
        setErrorNotConfigSaleNumber(null)
        setDisableSaleYear(true)
    }, [auctionCodeFromState, auctionCodeFromState])

    const buildSaleYearDropdownOptions = (saleYears) => {
        const options = saleYears.map((saleYear) => {
            return {
                key: saleYear,
                value: saleYear,
                name: saleYear
            }
        })
        return concat({
            key: null,
            value: null,
            name: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.PLACEHOLDER.SELECT')
        }, options)
    }

    const buildSaleNumberDropdownOptions = (saleNumbers) => {
        const options = saleNumbers.map((saleNumber) => {
            return {
                key: saleNumber,
                value: saleNumber,
                name: saleNumber
            }
        })
        return concat({
            key: null,
            value: null,
            name: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.PLACEHOLDER.SELECT')
        }, options)
    }

    const buildLaneNumberDropdownOptions = (laneNumbers) => {
        const options = laneNumbers.map((laneNumber) => {
            return {
                key: laneNumber,
                value: laneNumber,
                name: laneNumber
            }
        })
        return concat({
            key: null,
            value: null,
            name: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.PLACEHOLDER.SELECT')
        }, options)
    }

    const buildShowByDropdownOptions = () => {
        return [
            {
                key: SellerSearchTypeConstant.SELLER_NUMBER,
                value: SellerSearchTypeConstant.SELLER_NUMBER,
                name: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.OPTION.SELLER_NUMBER'),
                placeholder: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.PLACEHOLDER.ENTER_SELLER_NUMBER'),
                maxLength: ConstraintConstant.SELLER_NUMBER_MAX_LENGTH
            },
            {
                key: SellerSearchTypeConstant.GROUP_CODE,
                value: SellerSearchTypeConstant.GROUP_CODE,
                name: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.OPTION.GROUP_CODE'),
                placeholder: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.PLACEHOLDER.ENTER_GROUP_CODE'),
                maxLength: ConstraintConstant.GROUP_CODE_M4X_LENGTH
            },
            {
                key: SellerSearchTypeConstant.ALT_GROUP_CODE,
                value: SellerSearchTypeConstant.ALT_GROUP_CODE,
                name: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.OPTION.ALT_GROUP_CODE'),
                placeholder: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.PLACEHOLDER.ENTER_ALT_GROUP_CODE'),
                maxLength: ConstraintConstant.ALT_GROUP_CODE_M4X_LENGTH
            }
        ]
    }

    const auctionCode = useMemo(
        () => auctionCodeFromState || auctionCodeFromCookie,
        [auctionCodeFromState, auctionCodeFromCookie]
    )

    const sellerNumber = useRef();

    useMemo(() => {
        // invalid auction code
        if (auctionConnectionError?.isValidAuction === false) {
            return
        }

        getCurrentSale(auctionCode)
            .then((response) => {
                setErrorNotExistsAuctionCode(null)
                const { data } = response
                let currentSaleYear = data.saleYear
                let currentSaleNumber = data.saleNumber

                if(!isNil(laneManagementSearchData) && !isBlank(laneManagementSearchData.saleYear) && !isBlank(laneManagementSearchData.saleNumber)) {
                    currentSaleYear = laneManagementSearchData.saleYear
                    currentSaleNumber = laneManagementSearchData.saleNumber
                }

                getSaleNumbersBySaleYearAndAuctionCode(
                    currentSaleYear,
                    auctionCode)
                    .then((response) => {
                        const saleNumbers = response.data
                        getLaneNumbersBySaleYearAndSaleNumberAndAuctionCode(
                            currentSaleYear,
                            currentSaleNumber,
                            auctionCode)
                            .then((response) => {
                                const laneNumbers = response.data

                                setCurrentSaleYear(currentSaleYear)
                                setCurrentSaleNumber(currentSaleNumber)

                                setSaleNumbers(saleNumbers)
                                setLaneNumbers(laneNumbers)
                            })
                    })
            })
            .catch((ex) => {
                setDisableSaleYear(true)
                setErrorNotExistsAuctionCode(handleErrorException(ex))
            })
    }, [auctionCode])

    useMemo(() => {
        const saleYearOptions = buildSaleYearDropdownOptions(saleYears)
        setSaleYearOptions(saleYearOptions)
        if (!touched) {
            if (!isNil(currentSaleYear)) {
                let saleYear = currentSaleYear

                //Set sale year when user click on link from lane management if value existed
                if(!isNil(laneManagementSearchData) && !isBlank(laneManagementSearchData.saleYear)
                ) {
                    saleYear = laneManagementSearchData.saleYear
                }

                const option = find(saleYearOptions, (saleYearOption) => {
                    return isEqual(saleYearOption.key, saleYear)
                })
                if (!isNil(option)) {
                    setSelectedSaleYearOption(option)
                } else {
                    setSelectedSaleYearOption(first(saleYearOptions))
                }
            } else {
                setSelectedSaleYearOption(first(saleYearOptions))
            }
        } else {
            setSelectedSaleYearOption(first(saleYearOptions))
        }
        if(!isEmpty(saleYears)) {
            setDisableSaleYear(false)
        }
    }, [currentSaleYear, saleYears])

    useMemo(() => {
        const saleNumberOptions = buildSaleNumberDropdownOptions(saleNumbers)
        setSaleNumberOptions(saleNumberOptions)
        if (!touched) {
            if (!isNil(currentSaleNumber)) {
                let saleNumber = currentSaleNumber

                //Set sale number when user click on link from lane management if value existed
                if(!isNil(laneManagementSearchData) && !isBlank(laneManagementSearchData.saleNumber)) {
                    saleNumber = laneManagementSearchData.saleNumber
                }

                const option = find(saleNumberOptions, (saleNumberOption) => {
                    return isEqual(saleNumberOption.key, saleNumber)
                })
                if (!isNil(option)) {
                    setSelectedSaleNumberOption(option)
                } else {
                    setSelectedSaleNumberOption(first(saleYearOptions))
                }
            } else {
                setSelectedSaleNumberOption(first(saleNumberOptions))
            }
        } else {
            setSelectedSaleNumberOption(first(saleNumberOptions))
        }
    }, [currentSaleNumber, saleNumbers])

    useMemo(() => {
        const laneNumberOptions = buildLaneNumberDropdownOptions(laneNumbers)
        setLaneNumberOptions(laneNumberOptions)

        let laneNumber = null;
        //Set sale number when user click on link from lane management if value existed
        if(!isNil(laneManagementSearchData) && !isBlank(laneManagementSearchData.laneNumber)) {
            laneNumber = laneManagementSearchData.laneNumber
        }

        const option = find(laneNumberOptions, (laneNumberOption) => {
            return isEqual(laneNumberOption.key, laneNumber)
        })
        if (!isNil(option)) {
            setSelectedLaneNumberOption(option)
        } else {
            setSelectedLaneNumberOption(first(laneNumberOptions))
        }
    }, [laneNumbers])

    useMemo(() => {
        const showByOptions = buildShowByDropdownOptions()
        setShowByOptions(showByOptions)
        setSelectedShowByOption(first(showByOptions))
    }, [])

    useEffect(() => {
        if (isNil(auctionCode)
            || isEmpty(auctionCode)) {
            setDisableSaleYear(true)
            setErrorEmptyAuctionCode(t('MESSAGE.COMMON.AUCTION_EMPTY'))
        } else {
            setErrorEmptyAuctionCode(null)
            setErrorNotConfigSaleNumber(null)
            const patternUrlCharacter = /[`\%^\{}\[\]\"<>:]+/g
            if (patternUrlCharacter.test(auctionCode)) {
                setErrorInvalidAuctionCode(t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.INVALID_AUCTION_CODE_URL_CHARACTER'))
            } else {
                const patternSpecialCharacter = /[~!@#$&*()_\-+=:;,\.?\/:]+/g
                if (patternSpecialCharacter.test(auctionCode)) {
                    setErrorInvalidAuctionCode(t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.INVALID_AUCTION_CODE_SPECIAL_CHARACTER'))
                } else {
                    setErrorInvalidAuctionCode(null)
                    setEnteredShowByValue(EMPTY_STRING)
                }
            }
        }
        if (!initialized) {
            setTouched(true)
            setInventory([])
            setLaneSummary({})
            setLaneInventory([])
            setRunRanges([])
            setDraftVehicles([])
            window.resetShowNextAvailbleCheckbox()
        }
    }, [auctionCode])

    useEffect(() => {
        if (initialized) {
            //Check if not clicked from remaining inventory
            if (!!searchContext && !isBlank(searchContext.saleYear)) {
                dispatch(resetAssignInventorySearchContext())
            }
            dispatch(resetSaleYearLane())
            setInitialized(false)
        }
    }, [])

    useEffect(() => {
        if (!isNil(searchContext) && !isNil(searchContext.searchKeywords)
            && !isLinked
            && !!selectedSaleNumberOption.value && !!selectedSaleYearOption.value ) {
            setEnteredShowByValue(searchContext.searchKeywords)

            const sellerNumberInput = searchContext.searchKeywords
            if (!isNil(sellerNumber) && !isBlank(sellerNumberInput)) {
                getSaleSummary(auctionCode, selectedSaleYearOption.value, selectedSaleNumberOption.value, sellerNumberInput)
                    .then((response) => {
                        const laneSummary = response.data;
                        setLaneSummary(laneSummary);
                    })
            } else {
                setLaneSummary({})
            }
            setIsLinked(true)
            dispatch(resetAssignInventorySearchContext())
        }

    }, [selectedSaleNumberOption, selectedSaleYearOption])

    const onKeyDownInputShowByValue = useCallback((e) =>{
        if (selectedShowByOption.value === SellerSearchTypeConstant.SELLER_NUMBER) {
            handleEventOnKeyDownForNumber(e)
        }
        if (e.keyCode === 13) {
            if(isFullForm()) {
                getInventoryVehicle()
            }
        }
    }, [
        selectedSaleYearOption,
        selectedSaleNumberOption,
        selectedLaneNumberOption,
        enteredShowByValue,
        selectedShowByOption
    ])

    const onChangeDropdownSaleYear = useCallback((option) => {
        setTouched(true)
        setSelectedSaleYearOption(option)
        const saleYear = option.value
        if (isNil(saleYear)) {
            setSaleNumbers([])
            setLaneNumbers([])
        } else {
            getSaleNumbersBySaleYearAndAuctionCode(
                saleYear,
                auctionCode)
                .then((response) => {
                    const saleNumbers = response.data
                    const laneNumbers = []

                    setSaleNumbers(saleNumbers)
                    setLaneNumbers(laneNumbers)

                    if (!isNil(saleNumbers)
                        && !isEmpty(saleNumbers)) {
                        setErrorNotConfigSaleYear(null)
                    } else {
                        setErrorNotConfigSaleYear(
                            t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.NOT_CONFIG_SALE_YEAR',
                                {
                                    'AUCTION_CODE': auctionCode,
                                    'SALE_YEAR': saleYear
                                })
                        )
                    }
                })
                .catch((ex) => {
                    setErrorNotConfigSaleYear(
                        t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.NOT_CONFIG_SALE_YEAR',
                            {
                                'AUCTION_CODE': auctionCode,
                                'SALE_YEAR': saleYear
                            })
                    )
                })
        }
    }, [auctionCode])

    const onChangeDropdownSaleNumber = useCallback((option) => {
        setTouched(true)
        setSelectedSaleNumberOption(option)
        setLaneSummary({})
        const saleYear = selectedSaleYearOption.value
        const saleNumber = option.value
        if (isNil(saleNumber)) {
            setLaneNumbers([])
        } else {
            getLaneNumbersBySaleYearAndSaleNumberAndAuctionCode(
                saleYear,
                saleNumber,
                auctionCode)
                .then((response) => {
                    const laneNumbers = response.data
                    setLaneNumbers(laneNumbers)
                    if (!isNil(laneNumbers)
                        && !isEmpty(laneNumbers)) {
                        setErrorNotConfigSaleNumber(null)
                    } else {
                        setErrorNotConfigSaleNumber(
                            t('MESSAGE.COMMON.NEXT_AVAILABLE_ERROR_MESSAGE', { auctionCode: auctionCode, saleNumber: saleNumber })
                        )
                    }

                    if(!isNil(sellerNumber) && sellerNumber.current.value !=='' && selectedShowByOption.value === SellerSearchTypeConstant.SELLER_NUMBER) {
                        getSaleSummary(auctionCode, saleYear,saleNumber, sellerNumber.current.value)
                            .then((response) => {
                                const laneSummary = response.data;
                                setLaneSummary(laneSummary);
                            })
                    }
                    else {
                        setLaneSummary({})
                    }
                })
                .catch((ex) => {
                    setErrorNotConfigSaleNumber(
                        t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.NOT_CONFIG_SALE_NUMBER',
                            {
                                'AUCTION_CODE': auctionCode,
                                'SALE_YEAR': saleYear,
                                'SALE_NUMBER': saleNumber
                            })
                    )
                })
        }
    }, [auctionCode, selectedSaleYearOption, selectedShowByOption])

    const onChangeDropdownLaneNumber = useCallback((option) => {
        setSelectedLaneNumberOption(option)
    }, [])

    const onChangeDropdownShowBy = useCallback((option) => {
        setSelectedShowByOption(option)
        setEnteredShowByValue(EMPTY_STRING)
    }, [])

    const onChangeInputShowByValue = useCallback((e) => {
        const enteredValue = e.target.value
        if (isNil(enteredValue)
            || isEmpty(enteredValue)) {
            setEnteredShowByValue(null)
        } else {
            setEnteredShowByValue(enteredValue)
        }
    }, [])

    const onClickButtonSearchSeller = useCallback((e) => {
        setShownSearchSellerPanel(true)
    }, [])

    const isShowBySellerNumber = useCallback(() => {
        return isEqual(selectedShowByOption.value, SellerSearchTypeConstant.SELLER_NUMBER)
    }, [selectedShowByOption])

    const isFullForm = useCallback(() => {
        const check = isEmpty(toString(enteredShowByValue))
        if (isNil(selectedSaleYearOption.value)
            || isNil(selectedSaleNumberOption.value)
            || isNil(selectedLaneNumberOption.value)
            || isNil(enteredShowByValue)
            || isEmpty(toString(enteredShowByValue))
            || (!ConstraintConstant.NUMBERIC_PATTERN.test(enteredShowByValue) && selectedShowByOption.value === SellerSearchTypeConstant.SELLER_NUMBER)) {
            return false
        }
        return true
    }, [
        selectedSaleYearOption,
        selectedSaleNumberOption,
        selectedLaneNumberOption,
        enteredShowByValue
    ])
    const cleanAllErrors = useCallback(() => {
        setOnlyNumberError(false)
        setIsCharLimitError(false)
        setSearchError(false)
        setIsInvalidCodeError(false)
        setIsEmptySearch(false)
        setBadRequestError(false)
        setIsGetPreRegistError(false)
    }, [])

    const isDisabledDropdownSaleNumber = useCallback(() => {
        return isNil(selectedSaleYearOption.value)
            || isEmpty(toString(selectedSaleYearOption.value))
    }, [selectedSaleYearOption])

    const isDisabledDropdownLaneNumber = useCallback(() => {
        return isNil(selectedSaleNumberOption.value)
            || isEmpty(toString(selectedSaleNumberOption.value))
            || isNil(selectedSaleYearOption.value)
            || isEmpty(toString(selectedSaleYearOption.value))
    }, [selectedSaleYearOption, selectedSaleNumberOption])

    const getInventoryVehicle = () => {
        cleanAllErrors()
        window.setSaveDraftVehicleMessage(null)
        setLaneNumber(selectedLaneNumberOption.value)
        if (toString(enteredShowByValue).trim() == '') {
            setIsEmptySearch(true)
            return
        }
        if (selectedShowByOption.value === SellerSearchTypeConstant.SELLER_NUMBER && !/^\d+$/.test(enteredShowByValue)) {
            setOnlyNumberError(true)
            return
        } else if (selectedShowByOption.value !== SellerSearchTypeConstant.SELLER_NUMBER && enteredShowByValue.length > 4) {
            setCharLimitType(selectedShowByOption.name)
            setIsCharLimitError(true)
            return
        }

        dispatch(getAssignInventorySearchContext({
            saleYear: selectedSaleYearOption.value,
            saleNumber: selectedSaleNumberOption.value,
            laneNumber: selectedLaneNumberOption.value,
            searchType: selectedShowByOption.value,
            searchKeywords: enteredShowByValue
        }))
        dispatch(notifyInventorySearchChanged())

        setLaneInventory([])
        setInventory([])
        setVehicles([])
        setRunRanges([])
        setLaneSummary({})
        setDraftVehicles([])
        searchInventoryVehicles(
            auctionCode,
            selectedSaleYearOption.value,
            selectedSaleNumberOption.value,
            selectedLaneNumberOption.value,
            selectedShowByOption.value,
            enteredShowByValue
        )
            .then((response) => {
                if (response.data.errors) {
                    setSearchError(true)
                    setErrorMessage(response.data.error[0].errorMessage)
                } else {
                    let draftVehicles = response.data.draftVehicles
                    setDraftVehicles(draftVehicles)
                    setLaneInventory(response.data.laneInventory)
                    let inventory = response.data.inventory
                    for (let i = 0; i < inventory.length; i++) {
                        let inventoryItem = inventory[i]
                        inventoryItem = {
                            ...inventoryItem,
                            fromVehicle: {
                                saleYear: inventoryItem.saleYear,
                                saleNumber: inventoryItem.saleNumber,
                                laneNumber: inventoryItem.laneNumber,
                                runNumber: inventoryItem.runNumber
                            }
                        }
                        inventory[i] = inventoryItem
                    }
                    setInventory(inventory)
                    setVehicles(JSON.parse(JSON.stringify(inventory)))
                    setRunRanges(response.data.runRanges)
                    setLaneSummary(response.data.laneSummary)
                    handleSubmitCount()
                    dispatch(getAsgIvtAdvancedFilter(null))

                    //To show Lane Management link from AssignInventory
                    dispatch(getSaleYearLane({
                        saleYear: selectedSaleYearOption.value
                        , saleNumber: selectedSaleNumberOption.value
                        , laneNumber: selectedLaneNumberOption.value
                    }))
                }
            })
            .catch((ex) => {
                if (ex.response.status == 500) {
                    setErrorMessage(t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.SEARCH_DEALER.INTERNAL_ERROR'))
                    setSearchError(true)
                } else if (ex.response.status == 400) {
                    setBadRequestError(true)
                    setBadURI(ex.response.request.responseURL)
                    setBadPayload({
                        auctionCode,
                        saleYear: selectedSaleYearOption.value,
                        saleNumber: selectedSaleNumberOption.value,
                        laneNumber: selectedLaneNumberOption.value,
                        sellerSearchType: selectedShowByOption.value,
                        sellerType: enteredShowByValue
                    })
                } else {
                    const error = handleErrorException(ex);
                    setErrorMessage(error.message)
                    setSearchError(true)
                }
            })
            .finally(() => {
                dispatch(resetLaneManagementSearchContext())
            })
    }

    useEffect(() => {
        if (isNil(advancedFilter)) {
            return
        }

        const filters = buildFilters(advancedFilter)
        applyFilter(filters)
    }, [advancedFilter])

    //#region Handle advanced filter function
    function buildFilters(advancedFilter) {
        var filters = []
        filters = filters.concat(preProcess(advancedFilter.noSales, generalMaxLength))
        filters = filters.concat(preProcess(advancedFilter.gatePassStatus, generalMaxLength))
        filters = filters.concat(preProcess(advancedFilter.titleStatus, generalMaxLength))
        filters = filters.concat(preProcess(advancedFilter.crStatus, generalMaxLength))
        filters = filters.concat(preProcess(advancedFilter.floorPrice, generalMaxLength))
        filters = filters.concat(preProcess(advancedFilter.drivableStatus, drivableFiltersMaxLength))
        filters = filters.concat(preProcess(advancedFilter.vehicleType, vehicleTypeFiltersMaxLength))
        filters = filters.concat(preProcessRunningToday(advancedFilter.excludeTodaysRuns))
        if (advancedFilter.dispositionCodes !== "") {
            filters = filters.concat(advancedFor("advancedDispositionCode",
                {dispositionCodes: advancedFilter.dispositionCodes, exclude: advancedFilter.excludeDispositionCodes})
            )
        }
        if(advancedFilter.lotLocations !== "") {
            filters = filters.concat(advancedFor("advancedLotLocation",
                {lotLocations: advancedFilter.lotLocations, exclude: advancedFilter.excludeLotLocations})
            )
        }

        return filters
    }

    function advancedFor(filterType, options) {
        return vehicleAdvancedFilters[filterType](options)
    }

    function preProcess(filters, maxLength) {
        if (filters.length === 0 || filters.length === maxLength) {
            //If user check all or no check then do not add condition to filter
            return []
        }

        const filterExpressions = buildForAll(filters)
        return anyOf(filterExpressions)
    }

    function preProcessRunningToday(runningTodayExclusionFilter){
        if (!runningTodayExclusionFilter) { return [] }
        return [vehicleAdvancedFilters["notRunningToday"]];
      }

    function buildForAll(filterTypes) {
        return filterTypes.map(function(filterType) {
            return vehicleAdvancedFilters[filterType]
        })
    }

    function anyOf(filterExpressions) {
        return function(vehicle) {
            for (var i = 0; i < filterExpressions.length; i++) {
                if (filterExpressions[i](vehicle)) {
                    return true
                }
            }

          return false
        }
    }

    function applyFilter(filters) {
        var vehiclesFiltered = []
        if (isEmpty(vehicles)) {
            return
        }
        vehicles.forEach(function (vehicle) {
            var visibility = filters.reduce(function (finalVisibility, filter) {
                return finalVisibility && filter(vehicle)
            }, true)

            if (visibility === true) {
                vehiclesFiltered.push(vehicle)
            }
        })

        setInventory(vehiclesFiltered)
    }
    //#endregion

    // Handle error messages, can arrange priority of message base on order in the array
    const messageConditionMapping = useMemo(() => {
        return [
            {condition: () => auctionConnectionError?.isValidAuction === false, message: (<AuctionCodeErrorMessage auctionConnectionError={auctionConnectionError} />)},
            {condition: () => isEmpty(auctionConnectionError) && !!errorEmptyAuctionCode, message: errorEmptyAuctionCode},
            {condition: () => !!errorInvalidAuctionCode, message: errorInvalidAuctionCode},
            {condition: () => !!errorNotExistsAuctionCode, message: (<AuctionCodeErrorMessage auctionConnectionError={errorNotExistsAuctionCode} />)},
            {condition: () => !!errorNotConfigSaleYear, message: errorNotConfigSaleYear},
            {condition: () => !!errorNotConfigSaleNumber, message: errorNotConfigSaleNumber}
        ]
    }, [auctionConnectionError, errorEmptyAuctionCode, errorInvalidAuctionCode,
                errorNotExistsAuctionCode, errorNotConfigSaleYear, errorNotConfigSaleNumber])

    useEffect(() => {
        const result = find(messageConditionMapping, (element) => element.condition() === true, 0)
        if (result) {
            setErrorMessage(result.message)
            setSearchError(true)
        }
    }, [messageConditionMapping, setErrorMessage, setSearchError]);

    useEffect(() => {
        if(!isNil(laneManagementSearchData) && !isBlank(laneManagementSearchData.sellerNumber)) {
            setEnteredShowByValue(laneManagementSearchData.sellerNumber)
        }
        if(!isBlank(selectedSaleYearOption.value)
            && !isBlank(selectedSaleNumberOption.value)
            && !isBlank(selectedLaneNumberOption.value)
        ) {
            //Check data clicked from lane management and load data
            if(!isNil(laneManagementSearchData)
                && !isBlank(laneManagementSearchData.saleYear)
                && !isBlank(laneManagementSearchData.saleNumber)
                && !isBlank(laneManagementSearchData.laneNumber)
                && !isBlank(laneManagementSearchData.sellerNumber)
                && !isBlank(enteredShowByValue)
                && !isLinked) {
                dispatch(resetLaneManagementSearchContext)
                getInventoryVehicle()
                setIsLinked(true)
            }
        }
    }, [enteredShowByValue, selectedLaneNumberOption])

    return (
        <>
            <Form
                className="input-inventory-group"
                onSubmit={(e) => {
                    getInventoryVehicle()
                    e.preventDefault()
                }}>
                <Container fluid className="assign-inventory-search-bar py-1 pl-4 pr-3">
                    <Row>
                        <Col md={10} className="pl-0">
                            <Space>
                                <Space className="assign-inventory-search-bar-input-group">
                                <span
                                    mauto="maLabelSaleYear"
                                    className="assign-inventory-search-bar-label">
                                    {t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.SALE_YEAR')}
                                </span>
                                <Dropdown
                                    mauto="maDropdownSaleYear"
                                    options={saleYearOptions}
                                    selectedOption={selectedSaleYearOption}
                                    onChange={onChangeDropdownSaleYear}
                                    disabled={disableSaleYear}
                                />
                            </Space>
                            <Space className="assign-inventory-search-bar-input-group">
                                <span
                                    mauto="maLabelSaleNumber"
                                    className="assign-inventory-search-bar-label">
                                    {t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.SALE_NUMBER')}
                                </span>
                                <Dropdown
                                    mauto="maDropdownSaleNumber"
                                    options={saleNumberOptions}
                                    selectedOption={selectedSaleNumberOption}
                                    onChange={onChangeDropdownSaleNumber}
                                    disabled={isDisabledDropdownSaleNumber()}
                                />
                            </Space>
                            <Space className="assign-inventory-search-bar-input-group">
                                <span
                                    mauto="maLabelLaneNumber"
                                    className="assign-inventory-search-bar-label">
                                    {t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.LANE_NUMBER')}
                                </span>
                                <Dropdown
                                    mauto="maDropdownLaneNumber"
                                    options={laneNumberOptions}
                                    selectedOption={selectedLaneNumberOption}
                                    onChange={onChangeDropdownLaneNumber}
                                    disabled={isDisabledDropdownLaneNumber()}
                                />
                            </Space>
                            <Space className="assign-inventory-search-bar-input-group show-by-input-group">
                                <span
                                    mauto="maLabelShowBy"
                                    className="assign-inventory-search-bar-label show-by-label">
                                    {t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.SHOW_BY')}
                                </span>
                                <Dropdown
                                    mauto="maDropdownShowBy"
                                    options={showByOptions}
                                    selectedOption={selectedShowByOption}
                                    onChange={onChangeDropdownShowBy}
                                    disabled={false}
                                />
                                <Input
                                    mauto="maInputSellerNumber"
                                    type="text"
                                    name="inputSellerNumber"
                                    value={enteredShowByValue}
                                    placeholder={selectedShowByOption.placeholder}
                                    maxLength={selectedShowByOption.maxLength}
                                    onChange={onChangeInputShowByValue}
                                    innerRef={sellerNumber}
                                    onKeyDown={onKeyDownInputShowByValue}
                                />
                                <Button
                                    mauto="maSearchSellerBtn"
                                    type="button"
                                    variant="outline"
                                    className={classNames(isShowBySellerNumber() ? 'visible-button' : 'invisible-button')}
                                    onClick={onClickButtonSearchSeller}>
                                    <i className="icon prism-icon-search" />
                                </Button>
                                <Button
                                    mauto="maGoBtn"
                                    type="submit"
                                    color="primary"
                                    disabled={!isFullForm()}
                                    >
                                    {t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.GO')}
                                </Button>
                            </Space>
                        </Space>
                    </Col>
                </Row>
            </Container>
            </Form>
            {
                !!shownSearchSellerPanel
                && (
                    <SearchSellerPanel
                        auctionCode={auctionCode}
                        searchSellerOpen={shownSearchSellerPanel}
                        customers={sellerSearchCustomers}
                        searchData={sellerSearchData}
                        isVehicleDetailParticular={true}
                        setSearchSellerOpen={setShownSearchSellerPanel}
                        setSellerNumber={setEnteredShowByValue}
                        setCustomers={setSellerSearchCustomers}
                        setSearchData={setSellerSearchData}
                        setHasDataChanged={()=>{}}
                    />
                )
            }
        </>
    )
}
