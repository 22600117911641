import React, { useEffect, useMemo, useState } from 'react'

import { searchRunRequests } from '../../utils/data-source/request-history-datasource'
import { ResultTable } from './ResultTable'
import { SearchForm } from './SearchForm'
import { getMaxInteger, handleErrorException } from '../../utils/utils'
import { NoDataFound } from '../common/data-list-status/NoDataFound'
import { isEmpty } from "lodash";
import { ConstraintConstant, REQUEST_HISTORY_SORT_FIELD } from '../../utils/constant'
import './request-history.scss'

export function RequestHistory({
    auctionCodeFromState,
    auctionCodeFromCookie
}) {
    const auctionCode = useMemo(
        () => auctionCodeFromState || auctionCodeFromCookie,
        [auctionCodeFromState, auctionCodeFromCookie]
    )
    const [runRequests, setRunRequests] = useState([])
    const [saleNumber, setSaleNumber] = useState(null)
    const [laneNumber, setLaneNumber] = useState(null)
    const [criteriaForExport, setCriteriaForExport] = useState(null)
    const [criteriaSearch, setCriteriaSearch] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState({})

    useEffect(() => {
        if(saleNumber && laneNumber){
            setSaleNumber(null)
            setLaneNumber(null)
        }
    }, [saleNumber,laneNumber])

    function search(criteriaSearch) {
        searchRunRequests(criteriaSearch).then((response) => {
            const { runRequestDTOS, paginationDTO } = response.data
            setRunRequests(runRequestDTOS)
            setPaginationData(paginationDTO)
        }).catch((ex) => {
            handleErrorException(ex)
        })
    }

    function clearAll() {
        setRunRequests([])
    }

    function handlePagination(pageNumber) {
        setCurrentPage(pageNumber)
        let criteriaSearchWithPagination = {
            ...criteriaSearch,
            pageSize: ConstraintConstant.PAGE_SIZE,
            pageNumber: pageNumber
        }
        search(criteriaSearchWithPagination)
    }

    return (
        <>
        <div className='request-history-wrapper'>
            <div>
                <SearchForm
                    auctionCode={auctionCode}
                    search={(criteriaSearch) => {
                        setCurrentPage(1)
                        let searchCondition = {
                            ...criteriaSearch,
                            sortField: REQUEST_HISTORY_SORT_FIELD.CREATED_AT,
                            isAsc: false,
                            pageSize: ConstraintConstant.PAGE_SIZE,
                            pageNumber: 1
                        }
                        setCriteriaSearch(searchCondition)
                        search(searchCondition)
                        if (typeof window.resetSortCriteria === 'function') {
                            window.resetSortCriteria()
                        }
                        setCriteriaForExport({...criteriaSearch,
                            pageNumber: 1,
                            pageSize: getMaxInteger(),
                            sortField: REQUEST_HISTORY_SORT_FIELD.CREATED_AT,
                            isAsc: false})
                    }}
                    clearAll={clearAll}
                    saleNumber={saleNumber}
                    laneNumber={laneNumber}
                />
            </div>
            {isEmpty(runRequests) && (
                <div className="mt-lg-2">
                    <NoDataFound/>
                </div>
            )}
            {!isEmpty(runRequests) && (
                <div>
                    <ResultTable
                        runRequests={runRequests}
                        setSaleNumber={setSaleNumber}
                        setLaneNumber={setLaneNumber}
                        sortData={(sortObject) => {
                            setCurrentPage(1)
                            let criteriaSearchWithSort = {
                                ...criteriaSearch,
                                ...sortObject,
                                pageSize: ConstraintConstant.PAGE_SIZE,
                                pageNumber: 1
                            }
                            setCriteriaSearch(criteriaSearchWithSort)
                            search(criteriaSearchWithSort)
                        }}
                        currentPage={currentPage}
                        handlePagination={handlePagination}
                        paginationData={paginationData}
                        criteriaForExport={criteriaForExport}
                    />
                </div>
            )}
        </div>
        </>
    )
}
