import { Listbox, Transition } from '@headlessui/react'
import { first } from 'lodash';

export function Dropdown({
    options,
    selectedOption,
    onChange,
    hasError,
    mauto,
    disabled,
    placeholder,
}) {

    if (!selectedOption) {
        selectedOption = first(options)
    }

    return (
        <Listbox
            value={selectedOption}
            onChange={(newSelectedOption) => {
                if (newSelectedOption.key === selectedOption.key) {
                    return
                }
                onChange(newSelectedOption)
            }}
            disabled={disabled ? disabled : selectedOption.disable}>
            {({ open }) => (
                <>
                    <div
                        className={
                            hasError
                                ? 'prism-select prism-select-md outline-error'
                                : 'prism-select prism-select-md'
                        }>
                        <Listbox.Button
                            className="prism-select__toggle form-control"
                            mauto={mauto ? mauto : 'N/A'}>
                            { !!placeholder ? placeholder : selectedOption ? selectedOption.name : ''}
                        </Listbox.Button>
                        <Transition
                            show={open}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0">
                            <Listbox.Options static as="div">
                                <div className="prism-select__menu-open">
                                    {options.map((op) => (
                                        <Listbox.Option
                                            key={op.key}
                                            value={op}
                                            disabled={op.disable}
                                            as="div">
                                            {({
                                                active,
                                                selected,
                                                disabled
                                            }) => (
                                                <button
                                                    type="button"
                                                    disabled={op.disable}
                                                    className={`prism-select__option ${
                                                        active ? 'active' : ''
                                                    } ${
                                                        selected
                                                            ? 'prism-select__option-selected'
                                                            : ''
                                                    } ${
                                                        disabled
                                                            ? 'prism-select__option-disabled'
                                                            : ''
                                                    } `}>
                                                    <span
                                                        className={`prism-select__label ${
                                                            active
                                                                ? 'active'
                                                                : ''
                                                        } ${
                                                            selected
                                                                ? 'active'
                                                                : ''
                                                        } `}>
                                                        {op.name}
                                                    </span>
                                                </button>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </div>
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}
