import React from 'react'
import * as XLSX from 'xlsx'
import { searchRunRequests } from '../../utils/data-source/request-history-datasource'
import {
    buildFileNameExportRequestHistory,
    calculatedSalesPercentage,
    formatSearchHistoryDate,
    handleErrorException
} from '../../utils/utils'
import { DOT_STRING } from '../../utils/constant'

export function RequestHistoryXLSCSVDownload(criteriaSearch, fileExt){

    function getDataSearch(criteriaSearch) {
        searchRunRequests(criteriaSearch).then((response) => {
            const {runRequestDTOS} = response.data
            const customData = buildData(runRequestDTOS)
            handleDownloadFile(customData)
        }).catch((ex) => {
            handleErrorException(ex)
        })
    }

    function buildData(data) {
        return data.map(item => {
            let sellerName = ""
            let saleEff = "NA"
            if (!!item.seller) {
                const seller = JSON.parse(item.seller)
                sellerName = seller.name
                saleEff = calculatedSalesPercentage(seller.stats)
            }

            let createdAt = formatSearchHistoryDate(item.createdAt)
            let updatedAt = formatSearchHistoryDate(item.updatedAt)
            return ({
                "Auction Code": item.auctionCode,
                "Sale Year": item.saleYear,
                "Sale Number": item.saleNumber,
                "Lane Number": item.laneNumber,
                "Seller Number": item.sellerNumber,
                "Seller Name": sellerName,
                "Sales Efficiency": saleEff,
                "Run Count": item.runCount,
                "Created At": createdAt,
                "Updated At":updatedAt,
                "Requested By": item.requestedBy,
                "Created By": item.createdBy,
                "Comments": item.comments
            })
        })
    }

    function handleDownloadFile(customData) {
        const ws = XLSX.utils.book_new();

        XLSX.utils.sheet_add_json(ws, customData);
        const workbook = XLSX.utils.book_new();
        const bookStyle = fileExt == "csv" ? null : {
            bookType: 'xlml'
        }
        XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
        XLSX.writeFile(workbook, buildFileNameExportRequestHistory() + DOT_STRING + fileExt, bookStyle );
    }

    //Call get data api and download file
    getDataSearch(criteriaSearch)

}