import { useRef } from 'react';
import { isEqual } from 'lodash';

export default function useVehiclesCache() {
    const cacheRef = useRef([]);

    /**
     * push vehicles to cache if it does not already exist
     * @param key has format {{AdvancedFilterParams}}
     * @param vehicles list vehicle need to cache
     */
    const put = (key, vehicles) => {
        const foundVehicles = findVehicles(key)
        if (!foundVehicles) {
            cacheRef.current.push({key, vehicles});
        }
    }

    /**
     * Return existing cached vehicles by key
     * @param key
     * @returns {*|*[]}
     */
    const get = (key) => {
        return findVehicles(key)
    }

    /**
     * Clear all existing cached vehicles
     */
    const clear = () => {
        cacheRef.current = []
    }

    function findVehicles(key) {
        return cacheRef.current.find(cache => isEqual(cache.key, key))
    }

    return {
        put, get, clear
    }
}