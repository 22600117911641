import axiosInstance from '../axios'

export function getRemainingInventory(
    auctionCode,
    sellerSearchType,
    sellerType
) {
    return axiosInstance.get('/remaining-inventory', {
        params: {
            'auction-code': auctionCode,
            'seller-search-type': sellerSearchType,
            'seller-type': sellerType
        }
    })
}

export function getVehicleDetail(auctionCode, sblu) {
    return axiosInstance.get('/remaining-inventory/vehicle-detail', {
        params: {
            'auction-code': auctionCode,
            'sblu': sblu
        }
    })
}

export function getVehicleNotes(auctionCode, workOrder) {
    return axiosInstance.get('/remaining-inventory/vehicle-notes', {
        params: {
            'auction-code': auctionCode,
            'work-order': workOrder
        }
    })
}

export function getDealerShipSearch(
    auctionCode,
    name,
    group_code,
    alt_group_code
) {
    return axiosInstance.get('/dealerships/search', {
        params: {
            'auction-code': auctionCode,
            'name': name.trim(),
            'group-code': group_code.trim(),
            'alt-group-code': alt_group_code.trim()
        }
    })
}

export function filterRemainingInventory(filter) {
    return axiosInstance.post('/remaining-inventory/filtered-search', filter)
}