import {DatePicker} from "@prism/library";
import React, {useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {randomInteger} from "../../utils/utils";

export default function SearchHistoryDatePicker({
    defaultRequestCreationDateTo,
    handlePressEnterForSearching = (event) => {},
    validateSearchForm,
    classNames,
}) {

    const { clearAll } = useSelector(state => state.resetStateObservingContext.searchRequestHistory)

    const datePickerMainComponent = useMemo(() => {
        return (
            <DatePicker
                className={classNames}
                nullAllowed={true}
                defaultValue={defaultRequestCreationDateTo}
                onKeyDown={
                    (event)=>{handlePressEnterForSearching(event)}
                }
                afterCalendarClose={validateSearchForm}
                value={defaultRequestCreationDateTo}
                key={randomInteger()}
            />
        )
    }, [clearAll, defaultRequestCreationDateTo, handlePressEnterForSearching, validateSearchForm])

    return (
        <>
            {datePickerMainComponent}
        </>
    )
}