import React, {useCallback, useEffect, useState} from 'react'
import {Button, Chip, Col, Container, Row} from '@prism/library'
import {useTranslation} from 'react-i18next'
import {LaneManagementVehicleMetric} from './LaneManagementVehicleMetric'
import { isEmpty, isNil } from 'lodash'
import { LaneManagementCreateRequest } from './LaneManagementCreateRequest'
import { isValidNumber } from '../../utils/validation-utils'
import {savedMappedLanes} from "../../utils/data-source/lane-management-datasource";
import RevertConfirmModal from "./ReverConfirmModal";
import { useToggleAS400 } from "../../hooks/common/UseToggleAS400";
import { useDrop } from 'react-dnd'
import { DnDDragTypeConstant, RUN_LANE_MAX_LENGTH } from '../../utils/constant'

import './lane-management-request-board.scss'
import DynamicSizeList from "../common/dynamic-size-list/DynamicSizeList";
import { useVehicleDraggingMonitor } from "../../hooks/assign-inventory/UseVehicleDraggingMonitor";
import { handleErrorException } from "../../utils/utils";
import { LaneManagementChangeRuns } from './LaneManagementChangeRuns';
import {useDispatch} from 'react-redux'
import {notifyLaneManagementClearAllMessages} from '../../actions/ResetStateObservingAction'

export function LaneManagementRequestBoard({
                                               mappedRequests,
                                               unMappedRequests,
                                               setIsAddRequestError,
                                               laneNumber,
                                               setMappedRequests,
                                               setUnMappedRequests,
                                               runRequestData,
                                               laneSavedToAS400,
                                               loadDataAfterSendToAS400
                                           }) {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [isCreateRequestOpen, setIsCreateRequestOpen] = useState(false)
    const [isEditRequestOpen, setIsEditRequestOpen] = useState(false)
    const [editingRequest, setEditingRequest] = useState()
    const [isRevertConfirmOpen, setIsRevertConfirmOpen] = useState(false)

    const [newRequestAdded, setNewRequestAdded] = useState(0);
    const { isEnableUpdateAS400Flag } = useToggleAS400(runRequestData.auctionCode)

    const [totalMappedRequests, setTotalMappedRequests] = useState(0);
    const [totalUnMappedRequests, setTotalUnMappedRequests] = useState(0);
    const {didDrop, isDragging, draggingItem} = useVehicleDraggingMonitor();
    const [isScrolling, setIsScrolling] = useState(false)
    const [isMappedRequestScrolling, setIsMappedRequestScrolling] = useState(false)
    const [isUnMappedRequestScrolling, setIsUnMappedRequestScrolling] = useState(false)
    const [isChangeRunsOpen, setIsChangeRunsOpen] = useState(false)

    useEffect(() => {
        if (!isDragging || didDrop) {
            setTotalMappedRequests(calculateTotalRequests(mappedRequests))
            setTotalUnMappedRequests(calculateTotalRequests(unMappedRequests))
        }

    },[mappedRequests, unMappedRequests])

    function calculateTotalRequests(requests) {
        if (isEmpty(requests)) {
            return 0
        }
        return requests.reduce((accumulator, req) => accumulator + req.runCount, 0)
    }

    const handleSaveAsDraft = useCallback((mappedRequests)=>{
        let runRequests = []
        mappedRequests.map(mappedRequest =>{
            if( mappedRequest.savedToAs400 === 'false' ){
                const {globalId, runCount, startingRun, sellerNumber, alternateSellerName, source} = mappedRequest;
                runRequests.push({globalId, runCount, startingRun, sellerNumber, alternateSellerName, source})
            }
        })
        savedMappedLanes({laneManagement: runRequestData,runRequests,isDraft: true}).then((response) => {
            loadDataAfterSendToAS400(response)
            window.setLaneManagementSaveMappedLaneError(null)
            window.setLaneManagementSaveAsDraftMessage(response.data.message);
        }).catch((ex) => {
            //TODO: handle error
           let error = handleErrorException(ex)
            window.setLaneManagementSaveMappedLaneError(error)

        })
    },[mappedRequests,runRequestData])

    const handleSendToAs400 = useCallback((mappedRequests)=> {
         let runRequests = []
         const isSaving = true;
        mappedRequests.map(mappedRequest =>{
            if( mappedRequest.savedToAs400 === 'false' ){
                const {globalId, runCount, startingRun, sellerNumber, alternateSellerName, source} = mappedRequest;
                runRequests.push({globalId, runCount, startingRun, sellerNumber, alternateSellerName, source})
            }
       })
        savedMappedLanes({
            laneManagement: runRequestData,
            isSaving,
            runRequests
        }).then((response) => {
            loadDataAfterSendToAS400(response)
            window.setLaneManagementSaveAsDraftMessage(response.data.message);
            window.setLaneManagementSaveMappedLaneError(null)
        }).catch((ex) => {
            dispatch(notifyLaneManagementClearAllMessages())
            let error = handleErrorException(ex)
            window.setLaneManagementSaveMappedLaneError(error)
        })
    },[mappedRequests,runRequestData])

    //#region Handle Drag and Drop
    //Implement dragging an item outside the list
    const [{}, mappedDrop] = useDrop(
        () => ({
            accept: DnDDragTypeConstant.LANE_MANAGEMENT,
            drop(item, monitor){
                if (monitor.didDrop()) {
                    return
                }
                window.updateRunRequestAfterDnD(item.vehicle, true, item.isMapped, item.index)
            },
            hover: (item, monitor) => {
                deleteUnmappedPlaceholder(item?.vehicle.id)
                setIsMappedRequestScrolling(true)
                setIsUnMappedRequestScrolling(false)
            }
        })
    )

    const [{}, unmappedDrop] = useDrop(
        () => ({
            accept: DnDDragTypeConstant.LANE_MANAGEMENT,
            drop(item, monitor){
                if (monitor.didDrop()) {
                    return
                }
                window.updateRunRequestAfterDnD(item.vehicle, false, item.isMapped, item.index)
            },
            hover: (item, monitor) => {
                deleteMappedPlaceholder(item?.vehicle.id)
                setIsMappedRequestScrolling(false)
                setIsUnMappedRequestScrolling(true)
            }
        })
    )

    window.updateRunRequestWhileDropHover = function(request, destinationIndex, isHoverOnMapped) {
        if (!isScrolling) {
            updateRunRequestToList({...request, isHover: true}, destinationIndex, isHoverOnMapped)
        }
    }

    window.revertMoveRequest = function(request, originIndex, isMapped) {
        updateRunRequestToList(request, originIndex, isMapped)
    }

    function updateRunRequestToList(request, index, isMapped) {
        if (isMapped) {
            setMappedRequests(() => {
                let newMappedRequests = [...mappedRequests]
                updateRunRequestList(request, index, newMappedRequests)
                return newMappedRequests
            })
            unMappedRequests.removeAnElementIf(p => p.id === request.id)
            setUnMappedRequests(unMappedRequests)
        } else {
            setUnMappedRequests(() => {
                let newUnMappedRequests = [...unMappedRequests]
                updateRunRequestList(request, index, newUnMappedRequests)
                return newUnMappedRequests
            })
            mappedRequests.removeAnElementIf(p => p.id === request.id)
            setMappedRequests(mappedRequests)
        }
    }

    /**
     * This is a common function to update mappedRequest and unMappedRequest
     */
    function updateRunRequestList(request, destinationIndex, requests) {
        //Delete the existing request in list to make sure no dupplicate request
        requests.removeAnElementIf(p => p.id === request.id)
        //Insert the dragging item (dragging request) to the list at 'destinationIndex'
        requests.splice(destinationIndex, 0, request)
    }

    window.updateRunRequestAfterDnD = function(request, destinationIsMapped, originIsMapped, originIndex) {
        if (destinationIsMapped) {
            //Check startingRun, runCount
            let mappedRequestsFilter = mappedRequests.filter(p => p.id === request.id)
            let isDropInsideTheList = mappedRequestsFilter && mappedRequestsFilter.length > 0;
            if (isDropInsideTheList) {
                let mappedRequest = mappedRequestsFilter[0]
                const index = mappedRequests.indexOf(mappedRequest)
                const canUpdate = canUpdateMappedRequestAfterDnD(mappedRequest, index, mappedRequests)
                if (canUpdate) {
                    const newMappedRequests = updateRunNumberForMappedRequestsAfterDropped(mappedRequests)
                    setMappedRequests(newMappedRequests)
                } else {
                    //Revert the moved run request - rollback the moved item to origin position
                    window.revertMoveRequest(request, originIndex, originIsMapped)
                }
            } else {
                // Handle dropped request outside the list
                const preItem = mappedRequests[mappedRequests.length - 1]
                request.startingRun = isNil(preItem) ? 1 : (preItem.startingRun + preItem.runCount)
                request.isShowAdditionalRequests = undefined
                request.requestSummary = undefined
                const addedList = [...mappedRequests, request]
                const canUpdate = canUpdateMappedRequestAfterDnD(request, addedList.length - 1, addedList)
                if (canUpdate) {
                    setMappedRequests((prev) => [...prev, request])
                    setUnMappedRequests((prev) => {
                        const newUnMappedRequests = [...prev]
                        return newUnMappedRequests.filter(p => p.id!== request.id)
                    })
                } else {
                    window.revertMoveRequest(request, originIndex, originIsMapped)
                }
            }
        } else {
            //Set starting run to 0
            let unMappedRequestsFilter = unMappedRequests.filter(p => p.id === request.id)
            let isDropInsideTheList = unMappedRequestsFilter && unMappedRequestsFilter.length > 0
            if (isDropInsideTheList) {
                let unMappedRequest = unMappedRequestsFilter[0]
                const index = unMappedRequests.indexOf(unMappedRequest)
                unMappedRequest.startingRun = 0
                unMappedRequests[index] = unMappedRequest
                setUnMappedRequests(unMappedRequests)
                const newMappedRequests = updateRunNumberForMappedRequestsAfterDropped(mappedRequests)
                setMappedRequests(newMappedRequests)
            } else {
                // Handle dropped request outside the list
                request.startingRun = 0
                setUnMappedRequests((prev) => [...prev, request])
                setMappedRequests((prev) => {
                    let newMappedRequests = [...prev]
                    newMappedRequests = newMappedRequests.filter(p => p.id!== request.id)
                    newMappedRequests = updateRunNumberForMappedRequestsAfterDropped(newMappedRequests)
                    return newMappedRequests
                })
            }
        }
    }

    /**
     * Calculate and update run number for whole mapped request list
     */
    function updateRunNumberForMappedRequestsAfterDropped(list) {
        let result = [...list]
        let startingRun = 1
        result.forEach(request => {
            if (request.savedToAs400 === 'true') {
                startingRun = request.startingRun + request.runCount
            } else {
                request.startingRun = startingRun
                request.isShowAdditionalRequests = undefined
                request.requestSummary = undefined
                startingRun += request.runCount
            }
        })
        return result
    }

    /**
     * Check whether after perform DnD user can update the run request in mapped request list or not
     */
    function canUpdateMappedRequestAfterDnD(item, index, list) {
        let as400RequestNearestBefore = undefined
        let as400RequestNearestAfter = undefined
        let runCountNotAS400Before = 0
        let runCountNotAS400After = 0
        for (let i = index + 1; i < list.length; i++) {
            const currentItem = list[i]
            if (currentItem.savedToAs400 === 'true') {
                as400RequestNearestAfter = list[i]
                break
            } else {
                runCountNotAS400After += currentItem.runCount
            }
        }

        for (let i = index - 1; i >= 0; i--) {
            const currentItem = list[i]
            if (currentItem.savedToAs400 === 'true') {
                as400RequestNearestBefore = list[i]
                break
            } else {
                runCountNotAS400Before += currentItem.runCount
            }
        }

        let maxRunUsed = as400RequestNearestAfter ? as400RequestNearestAfter.startingRun : (RUN_LANE_MAX_LENGTH + 1)
        let minRunCanUse = as400RequestNearestBefore ? (as400RequestNearestBefore.startingRun + as400RequestNearestBefore.runCount) : 1
        return (maxRunUsed - minRunCanUse) - (runCountNotAS400After + runCountNotAS400Before) >= item.runCount
    }

    //#endregion

    window.updateAdditionalRequests = function(index, requestSummary) {
        let newMappedRequests = [...unMappedRequests]
        let runRequest = newMappedRequests[index]
        if (runRequest) {
            runRequest.isShowAdditionalRequests = true
            runRequest.requestSummary = requestSummary
            newMappedRequests[index] = runRequest
            setUnMappedRequests(newMappedRequests)
        }
    }

    // Handle remove placeholder while item is dragging
    useEffect(() => {
        if (isScrolling) {
            deleteMappedPlaceholder(draggingItem?.vehicle?.id)
            deleteUnmappedPlaceholder(draggingItem?.vehicle?.id)
        }
    }, [isScrolling]);

    const deleteMappedPlaceholder = (id) => {
        setMappedRequests((prev) => {
            const newMappedRequest = [...prev]
            newMappedRequest.removeAnElementIf(item => item.id === id)
            return newMappedRequest
        })
    }

    const deleteUnmappedPlaceholder = (id) => {
        setUnMappedRequests((prev) => {
            const newUnmappedRequest = [...prev]
            newUnmappedRequest.removeAnElementIf(item => item.id === id)
            return newUnmappedRequest
        })
    }

    /* +/- Implements Logic */
    window.increaseDecreaseRunCount = function (index,count){
       if(totalMappedRequests+count <= 2000){
           mappedRequests[index].runCount += count;
           let newMappedRequests = updateRunNumberOnClickPlusMinus(mappedRequests)
           setMappedRequests(newMappedRequests)
       }
    }
    function updateRunNumberOnClickPlusMinus(list) {
        let result = [...list]
        let startingRun = 1
        result.forEach(request => {
            request.startingRun = startingRun
            startingRun += request.runCount
        })
        return result
    }

    return (
        <>
            <Container fluid className="mt-1 pr-0 lane-management-request-board-wrapper" mauto="maLaneManagementRequest">
                <Row className="mr-lg-2 row-lane-management-request-board">
                    <Col md={6} className="pr-1 assign-inventory-wrapper">
                        <div className="lane-management-container">
                            <div className="lane-management-header">
                                <Row className="row mt-lg-1 mb-lg-1">
                                    <Col md={6}>
                                        {!isNil(laneNumber) && (
                                            <span>{t('LABEL.LANE_MANAGEMENT.LANE') + " " + laneNumber} </span>

                                        )}
                                        {isNil(laneNumber) && (
                                            <span>{t('LABEL.LANE_MANAGEMENT.LANE_NOT_SELECTED')} </span>
                                        )}

                                    </Col>
                                    <Col md={6} className="text-right run-reserve-wrapper">
                                        <span className="mr-lg-1">
                                        {t(
                                            'LABEL.LANE_MANAGEMENT.RUN_RESERVE'
                                        )}
                                        </span>
                                        <Chip color="light" label={totalMappedRequests.toString()}/>
                                    </Col>
                                </Row>
                            </div>
                            <div ref={mappedDrop} className="lang-management-draggable table-bordered mapped-requests">
                                {isEmpty(mappedRequests) && (
                                    <div className="text-center mt-lg-3">
                                        {t(
                                            'LABEL.LANE_MANAGEMENT.DRAG_INSTRUCTION'
                                        )}
                                    </div>
                                )}
                                {!!mappedRequests && !isEmpty(mappedRequests) &&
                                    <DynamicSizeList
                                        data={mappedRequests}
                                        isAllowAutoScroll={!!isMappedRequestScrolling}
                                        key={'mapped-request-list'}
                                        ignoreBottomChecking={true}
                                        scrollingCallback={setIsScrolling}
                                        offsetTop={100}
                                        offsetBottom={100}
                                    >
                                        {(mappedRequest, index) => (
                                            <>
                                                <LaneManagementVehicleMetric
                                                    key={`mapped-request-${index}`}
                                                    vehicle={mappedRequest}
                                                    isMapped={true}
                                                    laneSavedToAS400={laneSavedToAS400}
                                                    index={index}
                                                />
                                                {index === (mappedRequests.length - 1) &&
                                                    <div className="h-10"></div>
                                                }
                                            </>

                                        )}
                                    </DynamicSizeList>
                                }
                            </div>
                            <div className="sub-menu table-bordered">
                                <Row className="pl-1 py-1">
                                    <Col md={6}>
                                        <Button
                                            className="btn btn-outline text-nowrap"
                                            mauto="maChangeRun"
                                            onClick={() => {
                                                setIsChangeRunsOpen(true)
                                            }}
                                            disabled={ !isEnableUpdateAS400Flag || !laneSavedToAS400 }
                                        >
                                            {t(
                                                'LABEL.LANE_MANAGEMENT.CHANGE_RUN'
                                            )}
                                        </Button>
                                        <Button
                                            className="btn btn-outline ml-lg-1 save-draft-btn"
                                            mauto="maSaveDraftButton"
                                            onClick={()=>{
                                                handleSaveAsDraft(mappedRequests)
                                            }}
                                            disabled={!isEnableUpdateAS400Flag || (isEmpty(mappedRequests)&& isEmpty(unMappedRequests))}>
                                            {t(
                                                'LABEL.LANE_MANAGEMENT.SAVE_AS_DRAFT'
                                            )}
                                        </Button>

                                    </Col>
                                    <Col md={2} className="text-center revert-button-col">
                                        <Button
                                            className="btn btn-outline"
                                            mauto="maRevertLabel"
                                            onClick={() => {
                                                setIsRevertConfirmOpen(true)
                                            }}
                                            disabled={ !isEnableUpdateAS400Flag || !laneSavedToAS400 }
                                        >
                                            {t(
                                                'LABEL.LANE_MANAGEMENT.REVERT'
                                            )}
                                        </Button>
                                    </Col>
                                    <Col md={4} className="text-right send-to-as400-col">
                                        <Button
                                            className="btn btn-primary mr-lg-1"
                                            mauto="maSendAs400Button"
                                            onClick={() => {
                                                handleSendToAs400(mappedRequests)
                                            }}
                                            disabled={isEmpty(mappedRequests) || !isEnableUpdateAS400Flag}>
                                            {
                                                t(
                                                    'LABEL.LANE_MANAGEMENT.SEND_TO_AS400'
                                                )

                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="pl-1 pr-0 unassign-inventory-wrapper">
                        <div className="lane-management-container">
                            <div className="lane-management-header">
                                <Row className="row mt-lg-1 mb-lg-1">
                                    <Col md={4} className="pl-2" mauto="maRequestBoard">
                                        {t(
                                            'LABEL.LANE_MANAGEMENT.CREATE_REQUEST_BOARD.REQUEST_BOARD_LABEL'
                                        )}
                                    </Col>
                                    <Col md={4} className="pl-2 run-request-wrapper" mauto="maRunRequest">
                                            <span className="mr-lg-1">
                                            {t(
                                                'LABEL.LANE_MANAGEMENT.REQUEST_RUNS'
                                            )}
                                            </span>
                                        <Chip color="light" label={totalUnMappedRequests.toString()}/>
                                    </Col>
                                    <Col
                                        md={4}
                                        className="text-right pl-0 px-0">
                                        <Button
                                            className="btn btn-primary create-request-btn ml-2 mx-2 mb-n1 mt-n1"
                                            mauto="maCreateRequestButton"
                                            disabled={!isEnableUpdateAS400Flag || !isValidNumber(runRequestData.laneNumber)}
                                            onClick={() => {
                                                setIsCreateRequestOpen(true)
                                            }}>
                                            {
                                                t('LABEL.LANE_MANAGEMENT.CREATE_REQUEST')
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <div ref={unmappedDrop} className="lang-management-draggable table-bordered right-panel unmapped-requests">
                                {isEmpty(unMappedRequests) && (
                                    <div className="text-center mt-lg-3">
                                        {t(
                                            'LABEL.LANE_MANAGEMENT.SELECT_REQUEST_INSTRUCTION'
                                        )}
                                    </div>
                                )}
                                {!!unMappedRequests && !isEmpty(unMappedRequests) &&
                                    <DynamicSizeList
                                        data={unMappedRequests}
                                        key={"unmapped-request-list"}
                                        ignoreBottomChecking={true}
                                        isAllowAutoScroll={!!isUnMappedRequestScrolling}
                                        scrollingCallback={setIsScrolling}
                                        offsetTop={100}
                                        offsetBottom={100}
                                    >
                                        {(unmappedRequest, index) => (
                                            <>
                                                <LaneManagementVehicleMetric
                                                    key={`unmapped-request-${index}`}
                                                    vehicle={unmappedRequest}
                                                    isMapped={false}
                                                    setIsAddRequestError={setIsAddRequestError}
                                                    setIsEditRequestOpen={setIsEditRequestOpen}
                                                    setEditingRequest={setEditingRequest}
                                                    isNewRequestAdded={newRequestAdded === unmappedRequest.id}
                                                    index={index}/>
                                                {index === (unMappedRequests.length - 1) &&
                                                    <div className="h-10"></div>
                                                }
                                            </>
                                        )}
                                    </DynamicSizeList>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                <LaneManagementCreateRequest
                    runRequestData={runRequestData}
                    isCreateRequestOpen={isCreateRequestOpen}
                    setIsCreateRequestOpen={setIsCreateRequestOpen}
                    isEditRequestOpen={isEditRequestOpen}
                    editingRequest={editingRequest}
                    setEditingRequest={setEditingRequest}
                    setIsEditRequestOpen={setIsEditRequestOpen}
                    unMappedRequests={unMappedRequests}
                    setUnMappedRequests={setUnMappedRequests}
                    setNewRequestAdded={setNewRequestAdded}
                />
            </Container>

            <RevertConfirmModal
                runRequestData={runRequestData}
                setIsRevertConfirmOpen={setIsRevertConfirmOpen}
                isRevertConfirmOpen={isRevertConfirmOpen}
            />
            {!!isChangeRunsOpen && (
                <LaneManagementChangeRuns
                    runRequestData={runRequestData}
                    isChangeRunsOpen={isChangeRunsOpen}
                    setIsChangeRunsOpen={setIsChangeRunsOpen}
                    setMappedRequests={setMappedRequests}
                    setUnMappedRequests={setUnMappedRequests}
                    isRequestBoard={true}
                />
            )}
        </>
    )
}
