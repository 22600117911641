import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { Container, Row, Space } from '@prism/library'
import { isEmpty, isNil, includes, first, find, isEqual } from 'lodash'

import { getCurrentSale, getSaleNumbersBySaleYearAndAuctionCode } from '../../utils/data-source/sale-datasource'
import { Dropdown } from "../common/custom/prism/dropdown/Dropdown"

import './next-available-picker.scss'
import { buildDropdownOptionsData, handleErrorException } from "../../utils/utils"

export function NextAvailablePicker({
                                        auctionCodeFromState,
                                        auctionCodeFromCookie,
                                        saleYears,
                                        updateNextAvailableRuns,
                                        isAuctionCodeChange,
                                        setErrorMessage,
                                        selectedSaleYear,
                                        setSelectedSaleYear,
                                        selectedSaleNumber,
                                        setSelectedSaleNumber,
                                        saleYearOptions,
                                        setSaleYearOptions,
                                        saleNumberOptions,
                                        setSaleNumberOptions,
                                        setNextAvailableRuns
                                    }) {
    const {t} = useTranslation()
    const buildDropdownSelectionData = useCallback((data, disable) => {
        const name = data
        const value = data === t('LABEL.SALE_PICKER.SELECT') ? null : data
        return {
            name: name,
            value: value,
            disable: disable,
            key: uuid()
        }}, [])

    const auctionCode = useMemo(
        () => auctionCodeFromState || auctionCodeFromCookie,
        [auctionCodeFromState, auctionCodeFromCookie]
    )

    const [isLoadingSaleNumber, setIsLoadingSaleNumber] = useState(false)
    const [isLoadingSaleYear, setIsLoadingSaleYear] = useState(false)

    useEffect(() => {
        const saleYearOptionsData = buildDropdownOptionsData(saleYears)
        if(!isNil(selectedSaleYear.value) || !isNil(selectedSaleNumber.value)) {
            return
        }
        if (auctionCode) {
            if (isNil(saleYears) || isEmpty(saleYears)) {
                 setSaleYearOptions(buildDropdownOptionsData([]))
                setSelectedSaleYear(buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), true))
                setSaleNumberOptions(buildDropdownOptionsData([]))
                setSelectedSaleNumber(buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), true))
            } else {
                 setSaleYearOptions(saleYearOptionsData)
                if (isAuctionCodeChange) {
                    setSelectedSaleYear(first(saleYearOptionsData))
                    setSaleNumberOptions(buildDropdownOptionsData([]))
                    setSelectedSaleNumber(buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), true))
                } else if (auctionCode) {
                    setSelectedSaleYear(buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), true))
                    setSaleNumberOptions(buildDropdownOptionsData([]))
                    setSelectedSaleNumber(buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), true))
                    getCurrentSale(auctionCode)
                        .then((response) => {
                            const {data} = response
                            let currentSaleYear = data.saleYear
                            let currentSaleNumber = data.saleNumber
                            getSaleNumbersBySaleYearAndAuctionCode( currentSaleYear, auctionCodeFromCookie).then((response) => {
                                if (!includes(response.data, currentSaleNumber)) {
                                    for (let i = 0; i < response.data.length; i++) {
                                        let value = response.data[i]
                                        if (currentSaleNumber <= value) {
                                            currentSaleNumber = value
                                            break
                                        }
                                    }
                                }
                                const optionSaleYear = find(saleYearOptionsData, (saleYearOption) => {
                                    return isEqual(saleYearOption.value, currentSaleYear)
                                })
                                setSelectedSaleYear(optionSaleYear)
                                if (!isEmpty(response.data) && !isNil(response.data)) {

                                    const saleNumberOptionData = buildDropdownOptionsData(response.data);
                                    setSaleNumberOptions(saleNumberOptionData)

                                    const optionSaleNumber = find(saleNumberOptionData, (saleNumberOption) => {
                                        return isEqual(saleNumberOption.value, currentSaleNumber)
                                    })
                                    setSelectedSaleNumber(optionSaleNumber)
                                    updateNextAvailableRuns(auctionCode, currentSaleYear, currentSaleNumber)
                                }
                            })
                        }).catch((ex) => {
                            let error = handleErrorException(ex)
                            setErrorMessage(error.message)
                        })
                }
            }
        } else {
            setSaleYearOptions(buildDropdownOptionsData([]))
            setSelectedSaleYear(buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), true))
            setSaleNumberOptions(buildDropdownOptionsData([]))
            setSelectedSaleNumber(buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), true))
        }
    }, [])

    function changeSaleYear(selectedData) {
        if (selectedData.value === selectedSaleYear.value) {
            return
        }
        setNextAvailableRuns([])
        setErrorMessage(null)
        if (!!selectedData.value) {
            setIsLoadingSaleYear(true)
        }
        setIsLoadingSaleNumber(true)
        setSelectedSaleYear(selectedData)
        setSelectedSaleNumber(buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'),true ))
        if (!!selectedData.value) {
            getSaleNumbersBySaleYearAndAuctionCode(selectedData.name, auctionCode).then((response) => {
                    if (isNil(response.data) || isEmpty(response.data)) {
                        setSaleNumberOptions(buildDropdownOptionsData([]))
                    } else {
                        const optionSaleNumber = buildDropdownOptionsData(response.data);
                        setSaleNumberOptions(optionSaleNumber)
                        setSelectedSaleNumber(first(optionSaleNumber))
                    }
                })
                .catch((ex) => {
                    let error = handleErrorException(ex)
                    setErrorMessage(error.message)
                })
                .finally(() => {
                    setIsLoadingSaleNumber(false)
                    setIsLoadingSaleYear(false)
                })
        } else {
            setSaleNumberOptions(buildDropdownOptionsData([]))
            setSelectedSaleNumber(buildDropdownSelectionData(t('LABEL.SALE_PICKER.SELECT'), true))
            setIsLoadingSaleNumber(false)
            setIsLoadingSaleYear(false)
        }
    }

    function changeSaleNumber(selectedData) {
        if (selectedData.value === selectedSaleNumber.value) {
            return
        }
        setNextAvailableRuns([])
        setErrorMessage(null)
        if (!!selectedData.value) {
            updateNextAvailableRuns(
                auctionCode,
                selectedSaleYear.value,
                selectedData.value
            )
        }
        setSelectedSaleNumber(selectedData)
    }

    return (
        <>
            <Container fluid className="next-available-picker-container py-1">
                <Row>
                    <Space size={6} className="next-available-group">
                        <span
                            mauto="maLabelSaleYear"
                            className="next-available-label">
                            {t('LABEL.SALE_PICKER.SALE_YEAR')}
                        </span>
                        <Dropdown
                            mauto="maDropdownSaleYear"
                            options={saleYearOptions}
                            onChange={changeSaleYear}
                            selectedOption={selectedSaleYear}
                            disabled={isLoadingSaleYear}
                        />
                    </Space>
                    <Space size={6} className="next-available-group ml-2">
                        <span
                            mauto="maLabelSaleNumber"
                            className="next-available-label">
                            {t('LABEL.SALE_PICKER.SALE_NUMBER')}
                        </span>
                        <Dropdown
                            mauto="maDropdownSaleNumber"
                            options={saleNumberOptions}
                            onChange={changeSaleNumber}
                            selectedOption={selectedSaleNumber}
                            disabled={isLoadingSaleNumber}
                        />
                    </Space>
                </Row>
            </Container>
        </>
    )
}
