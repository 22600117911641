import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Input, Row, SlideOut, Space, FormGroup } from '@prism/library'
import {
    ConstraintConstant,
    EMPTY_STRING,
    KEY_CODE,
    LANE_MANAGEMENT,
    MessageTypeConstant,
    RUN_REQUEST
} from '../../utils/constant'
import { SearchSellerPanel} from '../common/vehicle-info/SearchSellerPanel'
import {
    handleErrorException,
    handleEventOnKeyDownForNumber,
    handleEventOnKeyDownForNumberAlphabet,
    isBlank, openSnowModal
} from '../../utils/utils'
import { isValidNumber } from '../../utils/validation-utils'
import { NotificationMessage } from '../common/notification-message/NotificationMessage'
import {
    createRunRequest,
    getRunRequestSummary,
    updateRunRequest
} from '../../utils/data-source/lane-management-datasource'
import {isEmpty, isEqual} from 'lodash'

import './lane-management-create-request.scss'
import { toNumber } from 'lodash/lang'
import useCustomLocation from '../../hooks/common/UseCustomLocation';

export function LaneManagementCreateRequest({runRequestData
                                                , isCreateRequestOpen
                                                , isEditRequestOpen
                                                , setIsCreateRequestOpen
                                                , setIsEditRequestOpen
                                                , unMappedRequests
                                                , setUnMappedRequests
                                                , setNewRequestAdded
                                                , editingRequest
                                                , setEditingRequest
}) {
    const { t } = useTranslation()

    const [errorMessage, setErrorMessage] = useState({hasError: false, message: ""})
    const [shownSearchSellerPanel, setShownSearchSellerPanel] = useState(false)
    const [enteredShowByValue, setEnteredShowByValue] = useState(EMPTY_STRING)
    const [sellerSearchData, setSellerSearchData] = useState(null)
    const [sellerSearchCustomers, setSellerSearchCustomers] = useState(null)
    const [runRequestPayload, setRunRequestPayload] = useState()
    const [hasData, setHasData] = useState(false)
    const [sellerInfoMessage, setSellerInfoMessage] = useState({})
    const {pathName} = useCustomLocation()

    const onClickButtonSearchSeller = useCallback((e) => {
        setShownSearchSellerPanel(true)
    }, [])

    function onChangeSellerNumber(e) {
        setSellerInfoMessage({})
        const { value } = e.target
        setRunRequestPayload({...runRequestPayload, sellerNumber: value})
        setEnteredShowByValue(value)
    }


    function handleOnchange(e) {
        const { name, value } = e.target

        setRunRequestPayload(null)
        if(name === RUN_REQUEST.SELLER_NUMBER) {
            onChangeSellerNumber(e)
        }
        else if(name === RUN_REQUEST.RUN_COUNT)
        {
            setRunRequestPayload({...runRequestPayload, runCount: value})
        }
        else if(name === RUN_REQUEST.LANE_NUMBER) {
            setRunRequestPayload({...runRequestPayload, laneNumber: value})
        }
        else if(name === RUN_REQUEST.REQUEST_BY) {
            setRunRequestPayload({...runRequestPayload, requestedBy: value})
        }
        else if(name === RUN_REQUEST.COMMENTS) {
            setRunRequestPayload({...runRequestPayload, comments: value})
        }
    }

    function validateCreateRequest() {
        if(!isBlank(runRequestPayload?.runCount)) {
            if (!isValidNumber(runRequestPayload?.runCount) || isEqual(toNumber(runRequestPayload?.runCount), 0)) {
                let err = {
                    hasError: true,
                    message: t('LABEL.LANE_MANAGEMENT.MESSAGE.RUN_COUNT_ERROR')
                }
                setErrorMessage(err)
                setHasData(false)
                return
            }

            if (toNumber(runRequestPayload?.runCount) > 0) {
                setHasData(true)
            } else {
                setHasData(false)
                return
            }
        } else {
            setHasData(false)
            return
        }

     if (isEditRequestOpen) {
         if(!isBlank(runRequestPayload?.laneNumber)) {
             if (!isValidNumber(runRequestPayload?.laneNumber) || isEqual(toNumber(runRequestPayload?.laneNumber), 0)) {
                 let err = {
                     hasError: true,
                     message: t('LABEL.LANE_MANAGEMENT.MESSAGE.LANE_ERROR')
                 }
                 setErrorMessage(err)
                 setHasData(false)
                 return
             }

             if (toNumber(runRequestPayload?.laneNumber) > 0) {
                 setHasData(true)
             } else {
                 setHasData(false)
             }
         } else {
             setHasData(false)
         }
     }

        if(!isBlank(runRequestPayload?.sellerNumber)) {
            if(ConstraintConstant.PATTERN_REGEX_SPECIAL_CHARACTER.test(runRequestPayload.sellerNumber)) {
                let err = {hasError: true, message: t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.SELLER_INPUT_ERROR')}
                setErrorMessage(err)
                return
            }
        }

        if(!isBlank(runRequestPayload?.requestedBy)) {
            if(ConstraintConstant.PATTERN_REGEX_SPECIAL_CHARACTER.test(runRequestPayload.requestedBy)) {
                let err = {hasError: true, message: t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.REQUESTED_BY_ERROR')}
                setErrorMessage(err)
                return
            }
        }

        if(!isBlank(runRequestPayload?.comments)) {
            if(runRequestPayload?.comments.length > ConstraintConstant.COMMENTS_MAX_LENGTH) {
                let err = {hasError: true, message: t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.COMMENTS_MAX_LENGTH_ERROR')}
                setErrorMessage(err)
                return
            }
        }

        setErrorMessage({
            hasError: false,
            message: ""
        })
    }

    function buildDisplaySellerName(data) {
        if(!data.seller) {
            return data
        }
        let displaySeller = `${data.seller.id}`

        if (data.seller.name) {
            displaySeller += ` - ${data.seller.name}`
        }

        if (data.seller.city) {
            displaySeller += ` - ${data.seller.city}`
        }

        if (data.seller.province) {
            displaySeller += `, ${data.seller.province}`
        }

        return {...data, displaySeller: displaySeller}

    }

    function handleCreateRunRequest() {
        const request = {...runRequestData, ...runRequestPayload}
        createRunRequest(request).then((response) => {
            //Add created run request to unMappedRequest
            let data = buildDisplaySellerName(response.data)

            setUnMappedRequests((prevState) => ([data, ...prevState]));

            setNewRequestAdded(data.id);
            // Reset the newItemAdded state after 3 seconds
            setTimeout(() => {
                setNewRequestAdded(0);
            }, 3000);

            setIsCreateRequestOpen(false)
            clearPayloadData()
        }).catch((ex) => {
            let error = handleErrorException(ex)
            setErrorMessage({...errorMessage, hasError: true, message: error.message, status: error.status})
        });
    }

    function handleOnPressEnter(e) {
        if(e.keyCode === KEY_CODE.ENTER && !isEmpty(runRequestPayload) && hasData) {
         if(isCreateRequestOpen) {
             handleCreateRunRequest()
         }
         else {
             handleEditRunRequest()
         }
        }
    }

    function handleEditRunRequest() {
        const request = {
            auctionCode: runRequestPayload?.auctionCode,
            saleYear: runRequestPayload?.saleYear,
            saleNumber: runRequestPayload?.saleNumber,
            laneNumber: runRequestPayload?.laneNumber,
            runCount: runRequestPayload?.runCount,
            sellerNumber: runRequestPayload?.sellerNumber,
            requestedBy: runRequestPayload?.requestedBy,
            comments: runRequestPayload?.comments,
            globalId: runRequestPayload?.globalId,
        }

        updateRunRequest(request)
            .then((response) => {
                let data = buildDisplaySellerName(response.data)
                const updatedUnmappedLane = unMappedRequests.filter(runRequest => runRequest.globalId !== response.data.globalId)

                if(runRequestData.laneNumber === response.data.laneNumber) {
                    setUnMappedRequests([data, ...updatedUnmappedLane]);
                }
                else {
                    setUnMappedRequests([...updatedUnmappedLane]);
                }

                setNewRequestAdded(data.id);
                // Reset the newItemAdded state after 3 seconds
                setTimeout(() => {
                    setNewRequestAdded(0);
                }, 3000);

                setIsEditRequestOpen(false)
                clearPayloadData()
                window.setLaneManagementErrorMessage('')
            })
            .catch((ex) => {
                let error = handleErrorException(ex)
                if (error.message.includes(LANE_MANAGEMENT.EDIT_REQUEST_SERVER_ERROR)) {
                    const errorMessage = LANE_MANAGEMENT.EDIT_REQUEST_ERROR
                    window.setLaneManagementErrorMessage(errorMessage)
                    setIsEditRequestOpen(false)
                }
                else {
                    setErrorMessage({...errorMessage, hasError: true, message: error.message, status: error.status})
                }
            })
    }

    function handleCheckSellerNumber(e) {
        const { value } = e.target
        if(isValidNumber(value.trim()) && value > 0) {
            const request = {
                auctionCode: runRequestData.auctionCode,
                saleYear: runRequestData.saleYear,
                saleNumber: runRequestData.saleNumber,
                laneNumber: runRequestData.laneNumber,
                sellerNumber: value
            }
            getRunRequestSummary(request)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setSellerInfoMessage({})
                    } else {
                        setSellerInfoMessage(response.data)
                    }
                }).catch((ex) => {
            })
        }
    }

    function buildSellerInfoMessage() {
        if (isEmpty(sellerInfoMessage)) {
            return ""
        }

        let message = t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.SELLER_INFO_MESSAGE')
        let lanesInfo = []
        sellerInfoMessage.map(item => {
           lanesInfo.push(`Lane ${item.laneNumber}: (${item.runCount})`)
        })

        return message + lanesInfo.join(", ")
    }

    function clearPayloadData() {
        setRunRequestPayload(null)
        setEnteredShowByValue("")
        setHasData(false)
        setErrorMessage({hasError: false, message: ""})
        setSellerInfoMessage({})
        setSellerSearchCustomers(null)
        setSellerSearchData(null)
    }

    function checkForErrorMessage() {
        if (errorMessage.hasError && !!errorMessage.message && isEqual(errorMessage.status, 500)) {
            return (
                <NotificationMessage
                    className='error-message'
                    type={MessageTypeConstant.ERROR}
                    isOpen={true}>
                    {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.PRE_TEXT')}
                    <a href={pathName} onClick={openSnowModal}>
                        {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.LINK_TEXT')}
                    </a>
                </NotificationMessage>
            )
        } else if(errorMessage.hasError && !!errorMessage.message) {
            return (
                <NotificationMessage type={'error'} isOpen={true} className='error-message'>
                    {errorMessage.message}
                </NotificationMessage>
            )
        }
    }

    useEffect(() => {
        if (isCreateRequestOpen) {
            validateCreateRequest()
        } else {
            validateCreateRequest()
            if (isEditRequestOpen && isEmpty(runRequestPayload)) {
                if (!isValidNumber(editingRequest?.sellerNumber)) {
                    const adjustEditingRequest = {...editingRequest, sellerNumber: editingRequest?.alternateSellerName}
                    setRunRequestPayload(adjustEditingRequest)
                } else {
                    setRunRequestPayload(editingRequest)
                }
                setHasData(true)
            }
        }
    }, [runRequestPayload, isEditRequestOpen, editingRequest])

    return (
        <>
            <Container className="prism-overridden lane-management-create-request">
                <SlideOut
                    actionable={true}
                    onCloseButtonClick={() => {
                        setIsCreateRequestOpen(false)
                        setIsEditRequestOpen(false)
                        clearPayloadData()
                    }}
                    open={(isCreateRequestOpen || isEditRequestOpen )&& !shownSearchSellerPanel}
                    placement="right"
                    headerText={isCreateRequestOpen ? t(
                        'LABEL.LANE_MANAGEMENT.LABEL.CREATE_REQUEST_TITTLE'
                    ) : t(
                        'LABEL.LANE_MANAGEMENT.LABEL.EDIT_REQUEST_TITTLE'
                    )}>

                    {checkForErrorMessage()}

                    {!isEmpty(sellerInfoMessage) && (
                        <NotificationMessage type={'info'} isOpen={true} className='error-message'>
                            {buildSellerInfoMessage()}
                        </NotificationMessage>
                    )}

                    <Space vertical size='0'>

                        <Row className="ml-1">
                            <Col md={5} sm={5} xs={5} className="pl-0 pr-1">
                                <FormGroup row className={"input-seller-no"}>
                                    <Input.Label fontSize="sm" mauto="maLabelSellerNo">{t('LABEL.LANE_MANAGEMENT.LABEL.SELLER_NO')}</Input.Label>
                                    <Input
                                        className="prism-input"
                                        value={runRequestPayload?.sellerNumber}
                                        placeholder={t('LABEL.LANE_MANAGEMENT.LABEL.ENTER_SELLER_PLACE_HOLDER')}
                                        maxLength={ConstraintConstant.INPUT_FILTER_MAX_LENGTH}
                                        mauto="maInputSellerNo"
                                        name="sellerNumber"
                                        onKeyDown={(e) => {
                                            handleEventOnKeyDownForNumberAlphabet(e, [188, 32])
                                            handleOnPressEnter(e)
                                        }}
                                        onChange={(e) => {
                                            handleOnchange(e)
                                        }}
                                        onBlur={(e) => { handleCheckSellerNumber(e) }}
                                        onFocus={(e) =>{
                                           if(e.target.name === 'sellerNumber') {
                                               setEditingRequest('')
                                           }
                                         }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={1} sm={1} xs={1}
                                 className='pl-1 mr-3 search-seller-btn'>
                                <Button className='btn btn-outline'
                                        onClick={onClickButtonSearchSeller}
                                        mauto='maSearchSellerBtn'>
                                    <i className='icon prism-icon-search' />
                                </Button>
                            </Col>
                            <Col md={2} sm={2} xs={2} className="pl-0 pr-1">
                                <FormGroup row>
                                    <Input.Label fontSize="sm" mauto="maLabelRuns">{t('LABEL.LANE_MANAGEMENT.LABEL.RUNS')}</Input.Label>
                                    <Input
                                        className="prism-input"
                                        value={runRequestPayload?.runCount}
                                        placeholder={t('LABEL.LANE_MANAGEMENT.LABEL.RUNS_PLACE_HOLDER')}
                                        maxLength={ConstraintConstant.RUN_COUNT_MAX_LENGTH}
                                        mauto="maInputRuns"
                                        name="runNumber"
                                        onKeyDown={(e) => {
                                            handleEventOnKeyDownForNumber(e, null)
                                            handleOnPressEnter(e)
                                        }}
                                        onChange={(e)=> handleOnchange(e)}

                                    />
                                </FormGroup>
                            </Col>
                            {!!isEditRequestOpen && (
                                <Col md={2} sm={2} xs={2} className="pl-0 pr-0 no-wrap">
                                    <FormGroup row className={"ml-2"}>
                                        <Input.Label fontSize="sm"
                                                     mauto="maLabelLane">{t('LABEL.LANE_MANAGEMENT.LABEL.LANE')}</Input.Label>
                                        <Input
                                            className="prism-input"
                                            value={runRequestPayload?.laneNumber}
                                            placeholder={t('LABEL.LANE_MANAGEMENT.LABEL.RUNS_PLACE_HOLDER')}
                                            maxLength={ConstraintConstant.LANE_MANAGEMENT.LANE_NUMBER_MAX_LENGTH}
                                            mauto="maInputLane"
                                            name="laneNumber"
                                            onKeyDown={(e)=>{
                                                handleEventOnKeyDownForNumber(e, null)
                                                handleOnPressEnter(e)
                                            }}
                                            onChange={(e) => handleOnchange(e)}
                                        />
                                    </FormGroup>
                                </Col>
                            )}
                        </Row>
                        <Row className="ml-1">
                            <Col md={12} className="pl-0 ml-n1 font-italic leave-info-message">
                                    <span mauto="maLabelLeaveInfoMessage">
                                       {t('LABEL.LANE_MANAGEMENT.LABEL.LEAVE_MESSAGE')}
                                   </span>
                            </Col>
                        </Row>
                        <Row className="ml-1">
                            <Col md={12} className="pl-0">
                                <FormGroup row className={"mr-1"}>
                                    <Input.Label fontSize="sm">{t('LABEL.LANE_MANAGEMENT.LABEL.REQUESTED_BY')}</Input.Label>
                                    <Input
                                        className="prism-input"
                                        value={runRequestPayload?.requestedBy}
                                        placeholder={t('LABEL.LANE_MANAGEMENT.LABEL.ENTER_NAME_PLACE_HOLDER')}
                                        maxLength={ConstraintConstant.REQUESTED_BY_MAX_LENGTH}
                                        mauto="maInputRequestedBy"
                                        name="requestedBy"
                                        onKeyDown={(e)=>{
                                            handleEventOnKeyDownForNumberAlphabet(e, [188, 32])
                                            handleOnPressEnter(e)
                                        }}
                                        onChange={(e)=>handleOnchange(e)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="ml-1 mb-5">
                            <Col md={12} className="pl-0">
                                <FormGroup row className={"mr-1 mt-0"}>
                                    <Col className={"px-0"}>
                                        <Input.Label fontSize="sm">{t('LABEL.LANE_MANAGEMENT.LABEL.COMMENTS')}</Input.Label>
                                        <Input.TextCounter
                                            className="prism-input"
                                            value={runRequestPayload?.comments}
                                            placeholder={t('LABEL.LANE_MANAGEMENT.LABEL.COMMENTS_PLACE_HOLDER')}
                                            maxlength={ConstraintConstant.COMMENTS_MAX_LENGTH}
                                            rows="5"
                                            mauto="maInputComments"
                                            name="comments"
                                            onChange={(e)=>handleOnchange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Space>

                    <div className="row lane-management-create-request-footer">
                        {!!isCreateRequestOpen && (
                            <div className="flex-sm-row-reverse control-buttons">
                                <Button
                                    className="m-2"
                                    mauto="maButtonCreateRequest"
                                    disabled={!hasData || errorMessage.hasError}
                                    onClick={() => {
                                        handleCreateRunRequest()
                                    }}
                                    size="sm"
                                    color="primary">
                                    {t('LABEL.LANE_MANAGEMENT.LABEL.ADD_REQUEST')}
                                </Button>
                            </div>
                        )}
                        {!!isEditRequestOpen && (
                            <div className="flex-sm-row-reverse control-buttons">
                                <Button
                                    className="m-2"
                                    mauto="maButtonUpdateRequest"
                                    disabled={!hasData || errorMessage.hasError}
                                    onClick={() => {
                                        handleEditRunRequest()
                                    }}
                                    size="sm"
                                    color="primary">
                                    {t('LABEL.LANE_MANAGEMENT.LABEL.UPDATE_REQUEST')}
                                </Button>
                            </div>
                        )}
                    </div>
                </SlideOut>
            </Container>

            {
                !!shownSearchSellerPanel
                && (
                    <SearchSellerPanel
                        auctionCode={runRequestData.auctionCode}
                        searchSellerOpen={shownSearchSellerPanel}
                        customers={sellerSearchCustomers}
                        searchData={sellerSearchData}
                        isVehicleDetailParticular={true}
                        setSearchSellerOpen={setShownSearchSellerPanel}
                        setSellerNumber={(value)=>{
                            setRunRequestPayload({...runRequestPayload, sellerNumber: value})
                            setEditingRequest({...editingRequest, sellerNumber: value})
                        }}
                        setCustomers={setSellerSearchCustomers}
                        setSearchData={setSellerSearchData}
                        setHasDataChanged={()=>{}}
                    />
                )
            }
        </>
    )
}