import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {concat, find, first, isEmpty, isEqual, isNil, isNull, isNumber, toInteger, toString} from 'lodash'
import { Button, Col, Container, Row, Space } from '@prism/library'
import { Dropdown } from '../common/custom/prism/dropdown/Dropdown'
import {
    getCurrentSale,
    getLaneNumbersBySaleYearAndSaleNumberAndAuctionCode,
    getSaleNumbersBySaleYearAndAuctionCode
} from '../../utils/data-source/sale-datasource'
import { getRunRequest } from '../../utils/data-source/lane-management-datasource'
import { handleErrorException, isBlank } from '../../utils/utils'
import { laneManagementMessages } from '../../actions/LaneManagementAction'
import { useDispatch, useSelector } from 'react-redux'
import {getSaleYearLane, resetSaleYearLane} from '../../actions/AssignInventoryAction'
import {useLocation} from 'react-router-dom';
import {QUERY_PARAMS} from '../../utils/constant/LaneMangementSearchBarConstant';

export function LaneManagementSearchBar({ 
    auctionCodeFromState,
    auctionCodeFromCookie,
    saleYears,
    setVehicles,
    setLastSaved,
    setLaneNumber,
    setErrorEmptyAuctionCode,
    setErrorInvalidAuctionCode,
    setErrorNotExistsAuctionCode,
    setErrorNotConfigSaleYear,
    setErrorNotConfigSaleNumber,
    setErrorWhenSaveDraftVehicle,
    setErrorWhenClearMappedLane,
    setMappedRequests,
    setUnMappedRequests,
    setCreateRunRequestData,
    isSaveDraftVehicle,
    setIsSaveDraftVehicle
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [touched, setTouched] = useState(false)
    const [initialized, setInitialized] = useState(true)

    const [saleNumbers, setSaleNumbers] = useState([])
    const [laneNumbers, setLaneNumbers] = useState([])

    const [currentSaleYear, setCurrentSaleYear] = useState()
    const [currentSaleNumber, setCurrentSaleNumber] = useState()
    const [saleYearOptions, setSaleYearOptions] = useState([])
    const [saleNumberOptions, setSaleNumberOptions] = useState([])
    const [laneNumberOptions, setLaneNumberOptions] = useState([])

    const [selectedSaleYearOption, setSelectedSaleYearOption] = useState({})
    const [selectedSaleNumberOption, setSelectedSaleNumberOption] = useState({})
    const [selectedLaneNumberOption, setSelectedLaneNumberOption] = useState({})
    const location = useLocation()
    const queryParams =  new URLSearchParams(location.search)

    const [disableSaleYear, setDisableSaleYear] = useState(false)

    const { revertConfirmed } = useSelector(states => states.resetStateObservingContext.laneManagement);
    let saleYearLaneContext = useSelector(states => states.saleYearLane.saleYearLaneContext)

    // Reset when auction code changes
    useEffect(() => {
        setTouched(false)
        setInitialized(true)
        setLaneNumbers([])
        setLaneNumber(null)
        setErrorEmptyAuctionCode(null)
        setErrorInvalidAuctionCode(null)
        setErrorNotExistsAuctionCode(null)
        setErrorNotConfigSaleYear(null)
        setErrorNotConfigSaleNumber(null)
        setErrorWhenSaveDraftVehicle(null)
        setErrorWhenClearMappedLane(null)
        setDisableSaleYear(true)
        setVehicles([])
        setLastSaved(null)
        setMappedRequests([])
        setUnMappedRequests([])
    }, [auctionCodeFromState, auctionCodeFromState])

    // Update search context from query params
    useEffect(() => {
        if (initialized) {
            const linkParam = queryParams.get(QUERY_PARAMS.LINK);
            const saleNumberParam = queryParams.get(QUERY_PARAMS.SALE_NUMBER);
            const laneNumberParam = queryParams.get(QUERY_PARAMS.LANE_NUMBER);
            if (saleNumberParam && laneNumberParam && laneNumberParam && linkParam) {
                saleYearLaneContext.saleYear =  toInteger(queryParams.get(QUERY_PARAMS.SALE_YEAR))
                saleYearLaneContext.saleNumber = toInteger(saleNumberParam)
                saleYearLaneContext.laneNumber = toInteger(laneNumberParam)
                saleYearLaneContext.isClickedOnLink = linkParam
            }
        }

    }, [saleYearLaneContext])

    // Reset data when change sale year, sale number, lane number
    useEffect(() => {
        setVehicles([])
        setLaneNumber(null)
        setLastSaved(null)
        setErrorWhenSaveDraftVehicle(null)
        setErrorWhenClearMappedLane(null)
    }, [selectedSaleYearOption, selectedSaleNumberOption])

    const buildSaleYearDropdownOptions = (saleYears) => {
        const options = saleYears.map((saleYear) => {
            return {
                key: saleYear,
                value: saleYear,
                name: saleYear
            }
        })
        return concat({
            key: null,
            value: null,
            name: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.PLACEHOLDER.SELECT')
        }, options)
    }

    const buildSaleNumberDropdownOptions = (saleNumbers) => {
        const options = saleNumbers.map((saleNumber) => {
            return {
                key: saleNumber,
                value: saleNumber,
                name: saleNumber
            }
        })
        return concat({
            key: null,
            value: null,
            name: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.PLACEHOLDER.SELECT')
        }, options)
    }

    const buildLaneNumberDropdownOptions = (laneNumbers) => {
        const options = laneNumbers.map((laneNumber) => {
            return {
                key: laneNumber,
                value: laneNumber,
                name: laneNumber
            }
        })
        return concat({
            key: null,
            value: null,
            name: t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.PLACEHOLDER.SELECT')
        }, options)
    }

    const auctionCode = useMemo(
        () => auctionCodeFromState || auctionCodeFromCookie,
        [auctionCodeFromState, auctionCodeFromCookie]
    )

    useMemo(() => {
        getCurrentSale(auctionCode)
            .then((response) => {
                setErrorNotExistsAuctionCode(null)
                const { data } = response
                let currentSaleYear = data.saleYear
                let currentSaleNumber = data.saleNumber

                if(!isNil(saleYearLaneContext)
                    && !isBlank(saleYearLaneContext.saleYear)
                    && !isBlank(saleYearLaneContext.saleNumber)
                    && saleYearLaneContext.isClickedOnLink)
                {
                    currentSaleYear = saleYearLaneContext.saleYear
                    currentSaleNumber = saleYearLaneContext.saleNumber
                }

                getSaleNumbersBySaleYearAndAuctionCode(
                    currentSaleYear,
                    auctionCode)
                    .then((response) => {
                        const saleNumbers = response.data
                        getLaneNumbersBySaleYearAndSaleNumberAndAuctionCode(
                            currentSaleYear,
                            currentSaleNumber,
                            auctionCode)
                            .then((response) => {
                                const laneNumbers = response.data
                                setCurrentSaleYear(currentSaleYear)
                                setCurrentSaleNumber(currentSaleNumber)

                                setSaleNumbers(saleNumbers)
                                setLaneNumbers(laneNumbers)
                            })
                    })
            })
            .catch((ex) => {
                setDisableSaleYear(true)
                setErrorNotExistsAuctionCode(handleErrorException(ex))
            })
    }, [auctionCode])

    useMemo(() => {
        const saleYearOptions = buildSaleYearDropdownOptions(saleYears)
        setSaleYearOptions(saleYearOptions)
        if (!touched) {
            if (!isNil(currentSaleYear)) {
                let saleYear = currentSaleYear

                //Set sale year when user click on link from assign inventory if value existed
                if(!isNil(saleYearLaneContext) && !isBlank(saleYearLaneContext.saleYear && saleYearLaneContext.isClickedOnLink)
                ) {
                    saleYear = saleYearLaneContext.saleYear
                }
                const option = find(saleYearOptions, (saleYearOption) => {
                    return isEqual(saleYearOption.key, saleYear)
                })

                if (!isNil(option)) {
                    setSelectedSaleYearOption(option)
                } else {
                    setSelectedSaleYearOption(first(saleYearOptions))
                }
            } else {
                setSelectedSaleYearOption(first(saleYearOptions))
            }
        } else {
            setSelectedSaleYearOption(first(saleYearOptions))
        }
        if(!isEmpty(saleYears)) {
            setDisableSaleYear(false)
        }
    }, [currentSaleYear, saleYears])

    useMemo(() => {
        const saleNumberOptions = buildSaleNumberDropdownOptions(saleNumbers)
        setSaleNumberOptions(saleNumberOptions)
        if (!touched) {
            if (!isNil(currentSaleNumber)) {
                let saleNumber = currentSaleNumber

                //Set sale number when user click on link from assign inventory if value existed
                if(!isNil(saleYearLaneContext) && !isBlank(saleYearLaneContext.saleNumber && saleYearLaneContext.isClickedOnLink)) {
                    saleNumber = saleYearLaneContext.saleNumber
                }

                const option = find(saleNumberOptions, (saleNumberOption) => {
                    return isEqual(saleNumberOption.key, saleNumber)
                })
                if (!isNil(option)) {
                    setSelectedSaleNumberOption(option)
                } else {
                    setSelectedSaleNumberOption(first(saleYearOptions))
                }
            } else {
                setSelectedSaleNumberOption(first(saleNumberOptions))
            }
        } else {
            setSelectedSaleNumberOption(first(saleNumberOptions))
        }
    }, [currentSaleNumber, saleNumbers])

    useMemo(() => {
        const laneNumberOptions = buildLaneNumberDropdownOptions(laneNumbers)
        setLaneNumberOptions(laneNumberOptions)

        let laneNumber = null;
        //Set lane number when user click on link from assign inventory if value existed
        if(!isNil(saleYearLaneContext) && !isBlank(saleYearLaneContext.laneNumber && saleYearLaneContext.isClickedOnLink)) {
            laneNumber = saleYearLaneContext.laneNumber
        }

        const option = find(laneNumberOptions, (laneNumberOption) => {
            return isEqual(laneNumberOption.key, laneNumber)
        })
        if (!isNil(option)) {
            setSelectedLaneNumberOption(option)
        } else {
            setSelectedLaneNumberOption(first(laneNumberOptions))
        }
    }, [laneNumbers])

    useEffect(() => {
        if (isNil(auctionCode)
            || isEmpty(auctionCode)) {
            setDisableSaleYear(true)
            setErrorEmptyAuctionCode(t('MESSAGE.COMMON.AUCTION_EMPTY'))
        } else {
            setErrorEmptyAuctionCode(null)
            setErrorNotConfigSaleNumber(null)
            const patternUrlCharacter = /[`\%^\{}\[\]\"<>:]+/g
            if (patternUrlCharacter.test(auctionCode)) {
                setErrorInvalidAuctionCode(t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.INVALID_AUCTION_CODE_URL_CHARACTER'))
            } else {
                const patternSpecialCharacter = /[~!@#$&*()_\-+=:;,\.?\/:]+/g
                if (patternSpecialCharacter.test(auctionCode)) {
                    setErrorInvalidAuctionCode(t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.INVALID_AUCTION_CODE_SPECIAL_CHARACTER'))
                } else {
                    setErrorInvalidAuctionCode(null)
                }
            }
        }
        if (!initialized) {
            setTouched(true)
        }
    }, [auctionCode])

    useEffect(() => {
        if (initialized) {
            setInitialized(false)
        }
    }, [])


    useEffect(() => {
        setCreateRunRequestData(selectedSaleYearOption.value, selectedSaleNumberOption.value, selectedLaneNumberOption.value)
        if (!isNil(selectedLaneNumberOption.value)) {
            setLaneNumber(selectedLaneNumberOption.value)
            const request = {
                auctionCode: auctionCode,
                saleYear: selectedSaleYearOption.value,
                saleNumber: selectedSaleNumberOption.value,
                laneNumber: selectedLaneNumberOption.value
            }
            getRunRequest(request)
                .then((response) => {
                    setLastSaved(response.data?.savedLane)
                    setVehicles(response.data?.vehicleRuns)
                    setMappedRequests(response.data?.mappedRequests)
                    setUnMappedRequests(response.data?.unmappedRequests)
                    window.setLaneManagementErrorMessage(null)
                }).catch((ex) => {
                    //TODO: handle error
                console.log(ex)
            }).finally(()=>{
                dispatch(resetSaleYearLane())
                setIsSaveDraftVehicle(false)
            })
        }
    }, [selectedLaneNumberOption, revertConfirmed, isSaveDraftVehicle])

    const onChangeDropdownSaleYear = useCallback((option) => {
        setTouched(true)
        setSelectedSaleYearOption(option)
        setErrorNotConfigSaleNumber(null)
        window.setLaneManagementSaveMappedLaneError(null)
        window.setLaneManagementErrorMessage(null)
        const saleYear = option.value
        setMappedRequests([])
        setUnMappedRequests([])
        dispatch(laneManagementMessages(null))
        if (isNil(saleYear)) {
            setSaleNumbers([])
            setLaneNumbers([])
        } else {
            getSaleNumbersBySaleYearAndAuctionCode(
                saleYear,
                auctionCode)
                .then((response) => {
                    const saleNumbers = response.data
                    const laneNumbers = []

                    setSaleNumbers(saleNumbers)
                    setLaneNumbers(laneNumbers)

                    if (!isNil(saleNumbers)
                        && !isEmpty(saleNumbers)) {
                        setErrorNotConfigSaleYear(null)
                    } else {
                        setErrorNotConfigSaleYear(
                            t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.NOT_CONFIG_SALE_YEAR',
                                {
                                    'AUCTION_CODE': auctionCode,
                                    'SALE_YEAR': saleYear
                                })
                        )
                    }
                })
                .catch((ex) => {
                    setErrorNotConfigSaleYear(
                        t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.NOT_CONFIG_SALE_YEAR',
                            {
                                'AUCTION_CODE': auctionCode,
                                'SALE_YEAR': saleYear
                            })
                    )
                })
        }
    }, [auctionCode])

    const onChangeDropdownSaleNumber = useCallback((option) => {
        setTouched(true)
        setSelectedSaleNumberOption(option)
        setErrorNotConfigSaleNumber(null)
        window.setLaneManagementSaveMappedLaneError(null)
        window.setLaneManagementErrorMessage(null)
        setMappedRequests([])
        setUnMappedRequests([])
        const saleYear = selectedSaleYearOption.value
        const saleNumber = option.value
        dispatch(laneManagementMessages(null))
        if (isNil(saleNumber)) {
            setLaneNumbers([])
        } else {
            getLaneNumbersBySaleYearAndSaleNumberAndAuctionCode(
                saleYear,
                saleNumber,
                auctionCode)
                .then((response) => {
                    const laneNumbers = response.data
                    setLaneNumbers(laneNumbers)
                    if (!isNil(laneNumbers)
                        && !isEmpty(laneNumbers)) {
                        setErrorNotConfigSaleNumber(null)
                    } else {
                        setErrorNotConfigSaleNumber(
                            t('MESSAGE.COMMON.NEXT_AVAILABLE_ERROR_MESSAGE', { auctionCode: auctionCode, saleNumber: saleNumber })
                        )
                    }
                })
                .catch((ex) => {
                    setErrorNotConfigSaleNumber(
                        t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.NOT_CONFIG_SALE_NUMBER',
                            {
                                'AUCTION_CODE': auctionCode,
                                'SALE_YEAR': saleYear,
                                'SALE_NUMBER': saleNumber
                            })
                    )
                })
        }
    }, [auctionCode, selectedSaleYearOption])

    const onChangeDropdownLaneNumber = useCallback((option) => {
        setSelectedLaneNumberOption(option)
        setLaneNumber(option.value)
        setLastSaved(null)
        setVehicles([])
        setErrorNotConfigSaleNumber(null)
        setErrorWhenSaveDraftVehicle(null)
        setErrorWhenClearMappedLane(null)
        window.setLaneManagementSaveMappedLaneError(null)
        window.setLaneManagementErrorMessage(null)
        setMappedRequests([])
        setUnMappedRequests([])
        dispatch(laneManagementMessages(null))
    }, [])

    const isDisabledDropdownSaleNumber = useCallback(() => {
        return isNil(selectedSaleYearOption.value)
            || isEmpty(toString(selectedSaleYearOption.value))
    }, [selectedSaleYearOption])

    const isDisabledDropdownLaneNumber = useCallback(() => {
        return isNil(selectedSaleNumberOption.value)
            || isEmpty(toString(selectedSaleNumberOption.value))
            || isNil(selectedSaleYearOption.value)
            || isEmpty(toString(selectedSaleYearOption.value))
    }, [selectedSaleYearOption, selectedSaleNumberOption])

    return (
        <Container
            fluid
            className="assign-inventory-search-bar py-1 pl-4 pr-3">
            <Row>
                <Col md={10} className="pl-0">
                    <Space>
                        <Space className="assign-inventory-search-bar-input-group">
                            <span
                                mauto="maLabelSaleYear"
                                className="assign-inventory-search-bar-label">
                                {t(
                                    'LABEL.ASSIGN_INVENTORY.SEARCH_BAR.SALE_YEAR'
                                )}
                            </span>
                            <Dropdown
                                mauto="maDropdownSaleYear"
                                options={saleYearOptions}
                                selectedOption={selectedSaleYearOption}
                                onChange={onChangeDropdownSaleYear}
                                disabled={disableSaleYear}
                            />
                        </Space>
                        <Space className="assign-inventory-search-bar-input-group">
                            <span
                                mauto="maLabelSaleNumber"
                                className="assign-inventory-search-bar-label">
                                {t(
                                    'LABEL.ASSIGN_INVENTORY.SEARCH_BAR.SALE_NUMBER'
                                )}
                            </span>
                            <Dropdown
                                mauto="maDropdownSaleNumber"
                                options={saleNumberOptions}
                                selectedOption={selectedSaleNumberOption}
                                onChange={onChangeDropdownSaleNumber}
                                disabled={isDisabledDropdownSaleNumber()}
                            />
                        </Space>
                        <Space className="assign-inventory-search-bar-input-group">
                            <span
                                mauto="maLabelLaneNumber"
                                className="assign-inventory-search-bar-label">
                                {t(
                                    'LABEL.ASSIGN_INVENTORY.SEARCH_BAR.LANE_NUMBER'
                                )}
                            </span>
                            <Dropdown
                                mauto="maDropdownLaneNumber"
                                options={laneNumberOptions}
                                selectedOption={selectedLaneNumberOption}
                                onChange={onChangeDropdownLaneNumber}
                                disabled={isDisabledDropdownLaneNumber()}
                            />
                        </Space>
                    </Space>
                </Col>
            </Row>
        </Container>
    )
}
