import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AssignInventorySearchBar } from './AssignInventorySearchBar'
import { AssignInventoryDetail } from './AssignInventoryDetail'
import { isProduction } from '../../utils/utils'
import { includes } from 'lodash'
import AssignInventoryMessages from "./AssignInventoryMessages";
import "./assign-inventory.scss"
export function AssignInventory({
    auctionCodeFromState,
    auctionCodeFromCookie,
    saleYears,
    auctionConnectionError
}) {
    const { t } = useTranslation()

    const [isCharLimitError, setIsCharLimitError] = useState(false)
    const [isInvalidCodeError, setIsInvalidCodeError] = useState(false)
    const [onlyNumberError, setOnlyNumberError] = useState(false)
    const [searchError, setSearchError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [charLimitType, setCharLimitType] = useState(null)
    const [isEmptySearch, setIsEmptySearch] = useState(false)
    const [badRequestError, setBadRequestError] = useState(false)
    const [badURI, setBadURI] = useState(null)
    const [badPayload, setBadPayload] = useState(null)
    const [isGetPreRegistError, setIsGetPreRegistError] = useState(false)

    
    const [draftVehicles, setDraftVehicles] = useState([])
    const [laneInventory, setLaneInventory] = useState([])
    const [inventory, setInventory] = useState([])
    const [runRanges, setRunRanges] = useState([])
    const [laneNumber, setLaneNumber] = useState(null)
    //vehicles - the variable contains the list of origin vehicle from response
    const [vehicles, setVehicles] = useState(null)
    const [submitCount, setSubmitCount] = useState(0)
    const [laneSummary, setLaneSummary] = useState(null)
    const [saveDraftVehicleMessage, setSaveDraftVehicleMessage] = useState(null)
    const [isDraftVehiclesChangedByRemove, setIsDraftVehiclesChangedByRemove] = useState(false)

    // Reset when auction code changes
    useEffect(() => {
        setIsCharLimitError(false)
        setIsInvalidCodeError(false)
        setOnlyNumberError(false)
        setSearchError(false)
        setErrorMessage(null)
        setCharLimitType(null)
        setIsEmptySearch(false)
        setBadRequestError(false)
        setBadURI(null)
        setBadPayload(null)
        setIsGetPreRegistError(false)

        setDraftVehicles([])
        setLaneInventory([])
        setInventory([])
        setRunRanges([])
        setLaneNumber(null)

        setVehicles([])
        setLaneSummary(null)
        setSaveDraftVehicleMessage(null)
        setIsDraftVehiclesChangedByRemove(false)
    }, [auctionCodeFromState, auctionCodeFromCookie]);

    const handleSubmitCount = () => {
        setSubmitCount(submitCount + 1)
        
        //Reset flag when click on Go button
        setIsDraftVehiclesChangedByRemove(false)
    }

    window.updateDraftVehicles = function(vehicle) {
        const index = draftVehicles.findIndex(v => v.vin === vehicle.vin && v.workOrderNumber === vehicle.workOrderNumber)
        if (index !== -1) {
            draftVehicles[index] = vehicle
        } else {
            draftVehicles.push(vehicle)
        }

        setDraftVehicles((oldDraftVehicles) => {
            const newDraftVehicles = [...oldDraftVehicles]
            return newDraftVehicles
        })
    }

    window.updateDraftVehicleList = function(vehicleList) {
        const draftVehiclesCloned = [...draftVehicles]
        vehicleList.forEach(vehicle => {
            const index = draftVehiclesCloned.findIndex(v => v.vin === vehicle.vin && v.workOrderNumber === vehicle.workOrderNumber)
            if (index !== -1) {
                draftVehiclesCloned[index] = vehicle
            } else {
                draftVehiclesCloned.push(vehicle)
            }
        })

        setDraftVehicles(draftVehiclesCloned)
    }

    window.removeFromDraftVehicles = function(vehicle) {
        const index = draftVehicles.findIndex(v => v.vin === vehicle.vin && v.workOrderNumber === vehicle.workOrderNumber)
        if (index !== -1) {
            draftVehicles.splice(index, 1)
            setDraftVehicles((oldDraftVehicles) => {
                const newDraftVehicles = [...oldDraftVehicles]
                return newDraftVehicles
            })
        }

        if (!isDraftVehiclesChangedByRemove) {
            setIsDraftVehiclesChangedByRemove(true)
        }
    }

    window.setSaveDraftVehicleMessage = function(message) {
        setSaveDraftVehicleMessage(message)
    }

    /**
     * Remove a vehicle from vehicles
     */
    window.removeUnAssignVehicles = function(data) {
        let index = vehicles.findIndex(p => p.vin === data.vin && p.workOrderNumber === data.workOrderNumber && p.sblu === data.sblu)
        if (index !== -1) {
            vehicles.splice(index, 1)
            setVehicles(vehicles)
        }
    }

    /**
     * Update vehicles (for fliter) after perform auto assign
     * @param data
     */
    window.updateVehiclesAfterAutoAssign = function(vehicleList, isCheckedAll) {
        let vehiclesCloned = [...vehicles]
        vehicleList.forEach(item => {
            vehiclesCloned.removeAnElementIf(p => p.vin === item.vin && p.workOrderNumber === item.workOrderNumber && p.sblu === item.sblu)
        })
        if (isCheckedAll) {
            vehiclesCloned.forEach(p => {p.checked = false})
        }
        setVehicles(vehiclesCloned)
    }

    /**
     * Add a new vehicle to vehicles
     */
    window.addUnAssignVehicles = function(vehicle) {
        vehicles.unshift(vehicle)
        setVehicles(vehicles)
    }

    /**
     * Update an existed vehicle from vehicles
     */
    window.updateVehiclesInAssignInventory = function(newVehicleData) {
        let existedVehicles = vehicles.filter(p => p.vin === newVehicleData.vin && p.workOrderNumber === newVehicleData.workOrderNumber && p.sblu === newVehicleData.sblu)
        if (existedVehicles && existedVehicles.length > 0) {
            let existedVehicle = existedVehicles[0]
            let index = vehicles.indexOf(existedVehicle)
            existedVehicle = {...existedVehicle, ...newVehicleData}
            vehicles[index] = existedVehicle
            setVehicles((oldVehicles) => {
                const newVehicles = [...oldVehicles]
                return newVehicles
            })
        }
    }

    /**
     * Remove draft vehicles upon vehicles sent to AS400 success
     */
    window.handleVehiclesSentToAS400 = function (sbluList) {
        draftVehicles.removeIf(item => includes(sbluList, item.sblu))
        setDraftVehicles(draftVehicles)
    }

    return (
        <div className="main-assign-inventory">
            <div className="assign-inventory">
                <AssignInventorySearchBar
                    auctionCodeFromState={auctionCodeFromState}
                    auctionCodeFromCookie={auctionCodeFromCookie}
                    saleYears={saleYears}
                    setDraftVehicles={setDraftVehicles}
                    setLaneInventory={setLaneInventory}
                    inventory={inventory}
                    setInventory={setInventory}
                    setRunRanges={setRunRanges}
                    setLaneNumber={setLaneNumber}
                    setErrorMessage={(value) => setErrorMessage(value)}
                    setSearchError={(value) => setSearchError(value)}
                    setOnlyNumberError={setOnlyNumberError}
                    setCharLimitType={setCharLimitType}
                    setIsCharLimitError={setIsCharLimitError}
                    setIsInvalidCodeError= {setIsInvalidCodeError}
                    setIsEmptySearch={setIsEmptySearch}
                    setBadRequestError={setBadRequestError}
                    setBadURI={setBadURI}
                    setBadPayload={setBadPayload}
                    vehicles={vehicles}
                    setVehicles={setVehicles}
                    handleSubmitCount={handleSubmitCount}
                    setLaneSummary={setLaneSummary}
                    setIsGetPreRegistError={setIsGetPreRegistError}
                    auctionConnectionError={auctionConnectionError}
                />
            </div>

            {/* Inventory messages */}
            <div className="assign-inventory-message">
                <AssignInventoryMessages
                    isCharLimitError={isCharLimitError}
                    isInvalidCodeError={isInvalidCodeError}
                    onlyNumberError={onlyNumberError}
                    searchError={searchError}
                    isEmptySearch={isEmptySearch}
                    badRequestError={badRequestError}
                    isProduction={isProduction}
                    laneSummary={laneSummary}
                    isGetPreRegistError={isGetPreRegistError}
                    saveDraftVehicleMessage={saveDraftVehicleMessage}
                    errorMessage={errorMessage}
                    charLimitType={charLimitType}
                    badPayload={badPayload}
                />
            </div>


            {/* Inventory detail */}
                <AssignInventoryDetail 
                  auctionCodeFromState={auctionCodeFromState}
                  auctionCodeFromCookie={auctionCodeFromCookie}
                  inventory={inventory}
                  setInventory={setInventory}
                  draftVehicles={draftVehicles}
                  setDraftVehicles={setDraftVehicles}
                  isDraftVehiclesChangedByRemove={isDraftVehiclesChangedByRemove}
                  laneInventory={laneInventory}
                  setLaneInventory={setLaneInventory}
                  runRanges={runRanges}
                  laneNumber={laneNumber}
                  vehicles={vehicles}
                  setVehicles={setVehicles}
                  submitCount={submitCount}
                  setIsGetPreRegistError={setIsGetPreRegistError}
                  />
        </div>
    )
}
