import { TabPane, Tabs } from "@prism/library";
import { PathUrlConstant } from "../../../utils/constant";
import React, {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";

export function MenuTabs({
        activeTabIndex,
        disableTab
}) {
    const { t } = useTranslation()

    return (
        <Tabs color="dark" border={false} activeIndex={activeTabIndex}>
            <TabPane id={0} label={t('LABEL.MENU_BAR.SALE_OVERVIEW')} href={`#/${PathUrlConstant.SaleOverview}`} mauto="maLinkSaleOverview" disabled={disableTab}/>
            <TabPane id={1} label={t('LABEL.MENU_BAR.LANE_MANAGEMENT')}  href={`#/${PathUrlConstant.LaneManagement}`} mauto="maLinkLaneManagement" disabled={disableTab}/>
            <TabPane id={2} label={t('LABEL.MENU_BAR.ASSIGN_INVENTORY')}  href={`#/${PathUrlConstant.AssignInventory}`} mauto="maLinkAssignInventory" disabled={disableTab} />
            <TabPane id={3} label={t('LABEL.MENU_BAR.REMAINING_INVENTORY')}  href={`#/${PathUrlConstant.RemainingInventory}`}  mauto="maLinkRemainingInventory" disabled={disableTab} />
            <TabPane id={4} label={t('LABEL.MENU_BAR.REQUEST_HISTORY')}  href={`#/${PathUrlConstant.RequestHistory}`} mauto="maLinkRequestHistory" disabled={disableTab} />
        </Tabs>
    )
}