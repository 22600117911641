import { Modal } from "@prism/library";
import {useCallback, useEffect, useState} from "react";
import './default-modal.scss'

export default function DefaultModal({
    message = '',
    header = '',
    onYesClick = () => {},
    onNoClick = () => {},
    isOpen = false,
}) {
    const [showModal, setShowModal] = useState(isOpen);

    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen]);

    const handleYesClick = useCallback(() => {
        onYesClick();
        setShowModal(false);
    }, [onYesClick]);

    const handleNoClick = useCallback(() => {
        onNoClick();
        setShowModal(false);
    }, [onNoClick]);

    return (
        <div>
            <Modal className="default-modal"
                isOpen={showModal}
                toggle={handleNoClick}
                keyboard={false}
                backdrop={"static"}
            >
                <Modal.Header test-data-id="header"  toggle={handleNoClick}>
                    <div dangerouslySetInnerHTML={{ __html: header }}/>
                </Modal.Header>
                <Modal.Body test-data-id="body">
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                </Modal.Body>
                <Modal.Footer>
                    <Modal.Cancel test-data-id="no-button"  text="NO" onClick={handleNoClick} />
                    <Modal.Okay test-data-id="yes-button" text="YES" onClick={handleYesClick} />
                </Modal.Footer>
            </Modal>
        </div>
    );
}