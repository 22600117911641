import { useEffect, useState, useRef } from 'react'

const BOUND_HEIGHT = 70

function getScrollDirection({
                                position,
                                upperBounds = Infinity,
                                lowerBounds = -Infinity
                            }) {
    if (position === undefined) {
        return "stable"
    }
    if (position > lowerBounds - BOUND_HEIGHT) {
        return "bottom"
    }
    if (position < upperBounds + BOUND_HEIGHT) {
        return "top"
    }
    return "stable"
}

export const useScroll = ref => {
    const [config, setConfig] = useState({
        position: 0,
        isScrollAllowed: false,
        forceStopAutoScroll: false
    })

    const scrollTimer = useRef(null)

    const scrollSpeed = 5
    const { position, isScrollAllowed, forceStopAutoScroll } = config

    const bounds = ref.current?._outerRef?.getBoundingClientRect()

    const direction = getScrollDirection({
        position,
        upperBounds: bounds?.top,
        lowerBounds: bounds?.bottom
    })

    useEffect(() => {
        if (direction !== "stable" && isScrollAllowed) {
            scrollTimer.current = setInterval(() => {
                ref.current?._outerRef?.scrollBy(0, scrollSpeed * (direction === "top" ? -1 : 1))
            }, 1)
        }

        // Force clear interval when the component unmounts
        if (forceStopAutoScroll) {
            handleClearInterval(scrollTimer.current)
        }

        return () => {
            handleClearInterval(scrollTimer.current)
        }
    }, [isScrollAllowed, direction, ref, scrollSpeed])

    function handleClearInterval(id) {
        if (id) {
            clearInterval(id)
        }
    }


    return {
        updatePosition: setConfig
    }
}
