import { NotificationMessage } from "../notification-message/NotificationMessage";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {openSnowModal} from '../../../utils/utils';
import useCustomLocation from '../../../hooks/common/UseCustomLocation';

export default function AuctionCodeErrorNotificationMessage({
    auctionConnectionError
}) {
    const {t} = useTranslation()
    const {pathName} = useCustomLocation()

    const memoizedAuctionCodeNotificationErrorMessage = useMemo(() =>
        <NotificationMessage type={'error'} isOpen={true}>
            {t('MESSAGE.COMMON.AUCTION_ERROR.PRE_TEXT')}
            <a href={pathName} onClick={openSnowModal}>
                {t('MESSAGE.COMMON.AUCTION_ERROR.LINK_TEXT')}
            </a>
            {t('MESSAGE.COMMON.AUCTION_ERROR.POST_TEXT')
                .replace(
                    '%ERROR_STATUS%',
                    auctionConnectionError.status
                )
                .replace(
                    '%ERROR_MESSAGE%',
                    auctionConnectionError.message
                )}
        </NotificationMessage>, [auctionConnectionError]);

    return (
        <>
            {memoizedAuctionCodeNotificationErrorMessage}
        </>
    )
}

export function AuctionCodeErrorMessage ({
    auctionConnectionError
}) {
    const {t} = useTranslation()
    const {pathName} = useCustomLocation()

    const memoizedAuctionCodeErrorMessage = useMemo(() => (
        <>
            {t('MESSAGE.COMMON.AUCTION_ERROR.PRE_TEXT')}
            <a href={pathName} onClick={openSnowModal}>
                {t('MESSAGE.COMMON.AUCTION_ERROR.LINK_TEXT')}
            </a>
            {t('MESSAGE.COMMON.AUCTION_ERROR.POST_TEXT')
                .replace(
                    '%ERROR_STATUS%',
                    auctionConnectionError.status
                )
                .replace(
                    '%ERROR_MESSAGE%',
                    auctionConnectionError.message
                )}
        </>

        ), [auctionConnectionError]);

    return (
        <>
            {memoizedAuctionCodeErrorMessage}
        </>
    )
}