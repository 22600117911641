import NotificationMessages from "../common/notification-messages/NotificationMessages";
import {useMessages} from "../../hooks/common/UseMessages";
import {useEffect, useMemo} from "react";
import {MessageTypeConstant} from "../../utils/constant";
import {isProduction} from "../../utils/utils";
import {useTranslation} from "react-i18next";
import {AuctionCodeErrorMessage} from "../common/error/AuctionCodeErrorNotificationMessage";

export default function SaleOverviewMessages ({
    auctionCode,
    errorNotConfigSaleNumber,
    auctionConnectionError
}) {

    const { messages, putMessage, clearMessages } = useMessages()
    const { t } = useTranslation()

    const messageConditionMapping = useMemo(() =>
        [
            {
                condition: () => !isProduction(),
                type: MessageTypeConstant.INFO,
                message: t('MESSAGE.COMMON.INFO_MESSAGE_FOR_TESTING')
            },
            {
                condition: () => auctionConnectionError?.isValidAuction === false,
                type: MessageTypeConstant.ERROR,
                message: <AuctionCodeErrorMessage auctionConnectionError={auctionConnectionError} />
            },
            {
                condition: () => !auctionCode,
                type: MessageTypeConstant.ERROR,
                message: t('MESSAGE.COMMON.AUCTION_EMPTY')
            },
            {
                condition: () => !!errorNotConfigSaleNumber,
                type: MessageTypeConstant.ERROR,
                message: errorNotConfigSaleNumber
            }
        ], [isProduction, auctionConnectionError, auctionConnectionError?.isValidAuction,
        auctionCode, errorNotConfigSaleNumber])

    useEffect(() => {
        clearMessages()
        messageConditionMapping.forEach(({condition, message, type}) => {
            if (condition() === true) {
                putMessage(message, type)
            }
        })

    }, [messageConditionMapping])

    return (
        <NotificationMessages messages={messages}></NotificationMessages>
    )
}
