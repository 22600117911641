import React, { useState } from "react";
import { buildMessage } from "../../utils/MessageUtil";
import { MessageTypeConstant } from "../../utils/constant";

export const useMessages = () => {
    const [messages, setMessages] = useState([])

    const putMessage = (messageContent, messageType) => {
        setMessages((prev) => {
            const newMessages = [...prev]
            const index = newMessages.findIndex(message => message.type === messageType)

            // Only one item for each message type except INFO
            if (index === -1) {
                newMessages.push(buildMessage(messageContent, messageType))
            }

            // Combine message infos
            if (messageType === MessageTypeConstant.INFO && index >-1) {
                const newContent = newMessages[index].message + "</br>" + messageContent
                newMessages[index].message = (<div dangerouslySetInnerHTML={{__html: newContent}}/>)
            }

            return newMessages
        })
    }

    const clearMessages = () => setMessages((prev) => [])

    return {
        messages,
        putMessage,
        clearMessages,
    }
}