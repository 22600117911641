import { isEmpty } from 'lodash'
import { AssignVehicleList } from './AssignVehicleList'
import { useTranslation } from 'react-i18next'
import {useMemo} from "react";
import './assign-vehicle-list-wrapper.scss'

export function AssignVehicleListWrapper({
    auctionCode,
    runRanges,
    isShowChecked,
    draftVehicles,
    setDraftVehicles,
    laneInventory,
    setLaneInventory,
    laneNumber,
    setIsGetPreRegistError,
    bulkUpdateVehicleErrors,
    scrollToItem
}) {

    const { t } = useTranslation()

    //Prevent unnecessary rerender
    const mainAssignVehicleList = useMemo(() =>
            <div className="assign-inventory-draggable assign-vehicle-list-wrapper table-bordered assigned-inventory">
                {isEmpty(runRanges) && (
                    <div className="text-center">
                        {t(
                            'LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.DRAG_INSTRUCTION'
                        )}
                    </div>
                )}
                <AssignVehicleList
                    auctionCode={auctionCode}
                    isShowChecked={isShowChecked}
                    draftVehicles={draftVehicles}
                    setDraftVehicles={setDraftVehicles}
                    laneInventory={laneInventory}
                    setLaneInventory={setLaneInventory}
                    runRanges={runRanges}
                    laneNumber={laneNumber}
                    setIsGetPreRegistError={setIsGetPreRegistError}
                    bulkUpdateVehicleErrors={bulkUpdateVehicleErrors}
                    scrollToItem={scrollToItem}
                />
            </div>
        , [
            auctionCode,
            isShowChecked,
            draftVehicles,
            laneInventory,
            runRanges,
            setIsGetPreRegistError,
            bulkUpdateVehicleErrors,
            scrollToItem
        ]);

    return (
        <>
            {mainAssignVehicleList}
        </>

    )
}