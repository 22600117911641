import {Icon, Message} from '@prism/library'
import {useEffect, useRef, useState} from 'react'
import classNames from 'classnames'
import './error-message.scss'
import {removeSvg} from '../../../utils/utils';

const ICON_CLASSES = {
    success: 'prism-icon-checkmark-circle',
    danger: 'prism-icon-notification-circle',
    warning: 'prism-icon-notification-circle',
    info: 'prism-icon-notification-circle'
}

export function MessageCommon({
    title = null,
    isOpen,
    color,
    children,
    className,
    onToggleCallBack
}) {
    const [isShow, setIsShow] = useState(isOpen)
    const messageRef = useRef();

    const onToggle = () => {
        setIsShow(false)
        onToggleCallBack && onToggleCallBack();
    }

    // Prevent error when svg tag when Snow Modal auto capture screenshot
    // The auto capture does not support sgv tag
    useEffect(() => {
        removeSvg(messageRef.current)
    }, []);

    return (
        <Message
            key={() => Math.random().toString(36).substr(2, 9)}
            isOpen={isShow}
            title={title}
            color={color}
            toggle={onToggle}
            innerRef={messageRef}
            className={classNames('mx-2', 'my-1', 'px-2', 'py-1', className)}>
            {/* Replace default icon by i tag, because of capture screen problem */}
            <i className={classNames('message-icon', 'icon', ICON_CLASSES[color])} />
            {children}
        </Message>
    )
}
