import React from 'react'
import { useDragLayer } from 'react-dnd'
import { AssignInventoryVehicleItem } from '../../assign-inventory/AssignInventoryVehicleItem'
import '../.././assign-inventory/assign-inventory-vehicle-item.scss'
import './custom-drag-layer.scss'
import { DnDDragTypeConstant } from '../../../utils/constant'
import { LaneManagementVehicleMetric } from '../../lane-management/LaneManagementVehicleMetric'

function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none'
        }
    }
    let { x, y } = currentOffset
    const transform = `translate(${x}px, ${y}px)`
    return {
        transform,
        WebkitTransform: transform
    }
}

const CustomDragLayer = (props) => {
    const { itemType, isDragging, item, initialOffset, currentOffset } =
      useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }))

    function buildWidth() {
        switch (itemType) {
            case DnDDragTypeConstant.ASSIGN_VEHICLE:
            case DnDDragTypeConstant.UNASSIGN_VEHICLE:
                return '40%'
            case DnDDragTypeConstant.LANE_MANAGEMENT:
                return '48%'
            default:
                return '50%'
        }
    }

    function renderItem() {
        switch (itemType) {
            case DnDDragTypeConstant.ASSIGN_VEHICLE:
                return <AssignInventoryVehicleItem vehicle={item} dragData={{dragType: DnDDragTypeConstant.CUSTOM_DRAG, origin: DnDDragTypeConstant.ASSIGN_VEHICLE}} canRemove={true} />
            case DnDDragTypeConstant.UNASSIGN_VEHICLE:
                return <AssignInventoryVehicleItem vehicle={item} dragData={{dragType: DnDDragTypeConstant.CUSTOM_DRAG, origin: DnDDragTypeConstant.UNASSIGN_VEHICLE}} isUnassign={true} />
            case DnDDragTypeConstant.LANE_MANAGEMENT:
                return <LaneManagementVehicleMetric vehicle={{...item.vehicle, isCustomDragLayer: true}} isMapped={item.isMapped} laneSavedToAS400={[]}/>
            default:
                return null
        }
    }

    function draggingToggle() {
        document.body.classList.toggle('drag-layer-item-dragging', isDragging)
    }

    if (!isDragging) {
        draggingToggle()
        return null
    }
    else {
        draggingToggle()
    }

    return (
        <div className='drag-layer' style={{width:buildWidth()}}>
            <div className='drag-layer-item' style={getItemStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </div>
    )
}

export default CustomDragLayer