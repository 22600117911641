import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { calculatedSalesPercentage, formatSearchHistoryDate } from '../../utils/utils'
import { isEmpty } from 'lodash'

export function RequestHistoryItem({runRequest, itemIndex, setSaleNumber, setLaneNumber}) {
    const { t } = useTranslation()
    const [ isMoreDetail, setIsMoreDetail ] = useState(false)
    const updateMoreDetailState = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsMoreDetail(!isMoreDetail)
        },
        [isMoreDetail]
    )
    useEffect(() => {
        setIsMoreDetail(false)
    }, [runRequest])

    function salePercentage(seller) {
        if(seller){
            seller = JSON.parse(seller);
            return isEmpty(seller) ? 'NA' : calculatedSalesPercentage(seller.stats)
        }else{
            return 'NA'
        }

    }

    function onClickSeeAllRequest(saleNumber, laneNumber){
        setSaleNumber(saleNumber)
        setLaneNumber(laneNumber)
    }

    function getSellerName(seller) {
        if(seller) {
            const sellerInfo = JSON.parse(seller);
            return sellerInfo.name;
        }
        return '';
    }

    return (
        <>
            <tr
                mauto="maTrResult"
                className={classNames('tr-run-request',isMoreDetail ? 'tr-bg-gray' : '')}
                key={runRequest.globalId}>
                <td mauto="maTdYear" className={classNames('td-sale-year',isMoreDetail ? 'td-hide-bottom-border' : '')}>
                    {runRequest.saleYear}
                </td>
                <td mauto="maTdSale" className={classNames('td-sale-number',isMoreDetail ? 'td-hide-bottom-border' : '')}>
                    {runRequest.saleNumber}
                </td>
                <td mauto="maTdLane" className={classNames('td-lane-number',isMoreDetail ? 'td-hide-bottom-border' : '')}>
                    {runRequest.laneNumber}
                </td>
                <td mauto="maTdNumber" className={classNames('td-seller-number',isMoreDetail ? 'td-hide-bottom-border' : '')}>
                    {runRequest.sellerNumber}
                </td>
                <td mauto="maTdName" className={classNames('td-name',isMoreDetail ? 'td-hide-bottom-border' : '')}>
                    {getSellerName(runRequest.seller)}
                </td>
                <td mauto="maTdRunCount" className={classNames('td-run-count',isMoreDetail ? 'td-hide-bottom-border' : '')}>
                    {runRequest.runCount}
                </td>
                <td mauto="maTdCreatedAt" className={classNames('td-created-at',isMoreDetail ? 'td-hide-bottom-border' : '')}>
                    {formatSearchHistoryDate(runRequest.createdAt)}
                </td>
                <td mauto="maTdUpdateAt" className={classNames('td-updated-at',isMoreDetail ? 'td-hide-bottom-border' : '')}>
                    {formatSearchHistoryDate(runRequest.updatedAt)}
                </td>
                <td mauto="maTdControl" className={classNames('td-control',isMoreDetail ? 'td-hide-bottom-border' : '')}>
                    <a
                        mauto="maMoreDetailCloseBtn"
                        href="#"
                        onClick={updateMoreDetailState}>
                        {isMoreDetail
                            ? t('LABEL.REQUEST_HISTORY.RESULT_TABLE.CLOSE')
                            : t('LABEL.REQUEST_HISTORY.RESULT_TABLE.MORE_DETAILS')}
                    </a>
                </td>
            </tr>
            {isMoreDetail && (
                <>
                    <tr
                        className={classNames('tr-more-detail tr-bg-gray')}
                       >
                        <td colSpan={9} className={'td-sell-all-request td-hide-border'}>
                            <a
                                mauto="maSeeAllRequest"
                                className="see-all-request"
                                href="#"
                                onClick={e => {
                                    e.preventDefault()
                                    onClickSeeAllRequest(runRequest.saleNumber,runRequest.laneNumber)
                                }}>
                                {t('LABEL.REQUEST_HISTORY.RESULT_TABLE.SEE_ALL_REQUEST',{ laneNumber: runRequest.laneNumber, saleNumber: runRequest.saleNumber })}
                            </a>
                        </td>
                    </tr>
                    <tr
                        className={classNames('tr-more-detail tr-bg-gray')}
                       >
                        <td
                            colSpan={2}
                            className={classNames('td-created-by td-hide-border')}
                            mauto="maLblCreatedBy">
                            {t(
                                'LABEL.REQUEST_HISTORY.RESULT_TABLE.CREATED_BY'
                            )}
                        </td>
                        <td
                            colSpan={2}
                            className={classNames('td-sale-efficiency td-hide-border')}
                            mauto="maLblSaleEfficiency">
                            {t(
                                'LABEL.REQUEST_HISTORY.RESULT_TABLE.SALE_EFFICIENCY'
                            )}
                        </td>
                        <td
                            className={classNames('td-requested-by td-hide-border')}
                            mauto="maLblRequestedBy">
                            {t(
                                'LABEL.REQUEST_HISTORY.RESULT_TABLE.REQUESTED_BY'
                            )}
                        </td>
                        <td colSpan={4} className={'td-comments td-hide-border'}
                            mauto="maLblComments">
                            {t(
                                'LABEL.REQUEST_HISTORY.RESULT_TABLE.COMMENTS'
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td
                            colSpan={2}
                            className={classNames('td-created-by td-hide-top-border')}
                            mauto="maDataCreatedBy">
                            {runRequest.createdBy}
                        </td>
                        <td
                            colSpan={2}
                            className={classNames('td-sale-efficiency td-hide-top-border')}
                            mauto="maDataSaleEfficiency">
                            {salePercentage(runRequest.seller)}
                        </td>
                        <td

                            className={classNames('td-requested-by td-hide-top-border')}
                            mauto="maDataRequestedBy">
                            {runRequest.requestedBy}
                        </td>
                        <td
                            colSpan={4}
                            className={classNames('td-comments td-hide-top-border')}
                            mauto="maDataComments">
                            {runRequest.comments}
                        </td>
                    </tr>
                </>
            )}
        </>
    )
}
