import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from '@prism/library'
import { useTranslation } from 'react-i18next'
import { AssignInventoryVehicleItem } from '../assign-inventory/AssignInventoryVehicleItem'
import { first, isEmpty, isEqual, isNil } from 'lodash'
import { getPreRegistrations } from '../../utils/data-source/pre-registrations-datasource'
import { PreRegistration } from '../common/pre-registration/PreRegistration'
import { EmptyRun } from '../assign-inventory/EmptyRun'
import './lane-management-detail-default.scss'
import { getFormatLastSaved } from '../../utils/utils'
import { VehicleVcf } from '../common/vehicle-info/VehicleVcf'
import {useDispatch, useSelector} from 'react-redux'
import { getVehicleAfterUpdatePreRegist } from '../../actions/PreRegistAction'
import { NotificationMessage } from '../common/notification-message/NotificationMessage'
import { ERROR_STATUS_CODE, MessageTypeConstant } from '../../utils/constant'
import {laneManagementRevertedMessage} from '../../actions/LaneManagementAction'
import { useToggleAS400 } from '../../hooks/common/UseToggleAS400'
import { clearMappedLanes, createRunAssignments } from '../../utils/data-source/lane-management-datasource'
import { LaneManagementAddRun } from './LaneManagementAddRun'
import { LaneManagementChangeRuns } from './LaneManagementChangeRuns'
import ClearMappedLaneConfirmModal from './ClearMappedLaneConfirmModal'
import { StatusCodes } from 'http-status-codes';
import { VehicleVcfEdit } from '../common/vehicle-info/VehicleVcfEdit'
import { isPreRegisterVehicle } from '../../utils/vehicle-utils'
import compareVehicles from '../assign-inventory/helper/VehicleHelper';

export function LaneManagementDetailDefault({
                                                auctionCode,
                                                vehicles,
                                                setVehicles,
                                                setIsGetPreRegistError,
                                                lastSaved,
                                                setLastSaved,
                                                laneNumber,
                                                setIsRequestBoardClicked,
                                                runRequestData,
                                                setIsSaveDraftVehicle,
                                                setErrorWhenSaveDraftVehicle,
                                                setErrorWhenClearMappedLane,
                                                setMappedRequests,
                                                setUnMappedRequests
                                                }) {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [isPreRegistrationOpen, setIsPreRegistrationOpen] = useState(false)
    const [preRegistrationData, setPreRegistrationData] = useState(null)
    const [titleVehicle, setTitleVehicle] = useState(null)
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [holderVehicles, setHolderVehicles] = useState(new Map())
    const [sblu, setSblu] = useState(null)
    const [messageUpdateVehicleSuccessful, setMessageUpdateVehicleSuccessful] = useState([])
    const [isVcfOpen, setIsVcfOpen] = useState(false)
    const [saveDraftVehicle, setSaveDraftVehicle] = useState([])
    const [hasDraftVehicle, setHasDraftVehicle] = useState(false)
    const laneManagementData = useSelector(state => state.laneManagementContext.laneManagementData)
    const spinner = useSelector(state => state.spinnerContext.spinner)
    const { isEnableUpdateAS400Flag } = useToggleAS400(runRequestData.auctionCode)
    const [isAddRunOpen, setIsAddRunOpen] = useState(false)
    const [isChangeRunsOpen, setIsChangeRunsOpen] = useState(false)
    const [isClearConfirmOpen, setIsClearConfirmOpen] = useState(false)
    const [isVcfEditOpen, setIsVcfEditOpen] = useState(false)

    function handleOnClickOpenPregistTration(sblu, linktoSeller, title) {
        setIsGetPreRegistError(false)
        getPreRegistrations(auctionCode, sblu, linktoSeller)
            .then((response) => {
                setIsPreRegistrationOpen(true)
                setTitleVehicle(title)
                setPreRegistrationData(response.data)
            }).catch((ex) => {
            setIsGetPreRegistError(true)
        })
    }

    const onUpdatePreRegistrationInAS400 = (newVehicleData) => {
        updatePreRegistrationData(newVehicleData);
        setIsPreRegistrationOpen(false);
    }

    const onDeletePreRegistrationFromAS400 = () => {
        updatePreRegistrationData({vin:""});
        setIsPreRegistrationOpen(true);
    }
    function updateHolderVehicles(vehicle) {
        holderVehicles.set(selectedVehicle.runNumber, vehicle)
        setHolderVehicles(holderVehicles)
    }
    function updateVehicleData(newVehicleData) {
        updateElementInList(vehicles, setVehicles, newVehicleData)
    }

    function updateElementInList(list, setList, newVehicleData) {
        let existedVehicle = list.find(vehicle => compareVehicles(vehicle, newVehicleData))
        if (existedVehicle) {
            setList((prev) => {
                const listCloned = [...prev]
                let index = listCloned.indexOf(existedVehicle)
                existedVehicle = {...existedVehicle, ...newVehicleData}
                listCloned[index] = {...existedVehicle}

                return listCloned
            })
        }
    }

    function handleSendToAs400() {
        const runAssignments = []
        saveDraftVehicle.forEach((vehicle) => {
            const run = {}
            run['runNumber'] = vehicle.runNumber
            run['sblu'] = vehicle.sblu
            runAssignments.push(run)
        })
        const request = {
            auctionCode: runRequestData.auctionCode,
            saleYear: runRequestData.saleYear,
            saleNumber: runRequestData.saleNumber,
            laneNumber: runRequestData.laneNumber,
            runAssignments:runAssignments
        }

         createRunAssignments(request).then((response) => {
             setIsSaveDraftVehicle(true)
             setHasDraftVehicle(false)
         }).catch((ex) => {
             setErrorWhenSaveDraftVehicle(t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.AS400.ERROR_MESSAGE'))
             handleErrorForDraftVehicles(ex)
         })
    }

    function handleErrorForDraftVehicles(ex) {
        if (isEqual(ERROR_STATUS_CODE.CONFLICT, ex.request.status)) {
            //Get error messages to array
            const errorsList = ex.response.data.message.split(',')
            const updateVehicles = vehicles.map(v => {
                if (!!v?.draftVehicle) {
                    const hasError = errorsList.filter(e => e.includes(v.sblu))
                    const error = isEmpty(hasError) ? "" : first(hasError).split(':').pop()
                    if (!!error) { v.errorMessage = error }
                }
                return v
            })
            setVehicles([...updateVehicles])
        }
    }

    function handleClearMappedLanes() {
        const request = {
            auctionCode: runRequestData.auctionCode,
            saleYear: runRequestData.saleYear,
            saleNumber: runRequestData.saleNumber,
            laneNumber: runRequestData.laneNumber,
        }
        clearMappedLanes(request).then((response) => {
            setLastSaved(null)
            setMappedRequests(response?.data?.mappedRequests)
            setUnMappedRequests(response?.data?.unmappedRequests)
        }).catch((ex) => {
            if (
                ex?.response?.status === StatusCodes.UNPROCESSABLE_ENTITY ||
                ex?.response?.status === StatusCodes.LOCKED
            ) {
                setErrorWhenClearMappedLane(ex.response?.data?.message)
            } else if (ex?.response?.status === StatusCodes.INTERNAL_SERVER_ERROR) {
                setErrorWhenClearMappedLane(ex.response?.data)
            } else setErrorWhenClearMappedLane(ex?.message)
        }).finally(() => {
            setIsClearConfirmOpen(false)
        })
    }

    function getDraftVehicle(){
        const draftVehicle = vehicles.filter((vehicle) => {
            if (vehicle.draftVehicle) {
                return vehicle.draftVehicle === true
            }
        })
        setSaveDraftVehicle(draftVehicle)
        if(draftVehicle.length > 0) {
            setHasDraftVehicle(true)
        }
    }


    useEffect(() => {
        if (!isNil(laneManagementData.revertedMessage) && spinner.show === false) {
            setTimeout(() => {
                dispatch(laneManagementRevertedMessage(null))
            }, 3000)
        }
        getDraftVehicle()
    }, [laneManagementData.revertedMessage, spinner.show])

    return (
        <>
            <Container fluid className="lane-management-detail-container mt-1">
                <Row className="lane-management-header">
                    <Col sm={8} md={6} xs={6} className="header-content pl-2 pt-1" mauto="maLblLane">
                        {!isNil(laneNumber) && (
                            <div>
                                <span>{t( 'LABEL.LANE_MANAGEMENT.LANE') + " " + laneNumber } </span>
                                {!isEmpty(lastSaved) && (
                                    <i className='ml-1'>{getFormatLastSaved(lastSaved)}</i>
                                )}
                            </div>

                        )}
                        {isNil(laneNumber) && (
                            <span>{t('LABEL.LANE_MANAGEMENT.LANE_NOT_SELECTED')} </span>
                        )}
                    </Col>
                    <Col  sm={4} md={6} xs={6}  className="text-right pl-0 px-0">
                        <Button
                            className='btn btn-primary'
                            mauto="maLblRequestBoard"
                            onClick={() => {
                                setIsRequestBoardClicked(true)
                            }}>
                            {t(
                                'LABEL.LANE_MANAGEMENT.REQUEST_BOARD'
                            )}
                        </Button>
                    </Col>
                </Row>

                <div className="table-bordered lane-management-list">
                    { !isNil(laneManagementData.revertedMessage) &&
                        <NotificationMessage type={MessageTypeConstant.SUCCESS}
                            children={t('LABEL.LANE_MANAGEMENT.REVERTED_SUCCESSFUL_MESSAGE')} isOpen={true} />
                    }
                    {!!vehicles && !isEmpty(vehicles) &&
                        vehicles.map((vehicle, index) => (
                            <>
                                {!isNil(vehicle) && vehicle.status === "EMPTY" &&
                                    <EmptyRun
                                        laneNumber={laneNumber}
                                        vehicle={vehicle}
                                        index={index}
                                    />
                                }
                                {!isNil(vehicle) && vehicle.status !== "EMPTY" && (
                                    <AssignInventoryVehicleItem
                                        vehicle={vehicle}
                                        messageUpdateVehicleSuccessful={messageUpdateVehicleSuccessful}
                                        dragData={{dragType: ''}}
                                        canRemove={false}
                                        isUnassign={false}
                                        handleOnClickOpenPregistTration={(sblu, linktoSeller, title) => {
                                            setSelectedVehicle(vehicle);
                                            handleOnClickOpenPregistTration(sblu, true, title)
                                        }}
                                        setMessageUpdateVehicleSuccessful={setMessageUpdateVehicleSuccessful}
                                        handleVcfOnClick={(sblu) => {
                                            setSblu(sblu)
                                            if(isPreRegisterVehicle(vehicle)) {
                                                setIsVcfEditOpen(true)
                                            } else {
                                                setIsVcfOpen(true)
                                            }
                                        }}
                                        isLaneManagement={true}
                                        allowDrag={false}
                                        allowDrop={false}
                                        updateVehicleData={updateVehicleData}
                                        setGetVehicleError={() => {}}
                                        isShownSellerSearchButton={true}
                                        isAlwaysShowUpdateParticularSuccessfulMessage={true}
                                    />

                                )}
                            </>
                        ))
                    }
                </div>

                <div className="sub-menu table-bordered">
                    <Row className="pl-2 py-1">
                        <Col  sm={9} md={6} xs={9}>
                            <Button
                                className="btn btn-outline"
                                mauto="maLblBtnChangeRuns"
                                onClick={() => {
                                    setIsChangeRunsOpen(true)
                                }}
                                disabled={!isEnableUpdateAS400Flag || isEmpty(vehicles)}
                            >
                                {t(
                                    'LABEL.LANE_MANAGEMENT.CHANGE_RUNS'
                                )}
                            </Button>
                            <Button
                                className="btn btn-outline ml-1"
                                mauto="maLblBtnAddRuns"
                                onClick={() => {setIsAddRunOpen(true)
                                }}
                                disabled={!isEnableUpdateAS400Flag || isEmpty(vehicles)}
                            >
                                {t(
                                    'LABEL.LANE_MANAGEMENT.ADD_RUNS'
                                )}
                            </Button>
                            <Button
                                className="btn btn-outline ml-1"
                                mauto="maLblBtnAS400"
                                onClick={handleSendToAs400}
                                disabled={!isEnableUpdateAS400Flag || !hasDraftVehicle}
                            >
                                {t(
                                    'LABEL.LANE_MANAGEMENT.SEND_TO_AS400'
                                )}
                            </Button>
                        </Col>
                        <Col sm={3} md={6} xs={3} className="text-right">
                            <Button
                                variant="textonly"
                                mauto="maLblBtnClearAll"
                                onClick={()=> setIsClearConfirmOpen(true)}
                                disabled={!isEnableUpdateAS400Flag}
                            >
                                {t(
                                    'LABEL.LANE_MANAGEMENT.CLEAR_ALL'
                                )}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Container>
            <ClearMappedLaneConfirmModal
                setIsClearConfirmOpen={setIsClearConfirmOpen}
                isClearConfirmOpen={isClearConfirmOpen}
                handleClearMappedLane={handleClearMappedLanes}
            />
            {!!isPreRegistrationOpen && (
                <PreRegistration
                    isPreRegistrationOpen={isPreRegistrationOpen}
                    setIsPreRegistrationOpen={setIsPreRegistrationOpen}
                    auctionCode={auctionCode}
                    preRegistrationData={preRegistrationData}
                    titleVehicle={titleVehicle}
                    onDeleteFromAS400={onDeletePreRegistrationFromAS400}
                    onUpdateInAS400={onUpdatePreRegistrationInAS400}
                />
            )}
            {!!isVcfOpen && (
                <VehicleVcf
                    isVcfOpen={isVcfOpen}
                    setIsVcfOpen={setIsVcfOpen}
                    auctionCode={auctionCode}
                    sbluNumber={sblu}
                />
            )}
            {!!isVcfEditOpen && (
                <VehicleVcfEdit
                    isVcfEditOpen={isVcfEditOpen}
                    setIsVcfEditOpen={setIsVcfEditOpen}
                    auctionCode={auctionCode}
                    sbluNumber={sblu}
                />
            )}
            {!!isAddRunOpen && (
                <LaneManagementAddRun
                    runRequestData={runRequestData}
                    isAddRunOpen={isAddRunOpen}
                    setIsAddRunOpen={setIsAddRunOpen}
                    setVehicles={setVehicles}
                    setLastSaved={setLastSaved}
                />
            )}
            {!!isChangeRunsOpen && (
                <LaneManagementChangeRuns
                runRequestData={runRequestData}
                isChangeRunsOpen={isChangeRunsOpen}
                setIsChangeRunsOpen={setIsChangeRunsOpen}
                setVehicles={setVehicles}
                isRequestBoard={false}
                />
            )}
        </>
    )
    function updatePreRegistrationData(newPreRegistrationData) {
        const index = vehicles.findIndex(vehicle =>
            vehicle.laneNumber === selectedVehicle.laneNumber &&
            vehicle.runNumber === selectedVehicle.runNumber &&
            vehicle.sblu === selectedVehicle.sblu
        );
        if (index > -1) {
            vehicles[index] = {...vehicles[index], ...newPreRegistrationData};
            updateHolderVehicles(vehicles[index])
            setVehicles((prevState) => [...vehicles]);

            dispatch(getVehicleAfterUpdatePreRegist({id: `vehicle-item-${vehicles[index].vin}-${vehicles[index].workOrderNumber}-${vehicles[index].runNumber}`}))
        }
    }
}