import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Input, Row, SlideOut, Space, FormGroup, RadioButton } from '@prism/library'
import {
    ConstraintConstant,
    HYPHEN_STRING,
    MessageTypeConstant,
    SPACE_STRING,
    CHANGE_RUNS,
    KEY_CODE, EMPTY_STRING
} from '../../utils/constant'
import { SearchSellerPanel } from '../common/vehicle-info/SearchSellerPanel'
import {
    handleErrorException,
    handleEventOnKeyDownForNumber,
    isBlank, openSnowModal
} from '../../utils/utils'
import { isValidNumber } from '../../utils/validation-utils'
import { NotificationMessage } from '../common/notification-message/NotificationMessage'
import { isEqual } from 'lodash'
import { modifiedRuns } from '../../utils/data-source/lane-management-datasource'

import './lane-management-change-runs.scss'
import useCustomLocation from '../../hooks/common/UseCustomLocation';

export function LaneManagementChangeRuns({
                                         runRequestData,
                                         isChangeRunsOpen,
                                         setIsChangeRunsOpen,
                                         setMappedRequests,
                                         setUnMappedRequests,
                                         setVehicles,
                                         isRequestBoard
                                     }) {

    const {t} = useTranslation()
    const [messageChangeRuns, setMessageChangeRuns] = useState({hasError: false, message: ""})
    const [shownSearchSellerPanel, setShownSearchSellerPanel] = useState(false)
    const [sellerSearchData, setSellerSearchData] = useState(null)
    const [sellerSearchCustomers, setSellerSearchCustomers] = useState(null)
    const [changeRunsPayload, setChangeRunsPayload] = useState({})
    const [hasData, setHasData] = useState(false)
    const [changeRunsType, setChangeRunsType] = useState(CHANGE_RUNS.SINGLE)
    const {pathName} = useCustomLocation()

    const onClickButtonSearchSeller = useCallback((e) => {
        setShownSearchSellerPanel(true)
    }, [])

    function validateChangeRunsRequest() {

        if (changeRunsPayload.sellerNumber && !isValidNumber(changeRunsPayload.sellerNumber)) {
            let err = {
                hasError: true,
                message: t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.SELLER_NUMBER_ERROR_FORMAT')
            }
            setMessageChangeRuns(err)
            return
        }

        if (!isBlank(changeRunsPayload.singleRun) && isSingleRun()) {
            if (!isValidNumber(changeRunsPayload.singleRun) || changeRunsPayload.singleRun < ConstraintConstant.RUN_COUNT_MIN_VALUE || changeRunsPayload.singleRun > ConstraintConstant.RUN_COUNT_MAX_VALUE) {
                let err = {
                    hasError: true,
                    message: t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CHANGE_RUNS.ERROR_MESSAGE.SINGLE_RUN')
                }
                setMessageChangeRuns(err)
                return
            }
        }

        if (!isBlank(changeRunsPayload.runRangeStart) && !isSingleRun()) {
            if (!isValidNumber(changeRunsPayload.runRangeStart) || changeRunsPayload.runRangeStart < ConstraintConstant.STARTING_RUN_MIN_VALUE || changeRunsPayload.runRangeStart > ConstraintConstant.STARTING_RUN_MAX_VALUE) {
                let err = {
                    hasError: true,
                    message: t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CHANGE_RUNS.ERROR_MESSAGE.RUN_RANGE_START')
                }
                setMessageChangeRuns(err)
                return
            }
        }

        if (!isBlank(changeRunsPayload.runRangeEnd)) {
            if (!isValidNumber(changeRunsPayload.runRangeEnd) || changeRunsPayload.runRangeEnd < ConstraintConstant.STARTING_RUN_MIN_VALUE || changeRunsPayload.runRangeEnd > ConstraintConstant.STARTING_RUN_MAX_VALUE) {
                let err = {
                    hasError: true,
                    message: t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CHANGE_RUNS.ERROR_MESSAGE.RUN_RANGE_END')
                }
                setMessageChangeRuns(err)
                return
            }
        }

        if((!isBlank(changeRunsPayload.runRangeStart) && !isBlank(changeRunsPayload.runRangeEnd))
            && changeRunsPayload.runRangeStart > changeRunsPayload.runRangeEnd) {
            let err = {
                hasError: true,
                message: t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CHANGE_RUNS.ERROR_MESSAGE.COMPARE_RUN_RANGE')
            }
            setMessageChangeRuns(err)
        }

        if ((CHANGE_RUNS.SINGLE === changeRunsType && !isBlank(changeRunsPayload.singleRun)) ||
            (CHANGE_RUNS.RUN_RANGE === changeRunsType && !isBlank(changeRunsPayload.runRangeStart) && !isBlank(changeRunsPayload.runRangeEnd))) {
            setHasData(true)
        } else {
            setHasData(false)
        }

        setMessageChangeRuns({
            hasError: false,
            message: ""
        })
    }

    useEffect(() => {
        validateChangeRunsRequest()
    }, [changeRunsPayload, changeRunsType])

    function handleChangeRuns(release) {
        if (!hasData) {
            setMessageChangeRuns({...messageChangeRuns, hasError: true, message: t('LABEL.LANE_MANAGEMENT.MESSAGE.ENTER_RUN_OR_RANGE_ERROR'), status: EMPTY_STRING})
        }

        const request = {...runRequestData
            , ...changeRunsPayload
            , isTransfer: !release
            , isRelease: release
            , isRunRange: !isSingleRun()
            , isRequestBoard}

        // remove un-used property
        delete request.runCount
        setMessageChangeRuns({hasError: false, message: ""})
        modifiedRuns(request).then((response) => {
            if (isRequestBoard) {
                setMappedRequests(response.data?.mappedRequests)
                setUnMappedRequests(response.data?.unmappedRequests)
                const messageSuccess = release ? t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CHANGE_RUNS.SUCCESS_MESSAGE.RELEASE_SUCCESS') : t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CHANGE_RUNS.SUCCESS_MESSAGE.TRANSFER_SUCCESS')
                setMessageChangeRuns({hasError: false, message: messageSuccess, status: response.status})
            } else {
                setVehicles(response.data?.vehicleRuns)
                const messageSuccess = release ? t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CHANGE_RUNS.SUCCESS_MESSAGE.RELEASE_SUCCESS') : t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CHANGE_RUNS.SUCCESS_MESSAGE.TRANSFER_SUCCESS')
                setMessageChangeRuns({hasError: false, message: messageSuccess, status: response.status})
            }
        }).catch((ex) => {
            let error = handleErrorException(ex)
            setMessageChangeRuns({...messageChangeRuns, hasError: true, message: error.message, status: error.status})
        })
    }

    function renderErrorMessage() {
        if (messageChangeRuns.hasError && !!messageChangeRuns.message && isEqual(messageChangeRuns.status, 500)) {
            return (
                <NotificationMessage
                    className='error-message pl-0'
                    type={MessageTypeConstant.ERROR}
                    isOpen={true}>
                    {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.PRE_TEXT')}
                    <a href={pathName} onClick={openSnowModal}>
                        {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.LINK_TEXT')}
                    </a>
                </NotificationMessage>
            )
        } else if (messageChangeRuns.hasError && !!messageChangeRuns.message) {
            return (
                <NotificationMessage type={'error'} isOpen={true} className='error-message pl-0'>
                    {messageChangeRuns.message}
                </NotificationMessage>
            )
        } else if (!messageChangeRuns.hasError && !!messageChangeRuns.message && isEqual(messageChangeRuns.status, 200)) {
            return (
                <NotificationMessage type={MessageTypeConstant.SUCCESS} isOpen={true} className='error-message pl-0'>
                    {messageChangeRuns.message}
                </NotificationMessage>
            )
        }
    }

    function isSingleRun() {
        return CHANGE_RUNS.SINGLE === changeRunsType
    }

    function isRunRange() {
        return CHANGE_RUNS.RUN_RANGE === changeRunsType
    }

    function handlePressEnter(event) {
        if(event.keyCode === KEY_CODE.ENTER && hasData && !messageChangeRuns.hasError) {
            handleChangeRuns(false)
        }
    }

    const submitButtonDisabled = useMemo(() => {
        return !hasData || messageChangeRuns.hasError
    }, [hasData, messageChangeRuns.hasError])

    return (
        <>
            <Container className='prism-overridden lane-management-change-run'>
                <SlideOut
                    actionable={true}
                    onCloseButtonClick={() => {
                        setIsChangeRunsOpen(false)
                    }}
                    open={isChangeRunsOpen && !shownSearchSellerPanel}
                    placement='right'
                    mauto='changeRunSlideOut'
                    headerText={t(
                        'LABEL.LANE_MANAGEMENT.ADD_RUN.CHANGE_RUN_TITLE'
                    )}>

                    {renderErrorMessage()}

                    <Space vertical size='0'>
                        <Row className='ml-0 mr-1'>
                            <Col md={12} sm={12} xs={12} className='pl-0'>
                                    <span mauto='maLabelInfoMessage'>
                                       {t('LABEL.LANE_MANAGEMENT.MESSAGE_CHANGE_RUN')}
                                   </span>
                            </Col>
                        </Row>
                        <Row className='ml-1'>
                            <Col md={4} sm={4} xs={4} className='pl-0 pr-1'>
                                <FormGroup row className={'input-seller-no'}>
                                    <Input.Label fontSize='sm'
                                                 mauto='maLabelSellerNo'>{t('LABEL.LANE_MANAGEMENT.LABEL.SELLER_NO')}</Input.Label>
                                    <Input
                                        className='prism-input'
                                        value={changeRunsPayload.sellerNumber}
                                        placeholder={t('LABEL.LANE_MANAGEMENT.ADD_RUN.ENTER_HERE')}
                                        maxLength={ConstraintConstant.SELLER_NUMBER_MAX_LENGTH}
                                        mauto='maInputSellerNo'
                                        name='sellerNumber'
                                        onKeyDown={(event) =>{
                                            handleEventOnKeyDownForNumber(event)
                                            handlePressEnter(event)
                                        }}
                                        onChange={(e) => {
                                            setChangeRunsPayload({...changeRunsPayload, sellerNumber: e.target.value})
                                        }}
                                        onBlur={(e) => {
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={1} sm={1} xs={1}
                                 className='pl-1 mr-3 search-seller-btn'>
                                <Button className='btn btn-outline'
                                        onClick={onClickButtonSearchSeller}
                                        mauto='maSearchSellerBtn'>
                                    <i className='icon prism-icon-search'/>
                                </Button>
                            </Col>
                        </Row>
                        <Row className='ml-1'>
                            <Col md={4} sm={4} xs={4} className='pl-0 pr-1'>
                                <FormGroup row className={'input-seller-no mt-0'}>
                                    <Input.Label fontSize='sm'
                                                 mauto='maLabelSingleRun'>
                                                    {
                                                        <RadioButton 
                                                            value="runRange" 
                                                            label={t('LABEL.LANE_MANAGEMENT.LABEL.SINGLE_RUN')} 
                                                            onChange={e => {
                                                                setChangeRunsType(CHANGE_RUNS.SINGLE)
                                                            }}
                                                            checked={isSingleRun()}/>
                                                    }
                                    </Input.Label>
                                    <Input
                                        className='prism-input'
                                        value={changeRunsPayload.singleRun}
                                        disabled={!isSingleRun()}
                                        placeholder={runRequestData.laneNumber + SPACE_STRING + HYPHEN_STRING}
                                        maxLength={ConstraintConstant.RUN_COUNT_MAX_LENGTH}
                                        mauto='maInputSingleRun'
                                        name='singleRun'
                                        onKeyDown={(event) =>{
                                            handleEventOnKeyDownForNumber(event)
                                            handlePressEnter(event)
                                        }}
                                        onChange={(e) => {
                                            setChangeRunsPayload({...changeRunsPayload, singleRun: e.target.value})
                                        }}
                                        onBlur={(e) => {
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='ml-1'>
                            <Col md={4} sm={4} xs={4} className='p-0'>
                                <FormGroup row className='mt-0'>
                                    <Input.Label fontSize='sm'
                                                 mauto='maLabelRuns'>{
                                                    <RadioButton 
                                                        value="runRange" 
                                                        label={t('LABEL.LANE_MANAGEMENT.LABEL.RUN_RANGE')}
                                                        onChange={e => {
                                                            setChangeRunsType(CHANGE_RUNS.RUN_RANGE)
                                                        }}
                                                        checked={CHANGE_RUNS.RUN_RANGE === changeRunsType}/>
                                                 }
                                    </Input.Label>
                                    <Input
                                        className='prism-input'
                                        value={changeRunsPayload.runRangeStart}
                                        disabled={!isRunRange()}
                                        placeholder={runRequestData.laneNumber + SPACE_STRING + HYPHEN_STRING}
                                        maxLength={ConstraintConstant.RUN_COUNT_MAX_LENGTH}
                                        mauto='maInputRunRangeStart'
                                        onKeyDown={(event) =>{
                                            handleEventOnKeyDownForNumber(event)
                                            handlePressEnter(event)
                                        }}
                                        onChange={(e) => {
                                            setChangeRunsPayload({...changeRunsPayload, runRangeStart: e.target.value})
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={1} sm={1} xs={1} className="add-symbol">
                                <span className="">{HYPHEN_STRING}</span>
                            </Col>
                            <Col md={4} sm={4} xs={4} className='p-0'>
                                <FormGroup row className='mt-3'>
                                    <Input
                                        className='form-text prism-input'
                                        value={changeRunsPayload.runRangeEnd}
                                        disabled={!isRunRange()}
                                        placeholder={runRequestData.laneNumber + SPACE_STRING + HYPHEN_STRING}
                                        maxLength={ConstraintConstant.RUN_COUNT_MAX_LENGTH}
                                        mauto='maInputRunRangeEnd'
                                        onKeyDown={(event) =>{
                                            handleEventOnKeyDownForNumber(event)
                                            handlePressEnter(event)
                                        }}
                                        onChange={(e) => {
                                            setChangeRunsPayload({...changeRunsPayload, runRangeEnd: e.target.value})
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Space>

                    <div className='row lane-management-change-run-footer'>
                        <div className='flex-sm-row-reverse control-buttons'>
                            <Button
                                className='m-2'
                                mauto='maButtonRelease'
                                disabled={submitButtonDisabled}
                                onClick={() => {
                                    handleChangeRuns(true)
                                }}
                                size='sm'
                                color='primary'>
                                {t('LABEL.LANE_MANAGEMENT.RELEASE')}
                            </Button>
                            <Button
                                className="m-2 mr-0 btn btn-outline text-nowrap"
                                mauto="maButtonTransfer"
                                onClick={() => {
                                    handleChangeRuns(false)
                                }}
                                disabled={submitButtonDisabled}
                            >
                                {t(
                                    'LABEL.LANE_MANAGEMENT.TRANSFER'
                                )}
                            </Button>
                        </div>
                    </div>
                </SlideOut>
            </Container>

            {
                !!shownSearchSellerPanel
                && (
                    <SearchSellerPanel
                        auctionCode={runRequestData.auctionCode}
                        searchSellerOpen={shownSearchSellerPanel}
                        customers={sellerSearchCustomers}
                        searchData={sellerSearchData}
                        isVehicleDetailParticular={true}
                        setSearchSellerOpen={setShownSearchSellerPanel}
                        setSellerNumber={(value) => {
                            setChangeRunsPayload({...changeRunsPayload, sellerNumber: value})
                        }}
                        setCustomers={setSellerSearchCustomers}
                        setSearchData={setSellerSearchData}
                        setHasDataChanged={() => {
                        }}
                    />
                )
            }
        </>
    )
}