import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { RequestHistoryPDFHeader } from '../../utils/constant'
import { searchRunRequests } from '../../utils/data-source/request-history-datasource'
import {
    buildFileNameExportRequestHistory,
    calculatedSalesPercentage,
    formatSearchHistoryDate,
    handleErrorException
} from '../../utils/utils'

export function RequestHistoryPDFDownload(criteriaSearch) {

    const FONT_SIZE = 7
    const HEADER_FONT_SIZE = 7.2
    const DEFAULT_COLOR = [10, 10, 10]

    function getDataSearch(criteriaSearch) {
        searchRunRequests(criteriaSearch).then((response) => {
            const {runRequestDTOS} = response.data
            const pdfBody = buildPDFBody(runRequestDTOS)
            handleDownloadPDF(pdfBody)
        }).catch((ex) => {
            handleErrorException(ex)
        })
    }

    function buildPDFBody(data) {
        return data.map(item => {
            let sellerName = ""
            let saleEff = "NA"
            if (!!item.seller) {
                const seller = JSON.parse(item.seller)
                saleEff = calculatedSalesPercentage(seller.stats)
                sellerName = seller.name

            }
            let createdAt =  formatSearchHistoryDate(item.createdAt)
            let updatedAt = formatSearchHistoryDate(item.updatedAt)
            return [item.auctionCode, item.saleYear, item.saleNumber, item.laneNumber, item.sellerNumber, sellerName, saleEff
                , item.runCount, createdAt, updatedAt, item.requestedBy, item.createdBy, item.comments]
        })
    }

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages()

        doc.setFont('helvetica', 'normal')
        doc.setFontSize(FONT_SIZE)

        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, {
                align: 'center'
            })
        }
    }

    function handleDownloadPDF(data) {
        // Document of 279mm wide and 216mm high
        const doc = new jsPDF('l', 'mm', "LETTER");
        autoTable(doc, {
            head: [RequestHistoryPDFHeader],
            columnStyles: {
                0: {cellWidth: 10}, //Auct code
                1: {cellWidth: 8}, //Sale Year
                2: {cellWidth: 8}, //Sale No.
                3: {cellWidth: 8}, //Lane No.
                4: {cellWidth: 15}, //Seller No.
                6: {cellWidth: 20}, //Sale Efficiency
                7: {cellWidth: 8, width: "fit-content"}, // Runs
                8: {cellWidth: 16}, // Created At
                9: {cellWidth: 16}, // Updated At
            },
            body: data,
            styles: {
                fontSize: FONT_SIZE,
                halign: 'left',
                lineWidth: 0.1,
                lineColor: DEFAULT_COLOR,
                textColor: DEFAULT_COLOR,
                fillColor: [220, 220, 220],
                cellPadding: 1
            },
            startY: 15,
            headStyles: {fillColor: "D2D2D2", halign: "center", valign: 'middle', fontSize: HEADER_FONT_SIZE},
            alternateRowStyles: {fillColor: [255, 255, 255]},

            theme: 'grid',
        })

        //Add page number for each page
        addFooters(doc)

        const fileName = buildFileNameExportRequestHistory();
        //Download PDF file
        doc.save(fileName)
    }

    //Call get data api and download pdf file
    getDataSearch(criteriaSearch)

}