import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import './remaining-vehicle-count.scss'

export function RemainingVehicleCount({
    isOpenedVehicleList,
    numberOfRemainingVehicle,
    updateOpenedVehicleListState
}) {
    const { t } = useTranslation()

    return (
        <>
            <div class='text-right'>
                <a
                    href="javascript:void(0)"
                    onClick={updateOpenedVehicleListState}>
                    {`${numberOfRemainingVehicle} ${t(
                        'LABEL.REMAINING_INVENTORY.VEHICLES'
                    )}`}
                    
                </a>
            </div>
            <div class='text-right chevron-icon' onClick={updateOpenedVehicleListState}>
                <i className={
                        classNames(
                            'icon', 'prism-icon__demo', 'ml-1', 'mx-1',
                            isOpenedVehicleList ? 'prism-icon-chevron-up' : 'prism-icon-chevron-down')
                    }></i>
            </div>
        </>
    )
}
