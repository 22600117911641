
import { Anchor, Card, Checkbox, Tooltip } from '@prism/library';
import { useTranslation } from 'react-i18next';
import {
    deleteShowNewSearchFeatureBoxFromStorage,
    setShowNewSearchFeatureBoxToStorage,
    NEW_SEARCH_FEATURE_STORAGE_KEY
} from '../../../../../utils/data-source/assign-inventory-datasource';

export function CustomToolTips({
       handleCloseToolTips,
       titleContent,
       bodyContent,
       target,
       size,
       storeKey
}) {

    const { t } = useTranslation()

    function handleCheckDontShowTooltips(e) {
        if (e.target.checked) {
            setShowNewSearchFeatureBoxToStorage(e.target.value, "NO")
        }
        else {
            deleteShowNewSearchFeatureBoxFromStorage(NEW_SEARCH_FEATURE_STORAGE_KEY)
        }
    }
    return (
        <>
            <Tooltip
                size={size}
                target={target}
                isOpen={true}
            >
                <Card title={titleContent} className="text-center">
                    <Anchor href="#" onClick={(e) => {handleCloseToolTips(e)}}>
                        <i className="icon prism-icon-cross"/>
                    </Anchor>
                    <div className="text-left">
                        {bodyContent}
                    </div>
                    <div className="text-left">
                        <Checkbox
                            checked={false}
                            label={t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.NEW_SEARCH_FEATURE_CHECKBOX')}
                            value={storeKey}
                            onChange={e => {
                                handleCheckDontShowTooltips(e)
                            }}
                        />
                    </div>
                </Card>
            </Tooltip>
            <div className="new-feature-overlay"></div>
        </>
    )
}
