import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Col, Container, Input, Row, SlideOut, Space } from '@prism/library'
import { ConstraintConstant, EMPTY_STRING } from '../../utils/constant';
import { handleEventOnKeyDownForNumberAlphabet, handleValueInArray } from '../../utils/utils';
import { useDispatch } from 'react-redux';
import { getAsgIvtAdvancedFilter } from '../../actions/AsgIvtAdvancedFilterAction';

import './assign-inventory-advanced-filters.scss'
import usePushQuickSearchValue from '../../hooks/common/UsePushQuickSearchValue';

export function AssignInventoryAdvancedFilter({
                                    isAdvancedFiltersOpen,
                                    setIsAdvancedFiltersOpen,
                                    isOnchangeAdvancedFilter,
                                    setIsOnchangeAdvancedFilter,
                                    advancedFilter,
                                    setAdvancedFilter,
                                    handleClearAdvancedFilter
                                }) {
    const {t} = useTranslation()

    const dispatch = useDispatch()

    const { pushQuickSearchValue } = usePushQuickSearchValue()

    const [isClearAdvanced, setIsClearAdvanced] = useState(true)

    const [isValidInput, setIsValidInput] = useState(true)

    //#region Handle event onChange for fields
    function onChangeNoSale(value) {
        setAdvancedFilter({
            ...advancedFilter,
            noSales: handleValueInArray(advancedFilter.noSales, value)
        })
        setIsOnchangeAdvancedFilter(true)
    }

    function onChangeGatePass(value) {
        setAdvancedFilter({
            ...advancedFilter,
            gatePassStatus: handleValueInArray(advancedFilter.gatePassStatus, value)
        })
        setIsOnchangeAdvancedFilter(true)
    }

    function onChangeInputDisposionCode(value) {
        setAdvancedFilter({
            ...advancedFilter,
            dispositionCodes: value
        })
        setIsOnchangeAdvancedFilter(true)
    }

    function onChangeCheckboxDisposionCode() {
        setAdvancedFilter({
            ...advancedFilter,
            excludeDispositionCodes: !advancedFilter.excludeDispositionCodes
        })
        setIsOnchangeAdvancedFilter(true)
    }

    function onChangeTitleStatus(value) {
        setAdvancedFilter({
            ...advancedFilter,
            titleStatus: handleValueInArray(advancedFilter.titleStatus, value)
        })
        setIsOnchangeAdvancedFilter(true)
    }

    function onChangeCRStatus(value) {
        setAdvancedFilter({
            ...advancedFilter,
            crStatus: handleValueInArray(advancedFilter.crStatus, value)
        })
        setIsOnchangeAdvancedFilter(true)
    }

    function onChangeFloorPrice(value) {
        setAdvancedFilter({
            ...advancedFilter,
            floorPrice: handleValueInArray(advancedFilter.floorPrice, value)
        })
        setIsOnchangeAdvancedFilter(true)
    }

    function onChangeRunningDay() {
        setAdvancedFilter({
            ...advancedFilter,
            excludeTodaysRuns: !advancedFilter.excludeTodaysRuns
        })
        setIsOnchangeAdvancedFilter(true)
    }

    function onChangeDrivable(value) {
        setAdvancedFilter({
            ...advancedFilter,
            drivableStatus: handleValueInArray(advancedFilter.drivableStatus, value)
        })
        setIsOnchangeAdvancedFilter(true)
    }

    function onChangeInputLotLocation(value) {
        setAdvancedFilter({
            ...advancedFilter,
            lotLocations: value
        })
        setIsOnchangeAdvancedFilter(true)
    }

    function onChangeCheckboxLotLocation() {
        setAdvancedFilter({
            ...advancedFilter,
            excludeLotLocations: !advancedFilter.excludeLotLocations
        })
        setIsOnchangeAdvancedFilter(true)
    }

    function onChangeVehicleType(value) {
        setAdvancedFilter({
            ...advancedFilter,
            vehicleType: handleValueInArray(advancedFilter.vehicleType, value)
        })
        setIsOnchangeAdvancedFilter(true)
    }
    //#endregion

    useEffect(() => {
        if(!isClearAdvanced) {
            setIsClearAdvanced(true)
        }
    }, [isClearAdvanced])

    useEffect(() => {
        validateInput()
    }, [advancedFilter])

    // Key code [comma: 188, white space: 32]
    const actionOnKeyDown = useCallback((e) => handleEventOnKeyDownForNumberAlphabet(e, [188, 32]), [])

    function validateInput() {
        if(ConstraintConstant.PATTERN_REGEX_SPECIAL_CHARACTER.test(advancedFilter.dispositionCodes)
            || ConstraintConstant.PATTERN_REGEX_SPECIAL_CHARACTER.test(advancedFilter.lotLocations)
        ) {
            setIsValidInput(false)
        } else {
            setIsValidInput(true)
        }
    }

    function handleApplyFilter() {
        window.resetUnassignVehicleCheckedStatus()
        let advFilter = {...advancedFilter, submitCount: advancedFilter.submitCount + 1}
        setAdvancedFilter(advFilter)
        dispatch(getAsgIvtAdvancedFilter(advFilter))
        pushQuickSearchValue(EMPTY_STRING)
        setIsAdvancedFiltersOpen(false)
    }

    function handleClearFilter() {
        window.resetUnassignVehicleCheckedStatus()
        setIsClearAdvanced(false)
        dispatch(getAsgIvtAdvancedFilter(null))
        pushQuickSearchValue(EMPTY_STRING)
        handleClearAdvancedFilter(false)
    }

    return (
        <>
            <Container className='prism-overridden assign-inventory-advanced-filters'>
                <SlideOut
                    actionable={true}
                    onCloseButtonClick={() => {
                        setIsAdvancedFiltersOpen(false)
                    }}
                    open={isAdvancedFiltersOpen}
                    placement='right'
                    headerText={t(
                        'LABEL.COMMON.ADVANCED_FILTERS.TITLE_ADVANCED_FILTERS'
                    )}>
                       { isClearAdvanced &&
                           <Space vertical>
                               <Row className='ml-1'>
                                   <Col md={12} className='pl-0'>
                                        <span mauto='maLabelNoSale'>
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.NO_SALES')}
                                       </span>
                                   </Col>
                                    <Col md={4} className='pl-0 ml-0'>
                                        <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.NO_SALES')}
                                           value='noSale'
                                           mauto='maCheckBoxNoSale'
                                           checked={advancedFilter.noSales.includes('noSale')}
                                           onChange={e => {
                                               onChangeNoSale(e.target.value)
                                           }}
                                       />
                                       </Col>
                                   <Col md={6} className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.FRESH_INVENTORY')}
                                           value='freshInventory'
                                           mauto='maCheckBoxFreshInventory'
                                           checked={advancedFilter.noSales.includes('freshInventory')}
                                           onChange={e => {
                                               onChangeNoSale(e.target.value)
                                           }}
                                       />
                                   </Col>
                               </Row>
                               <Row className='ml-1'>
                                   <Col md={12} className='pl-0'>
                                        <span mauto='maLabeGatePass'>
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.GATE_PASS.GATE_PASS')}
                                       </span>
                                   </Col>
                                   <Col md={4} className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.GATE_PASS.PRINTED')}
                                           value='gatePassPrinted'
                                           mauto='maCheckBoxPrinted'
                                           checked={advancedFilter.gatePassStatus.includes('gatePassPrinted')}
                                           onChange={e => {
                                               onChangeGatePass(e.target.value)
                                           }}
                                       />
                                   </Col>
                                   <Col md={6}  className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.GATE_PASS.NOT_PRINTED')}
                                           value='gatePassNotPrinted'
                                           mauto='maCheckBoxNotPrinted'
                                           checked={advancedFilter.gatePassStatus.includes('gatePassNotPrinted')}
                                           onChange={e => {
                                               onChangeGatePass(e.target.value)
                                           }}
                                       />
                                   </Col>
                               </Row>
                               <Row className='ml-1'>
                                   <Col md={12} className='pl-0'>
                                        <span mauto='maLabelDisposionCode'>
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.DISPOSITION_CODES')}
                                       </span>
                                   </Col>
                                   <Col md={8} className='pl-0 pr-1'>
                                       <Input
                                           className='prism-input'
                                           placeholder={t('LABEL.COMMON.ADVANCED_FILTERS.SEPARATED_BY_COMMAS')}
                                           maxLength={ConstraintConstant.INPUT_FILTER_MAX_LENGTH}
                                           value={advancedFilter.dispositionCodes}
                                           onKeyDown={actionOnKeyDown}
                                           onChange={e => {
                                               onChangeInputDisposionCode(e.target.value)
                                           }}
                                           mauto='maInputDisposionCode'
                                       />
                                   </Col>
                                   <Col md={4} className='pl-0 mt-1'>
                                       <Checkbox
                                           className='prism-checkbox'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.EXCLUDE')}
                                           mauto='maCheckBoxDispExclude'
                                           checked={advancedFilter.excludeDispositionCodes}
                                           onChange={onChangeCheckboxDisposionCode}
                                       />
                                   </Col>
                               </Row>
                               <Row className='ml-1'>
                                   <Col md={12} className='pl-0'>
                                        <span mauto='maLabelTitleStatus'>
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.TITLE_STATUS')}
                                       </span>
                                   </Col>
                                   <Col md={4} className='pl-0 pr-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.TITLE_STATUS_OPTION.TITLE_PRESENT')}
                                           value='titlePresent'
                                           mauto='maCheckBoxTitlePrsent'
                                           checked={advancedFilter.titleStatus.includes('titlePresent')}
                                           onChange={e => {
                                               onChangeTitleStatus(e.target.value)
                                           }}
                                       />
                                   </Col>
                                   <Col md={6}  className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.TITLE_STATUS_OPTION.TITLE_ABSENT')}
                                           value='titleAbsent'
                                           mauto='maCheckBoxTitleAbsent'
                                           checked={advancedFilter.titleStatus.includes('titleAbsent')}
                                           onChange={e => {
                                               onChangeTitleStatus(e.target.value)
                                           }}
                                       />
                                   </Col>
                               </Row>
                               <Row className='ml-1'>
                                   <Col md={12} className='pl-0'>
                                        <span mauto='maLabelCRStatus'>
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.CR_STATUS.CR_STATUS')}
                                       </span>
                                   </Col>
                                   <Col md={4} className='pl-0 pr-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.CR_STATUS.CR_COMPLETE')}
                                           value='crComplete'
                                           mauto='maCheckBoxCrComplete'
                                           checked={advancedFilter.crStatus.includes('crComplete')}
                                           onChange={e => {
                                               onChangeCRStatus(e.target.value)
                                           }}
                                       />
                                   </Col>
                                   <Col md={6}  className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.CR_STATUS.CR_INCOMPLETE')}
                                           value='crIncomplete'
                                           mauto='maCheckBoxCrImcomplete'
                                           checked={advancedFilter.crStatus.includes('crIncomplete')}
                                           onChange={e => {
                                               onChangeCRStatus(e.target.value)
                                           }}
                                       />
                                   </Col>
                               </Row>
                               <Row className='ml-1'>
                                   <Col md={12} className='pl-0'>
                                        <span mauto='maLabelFloorPrice'>
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.FLOOR_PRICE.FLOOR_PRICE')}
                                       </span>
                                   </Col>
                                   <Col md={4} className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.FLOOR_PRICE.FLOORED')}
                                           value='floored'
                                           mauto='maCheckBoxFloored'
                                           checked={advancedFilter.floorPrice.includes('floored')}
                                           onChange={e => {
                                               onChangeFloorPrice(e.target.value)
                                           }}
                                       />
                                   </Col>
                                   <Col md={6}  className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.FLOOR_PRICE.NOT_FLOORED')}
                                           value='notFloored'
                                           mauto='maCheckBoxNotFloored'
                                           checked={advancedFilter.floorPrice.includes('notFloored')}
                                           onChange={e => {
                                               onChangeFloorPrice(e.target.value)
                                           }}
                                       />
                                   </Col>
                               </Row>
                               <Row className='ml-1'>
                                   <Col md={12} className='pl-0'>
                                        <span mauto='maLabelRunningToday'>
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.RUNNING_TODAY')}
                                       </span>
                                   </Col>
                                   <Col md={6} className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.EXCLUDE')}
                                           value='notRunningToday'
                                           mauto='maCheckBoxRunningTodayExclude'
                                           onChange={onChangeRunningDay}
                                           checked={advancedFilter.excludeTodaysRuns}
                                       />
                                   </Col>
                               </Row>
                               <Row className='ml-1'>
                                   <Col md={12} className='pl-0'>
                                        <span mauto='maLabelDrivable'>
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.DRIVABLE.DRIVABLE')}
                                       </span>
                                   </Col>
                                   <Col md={2} className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.DRIVABLE.YES')}
                                           value='drivableYes'
                                           mauto='maCheckBoxDrivableYes'
                                           checked={advancedFilter.drivableStatus.includes('drivableYes')}
                                           onChange={e => {
                                               onChangeDrivable(e.target.value)
                                           }}
                                       />
                                   </Col>
                                   <Col md={2} className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.DRIVABLE.NO')}
                                           value='drivableNo'
                                           mauto='maCheckBoxDrivableNo'
                                           checked={advancedFilter.drivableStatus.includes('drivableNo')}
                                           onChange={e => {
                                               onChangeDrivable(e.target.value)
                                           }}
                                       />
                                   </Col>
                                   <Col md={3} className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.DRIVABLE.NO_START')}
                                           value='drivableNoStart'
                                           mauto='maCheckBoxDrivableNoStart'
                                           checked={advancedFilter.drivableStatus.includes('drivableNoStart')}
                                           onChange={e => {
                                               onChangeDrivable(e.target.value)
                                           }}
                                       />
                                   </Col>
                                   <Col md={3} className='pl-0'>
                                       <Checkbox
                                           className='prism-checkbox ml-0'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.DRIVABLE.UNKNOWN')}
                                           value='drivableUnknown'
                                           mauto='maCheckBoxDrivableUnknown'
                                           checked={advancedFilter.drivableStatus.includes('drivableUnknown')}
                                           onChange={e => {
                                               onChangeDrivable(e.target.value)
                                           }}
                                       />
                                   </Col>
                               </Row>
                               <Row className='ml-1'>
                                   <Col md={12} className='pl-0'>
                                        <span mauto='maLabelLotLocation'>
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.LOT_LOCATION')}
                                       </span>
                                   </Col>
                                   <Col md={8} className='pl-0 pr-1'>
                                       <Input
                                           className='prism-input'
                                           placeholder={t('LABEL.COMMON.ADVANCED_FILTERS.SEPARATED_BY_COMMAS')}
                                           maxLength={ConstraintConstant.INPUT_FILTER_MAX_LENGTH}
                                           value={advancedFilter.lotLocations}
                                           onKeyDown={actionOnKeyDown}
                                           onChange={e => {
                                               onChangeInputLotLocation(e.target.value)
                                           }}
                                           mauto='maInputLotLocation'
                                       />
                                   </Col>
                                   <Col md={4} className='pl-0 mt-1'>
                                       <Checkbox
                                           className='prism-checkbox'
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.EXCLUDE')}
                                           mauto='maCheckBoxLotLocationExclude'
                                           checked={advancedFilter.excludeLotLocations}
                                           onChange={onChangeCheckboxLotLocation}
                                       />
                                   </Col>
                               </Row>
                               <Row className='ml-1 mb-0'>
                                    <span mauto='maLabelVehicleType'>
                                       {t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.VEHICLE_TYPES')}
                                   </span>
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.TRAILER')}
                                       value='vehicleTypeA'
                                       mauto='maCheckBoxVehicleTrailer'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeA')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.BOAT_WATER')}
                                       value='vehicleTypeB'
                                       mauto='maCheckBoxVehicleBoatWater'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeB')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.CONSTRUCTION_EQUIPMENT')}
                                       value='vehicleTypeC'
                                       mauto='maCheckBoxVehicleConstructionEquipment'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeC')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.MEDIUM_DUTY_VEHICLE')}
                                       value='vehicleTypeD'
                                       mauto='maCheckBoxVehicleMediumDuty'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeD')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.FARM_EQUIPMENT')}
                                       value='vehicleTypeF'
                                       mauto='maCheckBoxVehicleFarmEquipment'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeF')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.GOLF_CART')}
                                       value='vehicleTypeG'
                                       mauto='maCheckBoxVehicleGolfCart'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeG')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.HEAVY_EQUIPMENT')}
                                       value='vehicleTypeH'
                                       mauto='maCheckBoxVehicleHeavyEquiment'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeH')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.INCOMPLETE_TRUCK')}
                                       value='vehicleTypeI'
                                       mauto='maCheckBoxVehicleIncompletTruck'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeI')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.LIMO')}
                                       value='vehicleTypeL'
                                       mauto='maCheckBoxVehicleLimo'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeL')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.MOTORCYCLE')}
                                       value='vehicleTypeM'
                                       mauto='maCheckBoxVehicleMotocycle'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeM')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.VAN')}
                                       value='vehicleTypeN'
                                       mauto='maCheckBoxVehicleVan'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeN')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.ALL_PURPOSE_VEHICLE')}
                                       value='vehicleTypeP'
                                       mauto='maCheckBoxVehicleAllPurpose'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeP')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.RECREATIONAL_VEHICLE')}
                                       value='vehicleTypeR'
                                       mauto='maCheckBoxVehicleRecreational'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeR')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.PARTS')}
                                       value='vehicleTypeS'
                                       mauto='maCheckBoxVehicleParts'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeS')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.TRUCK')}
                                       value='vehicleTypeT'
                                       mauto='maCheckBoxVehicleTruck'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeT')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0 mt-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.VEHICLE')}
                                       value='vehicleTypeV'
                                       mauto='maCheckBoxVehicle'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeV')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                               <Row className='ml-1 mb-0 mt-0'>
                                   <Checkbox
                                       className='prism-checkbox'
                                       label={t('LABEL.COMMON.ADVANCED_FILTERS.VEHICLE_TYPES.OTHER')}
                                       value='vehicleTypeX'
                                       mauto='maCheckBoxVehicleOther'
                                       checked={advancedFilter.vehicleType.includes('vehicleTypeX')}
                                       onChange={e => {
                                           onChangeVehicleType(e.target.value)
                                       }}
                                   />
                               </Row>
                           </Space>}
                    <div className='row advanced-filter-footer'>
                        <div className='control-buttons justify-content-right'>
                            <Space size={0} className={'ml-auto mr-0'}>
                                <Button className='m-2'
                                        onClick={() => {
                                            handleClearFilter()
                                        }}
                                        mauto='maButtonClearAdvanceFilter'>
                                    <i className='icon prism-icon-cancel-circle p-1' ></i>
                                    {t('LABEL.COMMON.ADVANCED_FILTERS.CLEAR')}
                                </Button>
                                <Button
                                    className='m-2'
                                    mauto='maButtonApplyAdvanceFilter'
                                    onClick={() => {
                                        handleApplyFilter()
                                    }}
                                    disabled={!(isOnchangeAdvancedFilter && isValidInput)}
                                    size='sm'
                                    color='primary'>
                                    {t('LABEL.COMMON.ADVANCED_FILTERS.APPLY_FILTERS')}
                                </Button>
                            </Space>

                        </div>
                    </div>
                </SlideOut>
            </Container>
        </>
    )
}
