import React, {useCallback, useEffect, useMemo, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'

import { SlideOut } from '@prism/library'
import { NoDataFound } from '../common/data-list-status/NoDataFound'
import { getNextAvailableRunData, handleNotificationMessage } from '../../utils/utils'
import { AuctionCodeErrorMessage } from "../common/error/AuctionCodeErrorNotificationMessage"
import { NextAvailablePicker } from "./NextAvailablePicker"

import './next-available.scss'

export function NextAvailable({  auctionCodeFromState,
                                  auctionCodeFromCookie,
                                  saleYears,
                                  isShowNextAvailableRuns,
                                  isAuctionCodeChange,
                                  setIsShowNextAvailableRuns,
                                  auctionConnectionError,
                                  selectedSaleYear,
                                  setSelectedSaleYear,
                                  selectedSaleNumber,
                                  setSelectedSaleNumber,
                                  nextAvailableRuns,
                                  setNextAvailableRuns,
                                  saleYearOptions,
                                  setSaleYearOptions,
                                  saleNumberOptions,
                                  setSaleNumberOptions,
                                  errorMessage,
                                  setErrorMessage
    }) {
    const { t } = useTranslation()
    const toggle = () => {
        setIsShowNextAvailableRuns(!isShowNextAvailableRuns)
    }
    const [loadNextAvailable, setLoadNextAvailable] = useState({
        loading: false,
        loaded: false
    })

    const [isShowNotFound, setIsShowNotFound] = useState(false)

    const updateNextAvailableRuns = useCallback(
        (auctionCode, saleYear, saleNumber) => {
            setLoadNextAvailable({ loading: true, loaded: false })
            getNextAvailableRunData(auctionCode, saleYear, saleNumber, setNextAvailableRuns, setErrorMessage, setLoadNextAvailable, t)
        }, [])

    useEffect(()=>{
        if(!auctionConnectionError.isValidAuction) {
            setErrorMessage(<AuctionCodeErrorMessage auctionConnectionError={auctionConnectionError} />)
        }
    },[auctionConnectionError])

    useEffect(()=>{
       setIsShowNotFound(!loadNextAvailable.loading && loadNextAvailable.loaded &&
           !isNil(selectedSaleYear.value) && !isNil(selectedSaleNumber.value))

    },[loadNextAvailable, selectedSaleYear, selectedSaleNumber])
    
    return (
        <>
            <div className='next-available-slide-out'>
                <SlideOut
                    actionable={true}
                    onCloseButtonClick={toggle}
                    open={isShowNextAvailableRuns}
                    placement="right"
                    headerText={t(
                        'LABEL.COMMON.SHOW_NEXT_AVAILABLE_LANE'
                    )}>
                    <div>
                        <NextAvailablePicker
                            auctionCodeFromState={auctionCodeFromState}
                            auctionCodeFromCookie={auctionCodeFromCookie}
                            saleYears={saleYears}
                            updateNextAvailableRuns={updateNextAvailableRuns}
                            isAuctionCodeChange={isAuctionCodeChange}
                            setErrorMessage={setErrorMessage}
                            selectedSaleYear={selectedSaleYear}
                            setSelectedSaleYear={setSelectedSaleYear}
                            selectedSaleNumber={selectedSaleNumber}
                            setSelectedSaleNumber={setSelectedSaleNumber}
                            saleYearOptions={saleYearOptions}
                            setSaleYearOptions={setSaleYearOptions}
                            saleNumberOptions={saleNumberOptions}
                            setSaleNumberOptions={setSaleNumberOptions}
                            setNextAvailableRuns={setNextAvailableRuns}
                        />
                    </div>
                        <div>
                            {!isNil(nextAvailableRuns) && !isEmpty(nextAvailableRuns) && (
                                <div className="next-available-panel table-sticky-wrapper">
                                    <table
                                        className="table color-header mb-0 available-runs-table"
                                        mauto="nextAvailableRunsTbl">
                                        <thead className="table-head sticky-top">
                                        <tr>
                                            <th>
                                        <span mauto="maThLane">
                                            {t('LABEL.NEXT_AVAILABLE_RUN.LANE')}
                                        </span>
                                            </th>
                                            <th>
                                        <span mauto="maThAvailableRuns">
                                            {t(
                                                'LABEL.NEXT_AVAILABLE_RUN.AVAILABLE_RUNS'
                                            )}
                                        </span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody mauto="nextAvailableRunsResults">
                                        {nextAvailableRuns.map(
                                            (nextAvailableRun, index) => (
                                                <tr key={index} mauto="maTrResult">
                                                    <td
                                                        className="table-data-cell"
                                                        data-heading={t(
                                                            'LABEL.NEXT_AVAILABLE_RUN.LANE'
                                                        )}>
                                                <span mauto="maTdLane">
                                                    {nextAvailableRun.lane}
                                                </span>
                                                    </td>
                                                    <td
                                                        className="table-data-cell"
                                                        data-heading={t(
                                                            'LABEL.NEXT_AVAILABLE_RUN.AVAILABLE_RUNS'
                                                        )}>
                                                <span mauto="maTdRuns">
                                                    {nextAvailableRun.runs.join(
                                                        ', '
                                                    )}
                                                </span>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {!!errorMessage && (
                                handleNotificationMessage(errorMessage)
                            )}
                            {isEmpty(nextAvailableRuns) && !errorMessage && isShowNotFound && (
                                <NoDataFound />
                            )}
                        </div>
                </SlideOut>
            </div>
        </>
    )
}
