import axiosInstance from '../axios'

export function getRunRequest(request) {
    return axiosInstance.post('/run-request',request)
}

export function getRunRequestSummary(request) {
    return axiosInstance.post('/run-request/summary',request)
}

export function createRunRequest(request) {
    return axiosInstance.post('/run-request/create', request)
}

export function deleteRunRequest(globalId) {
    return axiosInstance.delete('/run-request/delete-run-request', {
        params: {
            'global-id': globalId
        }
    })
}
export function savedMappedLanes(request) {
    return axiosInstance.post('/run-request/saved-mapped-lane',request)
}
export function revert(request) {
    return axiosInstance.post('/run-request/revert', request)
}

export function getRequestBoard(request) {
    return axiosInstance.post('/run-request/request-board',request)
}

export function createRunAssignments(request) {
    return axiosInstance.post('/run-request/run_assignments/create',request)
}

export function addReservation(request) {
    return axiosInstance.post('/run-request/add_reservation',request)
}

export function modifiedRuns(request) {
    return axiosInstance.put('/run-request/modified-runs', request)
}

export function modifiedRequestBoard(request) {
    return axiosInstance.put('/run-request/modified-requests-runs', request)
}

export function clearMappedLanes(request) {
    return axiosInstance.delete('/run-request/clear-mapped-lane',{ data: request })
}

export function updateRunRequest(request) {
    return axiosInstance.put('/run-request/update-run-request', request)
}