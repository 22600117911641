import React, {useEffect, useMemo, useState} from 'react'
import { isEmpty } from 'lodash'
import { Container } from '@prism/library'
import { ScopeConstant, SellerSearchTypeConstant } from '../../utils/constant'
import { getVehicles } from '../../utils/data-source/vehicle-datasource'
import { handleErrorException } from '../../utils/utils'
import { VehicleVcf } from '../common/vehicle-info/VehicleVcf'
import {TouchBackend} from 'react-dnd-touch-backend'
import {DndProvider} from 'react-dnd'
import './remaining-vehicle-list.scss'
import {useDispatch} from 'react-redux'
import {pushGetVehicleError, pushUpdatedVehicle} from '../../actions/RemainingInventoryAction'
import RemainingVehicleItem from './RemainingVehicleItem'
import usePrevious from '../../hooks/common/UsePrevious'
import compareVehicles from '../assign-inventory/helper/VehicleHelper';

export function RemainingVehicleList({ auctionCode, sellerNumber, setLoadVehiclesError, setMessageUpdateVehicleSuccessful, getAdvancedFilterParams, isAuctionCodeChange, setIsAuctionCodeChange,
                                         isOpenedVehicleList}) {
    const [vehicles, setVehicles] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        if (isOpenedVehicleList && isAuctionCodeChange !== true) {
            const filters = {
                ...getAdvancedFilterParams,
                auctionCode: auctionCode,
                sellerSearchType: SellerSearchTypeConstant.SELLER_NUMBER,
                sellerType: sellerNumber,
                scope: ScopeConstant.UNASSIGNED
            }

            const cachedVehicles = window.getCachedVehicles(filters)
            if (cachedVehicles) {
                updateLoadSuccessFullVehicles(cachedVehicles.vehicles)
                return
            }

            getVehicles(filters)
                .then((response) => {
                    window.cacheVehicles(filters, response.data)
                    updateLoadSuccessFullVehicles(response.data)
                }).catch((ex) => {
                setLoadVehiclesError(handleErrorException(ex))
            })
        }else{
            setIsAuctionCodeChange(false)
        }
    }, [isOpenedVehicleList])

    const [sblu, setSblu] = useState(null)
    const [isVcfOpen, setIsVcfOpen] = useState(false)
    const [getVehicleError, setGetVehicleError] = useState({ hasError: false, vin: '' })
    const prevGetVehicleError = usePrevious(getVehicleError)

    function updateVehicleData(newVehicleData) {
        let existedVehicle = vehicles.find(p => compareVehicles(p, newVehicleData))
        if (existedVehicle) {
            let index = vehicles.indexOf(existedVehicle)
            existedVehicle = {...existedVehicle, ...newVehicleData}
            vehicles[index] = existedVehicle
            dispatch(pushUpdatedVehicle(existedVehicle))
            setVehicles(vehicles)
        }
    }

    function updateLoadSuccessFullVehicles(data) {
        setLoadVehiclesError(null)
        setVehicles(data)
    }

    const handleGetVehicleError = (hasError) => {
        if (hasError) {
            dispatch(pushGetVehicleError({
                ...getVehicleError,
                hasError: hasError,
                prev: prevGetVehicleError
            }))
        }
    }

    const vehicleList = useMemo(() => {
        return (
            <>
                {!!vehicles && !isEmpty(vehicles) &&
                    vehicles.map((vehicle, itemIndex) => {
                        return (
                            <>
                                {itemIndex > 0 && <hr className="p-0 my-1" />}
                                <RemainingVehicleItem
                                    key={itemIndex}
                                    vehicle={vehicle}
                                    handleTitleClicking={(sblu) => {
                                        setGetVehicleError({ ...getVehicleError, vin: vehicle.vin })
                                    }}
                                    setMessageUpdateVehicleSuccessful={setMessageUpdateVehicleSuccessful}
                                    handleVcfOnClick={(sblu) => {
                                        setSblu(sblu)
                                        setIsVcfOpen(true)
                                    }}
                                    handleGetVehicleError={handleGetVehicleError}
                                    updateVehicleData={updateVehicleData}
                                />
                            </>
                        )
                    })
                }
            </>
        )
    }, [vehicles])

    return (
        <>
            <Container className='remaining-vehicle-list' fluid>
                <DndProvider backend={TouchBackend}>
                    <>
                        {vehicleList}
                    </>
                </DndProvider>
            </Container>

            {!!isVcfOpen && (
                <VehicleVcf
                    isVcfOpen={isVcfOpen}
                    setIsVcfOpen={setIsVcfOpen}
                    auctionCode={auctionCode}
                    sbluNumber={sblu}
                />
            )}
        </>
    )
}
