import { REMAINING_INVENTORY_ACTION_TYPE } from '../utils/constant';

const initialState = {
    messageVehicleUpdated: null,
    updatedVehicle: null,
    getVehicleError: null,
}

export  const remainingInventoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case REMAINING_INVENTORY_ACTION_TYPE.MESSAGE_VEHICLE_UPDATED:
            return {
                ...state,
                messageVehicleUpdated: action.messageVehicleUpdated
            }
        case REMAINING_INVENTORY_ACTION_TYPE.UPDATED_VEHICLE:
            return {
                ...state,
                 updatedVehicle: action.updatedVehicle
            }
        case REMAINING_INVENTORY_ACTION_TYPE.GET_VEHICLE_ERROR:
            return {
               ...state,
                getVehicleError: action.getVehicleError
            }
        default:
            return state;
    }
}