import { Col, Row } from '@prism/library'
import { useTranslation } from 'react-i18next'
import { useDrop } from 'react-dnd'
import { DnDDragTypeConstant } from '../../utils/constant'

export function EmptyRun ({
    vehicle,
    laneNumber,
    index,
    updateAssignedVehicleAfterDrop
}) {
    const { t } = useTranslation()

    const [{ isActive }, drop] = useDrop(
        () => ({
            accept: [DnDDragTypeConstant.ASSIGN_VEHICLE, DnDDragTypeConstant.UNASSIGN_VEHICLE],
            drop: (item, monitor) => {
                handleUpdateVehicle(item, index)
            },
            collect: (monitor) => ({
                isActive: monitor.isOver() && monitor.canDrop()
            }),
        }),
        [vehicle, index, laneNumber]
    )

    function handleUpdateVehicle(vehicle, index) {
        let dragData = {
            vehicle: vehicle,
            itemIndex: index
        }
        updateAssignedVehicleAfterDrop(dragData)
    }

    return (
        <div ref={drop} className={`assign-vehicle-item ${isActive ? 'bg-cerulean' : 'bg-smoke'}`}>
            <Row>
                <Col md={6} sm={6} xs={6}>
                    <div mauto="maEmpty"> {t('LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.EMPTY')}</div>
                </Col>
                <Col md={6} sm={6} xs={6}
                    className="right-align">
                    <Row>
                    <div className="mt-0 w-100 text-right">
                        <span className="text-muted mr-2 font-weight-bold current-ran">
                            {laneNumber.toString().padStart(2, '0') + '-' + vehicle.runNumber.toString().padStart(3, '0')}
                        </span>
                    </div>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}