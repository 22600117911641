import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Input, Row, SlideOut, Space } from '@prism/library'
import { Dropdown } from '../custom/prism/dropdown/Dropdown'
import {
    BREAKING_SPACE,
    ConstraintConstant,
    EMPTY_STRING,
    ERR_NETWORK,
    HYPHEN_STRING,
    SPACE_STRING,
    ToggleFunction,
    VehicleDetailPattern,
} from '../../../utils/constant'
import { first, isEmpty, isNil } from 'lodash'
import classNames from 'classnames'
import airbagCodes from '../../../utils/as400-codes/airbag-codes.json'
import bodyCodes from '../../../utils/as400-codes/body-codes.json'
import engineCodes from '../../../utils/as400-codes/engine-codes.json'
import fourByFourCodes from '../../../utils/as400-codes/four-by-four-codes.json'
import interiorCodes from '../../../utils/as400-codes/interior-codes.json'
import primeCodes from '../../../utils/as400-codes/prime-codes.json'
import radioCodes from '../../../utils/as400-codes/radio-codes.json'
import topCodes from '../../../utils/as400-codes/top-codes.json'
import transmissionCodes from '../../../utils/as400-codes/transmission-codes.json'
import { v4 as uuid } from 'uuid'
import { SearchSellerPanel } from '../vehicle-info/SearchSellerPanel'
import {
    handleErrorException,
    handleEventOnKeyDownForNumber,
    handleEventOnKeyDownForNumberAlphabet,
    isEnableToggleFlag, openSnowModal,
} from '../../../utils/utils'

import './pre-registration.scss'
import { getVinDecode } from '../../../utils/data-source/vin-decoce-datasource'
import { NotificationMessage } from '../notification-message/NotificationMessage'
import { deletePreRegistration, updatePreRegistration } from "../../../utils/data-source/pre-registrations-datasource";
import { useSelector } from "react-redux";
import { isValidNumber } from '../../../utils/validation-utils'
import useCustomLocation from '../../../hooks/common/UseCustomLocation';

export function PreRegistration({
    isPreRegistrationOpen,
    setIsPreRegistrationOpen,
    auctionCode,
    preRegistrationData,
    titleVehicle,
    onDeleteFromAS400,
    onUpdateInAS400,
}) {
    const {t} = useTranslation()

    const decodeRef = useRef(null)

    const [isShowVinDecodeTrimSelect, setIsShowVinDecodeTrimSelect] = useState(false)

    const [isDisableOnInvDropdown, setIsDisableOnInvDropdown] = useState(true)

    const [enteredShowByValue, setEnteredShowByValue] = useState(EMPTY_STRING)

    const [shownSearchSellerPanel, setShownSearchSellerPanel] = useState(false)

    const [sellerSearchCustomers, setSellerSearchCustomers] = useState(null)

    const [sellerSearchData, setSellerSearchData] = useState(null)

    const [isEnableWriteDataInAS400, setIsEnableWriteDataInAS400] = useState(false)
    const [title, setTitle] = useState(titleVehicle)

    const toggleConfigs = useSelector(states => states.session.toggleConfigs)
    const [isEnableDeleteFromAS400, setIsEnableDeleteFromAS400] = useState(false);

    const [vehicle, setVehicle] = useState(preRegistrationData.vehicle);

    const {pathName} = useCustomLocation()

    useEffect(() => {
        // Check if the toggle config is enabled for the auction code
        const toggleConfigValue = toggleConfigs[ToggleFunction.AUCTION_LIST_ALLOWED_DB_WRITE]
        const isEnableUpdateAS400Flag = isEnableToggleFlag(toggleConfigValue, auctionCode)
        setIsEnableWriteDataInAS400(isEnableUpdateAS400Flag)

        // Check allow to submit delete from AS400
        setIsEnableDeleteFromAS400(!!vehicle?.vin);
    }, [])

    const [vinDecodeTrimOption, setVinDecodeTrimOption] = useState(
        buildDropdownOptionsVinDecode([])
    )

    const [selectedVinDecode, setSelectedVinDecode] = useState()

    const [bodyCodeOption, setBodyCodeOption] = useState(
        buildDropdownOptionsBodyCodeOption()
    )

    const [selectedBodyCodeOption, setSelectedBodyCodeOption] = useState(
        first(buildDropdownOptionsBodyCodeOption())
    )

    const [engineOption, setEngineOption] = useState(
        buildDropdownOptions(engineCodes)
    )

    const [selectedEngineOption, setSelectedEngineOption] = useState(
        first(buildDropdownOptions(engineCodes))
    )

    const [radioOption, setRadioOption] = useState(
        buildDropdownOptionRadioOption()
    )

    const [selectedRadioOption, setSelectedRadioOption] = useState(
        first(buildDropdownOptionRadioOption())
    )

    const [transOption, setTransOption] = useState(
        buildDropdownOptions(transmissionCodes)
    )

    const [selectedTransOption, setSelectedTransOption] = useState(
        first(buildDropdownOptions(transmissionCodes))
    )

    const [topOption, setTopOption] = useState(
        buildDropdownOptions(topCodes)
    )

    const [selectedTopOption, setSelectedTopOption] = useState(
        first(buildDropdownOptions(topCodes))
    )

    const [intOption, setIntOption] = useState(
        buildDropdownOptions(interiorCodes)
    )

    const [selectedIntOption, setSelectedIntOption] = useState(
        first(buildDropdownOptions(interiorCodes))
    )

    const [fourByFourOption, setFourByFourOption] = useState(
        buildDropdownOptions(fourByFourCodes)
    )

    const [selectedFourByFourOption, setSelectedFourByFourOption] = useState(
        first(buildDropdownOptions(fourByFourCodes))
    )

    const [abOption, setAbOption] = useState(
        buildDropdownOptions(airbagCodes)
    )

    const [selectedAbOption, setSelectedAbOption] = useState(
        first(buildDropdownOptions(airbagCodes))
    )

    const [buOption, setBuOption] = useState(
        buildDropdownOptionsPrimeCodesOption()
    )

    const [selectedBuOption, setSelectedBuOption] = useState(
        first(buildDropdownOptionsPrimeCodesOption())
    )

    const [onInvOption, setOnInvOption] = useState
    (buildDropdownOptionsOnInvoice()
    )

    const [selectedOnInvOption, setSelectedOnInvOption] = useState(
        buildDropdownOptionsOnInvoice()[1]
    )

    const [decodeError, setDecodeError] = useState({hasError: false, message: "", status: ""})

    const [errorInput, setErrorInput] = useState({hasError: false, message: ""})

    const [isOnChange, setIsOnchange] = useState(false)

    const [isEnableButtonUpdateInAs400, setIsEnableButtonUpdateInAS400] = useState(true);

    //#region build select option

    function buildDropdownOptions(optionList) {
        let optionsData = optionList.map(item => {
            return {
                name: item.name,
                value: item.value,
                disable: false,
                key: uuid()
            }
        })
        return optionsData
    }

    function buildDropdownOptionsBodyCodeOption() {
        let optionsData = bodyCodes.map(item => {
            return {
                name: (!!item.bodyCode ? item.bodyCode + SPACE_STRING : EMPTY_STRING ) + (!!item.vehicleTypeCode ? item.vehicleTypeCode + SPACE_STRING : EMPTY_STRING ) + t(item.bodyDescription),
                value: item.bodyCode,
                disable: false,
                key: uuid()
            }
        })
        return optionsData
    }

    function buildDropdownOptionsPrimeCodesOption() {
        let optionsData = primeCodes.map(item => {
            return {
                name:  (!!item.value ? item.value + SPACE_STRING + HYPHEN_STRING + SPACE_STRING : EMPTY_STRING ) + item.name,
                value: item.value,
                disable: false,
                key: uuid()
            }
        })
        return optionsData
    }

    function buildDropdownOptionRadioOption() {
        let optionsData = radioCodes.map(item => {
            return {
                name:  (!!item.value ? item.value + SPACE_STRING + HYPHEN_STRING + SPACE_STRING : EMPTY_STRING ) + item.name,
                value: item.value,
                disable: false,
                key: uuid(),
                powerSteering: item.powerSteering,
                powerBrakes: item.powerBrakes,
                airConditioning: item.airConditioning,
                electricWindows: item.electricWindows,
                electricSteering: item.electricSteering,
                cruiseControl: item.cruiseControl,
                electricLights: item.electricLights,
                tiltSteering: item.tiltSteering,
                selectedTransmissionCode: item.selectedTransmissionCode,
                selectedRadioCode: item.selectedRadioCode,
            }
        })
        return optionsData
    }

    function buildDropdownOptionsOnInvoice() {
        let optionsData = [
            {
                name: t( 'LABEL.COMMON.PRE_REGISTRATION.ON_INVOICE_OPTION.OPERATOR'),
                value: '*',
                key: uuid()
            },
            {
                name: t( 'LABEL.COMMON.PRE_REGISTRATION.ON_INVOICE_OPTION.DEALER'),
                value: null,
                key: uuid()
            }
        ]
        return optionsData
    }

    function buildDropdownOptionsVinDecode(optionList) {
        const defaultOption = {
            name: t('LABEL.COMMON.PRE_REGISTRATION.SELECT_TRIM'),
            value: null,
            disable: false,
            key: uuid()
        }
        if (isEmpty(optionList)) {
            return defaultOption
        }

        let optionsData = optionList.map(item => {
            let trim = item.trim;
            if (isEmpty(trim)) {
                trim = t('LABEL.COMMON.PRE_REGISTRATION.SELECT_TRIM_OPTION.BLANK')
            }

            return {
                name: trim,
                value: trim,
                year: item.year,
                make: item.make,
                shortModel: item.shortModel,
                model: item.model,
                trim: item.trim,
                engine: item.engine,
                fourByFour: item.fourByFour,
                bodyCode: item.bodyCode,
                disable: false,
                key: uuid()
            }
        })
        optionsData.unshift(defaultOption);
        return optionsData
    }

    //#endregion

    //#region Handle event onChange for fields

    function onChangeData(e) {
        const { name, value } = e.target
        setVehicle({
            ...vehicle,
            [name]: value
        });
        setIsOnchange(true);
    }

    function onChangeTrimSelect(e) {
        fillVinDecodeData(e)
        setIsShowVinDecodeTrimSelect(false)
    }

    function onChangeBodyCodeOption(e) {
        const selectedBodyCode = bodyCodes.find(item => item.bodyCode === e.value);
        setVehicle({
            ...vehicle,
            bodyCode: selectedBodyCode.bodyCode
        })
        setSelectedBodyCodeOption(e)
        setIsOnchange(true)
    }

    function onChangeEngineOption(e) {
        setVehicle({
            ...vehicle,
            engine: e.value
        })
        setSelectedEngineOption(e)
        setIsOnchange(true)
    }

    function onChangeRadioOption(e) {
        setVehicle({
            ...vehicle,
            radio: e.value
        })
        setSelectedRadioOption(e)
        setIsOnchange(true)

        if(!isNil(e.value) &&
           isNil(vehicle.transmission) &&
            isEmpty(vehicle.powerSteering) &&
            isEmpty(vehicle.powerBrakes) &&
            isEmpty(vehicle.airConditioning)
        ) {
            setVehicle({
                ...vehicle,
                powerSteering: e.powerSteering,
                powerBrakes: e.powerBrakes,
                airConditioning: e.airConditioning,
                electricWindows: e.electricWindows,
                electricSteering: e.electricSteering,
                cruiseControl: e.cruiseControl,
                electricLights: e.electricLights,
                tiltSteering: e.tiltSteering,
                transmission: e.selectedTransmissionCode,
                radio: e.selectedRadioCode,
            })
            setSelectedTransOption(findSelectedOption(transOption, e.selectedTransmissionCode))
            setSelectedRadioOption(findSelectedOption(radioOption, e.selectedRadioCode))
        }
    }

    function onChangeTransOption(e) {
        setVehicle({
            ...vehicle,
            transmission: e.value
        })
        setSelectedTransOption(e)
        setIsOnchange(true)
    }

    function onChangeTopOption(e) {
        setVehicle({
            ...vehicle,
            top: e.value
        })
        setSelectedTopOption(e)
        setIsOnchange(true)
    }

    function onChangeIntOption(e) {
        setVehicle({
            ...vehicle,
            interior: e.value
        })
        setSelectedIntOption(e)
    }

    function onChangeFourByFourOption(e) {
        setVehicle({
            ...vehicle,
            fourByFour: e.value
        })
        setSelectedFourByFourOption(e)
        setIsOnchange(true)
    }

    function onChangeAbOption(e) {
        setVehicle({
            ...vehicle,
            airbag: e.value
        })
        setSelectedAbOption(e)
        setIsOnchange(true)
    }

    function onChangeBuOption(e) {
        setVehicle({
            ...vehicle,
            primeCode: e.value
        })
        setSelectedBuOption(e)
    }

    function onChangeOnInvOption(e) {
        setVehicle({
            ...vehicle,
            onInvoice: e.value
        })
        setSelectedOnInvOption(e)
        setIsOnchange(true)
    }

    //#endregion

    useEffect(() => {
        if (enteredShowByValue) {
            setVehicle({
                ...vehicle,
                operatorNumber: enteredShowByValue
            })
            setIsOnchange(true)
            handleDisableOnInvDropdown(enteredShowByValue)
        }
    }, [enteredShowByValue])

    useEffect(() => {
        setSelectedBodyCodeOption(findSelectedOption(bodyCodeOption, vehicle.bodyCode))
        setSelectedEngineOption(findSelectedOption(engineOption, vehicle.engine))
        setSelectedRadioOption(findSelectedOption(radioOption, vehicle.radio))
        setSelectedTransOption(findSelectedOption(transOption, vehicle.transmission))
        setSelectedTopOption(findSelectedOption(topOption, vehicle.top))
        setSelectedIntOption(findSelectedOption(intOption, vehicle.interior))
        setSelectedFourByFourOption(findSelectedOption(fourByFourOption, vehicle.fourByFour))
        setSelectedAbOption(findSelectedOption(abOption, vehicle.airbag))
        setSelectedBuOption(findSelectedOption(buOption, vehicle.primeCode))
        setSelectedOnInvOption((isNil(vehicle.onInvoice) || isEmpty(vehicle.onInvoice)) ? onInvOption[1] : first(onInvOption))

        handleDisableOnInvDropdown(vehicle.operatorNumber)
    }, [vehicle])

    const onClickButtonSearchSeller = useCallback((e) => {
        setShownSearchSellerPanel(true)
    }, [])

    function findSelectedOption(dataOptions, value) {
        if(value === '') {
            return first(dataOptions)
        }
        let selectOption = dataOptions.find(option => option.value === value)

        if (!selectOption) {
            return first(dataOptions)
        }

        return selectOption;
    }

    function validationVinCode(value) {
        if (value.length !== ConstraintConstant.VIN_MAX_LENGTH) {
            setErrorInput({...errorInput, hasError: true, message: t('MESSAGE.COMMON.PRE_REGISTRATION.DECODE_ERROR.MAX_LENGTH'), status: 400})
            decodeRef.current.focus()
            return false
        }

        if (!ConstraintConstant.PATTERN_REGEX_VIN.test(value) || !checkVinValid(value.toUpperCase())) {
            setErrorInput({...errorInput, hasError: true, message: t('MESSAGE.COMMON.PRE_REGISTRATION.DECODE_ERROR.INVALID_VIN_CODE'), status: 400})
            decodeRef.current.focus()
            return false
        }
        return true
    }

    function fillVinDecodeData(item) {
        let vinDecodeData = {...vehicle, ...item}
        const bodyCodeFound = findSelectedOption(bodyCodeOption, item.bodyCode)
        const engineFound = findSelectedOption(engineOption, item.engine)
        const fourByFourFound = findSelectedOption(fourByFourOption, item.fourByFour)

        vinDecodeData.bodyCode = bodyCodeFound.value
        vinDecodeData.engine = engineFound.value
        vinDecodeData.fourByFour = fourByFourFound.value

        setVehicle(vinDecodeData)
        setSelectedBodyCodeOption(bodyCodeFound)
        setSelectedEngineOption(engineFound)
        setSelectedFourByFourOption(fourByFourFound)
    }

    function handleDisableOnInvDropdown(operator) {
        if (!operator) {
            setIsDisableOnInvDropdown(true)
            setSelectedOnInvOption(buildDropdownOptionsOnInvoice()[1])
        } else {
            setIsDisableOnInvDropdown(false)
        }
    }

    function handleVinDecode(vin) {
        setDecodeError({hasError: false, message: "", statusCode: ""})
        setIsShowVinDecodeTrimSelect(false)
        if (!validationVinCode(vin)) {
            return
        }

        getVinDecode(vin)
            .then((response) => {
                if (!isNil(response.data.vehicles)) {
                    if (response.data.vehicles.length === 1) {
                        fillVinDecodeData(first(response.data.vehicles))
                    } else if (response.data.vehicles.length > 1) {
                        let options = buildDropdownOptionsVinDecode(response.data.vehicles)
                        setVinDecodeTrimOption(options)
                        setSelectedVinDecode(first(options))
                        setIsShowVinDecodeTrimSelect(true)
                    }
                }
            }).catch((ex) => {
            let error = handleErrorException(ex)
            setErrorInput({...errorInput, hasError: true, message: error.message, status: error.status})
            handleVinDecodeErrorMessage(error)
            decodeRef.current.focus()
        })
    }

    function handleVinDecodeErrorMessage(error) {
        if (decodeError.hasError){
            if (error.status === 500 || error.status === ERR_NETWORK) {
                return (
                    <NotificationMessage type={'error'} isOpen={true}>
                        {t('MESSAGE.COMMON.PROCESS_ERROR.PRE_TEXT')}
                        <a href={pathName} onClick={openSnowModal}>
                            {t('MESSAGE.COMMON.PROCESS_ERROR.LINK_TEXT')}
                        </a>
                    </NotificationMessage>
                )

            }

            return (
                <NotificationMessage type={'error'} isOpen={true}>
                    {error.message}
                </NotificationMessage>
            )
        }
    }

    function checkVinValid(vin) {
        const letterMap = {A : 1, B : 2, C : 3, D : 4, E : 5, F : 6, G : 7, H : 8,
            J : 1, K : 2, L : 3, M : 4, N : 5,        P : 7,        R : 9,
            S : 2, T : 3, U : 4, V : 5, W : 6, X : 7, Y : 8, Z : 9,
            1 : 1, 2 : 2, 3 : 3, 4 : 4, 5 : 5, 6 : 6, 7 : 7, 8 : 8, 9 : 9, 0 : 0
        };
        const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];

        let products = 0
        for(let i = 0; i < vin.length; i++) {
            products += letterMap[vin[i].toUpperCase()] * weights[i]
        }
        let checkDigitShouldBe = products % 11;
        if(checkDigitShouldBe == 10) checkDigitShouldBe = 'X'

        return checkDigitShouldBe == vin[8]
    }

    function validatePreRegistration() {
        if (isNil(vehicle)) {
            return
        }

        if ((!isNil(vehicle.year)) &&
            (vehicle.year != '') &&
            (!isValidNumber(vehicle.year))) {
            let err = {
                hasError: true,
                message: t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.YEAR_ERROR_FORMAT')
            }
            setErrorInput(err)
            return
        }

        if ((!isNil(vehicle.floor)) &&
            (vehicle.floor != '') &&
            (!isValidNumber(vehicle.floor))) {
            let err = {
                hasError: true,
                message: t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.FLOOR_ERROR_FORMAT')
            }
            setErrorInput(err)
            return
        }

        if ((!isNil(vehicle.odometer)) &&
            (vehicle.odometer != '') &&
            (!isValidNumber(vehicle.odometer))) {
            let err = {
                hasError: true,
                message: t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.ODOMETER_ERROR_FORMAT')
            }
            setErrorInput(err)
            return
        }

        if (!(VehicleDetailPattern.DISPOSITION_CODE_PATTERN.test(vehicle.dispositionCode))) {
            let err = {
                hasError: true,
                message: t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.DISPOSITION_CODE_ERROR_FORMAT')
            }
            setErrorInput(err)
            return
        }

        if ((!isNil(vehicle.operatorNumber)) &&
            (vehicle.operatorNumber != '') &&
            (!isValidNumber(vehicle.operatorNumber))) {
            let err = {
                hasError: true,
                message: t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.OPERATOR_ERROR_FORMAT')
            }
            setErrorInput(err)
            return
        }

        setErrorInput({
            hasError: false,
            message: ""
        })
    }

    const updateInAS400Handler = () => {
        const foundBodyCode = bodyCodes.find(item => item.bodyCode === vehicle.bodyCode);
        const vehicleToUpdate = {
                ...vehicle,
                vehicleType: (foundBodyCode && foundBodyCode.bodyCode) ? foundBodyCode.vehicleTypeCode : EMPTY_STRING,
                longBody: (foundBodyCode && foundBodyCode.bodyCode) ? foundBodyCode.bodyDescription : EMPTY_STRING,
            }
        updatePreRegistration(preRegistrationData.auctionCode, preRegistrationData.sblu, preRegistrationData.linkToSeller, {
            saleDate: preRegistrationData.saleDate,
            vehicle: vehicleToUpdate
        }).then(() => {
            onUpdateInAS400(vehicle)
        }).catch((ex) => {
            let error = handleErrorException(ex)
            setErrorInput({...errorInput, hasError: true, message: error.message, status: error.status})
        });

    };

    const deleteFromAS400Handler = () => {
        deletePreRegistration(preRegistrationData.auctionCode, preRegistrationData.sblu, preRegistrationData.linkToSeller
        ).then(() => {
            onDeleteFromAS400()
            resetPreRegistrationForm()
            setIsEnableDeleteFromAS400(false)
        }).catch((ex) => {
            let error = handleErrorException(ex)
            setErrorInput({...errorInput, hasError: true, message: error.message, status: error.status})
        });
    }

    useEffect(() => {
        setIsEnableButtonUpdateInAS400(() => {
            if (!isEnableWriteDataInAS400) {
                return false;
            }

            return isOnChange && !decodeError.hasError && !errorInput.hasError;
        });
    }, [isOnChange, decodeError.hasError, errorInput.hasError, isEnableWriteDataInAS400]);

    function resetPreRegistrationForm() {
        const ignoreProps = ['status']
        for (let prop in vehicle) {
            if (vehicle.hasOwnProperty(prop) && ignoreProps.indexOf(prop) === -1) {
                vehicle[prop] = EMPTY_STRING
            }
        }
        setVehicle({...vehicle})
        setTitle(EMPTY_STRING);
        setIsOnchange(false)
    }

    useEffect(() => {
        if (!isNil(vehicle.bodyCode)) {
            validatePreRegistration();
        }
    }, [vehicle.bodyCode]);

    return (
        <>
            <Container className='prism-overridden assign-inventory-pre-registration'>
                <SlideOut
                    actionable={true}
                    onCloseButtonClick={() => {
                        setIsPreRegistrationOpen(false)
                    }}
                    open={isPreRegistrationOpen && !shownSearchSellerPanel }
                    placement='right'
                    headerText={isEmpty(title) ? BREAKING_SPACE : title}
                >
                    <Space vertical>
                        <Row className='ml-0 m-0 p-0 notification-message item-row'>
                            <Col md={12} sm={12} xs={12} className='pl-0 pr-0'>
                                {errorInput.hasError && !!errorInput.message && (
                                    <NotificationMessage type={'error'} isOpen={true} className='error-message'>
                                        {errorInput.message}
                                    </NotificationMessage>
                                )}
                            </Col>
                        </Row>
                        <Row className='ml-1 mb-1 item-row'>
                            <Col md={12} sm={12} xs={12} className='pl-0'>
                                <span mauto='maLblVin'>
                                    {t('LABEL.COMMON.PRE_REGISTRATION.VIN_REQUIRED')}
                                </span>
                                <Row className="vin-decode-wrapper ml-0 pl-0">
                                 <Col>
                                     <Space size={1}>
                                         <div className={'vin-decode-input'}>
                                             <Input
                                                 className='prism-input'
                                                 placeholder={t('LABEL.COMMON.PRE_REGISTRATION.ENTER_VIN_HERE')}
                                                 maxLength={ConstraintConstant.VIN_MAX_LENGTH}
                                                 value={vehicle.vin}
                                                 name='vin'
                                                 onKeyDown={(event) =>
                                                     handleEventOnKeyDownForNumberAlphabet(event)
                                                 }
                                                 onChange={onChangeData}
                                                 onBlur={validatePreRegistration}
                                                 mauto='maInputVin'
                                                 invalid={!!decodeError.hasError}
                                                 innerRef={decodeRef}
                                             />
                                         </div>
                                         <div className={'decode-button'}>
                                             <Button className='btn btn-outline'
                                                     onClick={() => {
                                                         handleVinDecode(vehicle.vin)
                                                     }}
                                                     mauto='vinDecodeBtn'>
                                                 {t('LABEL.COMMON.PRE_REGISTRATION.DECODE')}
                                             </Button>
                                         </div>
                                         <div className={'trim-select-dropdown'}>
                                             {isShowVinDecodeTrimSelect && (
                                                 <Dropdown
                                                     options={vinDecodeTrimOption}
                                                     onChange={ onChangeTrimSelect }
                                                     selectedOption={selectedVinDecode}
                                                     mauto='maDropdownAgeInventory'
                                                 />
                                             )}
                                         </div>
                                     </Space>
                                 </Col>
                             </Row>
                            </Col>
                        </Row>
                        <Row className='ml-1 mb-1 item-row'>
                            <Col md={1} sm={2} xs={2} className='pl-0 year-wrapper'>
                                <span mauto='maLblYear'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.YEAR')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.YYYY')}
                                    maxLength={ConstraintConstant.YEAR_MAX_LENGTH}
                                    value={vehicle.year}
                                    name='year'
                                    onKeyDown={(event) =>
                                        handleEventOnKeyDownForNumber(event)
                                    }
                                    onChange={onChangeData}
                                    onBlur={validatePreRegistration}
                                    mauto='maInputYear'
                                />
                            </Col>
                            <Col md={4} sm={3} xs={3} className='pl-0 make-wraper'>
                                <span mauto='maLblMake'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.MAKE')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.ENTER_HERE')}
                                    maxLength={ConstraintConstant.MAKE_MAX_LENGTH}
                                    value={vehicle.make}
                                    name='make'
                                    onChange={onChangeData}
                                    onBlur={validatePreRegistration}
                                    mauto='maInputMake'
                                />
                            </Col>
                            <Col md={7} sm={7} xs={7} className='pl-0'>
                                <Row className={'input-model-group'}>
                                    <Col className={'pr-0'}>
                                        <span className='pl-0' mauto='maLblModel'>
                                               {t('LABEL.COMMON.PRE_REGISTRATION.MODEL')}
                                        </span>
                                        <Input
                                            className='prism-input'
                                            placeholder={t('LABEL.COMMON.PRE_REGISTRATION.ENTER_HERE')}
                                            maxLength={ConstraintConstant.SHORT_MODEL_MAX_LENGTH}
                                            value={vehicle.shortModel}
                                            name='shortModel'
                                            onChange={onChangeData}
                                            onBlur={validatePreRegistration}
                                            mauto='maInputShortModel'
                                        />
                                    </Col>
                                    <Col className='align-self-end pl-0'>
                                        <Input
                                            className='prism-input'
                                            placeholder={t('LABEL.COMMON.PRE_REGISTRATION.ENTER_HERE')}
                                            maxLength={ConstraintConstant.MODEL_MAX_LENGTH}
                                            value={vehicle.model}
                                            name='model'
                                            onChange={onChangeData}
                                            onBlur={validatePreRegistration}
                                            mauto='maInputModel'
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='ml-1 item-row'>
                            <Col md={3} sm={3} xs={3} className='pl-0'>
                                <span mauto='maLblSub'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.SUBSERIES')}
                                </span>
                                <Input
                                    className='prism-input subseries'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.ENTER_HERE')}
                                    maxLength={ConstraintConstant.SUBSERIES_MAX_LENGTH}
                                    value={vehicle.trim}
                                    name='trim'
                                    onChange={onChangeData}
                                    onBlur={validatePreRegistration}
                                    mauto='maInputSubseries'
                                />
                            </Col>
                            <Col md={4} sm={4} xs={5} className='pl-0 body-code-wrapper'>
                                <span mauto='maLblBodyCode'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.BODY_CODE')}
                                </span>
                                <Dropdown
                                    options={bodyCodeOption}
                                    onChange={onChangeBodyCodeOption}
                                    selectedOption={selectedBodyCodeOption}
                                    mauto='maDropdownBodyCodes'
                                />
                            </Col>
                            <Col md={3} sm={3} xs={3} className='pl-0 color-wrapper'>
                                <span mauto='maLblColor'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.COLOR')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.ENTER_HERE')}
                                    maxLength={ConstraintConstant.COLOR_MAX_LENGTH}
                                    value={vehicle.exteriorColor}
                                    name='exteriorColor'
                                    onChange={onChangeData}
                                    onBlur={validatePreRegistration}
                                    mauto='maInputColor'
                                />
                            </Col>
                            <Col md={2} sm={2} xs={1} className='pl-0 pr-4 floor-input'>
                                <span mauto='maLblFloor'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.FLOOR')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.FLOOR_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.FLOOR_MAX_LENGTH}
                                    value={vehicle.floor}
                                    name='floor'
                                    onKeyDown={(event) =>
                                        handleEventOnKeyDownForNumber(event)
                                    }
                                    onChange={onChangeData}
                                    onBlur={validatePreRegistration}
                                    mauto='maInputFloor'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={12} xs={12} className='pl-0'>
                                <hr className='p-0 my-0' />
                            </Col>
                        </Row>
                        <Row className='ml-1 mb-0 item-row'>
                            <Col md={4} sm={4} xs={6} className='pl-0 engine-dropdown'>
                                <span mauto='maLblEngine'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.ENGINE')}
                                </span>
                                <Dropdown
                                    options={engineOption}
                                    onChange={onChangeEngineOption}
                                    selectedOption={selectedEngineOption}
                                    mauto='maDropdownEngine'
                                />
                            </Col>
                            <Col md={4} sm={4} xs={6} className='pl-0 radio-dropdown'>
                                <span mauto='maLblRadio'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.RADIO')}
                                </span>
                                <Dropdown
                                    options={radioOption}
                                    onChange={onChangeRadioOption}
                                    selectedOption={selectedRadioOption}
                                    mauto='maDropdownRadio'
                                />
                            </Col>
                            <Col xs={3}className='pl-0 trans-dropdown'>
                                <span mauto='maLblTrans'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.TRANS')}
                                </span>
                                <Dropdown
                                    options={transOption}
                                    onChange={onChangeTransOption}
                                    selectedOption={selectedTransOption}
                                    mauto='maDropdownTrans'
                                />
                            </Col>
                        </Row>
                        <Row className='ml-1 mb-1 item-row'>
                            <Col md={2} sm={2} xs={2} className='pl-0'>
                                <span mauto='maLblPs'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.PS')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.PS_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.PS_MAX_LENGTH}
                                    value={vehicle.powerSteering}
                                    name='powerSteering'
                                    onChange={onChangeData}
                                    mauto='maInputPS'
                                />
                            </Col>
                            <Col md={2} sm={2} xs={2} className='pl-0'>
                                <span mauto='maLblPb'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.PB')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.PS_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.PS_MAX_LENGTH}
                                    value={vehicle.powerBrakes}
                                    name='powerBrakes'
                                    onChange={onChangeData}
                                    mauto='maInputPB'
                                />
                            </Col>
                            <Col md={2} sm={2} xs={2} className='pl-0'>
                                <span mauto='maLblAc'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.AC')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.PS_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.PS_MAX_LENGTH}
                                    value={vehicle.airConditioning}
                                    name='airConditioning'
                                    onChange={onChangeData}
                                    mauto='maInputAc'
                                />
                            </Col>
                            <Col md={2} sm={2} xs={2} className='pl-0'>
                                <span mauto='maLblEw'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.EW')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.PS_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.PS_MAX_LENGTH}
                                    value={vehicle.electricWindows}
                                    name='electricWindows'
                                    onChange={onChangeData}
                                    mauto='maInputEw'
                                />
                            </Col>
                            <Col md={2} sm={2} xs={2} className='pl-0'>
                                <span mauto='maLblEs'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.ES')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.PS_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.PS_MAX_LENGTH}
                                    value={vehicle.electricSteering}
                                    name='electricSteering'
                                    onChange={onChangeData}
                                    mauto='maInputEs'
                                />
                            </Col>
                        </Row>
                        <Row className='ml-1 item-row'>
                            <Col md={5} sm={4} xs={4} className='pl-0'>
                                <span mauto='maLblTop'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.TOP')}
                                </span>
                                <Dropdown
                                    options={topOption}
                                    onChange={onChangeTopOption}
                                    selectedOption={selectedTopOption}
                                    mauto='maDropdownTop'
                                />
                            </Col>
                            <Col md={1} sm={2} xs={2} className='pl-0 cruise-wrapper'>
                                <span mauto='maLblCruise'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.CRUISE')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.PS_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.PS_MAX_LENGTH}
                                    value={vehicle.cruiseControl}
                                    name='cruiseControl'
                                    onChange={onChangeData}
                                    mauto='maInputCruise'
                                />
                            </Col>
                            <Col md={1} sm={2} xs={2} className='pl-0'>
                                <span mauto='maLblEl'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.EL')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.PS_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.PS_MAX_LENGTH}
                                    value={vehicle.electricLights}
                                    name='electricLights'
                                    onChange={onChangeData}
                                    mauto='maInputEL'
                                />
                            </Col>
                            <Col md={4} sm={4} xs={4} className='pl-0'>
                                <span mauto='maLblInt'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.Int')}
                                </span>
                                <Dropdown
                                    options={intOption}
                                    onChange={onChangeIntOption}
                                    selectedOption={selectedIntOption}
                                    mauto='maDropdownInt'
                                />
                            </Col>
                        </Row>
                        <Row className='ml-1 item-row'>
                            <Col md={3} sm={4} xs={4} className='pl-0'>
                                <span mauto='maLbl4x4'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.4X4')}
                                </span>
                                <Dropdown
                                    options={fourByFourOption}
                                    onChange={onChangeFourByFourOption}
                                    selectedOption={selectedFourByFourOption}
                                    mauto='maDropdownFourByFour'
                                />
                            </Col>
                            <Col md={3} sm={4} xs={4} className='pl-0'>
                                <span mauto='maLblAb'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.AB')}
                                </span>
                                <Dropdown
                                    options={abOption}
                                    onChange={onChangeAbOption}
                                    selectedOption={selectedAbOption}
                                    mauto='maDropdownAb'
                                />
                            </Col>
                            <Col md={1} sm={2} xs={2} className='pl-0'>
                                <span mauto='maLblTilt'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.TILT')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.PS_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.PS_MAX_LENGTH}
                                    value={vehicle.tiltSteering}
                                    name='tiltSteering'
                                    onBlur={validatePreRegistration}
                                    onChange={onChangeData}
                                    mauto='maInputTilt'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={12} xs={12} className='pl-0'>
                                <hr className='p-0 my-0' />
                            </Col>
                        </Row>
                        <Row className='ml-1 mr-2 item-row'>
                            <Col md={5} sm={5} xs={6} className='option-on-top pl-0 bu-wrapper'>
                                <span mauto='maLblBu'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.BU')}
                                </span>
                                <Dropdown
                                    options={buOption}
                                    onChange={onChangeBuOption}
                                    selectedOption={selectedBuOption}
                                    mauto='maDropdownBu'
                                />
                            </Col>
                            <Col md={5} sm={5} xs={4} className='pl-0'>
                                <span mauto='maLblAnnoun'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.ANNOUNCEMENTS')}
                                </span>
                                <Input
                                    className='prism-input announcements-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.PS_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.ANNOUNCEMENTS_MAX_LENGTH}
                                    value={vehicle.announcements}
                                    name='announcements'
                                    onBlur={validatePreRegistration}
                                    onChange={onChangeData}
                                    mauto='maInputAnnouncements'
                                />
                            </Col>
                            <Col md={2} sm={2} xs={2} className='pl-0'>
                                <span mauto='maLblOdom'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.ODOMETER')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.ODOMETER_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.ODOMETER_MAX_LENGTH}
                                    value={vehicle.odometer}
                                    name='odometer'
                                    onKeyDown={(event) =>
                                        handleEventOnKeyDownForNumber(event)
                                    }
                                    onBlur={validatePreRegistration}
                                    onChange={onChangeData}
                                    mauto='maInputOdometer'
                                />
                            </Col>
                        </Row>
                        <Row className='ml-1 item-row last-item-row'>
                            <Col md={1} sm={1} xs={1} className='pl-0 disp-wrapper'>
                                <span mauto='maLblDisp'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.DISP')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.PS_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.DISPOSITION_CODE_MAX_LENGTH}
                                    value={vehicle.dispositionCode}
                                    name='dispositionCode'
                                    onBlur={validatePreRegistration}
                                    onChange={onChangeData}
                                    mauto='maInputDisp'
                                />
                            </Col>
                            <Col md={3} sm={3} xs={3} className='pl-0'>
                                <span mauto='maLblAccount'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.ACCOUNT')}
                                </span>
                                <Input
                                    className='prism-input'
                                    placeholder={t('LABEL.COMMON.PRE_REGISTRATION.ACCOUNT_PLACEHOLDER')}
                                    maxLength={ConstraintConstant.ACCOUNT_MAX_LENGTH}
                                    value={vehicle.leaseAccount}
                                    name='leaseAccount'
                                    onBlur={validatePreRegistration}
                                    onChange={onChangeData}
                                    mauto='maInputAccount'
                                />
                            </Col>
                            <Col md={3} sm={3} xs={3} className='pl-0'>
                                <span mauto='maLblOperator'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.OPERATOR')}
                                </span>
                                <Row>
                                    <Col md={10} sm={10} xs={10} className='pr-0'>
                                        <Input
                                            className='prism-input'
                                            placeholder={t('LABEL.COMMON.PRE_REGISTRATION.PS_PLACEHOLDER')}
                                            maxLength={ConstraintConstant.SELLER_NUMBER_MAX_LENGTH}
                                            value={vehicle.operatorNumber}
                                            name='operatorNumber'
                                            onKeyDown={(event) =>
                                                handleEventOnKeyDownForNumber(event)
                                            }
                                            onBlur={validatePreRegistration}
                                            onChange={(e) => {
                                                    onChangeData(e);
                                                    return handleDisableOnInvDropdown(e.target.value);
                                                }
                                            }
                                            mauto='maInputOperator'
                                        />
                                    </Col>
                                    <Col md={2} sm={1} xs={1}
                                         className='pl-1 search-seller-wrapper'>
                                        <Button className='btn btn-outline'
                                        onClick={onClickButtonSearchSeller}
                                         mauto='maSearchSellerBtn'>
                                        <i className='icon prism-icon-search' />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} sm={4} xs={12} className='option-on-top ml-1 pr-1'>
                                <span mauto='maLblOnInv'>
                                       {t('LABEL.COMMON.PRE_REGISTRATION.ON_INV')}
                                </span>
                                <Dropdown
                                    className='m-0 p-0'
                                    options={onInvOption}
                                    onChange={onChangeOnInvOption}
                                    selectedOption={selectedOnInvOption}
                                    mauto='maDropdownInv'
                                    disabled={isDisableOnInvDropdown}
                                />
                            </Col>
                        </Row>
                        <Row className='flex-sm-row-reverse pre-registration-button-footer'>
                            <Button
                                className='m-2'
                                mauto='maBtnUpdateInAs400'
                                onClick={updateInAS400Handler}
                                color='primary'
                                disabled={!isEnableButtonUpdateInAs400}>
                                {t('LABEL.COMMON.PRE_REGISTRATION.UPDATE_IN_AS400')}
                            </Button>
                            <Button className={`m-2 btn-outline ${!(isEnableWriteDataInAS400 && isEnableDeleteFromAS400) ? 'disabled-background-color' : '' }`}
                                    onClick={deleteFromAS400Handler}
                                    mauto='maBtnDeleteInAs400'
                                    disabled={!(isEnableWriteDataInAS400 && isEnableDeleteFromAS400)}>
                                {t('LABEL.COMMON.PRE_REGISTRATION.DELETE_FROM_AS400')}
                            </Button>
                        </Row>
                    </Space>
            </SlideOut>
        </Container>
            {
                !!shownSearchSellerPanel
                && (
                    <SearchSellerPanel
                        auctionCode={auctionCode}
                        searchSellerOpen={shownSearchSellerPanel}
                        customers={sellerSearchCustomers}
                        searchData={sellerSearchData}
                        isVehicleDetailParticular={true}
                        setSearchSellerOpen={setShownSearchSellerPanel}
                        setSellerNumber={setEnteredShowByValue}
                        setCustomers={setSellerSearchCustomers}
                        setSearchData={setSellerSearchData}
                        setHasDataChanged={()=>{}}
                    />
                )
            }
        </>
    )
}
