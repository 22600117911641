import { RESET_OBSERVING_ACTION_TYPE } from '../utils/constant';
import { randomInteger } from "../utils/utils";

const initialState = {
    assignInventory: {
        search: 0
    },
    menu: {
        auctionCodeChange: 0
    },
    searchRequestHistory: {
        clearAll: 0,
    },
    laneManagement: {
        revertConfirmed: 0,
        clearAllMessages: 0,
    },
    spinner: {
        show: false,
    }
}

export  const resetStateObservingReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_OBSERVING_ACTION_TYPE.ASSIGN_INVENTORY_SEARCH:
            return {
                ...state,
                assignInventory: {
                    ...state.assignInventory,
                    search: randomInteger()
                }
            };
        case RESET_OBSERVING_ACTION_TYPE.AUCTION_CODE_CHANGED:
            return {
                ...state,
                menu: {
                    ...state.menu,
                    auctionCodeChange: randomInteger()
                }
            };
        case RESET_OBSERVING_ACTION_TYPE.SEARCH_HISTORY_CLEAR_ALL:
            return {
               ...state,
                searchRequestHistory: {
                   ...state.searchRequestHistory,
                     clearAll: randomInteger()
                }
            };
        case RESET_OBSERVING_ACTION_TYPE.LANE_MANAGEMENT_REVERT_CONFIRMED:
            return {
              ...state,
                laneManagement: {
                  ...state.laneManagement,
                    revertConfirmed: randomInteger()
                }
            };
        case RESET_OBSERVING_ACTION_TYPE.LANE_MANAGEMENT_CLEAR_ALL_MESSAGES:
            return {
                ...state,
                laneManagement: {
                    ...state.laneManagement,
                    clearAllMessages: randomInteger()
                }
            };
        case RESET_OBSERVING_ACTION_TYPE.SHOW_SPINNER:
            return {
              ...state,
                spinner: {
                  ...state.spinner,
                    show: action.show
                }
            };
        default:
            return state;
    }
}