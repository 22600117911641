import { combineReducers } from 'redux'
import { sessionReducer } from './SessionReducer'
import { asgIvtAdvancedFilterReducer } from './AsgIvtAdvancedFilterReducer'
import {
    dndVehicleDataReducer,
    quickSearchContextReducer,
    saleYearLaneContextReducer, scrollToItemContextReducer,
    searchContextReducer
} from './AssignInventoryReducer'
import { resetStateObservingReducer } from './ResetStateObservingReducer'
import { remainingInventoryReducer } from './RemainingInventoryReducer'
import { preRegistReducer } from './PreRegistReduce'
import { laneManagementReducer, laneManagementSearchReducer } from './LaneManagementReducer'
import { spinnerReducer } from './SpinnerReducer'
import VehicleNotesModalReducer from './VehicleNotesModalReducer';
import VehicleDetailReducer from './VehicleDetailReducer';

export default combineReducers({
    session: sessionReducer,
    asgIvtAdvancedFilter: asgIvtAdvancedFilterReducer,
    dndVehicle: dndVehicleDataReducer,
    asgIvtSearchContext: searchContextReducer,
    resetStateObservingContext: resetStateObservingReducer,
    remainingInventory: remainingInventoryReducer,
    preRegist: preRegistReducer,
    laneManagementContext: laneManagementReducer,
    laneManagementSearchContext: laneManagementSearchReducer,
    saleYearLane: saleYearLaneContextReducer,
    spinnerContext: spinnerReducer,
    inventoryQuickSearchContext: quickSearchContextReducer,
    inventoryScrollToItemContext: scrollToItemContextReducer,
    vehicleNotesModalContext: VehicleNotesModalReducer,
    vehicleDetailContext: VehicleDetailReducer
})