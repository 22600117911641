import React, {useEffect, useState} from 'react'
import {find, isNil} from 'lodash'
import axiosClient from '../../axios'
import { Spinner } from '@prism/library'

import './SpinnerInterceptor.scss'
import {useDispatch} from "react-redux";
import {updateSpinnerState} from "../../../actions/SpinnerAction";

export function GlobalSpinner() {
    return (
        <div className="lm-global-spinner mui-loading-indicator">
            <div className="mui-shim">
                <div className="mui-loading-indicator__content mui-p show--inline-block ">
                    <Spinner size="sm" color="#fff"  />
                    <p className="">Please wait...</p>
                </div>
            </div>
        </div>
    )
}
export default function SpinnerInterceptor() {
    const [show, setShow] = useState(false)
    const dispatch = useDispatch();
    const ingoredUris = [
        "/run-request/summary"
    ]

    useEffect(() => {
        let apiCounter = 0

        const requestInterceptor = axiosClient.interceptors.request.use((config) => {
            const uri = find(ingoredUris, (uri) => {
                return config.url.startsWith(uri)
            })
            const ignoreSpinner = !isNil(uri)
            if (!ignoreSpinner) {
                setShow(++apiCounter > 0)
            }
            return config
        })

        const responseInterceptor = axiosClient.interceptors.response.use(
            (response) => {
                setTimeout(() => {
                    if (apiCounter > 0) {
                        setShow(--apiCounter > 0)
                    }
                }, 100)
                return response
            },
            (error) => {
                setTimeout(() => {
                    if (apiCounter > 0) {
                        setShow(--apiCounter > 0)
                    }
                }, 100)
                return Promise.reject(error)
            }
        )

        return () => {
            axiosClient.interceptors.request.eject(requestInterceptor)
            axiosClient.interceptors.response.eject(responseInterceptor)
        }
    }, [])

    useEffect(() => {
        dispatch(updateSpinnerState(show))
    }, [show])

    if (show) {
        return <GlobalSpinner />
    }
    return false
}
