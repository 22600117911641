import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Anchor, Col, Container, Row, Space } from '@prism/library'

import { AuctionInput } from '../auction-input/AuctionInput'

import './menu-bar.scss'
import { MenuTabs } from "./MenuTabs";
import { NextAvailable } from "../../next-available/NextAvailable"
import { first } from "lodash"
import { buildDropdownOptionsData } from "../../../utils/utils"

export function MenuBar({
    auctionCodeFromState,
    auctionCodeFromCookie,
    className,
    onChangeAuctionCode,
    isAuctionCodeChange,
    setIsAuctionCodeChange,
    activeTabIndex,
    disableTab,
    saleYears,
    auctionConnectionError
}) {
    const { t } = useTranslation()

    const auctionCode = useMemo(
        () => auctionCodeFromState || auctionCodeFromCookie,
        [auctionCodeFromState, auctionCodeFromCookie]
    )
    const [isOpenedAuctionInput, setIsOpenedAuctionInput] = useState(disableTab)

    const [current, setCurrent] = useState(0)

    const [nextAvailableRuns, setNextAvailableRuns] = useState([])
    const [isShowNextAvailableRuns, setIsShowNextAvailableRuns] = useState(false)


    const [saleYearOptions, setSaleYearOptions] = useState(buildDropdownOptionsData([]))
    const [saleNumberOptions, setSaleNumberOptions] = useState(buildDropdownOptionsData([]))

    const [selectedSaleYear, setSelectedSaleYear] = useState(first(buildDropdownOptionsData([])))
    const [selectedSaleNumber, setSelectedSaleNumber] = useState(first(buildDropdownOptionsData([])))

    const [errorMessage, setErrorMessage] = useState(null)

    const renderMenuTabs = useMemo(() => (
        <MenuTabs activeTabIndex={activeTabIndex} disableTab={disableTab}/>
    ), [activeTabIndex,disableTab])

    useEffect(() => {
        setCurrent(activeTabIndex);
        window.cleanNextAvailableRuns()
        setIsAuctionCodeChange(false)
    }, [activeTabIndex]);

    useEffect(() => {
        window.cleanNextAvailableRuns()
    }, [auctionCode]);

     window.cleanNextAvailableRuns = function () {
        setSelectedSaleYear(first(buildDropdownOptionsData([])))
        setSelectedSaleNumber(first(buildDropdownOptionsData([])))
        setNextAvailableRuns([])
        setErrorMessage(null)
    }

    return (
        <>
            <Container fluid className={classNames('menu-bar', className)}>
                <Row>
                    <Col md={8}>
                        {current === activeTabIndex && renderMenuTabs}
                    </Col>
                    <Col md={4}>
                        <Space className="menu-group float-right">
                            <Anchor
                                color="light"
                                mauto="maShowNextAvailable">
                                <span
                                    className="show-next-available-label"
                                    onClick={() => {
                                        setIsShowNextAvailableRuns(true)
                                    }}
                                >{t('LABEL.MENU_BAR.NEXT_AVAILABLE')}</span>
                            </Anchor>
                            <Anchor
                                color="light"
                                data-track="Help"
                                target="_blank"
                                mauto="maLinkAuction">
                                <span>
                                    <span>
                                        {t('LABEL.MENU_BAR.AUCTION.NAME')}
                                    </span>
                                    <span className="pr-1">:</span>
                                    <span
                                        className="menu-item prism-anchor link-sm link-light"
                                        onClick={() => {
                                            setIsOpenedAuctionInput(true)
                                        }}>
                                        <dt>
                                            {!!auctionCode
                                                ? auctionCode
                                                : t(
                                                      'LABEL.MENU_BAR.AUCTION.PLACEHOLDER'
                                                  )}
                                        </dt>
                                    </span>
                                    {isOpenedAuctionInput && (
                                        <AuctionInput
                                            auctionCodeFromState={
                                                auctionCodeFromState
                                            }
                                            auctionCodeFromCookie={
                                                auctionCodeFromCookie
                                            }
                                            closeAuctionInput={() => {
                                                setIsOpenedAuctionInput(false)
                                            }}
                                            changeAuctionCode={(
                                                auctionCode
                                            ) => {
                                                onChangeAuctionCode(auctionCode)
                                            }}
                                            setIsAuctionCodeChange={setIsAuctionCodeChange}
                                        />
                                    )}
                                </span>
                            </Anchor>
                        </Space>
                    </Col>
                </Row>
            </Container>
            {isShowNextAvailableRuns && (
                <NextAvailable
                    auctionCodeFromState={auctionCodeFromState}
                    auctionCodeFromCookie={auctionCodeFromCookie}
                    saleYears={saleYears}
                    isAuctionCodeChange={isAuctionCodeChange}
                    setIsAuctionCodeChange={setIsAuctionCodeChange}
                    isShowNextAvailableRuns={isShowNextAvailableRuns}
                    setIsShowNextAvailableRuns={setIsShowNextAvailableRuns}
                    auctionConnectionError={auctionConnectionError}
                    selectedSaleYear={selectedSaleYear}
                    setSelectedSaleYear={setSelectedSaleYear}
                    selectedSaleNumber={selectedSaleNumber}
                    setSelectedSaleNumber={setSelectedSaleNumber}
                    nextAvailableRuns={nextAvailableRuns}
                    setNextAvailableRuns={setNextAvailableRuns}
                    saleYearOptions={saleYearOptions}
                    setSaleYearOptions={setSaleYearOptions}
                    saleNumberOptions={saleNumberOptions}
                    setSaleNumberOptions={setSaleNumberOptions}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}
        </>
    )
}
