import { Input, Row, Space } from '@prism/library';
import { useTranslation } from 'react-i18next';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import './quick-search.scss'
import {isEmpty, isUndefined, isNull } from 'lodash';
import { ConstraintConstant, EMPTY_STRING } from '../../../utils/constant';
import usePushQuickSearchValue from '../../../hooks/common/UsePushQuickSearchValue';
import {useSelector} from 'react-redux';
import {handleEventOnKeyDownForNumberAlphabet} from '../../../utils/utils'
import { CustomToolTips } from '../custom/prism/tooltips/CustomToolTips';
import {
    getShowNewSearchFeatureBoxToStorage,
    NEW_SEARCH_FEATURE_STORAGE_KEY
} from '../../../utils/data-source/assign-inventory-datasource';

export default function QuickSearch({
    isDisabled = false,
}) {
    const { t } = useTranslation()
    const [search, setSearch] = useState('')
    const [isShowPopover, setShowPopover] = useState(getShowNewSearchFeatureBoxToStorage(NEW_SEARCH_FEATURE_STORAGE_KEY))
    const { pushQuickSearchValue } = usePushQuickSearchValue()
    const { auctionCodeChange } = useSelector(state => state.resetStateObservingContext.menu)
    const assignInventory = useSelector(states => states.resetStateObservingContext.assignInventory)

    const onKeyDownHandler = useCallback((e) => {
        handleEventOnKeyDownForNumberAlphabet(e)
        if (e.key === 'Enter') {
            pushQuickSearchValue(e.target.value)
        }
    }, [])

    const onChangeHandler = useCallback((e) => {
        setSearch(e.target.value)
        if (isUndefined(e.target) || isEmpty(e.target.value) || isNull(e.target.value)) {
            pushQuickSearchValue(EMPTY_STRING)
        }
    }, [])

    const onPasteHandler = useCallback((e) => {
        e.preventDefault()
        const pastedText = e.clipboardData.getData('text/plain')
        const clearedText = pastedText.replace(/[^a-zA-Z0-9]/g, '')
        setSearch(clearedText)
    }, [])

    useEffect(() => {
        setSearch(EMPTY_STRING)
        pushQuickSearchValue(EMPTY_STRING)
    }, [auctionCodeChange, assignInventory.search]);

    const inputWrapperRef = useRef()

    useEffect(() => {
        if (isNull(inputWrapperRef.current)) {
             return
        }

        const buttonSearches = inputWrapperRef.current.querySelectorAll('.btn-input-icononly')
        if (buttonSearches && buttonSearches.length > 0) {
            if (isDisabled) {
                buttonSearches[0].classList.add('disabled-icon-button')
            } else {
                buttonSearches[0].classList.remove('disabled-icon-button')
            }
        }
    }, [isDisabled])

    function handleCloseToolTips(e) {
        setShowPopover(false)
        e.preventDefault()
    }


    return (
        <Row>
            <Space size={1} className='quick-search mr-1'>
                <div ref={inputWrapperRef}>
                    {isShowPopover && (
                        <CustomToolTips
                            handleCloseToolTips={handleCloseToolTips}
                            titleContent={t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.NEW_SEARCH_FEATURE_TITLE')}
                            bodyContent={t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.NEW_SEARCH_FEATURE')}
                            target='quick-search-input'
                            size='sm'
                            storeKey={NEW_SEARCH_FEATURE_STORAGE_KEY}
                        />
                    )}
                    <Input.Interactive
                        onClickRight={(e) => pushQuickSearchValue(search)}
                        type='text'
                        iconRight='prism-icon-search icon'
                        id='quick-search-input'
                        key='quick-search-input'
                        placeholder={t('LABEL.ASSIGN_INVENTORY.SEARCH_BAR.QUICK_SEARCH')}
                        value={search}
                        onChange={onChangeHandler}
                        onKeyDown={onKeyDownHandler}
                        onPaste={onPasteHandler}
                        maxLength={ConstraintConstant.VIN_MAX_LENGTH}
                        className='quick-search-input'
                        border={true}
                        disabled={isDisabled}
                    />
                </div>
            </Space>
        </Row>
    )
}
