import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { convertDateFromAS400, isBlank } from '../../../utils/utils'
import { Col, FormGroup, Input, Row, Space, Label } from '@prism/library'
import { first, isEmpty, trim, isNil } from 'lodash'
import { v4 as uuid } from 'uuid'
import { Dropdown } from '../custom/prism/dropdown/Dropdown'
import { BLANK_HOLDER, ConstraintConstant } from '../../../utils/constant'

export function VehicleVcfItem({
                                   vcfData,
                                   remainingCategories,
                                   updateCategoryHasChanged,
                                   removeCategoryHasChanged,
                                   getRemainingCategories,
                                   setHasChanged
                               }) {
    const {t} = useTranslation()

    const buildDropdownOptionsCategoryData = useCallback((data) => {
        const defaultDropdownOption = {
            name: t('LABEL.COMMON.EDIT_VCF.SELECT'),
            value: null,
            disable: false,
            key: uuid()
        }

        let optionsData = []
        if (isNil(data) || isBlank(data.categoryName)) {
            optionsData.push(defaultDropdownOption)
            const remainingItems = getRemainingCategories()
            remainingItems.map(item => {
                let optionData = {
                    name: item.categoryName,
                    value: item.categoryName,
                    disable: false,
                    key: uuid()
                }
                optionsData.push(optionData)
            })
            return optionsData
        }

        if (!data.isSaved) {
            optionsData.push(defaultDropdownOption)
        }

        let optionData = {
            name: data.categoryName,
            value: data.categoryName,
            disable: false,
            key: uuid()
        }
        optionsData.push(optionData)
        return optionsData
    }, [])

    const buildStatusOptions = useCallback((data) => {
        const defaultDropdownOption = {
            name: t('LABEL.COMMON.EDIT_VCF.SELECT'),
            value: null,
            disable: false,
            key: uuid()
        }

        let optionsData = []
        if (isNil(data) || isEmpty(data.statusChoices)) {
            return [defaultDropdownOption]
        }

        if (!data.isSaved) {
            optionsData.push(defaultDropdownOption)
        }

        data.statusChoices.map(item => {
            let optionData = {
                name: `${trim(item.statusCode)} ${item.statusDescription}`,
                value: item.statusCode,
                disable: false,
                key: uuid()
            }
            optionsData.push(optionData)
        })

        return optionsData
    }, [])

    function findSelectedOption(dataOptions, value) {
        if(!value || value === '') {
            return first(dataOptions)
        }
        let selectOption = dataOptions.find(option => trim(option.value) === trim(value)) || first(dataOptions)
        return selectOption;
    }

    const [categoryOptions, setCategoryOptions] = useState(
        buildDropdownOptionsCategoryData(vcfData)
    )
    const [selectedCategory, setSelectedCategory] = useState(
        first(categoryOptions)
    )
    const [statusOptions, setStatusOptions] = useState(
        buildStatusOptions(vcfData)
    )
    const [selectedStatus, setSelectedStatus] = useState(
        findSelectedOption(statusOptions, vcfData?.statusCode)
    )

    const [primaryNote, setPrimaryNote] = useState(vcfData.primaryNote)
    const [secondaryNote, setSecondaryNote] = useState(vcfData.secondaryNote)

    function changeCategory(selectedData) {
        setHasChanged(true)
        if (selectedData.value === selectedCategory.value) {
            return
        }
        setSelectedCategory(selectedData)

        //Remove prev category from change list
        if (!isNil(selectedCategory.value)) {
            removeCategoryHasChanged(selectedCategory.value)
        }

        //Get status choices by category name
        const statusChoices = remainingCategories.find(item => item.categoryName === selectedData.value)
        setStatusOptions(buildStatusOptions(statusChoices))
        //Reset select for status option
        setSelectedStatus(first(statusOptions))
    }

    function changeStatus(selectedData) {
        setHasChanged(true)
        if (selectedData.value === selectedStatus.value) {
            return
        }
        setSelectedStatus(selectedData)
    }

    function onChangePrimaryNote(e) {
        const {value} = e.target
        setPrimaryNote(value)
        if (!isNil(selectedCategory.value)) {
            setHasChanged(true)
        }
    }

    function onChangeSecondaryNote(e) {
        const {value} = e.target
        setSecondaryNote(value)
        if (!isNil(selectedCategory.value)) {
            setHasChanged(true)
        }
    }

    //Build payload request structure
    function buildChangeData(categoryName, statusCode, primaryNote, secondaryNote) {
        let data = {}
        let categoryValues =
            {
                statusCode: {
                    'original': vcfData.statusCode
                    , 'new': statusCode
                }, primaryNote: {
                    'original': vcfData.primaryNote
                    , 'new': primaryNote
                }, secondaryNote: {
                    'original': vcfData.secondaryNote
                    , 'new': secondaryNote
                },
                isSaved: !!vcfData.isSaved
            }
        data[categoryName] = categoryValues

        return data
    }

    useEffect(() => {
        if (!!selectedCategory?.value || selectedCategory?.name === t('LABEL.COMMON.EDIT_VCF.SELECT')) {
            const vcf = buildChangeData(selectedCategory?.value, selectedStatus.value, primaryNote, secondaryNote)
            //Update changed list when category selected option changed
            updateCategoryHasChanged(vcf)
        }
    }, [selectedCategory, selectedStatus, primaryNote, secondaryNote])

    useEffect(() => {
        setCategoryOptions(buildDropdownOptionsCategoryData())
    }, [remainingCategories])

    return (
        <>
            <Space vertical size='0' className={"vehicle-vcf-item mb-2"}>
                <Row className="ml-1">
                    <Col md={4} sm={4} xl={4} xs={4} className="pl-0">
                        <Label>
                            {t(
                                'LABEL.COMMON.EDIT_VCF.CATEGORY'
                            )}
                        </Label>
                        <Dropdown
                            id='dropdown-category'
                            className='toggle-disable'
                            options={categoryOptions}
                            selectedOption={selectedCategory}
                            onChange={changeCategory}
                            mauto='maDropdownCategory'
                            disabled={!!vcfData.isSaved}
                        />
                    </Col>
                    <Col md={8} sm={8} xl={8} xs={8} className="col-dropdown-status">
                        <Label>
                            {t(
                                'LABEL.COMMON.EDIT_VCF.STATUS'
                            )}
                        </Label>
                        <Dropdown
                            id='dropdown-status'
                            className='toggle-disable'
                            options={statusOptions}
                            selectedOption={selectedStatus}
                            onChange={changeStatus}
                            mauto='maDropdownStatus'
                        />
                    </Col>
                </Row>

                <Row className="ml-1 mt-2">
                    <Col md={4} sm={4} xl={4} xs={4} className="pl-0">
                        <Input.Label fontSize="sm">{t('LABEL.COMMON.EDIT_VCF.TEAM')}</Input.Label>
                        <Input readonly={true}
                               value={vcfData.team || BLANK_HOLDER}
                               className="prism-input readonly-text readonly-team"/>
                    </Col>
                    <Col md={4} sm={4} xl={4} xs={4} className="col-dropdown-status">
                        <Input.Label fontSize="sm">{t('LABEL.COMMON.EDIT_VCF.MANUAL')}</Input.Label>
                        <Input readonly={true}
                               value={vcfData.isManual || BLANK_HOLDER}
                               className="prism-input readonly-text readonly-manual"/>
                    </Col>
                    <Col md={4} sm={4} xl={4} xs={4} className="col-dropdown-status">
                        <Input.Label fontSize="sm">{t('LABEL.COMMON.EDIT_VCF.DATE')}</Input.Label>
                        <Input readonly={true}
                               value={convertDateFromAS400(vcfData.statusDate, 'MM/DD/YYYY') || BLANK_HOLDER}
                               className="prism-input readonly-text readonly-date"/>
                    </Col>
                </Row>

                <Row className="ml-1">
                    <Col md={12} sm={12} xl={12} xs={12} className="pl-0">
                        <FormGroup row className={"mb-0 mt-1"}>
                            <Col>
                                <Input.Label fontSize="sm">{t('LABEL.COMMON.EDIT_VCF.NOTES')}</Input.Label>
                                <Input value={primaryNote}
                                       placeholder={BLANK_HOLDER}
                                       onChange={(e) => onChangePrimaryNote(e)}
                                       maxLength={ConstraintConstant.VCF_NOTE_MAX_LENGTH}
                                       className="prism-input primary-note"/>
                            </Col>
                        </FormGroup>
                        <FormGroup row className={"mt-1 mb-0"}>
                            <Col>
                                <Input value={secondaryNote}
                                       placeholder={BLANK_HOLDER}
                                       onChange={(e) => onChangeSecondaryNote(e)}
                                       maxLength={ConstraintConstant.VCF_NOTE_MAX_LENGTH}
                                       className="prism-input secondary-note"/>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>

            </Space>
        </>
    )
}
