import { getSession } from '../../actions/SessionAction'
import { useAsyncValue } from '../../utils/CustomHooksUtil'
import axiosInstance from "../axios"
import {useDispatch} from "react-redux"

export function useUserInfo() {
    const dispatch = useDispatch()
    return useAsyncValue(() => {
        return axiosInstance
            .get('/user')
            .then((data) => {
                dispatch(getSession(data.data))
                return {
                    isAuthorized: !!data
                }
            })
            .catch((err) => {
                console.log(err)
                throw err
            })
    }, [])
}
