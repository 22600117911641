import { VEHICLE_DETAILS } from '../utils/constant';

const initialState = {
    isChanging: false,
}

export default function VehicleDetailReducer(state = initialState, action) {
    switch (action.type) {
        case VEHICLE_DETAILS.CHANGING:
            return {
                ...state, ...action.state
            }
        default: return state
    }
}