import React from 'react'
import { Chip } from '@prism/library'
import { SortConstant } from "../../../utils/constant"

export default function SortItem({sortValue, handleOnClickRemoveItem, handleChangeSortStrategy}) {

    return (
        <>
            <div className='sort-item'>
                <Chip
                    color="light"
                    mauto='maSortItem'
                    iconAfter={sortValue.order === SortConstant.ASCENDING ? 'arrow-up' : 'arrow-down'}
                    onClick={() => {
                        handleChangeSortStrategy(sortValue)
                    }} >
                    {sortValue.name}
                </Chip>
                <i className='icon prism-icon-cross' onClick={() => {
                    handleOnClickRemoveItem(sortValue)
                }}/>
            </div>

        </>
    )
}