import { SPINNER } from '../utils/constant';

const initialState = {
    spinner: {
        show: false
    }
}

export  const spinnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SPINNER.STATE:
            return {
                ...state,
                spinner: {
                    ...state.spinner,
                    show: action.state
                }
            };
        default:
            return state;
    }
}