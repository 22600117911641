import React from "react";
import _ from "lodash";

function getItemStyles(turn){
   return {
        position: "absolute",
        height: "100%",
        transform: `rotate(${turn}turn)`,
        WebkitTransform : `rotate(${turn}turn)`
    }
}
function Separator(props) {
    return (
        <div
            style={getItemStyles(props.turns)}
        >
            <div style={props.style} className={'separator'}/>
        </div>
    );
}

function RadialSeparators(props) {
    const turns = props.percent / 100;

    return _.range(props.count).map(index => (
        <Separator turns={index * turns} style={props.style} />
    ));
}

export default RadialSeparators;