import React, {useMemo} from 'react'
import { Col, Container, Row } from '@prism/library'
import { RemainingInventoryItem } from './RemainingInventoryItem'

import './remaining-inventory.scss'

export function ResultTable({
    auctionCode,
    remainingInventory,
    submitCount,
    setLoadVehiclesError,
    setMessageUpdateVehicleSuccessful,
    getAdvancedFilterParams,
    isAuctionCodeChange,
    setIsAuctionCodeChange
}) {
    const remainingInventoryList = useMemo(() => {
        return (
            <Col md={12} className="remaining-inventory-items">
                {!!remainingInventory.remainingInventoryDTOs &&
                    remainingInventory.remainingInventoryDTOs.map(
                        (dealer, index) => {
                            return (
                                <RemainingInventoryItem
                                    key={index}
                                    auctionCode={auctionCode}
                                    dealer={dealer}
                                    itemIndex={index}
                                    submitCount={submitCount}
                                    setLoadVehiclesError={setLoadVehiclesError}
                                    setMessageUpdateVehicleSuccessful={setMessageUpdateVehicleSuccessful}
                                    getAdvancedFilterParams={getAdvancedFilterParams}
                                    isAuctionCodeChange={isAuctionCodeChange}
                                    setIsAuctionCodeChange={setIsAuctionCodeChange}
                                />
                            )
                        }
                    )}
            </Col>
        )
    }, [remainingInventory.remainingInventoryDTOs, auctionCode, isAuctionCodeChange])

    return (
        <Container fluid className="prism-overridden remaining-inventory">
            <Row className='remaining-inventory-items-wrapper'>
                {remainingInventoryList}
            </Row>
        </Container>
    )
}
