import React, {useCallback, useState, useMemo, useEffect} from 'react'
import { LaneManagementSearchBar } from './LaneManagementSearchBar'
import { useTranslation } from 'react-i18next'
import { LaneManagementDetailDefault } from './LaneManagementDetailDefault'
import { LaneManagementRequestBoard } from './LaneManagementRequestBoard'
import LaneManagementMessages from "./LaneManagementMessages"
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import CustomDragLayer from '../common/custom-drag-layer/CustomDragLayer'
import { getRequestBoard } from '../../utils/data-source/lane-management-datasource'
import {isEmpty} from "lodash"
import {useSelector} from 'react-redux'
import './lane-management.scss'
import {useLocation} from 'react-router-dom';
export function LaneManagement({
    auctionCodeFromState,
    auctionCodeFromCookie,
    saleYears
}) {
    const { t } = useTranslation()
    const [errorEmptyAuctionCode, setErrorEmptyAuctionCode] = useState(null)
    const [errorInvalidAuctionCode, setErrorInvalidAuctionCode] = useState(null)
    const [errorNotExistsAuctionCode, setErrorNotExistsAuctionCode] = useState(null)
    const [errorNotConfigSaleYear, setErrorNotConfigSaleYear] = useState(null)
    const [errorNotConfigSaleNumber, setErrorNotConfigSaleNumber] = useState(null)
    const [errorWhenSaveDraftVehicle, setErrorWhenSaveDraftVehicle] = useState(null)
    const [errorWhenClearMappedLane, setErrorWhenClearMappedLane] = useState(null)

    const { clearAllMessages } = useSelector(states => states.resetStateObservingContext.laneManagement);

    const auctionCode = useMemo(
        () => auctionCodeFromState || auctionCodeFromCookie,
        [auctionCodeFromState, auctionCodeFromCookie]
    )

    const [vehicles, setVehicles] = useState([])
    const [isGetPreRegistError, setIsGetPreRegistError] = useState(false)
    const [lastSaved, setLastSaved] = useState(null)
    const [laneNumber, setLaneNumber] = useState(null)
    const [mappedRequests, setMappedRequests] = useState(null)
    const [unMappedRequests, setUnMappedRequests] = useState(null)
    const [isAddRequestError, setIsAddRequestError] = useState(false)
    const [isRequestBoardClicked, setIsRequestBoardClicked] = useState(null)

    const [runRequestData, setRunRequestData] = useState({
        auctionCode: auctionCode,
        saleYear: null,
        saleNumber: null,
        laneNumber: laneNumber
    })

    const [errorMessage, setErrorMessage] = useState(null)
    const [saveAsDraftMessage, setSaveAsDraftMessage] = useState(null)
    const [saveMappedLaneError, setSaveMappedLaneError] = useState(null)
    const [isSaveDraftVehicle, setIsSaveDraftVehicle] = useState(false)
    function buildCreateRunRequestPayload (saleYear, saleNumber, laneNumber) {
        setRunRequestData({
            auctionCode: auctionCode,
            saleYear: saleYear,
            saleNumber: saleNumber,
            laneNumber: laneNumber,
            runCount: null
        })
    }

    window.deleteUnMappedRequest = function(globalId) {
        const index = unMappedRequests.findIndex(p => p.globalId === globalId)
        if (index != -1) {
            unMappedRequests.splice(index, 1)
            setUnMappedRequests(() => {
                const newUnMappedRequests = [...unMappedRequests]
                return newUnMappedRequests
            })
        }
    }

    window.setLaneManagementErrorMessage = function(errorMessage) {
        setErrorMessage(errorMessage)
    }

    window.setLaneManagementSaveAsDraftMessage = function(message) {
        setSaveAsDraftMessage(message)
        setTimeout(() => {
            setSaveAsDraftMessage(null)
        }, 3000);
    }
    window.setLaneManagementSaveMappedLaneError = function(saveLaneErrorMessage) {
        setSaveMappedLaneError(saveLaneErrorMessage)
    }
    useEffect(() => {
        if(isRequestBoardClicked){
            const request = {
                auctionCode: auctionCode,
                saleYear: runRequestData.saleYear,
                saleNumber: runRequestData.saleNumber,
                laneNumber: runRequestData.laneNumber
            }
            getRequestBoard(request)
                .then((response) => {
                    setLastSaved(response.data?.savedLane)
                    setVehicles(response.data?.vehicleRuns)
                    setMappedRequests(response.data?.mappedRequests)
                    setUnMappedRequests(response.data?.unmappedRequests)
                    window.setLaneManagementErrorMessage(null)
                }).catch((ex) => {
                    //TODO: handle error
                    console.log(ex)
                }).finally(()=>{
                    setIsRequestBoardClicked(false)
                    // dispatch(getSaleYearLane(null))
                })
        }
    }, [isRequestBoardClicked,runRequestData]);

    const clearMessages = useCallback(() => {
        setErrorMessage(null);
        // setSaveMappedLaneError(null)
    }, [])

    useEffect(() => {
        clearMessages()
    }, [clearAllMessages]);

    function loadDataAfterSendToAS400 (response) {
        setLastSaved(response.data?.savedLane)
        setVehicles(response.data?.vehicleRuns)
        setMappedRequests(response.data?.mappedRequests)
        setUnMappedRequests(response.data?.unmappedRequests)
    }
    return (
        <div className="lane-management-wrapper">
            <div>
                <LaneManagementSearchBar
                    auctionCodeFromState={auctionCodeFromState}
                    auctionCodeFromCookie={auctionCodeFromCookie}
                    saleYears={saleYears}
                    setVehicles={setVehicles}
                    setLastSaved={setLastSaved}
                    setLaneNumber={setLaneNumber}
                    setErrorEmptyAuctionCode={setErrorEmptyAuctionCode}
                    setErrorInvalidAuctionCode={setErrorInvalidAuctionCode}
                    setErrorNotExistsAuctionCode={setErrorNotExistsAuctionCode}
                    setErrorNotConfigSaleYear={setErrorNotConfigSaleYear}
                    setErrorNotConfigSaleNumber={setErrorNotConfigSaleNumber}
                    setMappedRequests={setMappedRequests}
                    setUnMappedRequests={setUnMappedRequests}
                    setCreateRunRequestData={buildCreateRunRequestPayload}
                    isSaveDraftVehicle={isSaveDraftVehicle}
                    setIsSaveDraftVehicle={setIsSaveDraftVehicle}
                    setErrorWhenSaveDraftVehicle={setErrorWhenSaveDraftVehicle}
                    setErrorWhenClearMappedLane={setErrorWhenClearMappedLane}
                />
            </div>

            {/* Lane management messages */}
            <div className="lane-management-messages">
                <LaneManagementMessages
                    errorEmptyAuctionCode={errorEmptyAuctionCode}
                    errorInvalidAuctionCode={errorInvalidAuctionCode}
                    errorNotExistsAuctionCode={errorNotExistsAuctionCode}
                    errorNotConfigSaleYear={errorNotConfigSaleYear}
                    errorNotConfigSaleNumber={errorNotConfigSaleNumber}
                    errorWhenSaveDraftVehicle={errorWhenSaveDraftVehicle}
                    errorWhenClearMappedLane={errorWhenClearMappedLane}
                    isGetPreRegistError={isGetPreRegistError}
                    isAddRequestError={isAddRequestError}
                    errorMessage={errorMessage}
                    saveAsDraftMessage={saveAsDraftMessage}
                    saveMappedLaneError={saveMappedLaneError}
                    onSuccessMessageToggleCallBack={() => {
                        setSaveAsDraftMessage(null)
                    }}
                />
            </div>

            <DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>
                {(!lastSaved || !lastSaved.saved || lastSaved.dirty || isEmpty(vehicles)) && (
                    <LaneManagementRequestBoard
                        mappedRequests={mappedRequests}
                        unMappedRequests={unMappedRequests}
                        setIsAddRequestError={setIsAddRequestError}
                        laneNumber={laneNumber}
                        runRequestData={runRequestData}
                        setMappedRequests={setMappedRequests}
                        setUnMappedRequests={setUnMappedRequests}
                        laneSavedToAS400={lastSaved?.saved}
                        auctionCode={auctionCode}
                        loadDataAfterSendToAS400={loadDataAfterSendToAS400}
                    />
                )}

                {!!lastSaved && lastSaved.saved && !lastSaved.dirty && !isEmpty(vehicles)&& (
                    <LaneManagementDetailDefault
                        auctionCode={auctionCode}
                        vehicles={vehicles}
                        setVehicles={setVehicles}
                        setIsGetPreRegistError={setIsGetPreRegistError}
                        lastSaved={lastSaved}
                        setLastSaved={setLastSaved}
                        laneNumber={laneNumber}
                        setIsRequestBoardClicked={setIsRequestBoardClicked}
                        runRequestData={runRequestData}
                        setIsSaveDraftVehicle={setIsSaveDraftVehicle}
                        setErrorWhenSaveDraftVehicle={setErrorWhenSaveDraftVehicle}
                        setErrorWhenClearMappedLane={setErrorWhenClearMappedLane}
                        setMappedRequests={setMappedRequests}
                        setUnMappedRequests={setUnMappedRequests}
                    />
                )}
                <CustomDragLayer />
            </DndProvider>
        </div>
    )
}
