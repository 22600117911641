import React, { useEffect, useMemo } from "react";
import { isProduction } from "../../utils/utils";
import { MessageTypeConstant } from "../../utils/constant";
import { AuctionCodeErrorMessage } from "../common/error/AuctionCodeErrorNotificationMessage";
import { useMessages } from "../../hooks/common/UseMessages";
import NotificationMessages from "../common/notification-messages/NotificationMessages";
import { useTranslation } from "react-i18next";
import { updateVehicleSuccessfulMessage } from "../../actions/RemainingInventoryAction";
import { useDispatch, useSelector } from "react-redux";

export default function RemainingInventoryMessages({
    auctionConnectionError,
    isProcessError,
    auctionCode,
    loadVehiclesError,
}) {
    const { messages, clearMessages, putMessage } = useMessages()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const messageVehicleUpdated = useSelector(states => states.remainingInventory.messageVehicleUpdated)

    const loadVehiclesErrorErrorMessage = useMemo(() => (
        t('MESSAGE.COMMON.VEHICLES_LOAD_ERROR') + t('MESSAGE.COMMON.PROCESS_ERROR.SERVER_ERROR_TEXT', { statusCode: loadVehiclesError?.status, message: loadVehiclesError?.message })
    ), [loadVehiclesError])

    const messageConditionMapping = useMemo(() =>
        [
            {
                condition: () => !isProduction(),
                message: t('MESSAGE.COMMON.INFO_MESSAGE_FOR_TESTING'),
                type: MessageTypeConstant.INFO
            },
            {
                condition: () => auctionConnectionError?.isValidAuction === false && !isProcessError,
                message: (<AuctionCodeErrorMessage auctionConnectionError={auctionConnectionError} />),
                type: MessageTypeConstant.ERROR
            },
            {
                condition: () => !auctionCode,
                message: t('MESSAGE.COMMON.AUCTION_EMPTY'),
                type: MessageTypeConstant.ERROR
            },
            {
                condition: () => !!loadVehiclesError,
                message: loadVehiclesErrorErrorMessage,
                type: MessageTypeConstant.ERROR
            },
            {
                condition: () => !!auctionCode && !!isProcessError,
                message: (<AuctionCodeErrorMessage auctionConnectionError={isProcessError} />),
                type: MessageTypeConstant.ERROR
            },
            {
                condition: () => !!messageVehicleUpdated,
                message: (<div dangerouslySetInnerHTML={{__html: messageVehicleUpdated?.message}}/>),
                type: MessageTypeConstant.SUCCESS
            }
        ], [isProduction,
        auctionConnectionError,
        isProcessError,
        auctionCode,
        loadVehiclesError,
        loadVehiclesErrorErrorMessage,
        messageVehicleUpdated])

    useEffect(() => {
        clearMessages()
        messageConditionMapping.forEach(({condition, message, type}) => {
            if (condition() === true) {
                putMessage(message, type)
            }
        })

    }, [messageConditionMapping])

    function successMessageToggleCallBackHandler() {
        dispatch(updateVehicleSuccessfulMessage(null))
    }

    return (
        <NotificationMessages
            messages={messages}
            onSuccessMessageToggleCallBack={successMessageToggleCallBackHandler}
        />
    )
}