import React, { useRef, useEffect, useMemo, useState, useCallback } from 'react'
import { Button, Checkbox, Col, Container, Input, Row, Space } from '@prism/library'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'
import { AssignInventoryAdvancedFilter } from './AssignInventoryAdvancedFilter'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import CustomDragLayer from '../common/custom-drag-layer/CustomDragLayer'
import { AssignVehicleListWrapper } from './AssignVehicleListWrapper'
import AssignInventorySortBy from "./AssignInventorySortBy"
import {
    deleteDraftBySbluList,
    inventoryBulkUpdate,
    saveDraft
} from '../../utils/data-source/assign-inventory-datasource'
import { handleErrorException, handleEventOnKeyDownForNumber, isBlank, isEnableToggleFlag,    sortVehicleByCustomCriteria} from '../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { ConstraintConstant, EMPTY_STRING, PathUrlConstant, SortConstant, ToggleFunction } from '../../utils/constant'
import { UnAssignVehicleListWrapper } from './UnAssignVehicleListWrapper'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { getSaleYearLane } from '../../actions/AssignInventoryAction'
import QuickSearch from '../common/quick-search/QuickSearch'
import useQuickSearch from '../../hooks/common/UseQuickSearch'
import usePushQuickSearchValue from "../../hooks/common/UsePushQuickSearchValue"
import SortItem from "../common/sort-by/SortItem"
import classNames from "classnames"

import './assign-inventory-detail.scss'

export function AssignInventoryDetail({
    auctionCodeFromState,
    auctionCodeFromCookie,
    draftVehicles,
    setDraftVehicles,
    isDraftVehiclesChangedByRemove,
    laneInventory,
    setLaneInventory,
    inventory,
    setInventory,
    runRanges,
    laneNumber,
    vehicles,
    setVehicles,
    submitCount,
    setIsGetPreRegistError
}) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const auctionCode = useMemo(
        () => auctionCodeFromState || auctionCodeFromCookie,
        [auctionCodeFromState, auctionCodeFromCookie]
    )
    const [isShowChecked, setIsShowChecked] = useState(false)

    const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false)

    const [isOnchangeAdvancedFilter, setIsOnchangeAdvancedFilter] =  useState(false)

    const initFilterValue = {
        noSales: [],
        gatePassStatus: [],
        titleStatus: [],
        crStatus: [],
        floorPrice: [],
        drivableStatus: [],
        vehicleType: [],
        dispositionCodes: "",
        lotLocations: "",
        excludeDispositionCodes: false,
        excludeTodaysRuns: false,
        excludeLotLocations: false
    }

    const [advancedFilter, setAdvancedFilter] = useState(initFilterValue)

    const [isEnableWriteDataInAS400, setIsEnableWriteDataInAS400] = useState(false)

    const checkBoxRef = useRef(null)

    const searchContext = useSelector(states => states.asgIvtSearchContext.searchContext)
    const saleYearLaneContext = useSelector(states => states.saleYearLane.saleYearLaneContext)

    const toggleConfigs = useSelector(states => states.session.toggleConfigs)

    const [bulkUpdateVehicleErrors, setBulkUpdateVehicleErrors] = useState([])
    const [leftList, setLeftList] = useState(laneInventory)
    const [rightList, setRightList] = useState(inventory)
    const { pushQuickSearchValue } = usePushQuickSearchValue()
    const {scrollToLeftItem, scrollToRightItem} = useQuickSearch({
        leftList,
        rightList,
        columns: ['workOrderNumber', 'vin']})
    const [sortItems, setSortItems] = useState([])

    const [isEnableAutoAssign, setIsEnableAutoAssign] = useState(false)
    const [startingRunNumber, setStartingRunNumber] = useState(null)
    const selectAllRunRef = useRef(null)

    useEffect(() => {
        const toggleConfigValue = toggleConfigs[ToggleFunction.AUCTION_LIST_ALLOWED_DB_WRITE]
        const isEnableUpdateAS400Flag = isEnableToggleFlag(toggleConfigValue, auctionCode)
        setIsEnableWriteDataInAS400(isEnableUpdateAS400Flag)
        setBulkUpdateVehicleErrors([])
    }, [auctionCode])

    useEffect(() => {
        //Reset checkbox Show Next Available
        if (isShowChecked) {
            checkBoxRef.current.checked = false
            setIsShowChecked(false)
        }

        //Reset checkbox Select for All Vehicles
        if (selectAllRunRef.current.checked) {
            selectAllRunRef.current.checked = false
        }
        setStartingRunNumber('')
    }, [submitCount])

    window.resetShowNextAvailbleCheckbox = function() {
        checkBoxRef.current.checked = false
    }

    window.updateQuickSearchData = function(data, left) {
        if (left) {
            setLeftList(data)
        } else {
            setRightList(data)
        }
    }

    function handleClearAdvancedFilter() {
        setAdvancedFilter(initFilterValue)
        setInventory(JSON.parse(JSON.stringify(vehicles)))
        setIsOnchangeAdvancedFilter(false)
    }

    //#region Handle Save As Draft
    function handleBulkUpdateAssignInventory(isPersist = false) {
        window.setSaveDraftVehicleMessage(null)
        const bulkUpdateRequestBody = {
            auctionId: auctionCode,
            persist: isPersist,
            inventory: buildInventoryRequestBody()
        }

        if (isEmpty(draftVehicles)) {
            const saveDraftRequestBody = buildSaveDraftRequestBody(null)
            saveDraftVehicle(saveDraftRequestBody)
        } else {
            inventoryBulkUpdate(bulkUpdateRequestBody)
            .then((response) => {
                if (response.status === 200) {
                    const errorsOnBulkUpdate = response.data.errors
                    if (errorsOnBulkUpdate) {
                        setBulkUpdateVehicleErrors(errorsOnBulkUpdate)
                        window.setSaveDraftVehicleMessage({message: response.data.message, type: 'error'})
                    }

                    //Check if Send To AS400 button clicked
                    if (isPersist) {
                        const deleteDraftRequestBody = buildDeleteRequestBody(errorsOnBulkUpdate)
                        if (!isEmpty(deleteDraftRequestBody.sbluList)) {
                            deleteDraftVehicle(deleteDraftRequestBody, errorsOnBulkUpdate)
                        }
                    } else {
                        const saveDraftRequestBody = buildSaveDraftRequestBody(errorsOnBulkUpdate)
                        saveDraftVehicle(saveDraftRequestBody, errorsOnBulkUpdate)
                    }
                }
            })
            .catch((ex) => {
                const error = handleErrorException(ex)
                window.setSaveDraftVehicleMessage({message: error.message, type: 'error'})
            })
        }
    }

    /**
     * Call API to backend to save draft vehicle
     */
    function saveDraftVehicle(saveDraftRequestBody, errorsOnBulkUpdate) {
        saveDraft(saveDraftRequestBody)
        .then((res) => {
            if (!errorsOnBulkUpdate) {
                let successMessage = 'MESSAGE.COMMON.SUCCESS.AS400.VEHICLE_SAVE_AS_DRAFT_SUCCESSFUL'
                if (isEmpty(draftVehicles)) {
                    successMessage = 'MESSAGE.COMMON.SUCCESS.AS400.UPDATE_DRAFT_VEHICLE_SUCCESSFUL'
                }
                window.setSaveDraftVehicleMessage({message: t(successMessage), type:'success'})
            }
            
            window.resetDroppedVehicles()
            //Remove background after save-draft
            draftVehicles.forEach((vehicle)=>{
                const id = `vehicle-item-${vehicle.vin}-${vehicle.workOrderNumber}-${vehicle.runNumber}`
                const elementFound = document.getElementById(id);
                if (elementFound) {
                    elementFound.classList.remove('bg-dropped')
                }
            })
        })
        .catch((ex) => {
            const error = handleErrorException(ex)
            window.setSaveDraftVehicleMessage({message: error.message, type: 'error'})
        })
    }
    function deleteDraftVehicle(deleteDraftRequestBody, errorsOnBulkUpdate) {
        deleteDraftBySbluList(deleteDraftRequestBody)
            .then((res) => {
                if (!errorsOnBulkUpdate) {
                    let successMessage = 'MESSAGE.COMMON.SUCCESS.AS400.VEHICLE_SENT_TO_AS400_SUCCESSFUL'
                    if (isEmpty(draftVehicles)) {
                        successMessage = 'MESSAGE.COMMON.SUCCESS.AS400.UPDATE_DRAFT_VEHICLE_SUCCESSFUL'
                    }
                    window.setSaveDraftVehicleMessage({message: t(successMessage), type:'success'})
                }

                window.resetVehiclesSentToAS400(deleteDraftRequestBody.sbluList)
                window.handleVehiclesSentToAS400(deleteDraftRequestBody.sbluList)
                //Remove background after save-draft
                draftVehicles.map((vehicle)=>{
                    const id = `vehicle-item-${vehicle.vin}-${vehicle.workOrderNumber}-${vehicle.runNumber}`
                    document.getElementById(id).classList.remove('bg-dropped')
                })
            })
            .catch((ex) => {
                const error = handleErrorException(ex)
                window.setSaveDraftVehicleMessage({message: error.message, type: 'error'})
            })
    }

    function buildInventoryRequestBody() {
        let inventory = []
        draftVehicles.forEach(draftVehicle => {
            inventory.push({
                workOrderNumber: draftVehicle.workOrderNumber,
                reservedSpotDealerNumber: getReservedSpotDealerNumber(draftVehicle),
                from: {
                    laneNumber: (draftVehicle.draftVehicle ? draftVehicle.origLaneNumber : draftVehicle.fromVehicle.laneNumber) || 0,
                    runNumber: (draftVehicle.draftVehicle ? draftVehicle.origRunNumber : draftVehicle.fromVehicle.runNumber) || 0,
                    saleNumber: (draftVehicle.draftVehicle ? draftVehicle.origSaleNumber : draftVehicle.fromVehicle.saleNumber) || 0,
                    saleYear: (draftVehicle.draftVehicle ? draftVehicle.origSaleYear : draftVehicle.fromVehicle.saleYear) || 0
                },
                to: {
                    laneNumber: draftVehicle.laneNumber,
                    runNumber: draftVehicle.runNumber,
                    saleNumber: searchContext.saleNumber,
                    saleYear: searchContext.saleYear
                },
            })
        })
        return inventory
    }

    function buildSaveDraftRequestBody(errorsOnBulkUpdate) {
        let draftVehiclesRequestBody = []
        draftVehicles.forEach((item) => {
            let draftVehicle = {
                auctionCode: auctionCode,
                errorMessage: getErrorMessage(item.workOrderNumber, errorsOnBulkUpdate),
                laneNumber: String(searchContext.laneNumber).padStart(2, '0'),
                runNumber: String(item.runNumber).padStart(3, '0'),
                saleNumber: searchContext.saleNumber,
                saleYear: searchContext.saleYear,
                sblu: item.sblu,
                sellerAltGroupCode: item.altGroupCode ? item.altGroupCode.toUpperCase() : '*',
                sellerGroupCode: item.groupCode ? item.groupCode.toUpperCase() : '*',
                sellerNumber: item.sellerNumber,
                vin: item.vin,
                workOrderNumber: item.workOrderNumber
            }
            draftVehiclesRequestBody.push(draftVehicle)
        })

        const saveDraftRequestBody = {
            auctionCode: auctionCode,
            saleYear: searchContext.saleYear,
            saleNumber: searchContext.saleNumber,
            laneNumber: searchContext.laneNumber,
            searchType: searchContext.searchType,
            searchKeywords: searchContext.searchKeywords,
            draftVehicles: draftVehiclesRequestBody
        }

        return saveDraftRequestBody;
    }

    function buildDeleteRequestBody(errorsOnBulkUpdate) {
        let sbluList = []
        draftVehicles.forEach((item) => {
            if (getErrorMessage(item.workOrderNumber, errorsOnBulkUpdate) === '*') {
                sbluList.push(item.sblu)
            }
        })

        const deleteDraftRequestBody = {
            auctionCode: auctionCode,
            saleYear: searchContext.saleYear,
            saleNumber: searchContext.saleNumber,
            laneNumber: searchContext.laneNumber,
            sbluList: sbluList
        }

        return deleteDraftRequestBody;
    }

    function getErrorMessage(workOrderNumber, errorsOnBulkUpdate) {
        if (!isEmpty(errorsOnBulkUpdate)) {
            const errors = errorsOnBulkUpdate.filter(p => p.workOrderNumber.toString() === workOrderNumber.toString())
            if (!isNil(errors) && errors.length > 0) {
                return errors[0].errorMessage
            }
        }

        return '*'
    }

    function getReservedSpotDealerNumber(vehicle) {
        if (vehicle.replacedItem?.status === 'SP' && !isNil(vehicle.replacedItem.sellerNumber) && vehicle.replacedItem.status !== 'EMPTY') {
            return vehicle.replacedItem.sellerNumber
        }

        return "0000000"
    }
    //#endregion

    function isShowLaneManagementLink() {
        return !isNil(saleYearLaneContext)
            && !isBlank(saleYearLaneContext.saleYear)
            && !isBlank(saleYearLaneContext.saleNumber)
            && !isBlank(saleYearLaneContext.laneNumber)
    }

    useEffect(() => {
        pushQuickSearchValue(EMPTY_STRING)
    }, [isShowChecked])

    function handleChangeSortStrategy(itemSort) {
        setSortItems(sortItems.filter((item) => {
            if (item.value === itemSort.value) {
                item.order = itemSort.order === SortConstant.ASCENDING ? SortConstant.DESCENDING : SortConstant.ASCENDING;
            }
            return item;
        }))
    }
    function handleOnClickRemoveItem(item) {
        setSortItems(sortItems.filter(sortItem => sortItem.value !== item.value))
    }

    //#region Auto assign selected runs
    //Handle event check on checkbox CheckAll
    function handleCheckAllUnAssignVehicle() {
        const isCheckedAll = selectAllRunRef.current.checked
        let clonedInventory = [...inventory]
        let clonedVehicles = [...vehicles] //Update checked status for vehicles (also use in filter function)

        clonedInventory = clonedInventory.map(p => { return {...p, checked: isCheckedAll} })
        clonedVehicles = clonedVehicles.map(p => {
            const index = clonedInventory.findIndex(item => p.vin === item.vin && p.workOrderNumber === item.workOrderNumber && p.sblu === item.sblu)
            if(index !== -1) {
                return {...p, checked: isCheckedAll}
            } else {
                return p
            }
        })

        setInventory(clonedInventory)
        setVehicles(clonedVehicles)
    }

    //Handle event check on checkbox of an unassign vehicle
    window.updateCheckedRuns = function(vehicle) {
        //Update checked status for unassign vehicle (right column)
        const clonedInventory = [...inventory]
        let vehicleIndex = clonedInventory.findIndex(p => p.vin === vehicle.vin && p.workOrderNumber === vehicle.workOrderNumber && p.sblu === vehicle.sblu)
        if (vehicleIndex !== -1) {
            let unassignVehicle = {...clonedInventory[vehicleIndex]}
            unassignVehicle.checked = !unassignVehicle.checked
            clonedInventory[vehicleIndex] = unassignVehicle
            setInventory(clonedInventory)
        }

        //Update checked status for vehicles (also use in filter function)
        const clonedVehicles = [...vehicles]
        let vehicleCacheIndex = clonedVehicles.findIndex(p => p.vin === vehicle.vin && p.workOrderNumber === vehicle.workOrderNumber && p.sblu === vehicle.sblu)
        if (vehicleCacheIndex !== -1) {
            let vehicleCache = {...clonedVehicles[vehicleCacheIndex]}
            vehicleCache.checked = !vehicleCache.checked
            clonedVehicles[vehicleCacheIndex] = vehicleCache
            setVehicles(clonedVehicles)
        }

        //Check the previous check status of given vehicle to update the CheckAll checkbox
        if (!vehicle.checked === false) {
            selectAllRunRef.current.checked = false
        } else {
            const unCheckedIndex = clonedInventory.findIndex(p => isNil(p.checked) || p.checked === false)
            if (unCheckedIndex === -1) {
                selectAllRunRef.current.checked = true
            }
        }
    }

    /**
     * Reset checked status of unassign vehicle after user perform Apply Filter, Clear Filter, clicking on button REMOVE
     * @param {*} isVehicleChecked checked status of the removed vehicle (in case user click on REMOVE button)
     */
    window.resetUnassignVehicleCheckedStatus = function(isVehicleChecked) {
        if (selectAllRunRef.current.checked === true) {
            const clonedInventory = [...inventory]
            clonedInventory.forEach(p => {
                p.checked = false
            })
            setInventory(clonedInventory)

            //Update checked status for vehicles (also use in filter function)
            const clonedVehicles = [...vehicles]
            clonedVehicles.forEach(p => {
                p.checked = false
            })
            setVehicles(clonedVehicles)

            selectAllRunRef.current.checked = false
        } else if (isVehicleChecked) {
            const unCheckedItem = inventory.find(p => isNil(p.checked) || p.checked === false)
            if (isNil(unCheckedItem)) {
                selectAllRunRef.current.checked = true
            }
        }
    }

    window.resetSortCriterias = function() {
        setSortItems([])
    }

    //Handle event click on Auto Assign button
    function autoAssignSelectedRuns() {
        let startingRun = startingRunNumber
        if (isNil(startingRun)) {
            startingRun = 1
        }

        let checkedVehicles = inventory.filter(p => p.checked === true)
        let inventoryCloned = [...inventory] //Right column
        if (checkedVehicles.length > 0) {
            let hasChanged = false //Check whether need to update inventory and laneInventory or not

            let reserveVehicleSpots = new Map() //Left column
            let reservedSpots = window.getReservedSpots(checkedVehicles.length, startingRun)
            if (reservedSpots && reservedSpots.length > 0) {
                for (var i = 0; i < reservedSpots.length; i++) {
                    hasChanged = true

                    let vehicleToMove = checkedVehicles[i]
                    let assignedSpot = reservedSpots[i]

                    //Remove vehicle from the right column
                    inventoryCloned.removeIf(p => p.vin === vehicleToMove.vin && p.workOrderNumber === vehicleToMove.workOrderNumber && p.sblu === vehicleToMove.sblu)

                    vehicleToMove.type = 'TRANSIENT'
                    if(isNil(vehicleToMove.fromVehicle)) {
                        vehicleToMove.fromVehicle = JSON.parse(JSON.stringify(vehicleToMove))
                    }

                    vehicleToMove.saleYear = searchContext.saleYear
                    vehicleToMove.laneNumber = searchContext.laneNumber
                    vehicleToMove.saleNumber = searchContext.saleNumber
                    vehicleToMove.runNumber = assignedSpot.runNumber
                    vehicleToMove.replacedItem = assignedSpot
                    vehicleToMove.isUnsave = true //Make the vehicle draggable
                    vehicleToMove.isVehicleDetailOpen = false

                    //Store assignedSpot and vehicleToMove to a map
                    reserveVehicleSpots.set(assignedSpot.runNumber, vehicleToMove)
                }
            }

            if (hasChanged) {
                const movedVehicles = Array.from(reserveVehicleSpots.values())
                //Replace reserve vehicle on the left column
                window.replaceReserveVehicleWhenAutoAssign(reserveVehicleSpots)

                //Remove vehicle from vehicles list after auto assign vehicles
                window.removeUnassignedVehiclesAfterAutoAssign(movedVehicles)

                //Update vehicles (also use in filter function) after perform auto assign
                window.updateVehiclesAfterAutoAssign(movedVehicles, selectAllRunRef.current.checked)

                //Update draftVehicles for save
                window.updateDraftVehicleList(movedVehicles)
            }
        }

        //Handle checked status for remaning vehicle
        if (selectAllRunRef.current.checked === true) { //Case check all
            inventoryCloned.forEach(p => {
                p.checked = false
            })
            setInventory(inventoryCloned)
            selectAllRunRef.current.checked = false
        } else { //Case check individual
            setInventory(inventoryCloned)
        }
        setStartingRunNumber('')
    }

    function handleEventOnChangeStartingRun(event) {
        setStartingRunNumber(event.target.value)
    }

    const handleOnPasteInputStartingRun = useCallback((event) => {
        event.preventDefault()
        const pastedText = event.clipboardData.getData('text/plain')
        const clearedText = pastedText.replace(/[^0-9]/g, '').substring(0, 4)
        setStartingRunNumber(clearedText)
    }, [])

    useEffect(() => {
        const checkedItems = inventory.filter(p => p.checked === true)
        if (checkedItems && checkedItems.length > 0) {
            setIsEnableAutoAssign(true)
        } else {
            setIsEnableAutoAssign(false)
        }
    }, [inventory])
    //#endregion

    return (
        <div className="assign-inventory-detail-container-wrapper mt-1">
            <Container fluid className="pr-0 assign-inventory-detail-wrapper" mauto="maAssignInventoryDetail">
                <DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>
                <Row className="mr-2 row-vehicle-detail">
                    <Col md={6} sm={6} xs={6} className="assign-inventory-container-col">
                        <div className="assign-inventory-container">
                            <Space className="assigned-inventory-header" mauto="maAssignInventoryHeader">
                                {t(
                                    'LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.ASSIGN_INVENTORY_PANEL_HEADER'
                                )}
                            </Space>
                            <div className="sub-menu pr-1">
                                <Row className="pt-1">
                                    <Col md={6} sm={6} xs={6} className="pl-0 checkbox-all">
                                        {' '}
                                        <Checkbox
                                            className="ml-1"
                                            label="Show Next Available"
                                            mauto="maShowNextAvailableCk"
                                            onClick={() => {
                                                setIsShowChecked(!isShowChecked)
                                            }}
                                            innerRef={checkBoxRef}
                                        />
                                        {' '}
                                    </Col>
                                    <Col md={6} sm={6} xs={6} className="text-right m-auto pr-2">
                                        {isShowLaneManagementLink() && (
                                            <Link
                                                className={"font-weight-bold"}
                                                to={`/${PathUrlConstant.LaneManagement}`
                                                    + `?saleYear=${saleYearLaneContext.saleYear}`
                                                    + `&saleNumber=${saleYearLaneContext.saleNumber}`
                                                    + `&laneNumber=${saleYearLaneContext.laneNumber}`
                                                    + `&link=${true}`}
                                            >
                                                <span className="icon prism-icon-chevron-left chevron-left" />
                                                <span className="">
                                                {t(
                                                    'LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.LANE_MANAGEMENT'
                                                )}
                                            </span>
                                            </Link>
                                        )}

                                    </Col>
                                </Row>
                            </div>
                            <AssignVehicleListWrapper
                                auctionCode={auctionCode}
                                runRanges={runRanges}
                                isShowChecked={isShowChecked}
                                draftVehicles={draftVehicles}
                                setDraftVehicles={setDraftVehicles}
                                laneInventory={laneInventory}
                                setLaneInventory={setLaneInventory}
                                laneNumber={laneNumber}
                                setIsGetPreRegistError={setIsGetPreRegistError}
                                bulkUpdateVehicleErrors={bulkUpdateVehicleErrors}
                                scrollToItem={scrollToLeftItem}/>
                            <div className="sub-menu-footer table-bordered">
                                <Row className="pl-1 pt-1">
                                    <Col md={6} sm={6} xs={6}>
                                        <Button
                                            disabled={!isEnableWriteDataInAS400 || (isEmpty(draftVehicles) && !isDraftVehiclesChangedByRemove)}
                                            className="btn btn-outline mb-1 pl-8r"
                                            mauto="maSaveDraftButton"
                                            onClick={() => {handleBulkUpdateAssignInventory(false)}}>
                                            {t(
                                                'LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SAVE_DRAFT_BUTTON'
                                            )}
                                        </Button>
                                    </Col>
                                    <Col md={6} sm={6} xs={6} className="text-right mg-sm">
                                        <Button
                                            disabled={!isEnableWriteDataInAS400 || (isEmpty(draftVehicles) && !isDraftVehiclesChangedByRemove)}
                                            className="btn btn-primary mr-2 text-right mb-1"
                                            mauto="maSendAs400Button"
                                            onClick={() => {handleBulkUpdateAssignInventory(true)}}>
                                            {t(
                                                'LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SEND_AS400_BUTTON'
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} sm={6} xs={6} className="pl-0 px-0 unassign-inventory-container-col">
                        <div className="assign-inventory-container">
                            <div className="unassigned-inventory-header pb-0">
                                <Row>
                                    <Col md={4} sm={4} xs={4} className="header-content pl-2" mauto="maUnassignInventoryHeader">
                                        {t(
                                            'LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.UNASSIGNED_INVENTORY'
                                        )}
                                    </Col>
                                    <Col
                                        md={8} sm={8} xs={8}
                                        className="text-right px-0 pr-2">
                                        <Row className='justify-content-end'>
                                            <div className='quick-search mb-1 mx-2'>
                                                <QuickSearch
                                                    mauto="maQuickSearch"
                                                    isDisabled={isEmpty(inventory) && isEmpty(laneInventory)}/>
                                            </div>
                                            <Button
                                                disabled={isEmpty(vehicles)}
                                                className={`btn btn-primary ml-2 mx-2 mb-1`}
                                                mauto="maFilterButton"
                                                onClick={() => {setIsAdvancedFiltersOpen(true)}}>
                                                <i className="icon prism-icon__demo icon prism-icon-filter mr-1"></i>
                                                {t(
                                                    'LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.FILTER_BUTTON'
                                                )}
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="sub-menu">
                                <Row className='sort-by mx-0'>
                                    <div className='sort-items'>
                                        <div className='prism-icon-svg check-all-vehicles'>
                                            <Checkbox
                                                className="text-secondary custom-checkbox"
                                                onClick={handleCheckAllUnAssignVehicle}
                                                innerRef={selectAllRunRef}
                                                disabled={inventory.length === 0}
                                            />
                                        </div>
                                        {!isEmpty(sortItems) &&
                                            sortItems.map(item => {
                                                return(
                                                    <SortItem
                                                        sortValue={item}
                                                        handleChangeSortStrategy={handleChangeSortStrategy}
                                                        handleOnClickRemoveItem={handleOnClickRemoveItem}
                                                    />)
                                            })
                                        }
                                        <div  className={classNames('select-sort', {'hidden-select': sortItems.length == SortConstant.MAX_ITEM_SELECTED})}>
                                            <AssignInventorySortBy
                                                auctionCodeFromState={auctionCodeFromState}
                                                auctionCodeFromCookie={auctionCodeFromCookie}
                                                inventory={inventory}
                                                setInventory={setInventory}
                                                vehicles={vehicles}
                                                setVehicles={setVehicles}
                                                submitCount={submitCount}
                                                sortItems={sortItems}
                                                setSortItems={setSortItems}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </div>
                            <UnAssignVehicleListWrapper
                                auctionCode={auctionCode}
                                inventory={inventory}
                                setInventory={setInventory}
                                scrollToItem={scrollToRightItem}
                            />
                            <div className="sub-menu-footer table-bordered unassigned-footer">
                                <Row className="pl-1 dis-inl-flex pb-1 ml-1">
                                    <span className='starting-run-label pt-1' >{t('LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.STARTING_AT_RUN')}</span>
                                    <Input
                                        className='prism-input starting-run-number mt-1'
                                        maxLength={ConstraintConstant.STARTING_RUN_MAX_LENGTH}
                                        value={startingRunNumber}
                                        onKeyDown={handleEventOnKeyDownForNumber}
                                        onChange={handleEventOnChangeStartingRun}
                                        mauto='maStartingRunNumberInput'
                                        disabled={!isEnableAutoAssign}
                                        onPaste={handleOnPasteInputStartingRun}
                                    />
                                    <Button
                                        disabled={!isEnableAutoAssign}
                                        className="btn btn-outline mr-2 mt-1"
                                        mauto="maAutoAssignButton"
                                        onClick={autoAssignSelectedRuns}>
                                        {t(
                                            'LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.AUTO_ASSIGN_SELECTED'
                                        )}
                                    </Button>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <CustomDragLayer/>
                </DndProvider>
            </Container>

            {!!isAdvancedFiltersOpen && (
                <AssignInventoryAdvancedFilter
                isAdvancedFiltersOpen={isAdvancedFiltersOpen}
                setIsAdvancedFiltersOpen={setIsAdvancedFiltersOpen}
                isOnchangeAdvancedFilter={isOnchangeAdvancedFilter}
                setIsOnchangeAdvancedFilter={setIsOnchangeAdvancedFilter}
                advancedFilter={advancedFilter}
                setAdvancedFilter={setAdvancedFilter}
                handleClearAdvancedFilter={handleClearAdvancedFilter}
            />)}
        </div>
    )
}