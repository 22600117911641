
import React, {useEffect, useState} from 'react'

import { useTranslation } from 'react-i18next'
import { first } from 'lodash'
import { v4 as uuid } from 'uuid'
import { Col, Container, Row, Space } from '@prism/library'
import { Dropdown } from '../custom/prism/dropdown/Dropdown'
import { SellerSearchTypeConstant } from '../../../utils/constant'
import { AdvanceFilterButton } from '../button/AdvanceFilterButton'
import { ShowInventoryGroup } from './ShowInventoryGroup'

import './show-inventory-picker.scss'
import { useDispatch } from 'react-redux'
import { pushUpdatedVehicle } from '../../../actions/RemainingInventoryAction'

export function ShowInventoryPicker({
    updateInventoryGroup,
    cleanRemainingInventory,
    increaseSubmitCount,
    setIsAdvancedFiltersOpen,
    setIsEnableFilterByGroup,
    setSearchKeyGroupCode,
    isAuctionCodeChange,
    advancedFiltersDisabled,
    showByInventoryDisabled
}) {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    function buildDropdownOptionsData() {
        let optionsData = [
            {
                name: t(
                    'LABEL.REMAINING_INVENTORY.INVENTORY_PICKER.OPTION.ALL_DEALERS'
                ),

                primaryValue: SellerSearchTypeConstant.SELLER_TYPE,
                secondaryValue: SellerSearchTypeConstant.ALL_DEALDER,
                disable: false,
                key: uuid()
            },
            {
                name: t(
                    'LABEL.REMAINING_INVENTORY.INVENTORY_PICKER.OPTION.ALL_COMMERCIAL'
                ),
                primaryValue: SellerSearchTypeConstant.SELLER_TYPE,
                secondaryValue: SellerSearchTypeConstant.ALL_COMMERCIAL,
                disable: false,
                key: uuid()
            },
            {
                name: t(
                    'LABEL.REMAINING_INVENTORY.INVENTORY_PICKER.OPTION.GROUP_CODE'
                ),
                primaryValue: SellerSearchTypeConstant.GROUP_CODE,
                disable: false,
                key: uuid()
            },
            {
                name: t(
                    'LABEL.REMAINING_INVENTORY.INVENTORY_PICKER.OPTION.ALT_GROUP_CODE'
                ),
                primaryValue: SellerSearchTypeConstant.ALT_GROUP_CODE,
                disable: false,
                key: uuid()
            }
        ]

        return optionsData
    }

    const [showInventoryByOption, setShowInventoryByOption] = useState(
        buildDropdownOptionsData()
    )

    const [selectedShowInventoryBy, setSelectedShowInventoryBy] = useState(
        first(showInventoryByOption)
    )

    const [isLoadingShowInventory, setIsLoadingShowInventory] = useState(false)

    const [groupCode, setGroupCode] = useState('')

    const [isDisableButtonGo, setIsDisableButtonGo] = useState(true)

    const [isOpenGroupCode, setIsOpenGroupCode] = useState(false)

    useEffect(() => {
        setSearchKeyGroupCode(groupCode)
    }, [groupCode])

    function changeShowInventoryBy(selectedData) {
        dispatch(pushUpdatedVehicle(null))
        setSelectedShowInventoryBy(selectedData)
        cleanRemainingInventory()
        setGroupCode('')
        setIsDisableButtonGo(true)
        if (
            selectedData.secondaryValue ===
                SellerSearchTypeConstant.ALL_DEALDER ||
            selectedData.secondaryValue ===
                SellerSearchTypeConstant.ALL_COMMERCIAL
        ) {
            setIsOpenGroupCode(false)
            setIsEnableFilterByGroup(true)
        } else {
            setIsOpenGroupCode(true)
            setIsEnableFilterByGroup(false)
        }
        updateInventoryGroup(
            selectedData.primaryValue,
            selectedData.secondaryValue
        )
    }
    function handleShowInventoryGroup(inputData) {
        increaseSubmitCount()
        updateInventoryGroup(selectedShowInventoryBy.primaryValue, inputData)
    }
    useEffect(() => {
        if (isAuctionCodeChange) {
            changeShowInventoryBy(first(showInventoryByOption))
        }
    },[isAuctionCodeChange])
    return (
        <>
            <Container fluid
                       className='showInventory-picker-container py-1' >
                <Row className='justify-content-between flex-row pl-2'>
                    <Space className='mr-1'>
                        <Space
                            size={3}
                            className="showInventory-picker-group ml-2 mt-1">
                        <span
                            mauto="maLabelShowInventoryBy"
                            className="showInventory-overview-header-label">
                            {t(
                                'LABEL.REMAINING_INVENTORY.INVENTORY_PICKER.PICKER_LABEL'
                            )}
                            :
                        </span>
                            <Dropdown
                                mauto="maDropdownShowInventoryBy"
                                options={showInventoryByOption}
                                onChange={changeShowInventoryBy}
                                selectedOption={selectedShowInventoryBy}
                                disabled={isLoadingShowInventory || showByInventoryDisabled}
                            />
                            {isOpenGroupCode && (
                                <ShowInventoryGroup
                                    selectedShowInventoryBy={
                                        selectedShowInventoryBy.primaryValue
                                    }
                                    groupCode={groupCode}
                                    handleOnClick={handleShowInventoryGroup}
                                    setGroupCode={setGroupCode}
                                    updateIsDisableButtonGo={setIsDisableButtonGo}
                                    isDisableButtonGo={isDisableButtonGo}
                                    setIsEnableFilterByGroup={setIsEnableFilterByGroup}
                                />
                            )}
                        </Space>
                    </Space>
                    <div className="mt-1">
                        <AdvanceFilterButton
                            mauto="maButtonAdvanceFilter"
                            margin="ml-2 mx-2"
                            disabled={advancedFiltersDisabled}
                            handleOnClick={() => {
                                setIsAdvancedFiltersOpen(true)
                            }}
                        />
                    </div>
                </Row>
            </Container>
        </>
    )
}
