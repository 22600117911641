import React, { useMemo } from 'react'
import { useLoadHeaderScript, useLoadFooterScript } from './WorkbenchHeaderFooterDataSources'
import { InitWorkbenchHeaderFooter } from './InitWorkbenchHeaderFooter'
import './workbench-header-footer.scss'

export function WorkbenchHeaderFooter() {
    const {
        loading: loadingHeaderScript,
        error: errorLoadingHeaderScript
    } = useLoadHeaderScript()

    const {
        loading: loadingFooterScript,
        error: errorLoadingFooterScript
    } = useLoadFooterScript()

    const loading = useMemo(() => {
        return loadingHeaderScript || loadingFooterScript
    }, [loadingFooterScript, loadingHeaderScript])

    const errorLoadingScript = useMemo(() => {
        return errorLoadingHeaderScript || errorLoadingFooterScript
    }, [errorLoadingHeaderScript, errorLoadingFooterScript])

    if (loading || errorLoadingScript) return <></>

    return <InitWorkbenchHeaderFooter />
}
