import { RESET_OBSERVING_ACTION_TYPE } from '../utils/constant';

export function notifyInventorySearchChanged() {
    return {
        type: RESET_OBSERVING_ACTION_TYPE.ASSIGN_INVENTORY_SEARCH,
    }
}

export function notifyAuctionCodeChanged() {
    return {
        type: RESET_OBSERVING_ACTION_TYPE.AUCTION_CODE_CHANGED,
    }
}

export  function notifySearchHistoryClearAll() {
    return {
        type: RESET_OBSERVING_ACTION_TYPE.SEARCH_HISTORY_CLEAR_ALL,
    }
}

export  function notifyRevertConfirmed() {
    return {
        type: RESET_OBSERVING_ACTION_TYPE.LANE_MANAGEMENT_REVERT_CONFIRMED,
    }
}

export  function notifyLaneManagementClearAllMessages() {
    return {
        type: RESET_OBSERVING_ACTION_TYPE.LANE_MANAGEMENT_CLEAR_ALL_MESSAGES,
    }
}