import { isEmpty } from 'lodash'

export function isReserveVehicle(vehicle) {
    return vehicle.status === 'SP' && (vehicle.vin.trim() === '' || !vehicle.vin)
}

export function isPreRegisterVehicle(vehicle) {
    return vehicle.status === "SP" && vehicle.vin && (vehicle.vin.length >= 6)
}

export function isSentToAs400(vehicle) {
    return vehicle.resultType === "R" && !(vehicle.status === 'SP' && (vehicle.vin.trim() === '' || !vehicle.vin))
}

export function preregisteredVehicleDescription(vehicle){
    let validAttributes = ''
    if(isEmpty(vehicle)) {
        return ''
    }

    if(vehicle.year && vehicle.year !== 0 ) {
        validAttributes += vehicle.year + ' '
    }

    if(vehicle.make !== "") {
        validAttributes += vehicle.make + ' '
    }

    if(vehicle.model !== "") {
        validAttributes += vehicle.model + ' '
    }

    if(vehicle.trim !== "") {
        validAttributes += vehicle.trim + ' '
    }

    if(vehicle.exteriorColor && vehicle.exteriorColor !=="") {
        validAttributes += vehicle.exteriorColor
    }

    return validAttributes.toUpperCase()
}

export function getPreregisteredTitle(vehicle){
    let titlePreregistered = ''
    if(isEmpty(vehicle)) {
        return ''
    }

    if(vehicle.year && vehicle.year !== 0 ) {
        titlePreregistered += vehicle.year + ' '
    }

    if(vehicle.make !== "") {
        titlePreregistered += vehicle.make + ' '
    }

    if(vehicle.model !== "") {
        titlePreregistered += vehicle.model + ' '
    }

    if(vehicle.trim !== "") {
        titlePreregistered += vehicle.trim + ' '
    }

    return titlePreregistered
}

