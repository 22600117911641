import axiosInstance from '../axios'

export function getPreRegistrations(auctionCode, sblu, linktoSeller) {
    return axiosInstance.get('/pre-registrations', {
        params: {
            'auction-code': auctionCode,
            'sblu' : sblu,
            'link-to-seller' : linktoSeller
        }
    })
}

export function updatePreRegistration(auctionCode, sblu, linkToSeller, preRegistrationRequest) {
    return axiosInstance.put('/pre-registrations', preRegistrationRequest, {
        params: {
            'auction-code': auctionCode,
            'sblu' : sblu,
            'link-to-seller' : linkToSeller
        }
    });
}

export function deletePreRegistration(auctionCode, sblu, linkToSeller) {
    return axiosInstance.delete('/pre-registrations', {
        params: {
            'auction-code': auctionCode,
            'sblu' : sblu,
            'link-to-seller' : linkToSeller
        }
    });
}
