import {EMPTY_STRING, VEHICLE_NOTES} from '../utils/constant';

const initialState = {
    isOpen: false,
    auctionCode: EMPTY_STRING,
    workOrder: EMPTY_STRING
}

export default function VehicleNotesModalReducer(state = initialState, action) {
    switch (action.type) {
        case VEHICLE_NOTES.UPDATE:
            return {
                ...state, ...action.state
            }
        default: return state
    }
}