import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import { VariableSizeList as List } from 'react-window';
import DynamicSizeRow from './dynamic-size-row/DynamicSizeRow';
import AutoSizer from 'react-virtualized-auto-sizer';
import './dynamic-size-list.scss'
import { useScroll } from '../../../hooks/common/UseScroll';
import { useDragDropManager } from 'react-dnd';
import { RUN_LANE_LIMIT_BOTTOM, RUN_LANE_LIMIT_TOP } from '../../../utils/constant';
import CustomScrollbarsVirtualList from '../custom/scroll-bars/CustomScrollbarsVirtualList';
import {isNull} from 'lodash'
import {randomInteger} from '../../../utils/utils';

const SCROLL_TO_ITEM_STYLES = {
    CENTER: 'center',
    START: 'start',
    END: 'end'
}
const DEFAULT_ROW_SIZE = 0;
const DEFAULT_ROW_PADDING = 15

const DynamicSizeList = forwardRef(({
    data = [],
    isAllowAutoScroll = false,
    ignoreBottomChecking = false,
    scrollingCallback = (isScrolling) => {},
    offsetTop = 0,
    offsetBottom = 0,
    children
}, ref) => {
    const listRef = useRef();
    const sizeMap = useRef({});
    const getSize = index => sizeMap.current[index] || DEFAULT_ROW_SIZE;
    const { updatePosition } = useScroll(listRef)
    const dragDropManager = useDragDropManager()
    const monitor = dragDropManager.getMonitor()
    const isScrollAllowedRef = useRef(false);
    const currentIndexRef = useRef(0);
    const scrolledToBottom = useRef(false);
    const rowRefs = useRef([])
    const lastVisibleIndex = useRef()


    const setSize = useCallback((index, size) => {
        if (sizeMap.current && sizeMap.current[index] !== size) {
                sizeMap.current = { ...sizeMap.current, [index]: size };
                listRef.current.resetAfterIndex(index);
        }
    }, []);

    const setCurrentIndex = useCallback((index) => {
        currentIndexRef.current = index;
    }, [])

    useEffect(() => {
        if (isAllowAutoScroll) {
            const name = randomInteger();
            const dynamic = {}
            dynamic[name] = monitor.subscribeToOffsetChange(() => {
                if (isNull(listRef.current) || !isAllowAutoScroll) {
                    return
                }

                const offsetY = checkAllowScroll(monitor, listRef)
                if(offsetY > 0){
                    updatePosition({ position: offsetY, isScrollAllowed: true })
                    isScrollAllowedRef.current = true;
                }

                if (offsetY <= 0 && isScrollAllowedRef.current === true) {
                    isScrollAllowedRef.current = false;
                    updatePosition({ position: offsetY, isScrollAllowed: false })
                }
                scrollingCallback(isScrollAllowedRef.current)
                return dynamic[name]
            })

        } else {
            updatePosition({ position: 0, isScrollAllowed: false, forceStopAutoScroll: true })
        }
    }, [monitor, updatePosition, isAllowAutoScroll])

    const checkAllowScroll = useCallback((m, ls) => {
        //Get mouse position
        const offsetYTop = m.getSourceClientOffset()?.y + RUN_LANE_LIMIT_TOP - offsetTop
        const offsetYBottom = m.getSourceClientOffset()?.y + RUN_LANE_LIMIT_BOTTOM + offsetBottom
        const offsetX = m.getSourceClientOffset()?.x

        //Get current element list position
        const top = ls.current._outerRef.getBoundingClientRect()?.top
        const right = ls.current._outerRef.getBoundingClientRect()?.right
        const bottom = ls.current._outerRef.getBoundingClientRect()?.bottom

        //Check drag item is in scroll area
        if (offsetX < right)
        {
            //Check drag item is in scroll top area
            if(offsetYTop < top && listRef.current?.state.scrollOffset !== 0){
                return offsetYTop
            }

            //Check drag item is in scroll bottom area
            if(offsetYBottom > bottom && (scrolledToBottom.current === false || ignoreBottomChecking)) {
                return offsetYBottom
            }

            // Check if item rendered but still not in scroll into the view
            if (offsetYBottom > bottom && scrolledToBottom.current === true) {
                const lastItemBottom = rowRefs.current[lastVisibleIndex.current].getBoundingClientRect().bottom
                if (lastItemBottom + DEFAULT_ROW_PADDING > bottom) {
                    return offsetYBottom
                }
            }
        }

        return 0
    }, [offsetTop, offsetBottom, ignoreBottomChecking, lastVisibleIndex.current, scrolledToBottom.current, rowRefs.current])

    const handleItemsRendered = ({ overscanStopIndex }) => {
        // Check if the last item is within the visible range
        lastVisibleIndex.current = overscanStopIndex
        scrolledToBottom.current = overscanStopIndex === data.length - 1;
    };

    useImperativeHandle(ref, () => ({
        // This method call via ref to control scroll to item from outside
        scrollToItem(index) {
            listRef.current.scrollToItem(index, SCROLL_TO_ITEM_STYLES.CENTER);
        }
    }));

    return (
        <AutoSizer>
            {({width, height}) => (
                <List
                    ref={listRef}
                    height={height}
                    width={width}
                    itemCount={data.length}
                    itemSize={getSize}
                    itemData={data}
                    className='list'
                    outerElementType={CustomScrollbarsVirtualList}
                    onItemsRendered={handleItemsRendered}
                >
                    {({ data, index, style }) => (
                        <div style={style} ref={(el) => (rowRefs.current[index] = el)}>
                            <DynamicSizeRow
                                data={data}
                                index={index}
                                setSize={setSize}
                                setCurrentIndex={setCurrentIndex}
                            >
                                {children}
                            </DynamicSizeRow>
                        </div>
                    )}
                </List>
            )}
        </AutoSizer>
    );
})

export default DynamicSizeList;