import React, {useCallback, useMemo, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Dropdown } from '../common/custom/prism/dropdown/Dropdown'
import { Button, Col, Container, Row, Space } from '@prism/library'
import './request-inventory.scss'
import { TablePagination } from '../common/table-pagination/TablePagination'
import { ConstraintConstant, REQUEST_HISTORY_SORT_FIELD, SearchHistoryRequest } from '../../utils/constant'
import { first } from 'lodash'
import { RequestHistoryItem } from './RequestHistoryItem'
import { RequestHistoryPDFDownload as handlePDFDownload } from './RequestHistoryPDFDownload'
import { RequestHistoryXLSCSVDownload as handleXLSCSVDownload } from './RequestHistoryXLSCSVDownload'
import './result-table.scss'

export function ResultTable({ runRequests, setSaleNumber, setLaneNumber, sortData, currentPage, handlePagination, paginationData, criteriaForExport }) {
    const { t } = useTranslation()
    const [showExportFileOptions, setShowExportFileOptions] = useState([])
    const [selectedFileOptions, setSelectedFileOptions] = useState({})
    const runHistoryResultItemsRef = useRef(null)

    const [sortObject, setSortObject] = useState({sortField: REQUEST_HISTORY_SORT_FIELD.CREATED_AT, isAsc: false})

    const onChangeDropdownOptions = useCallback((option) => {
        setSelectedFileOptions(option)

    }, [selectedFileOptions])

    const buildFileDropDownOption = () => {
        return [
            {
                key: SearchHistoryRequest.FILE_TYPE.XLS,
                value: SearchHistoryRequest.FILE_TYPE.XLS,
                name: SearchHistoryRequest.FILE_TYPE.XLS,
                placeholder: SearchHistoryRequest.FILE_TYPE.XLS,
            },
            {
                key: SearchHistoryRequest.FILE_TYPE.CSV,
                value: SearchHistoryRequest.FILE_TYPE.CSV,
                name: SearchHistoryRequest.FILE_TYPE.CSV,
                placeholder: SearchHistoryRequest.FILE_TYPE.CSV,
            },
            {
                key: SearchHistoryRequest.FILE_TYPE.PDF,
                value: SearchHistoryRequest.FILE_TYPE.PDF,
                name: SearchHistoryRequest.FILE_TYPE.PDF,
                placeholder: SearchHistoryRequest.FILE_TYPE.PDF,
            }
        ]
    }

    useMemo(() => {
        const showByOptions = buildFileDropDownOption()
        setShowExportFileOptions(showByOptions)
        setSelectedFileOptions(first(showByOptions))
    }, [])

    function updateSortObject(sortField) {
        if (sortObject.sortField === sortField) {
            sortObject.isAsc = !sortObject.isAsc
        } else {
            sortObject.sortField = sortField
            sortObject.isAsc = true
        }
        setSortObject(sortObject)
        sortData(sortObject)
    }

    window.resetSortCriteria = function() {
        setSortObject({sortField: REQUEST_HISTORY_SORT_FIELD.CREATED_AT, isAsc: false})
    }

    const scrollToTop = () => {
        if (runHistoryResultItemsRef.current) {
            runHistoryResultItemsRef.current.scrollTop = 0;
        }
    };

    function onUpdateActivePageNumber(currentPage) {
        handlePagination(currentPage)
        scrollToTop()
    }

    function handleExportFile() {
        const fileTypes = SearchHistoryRequest.FILE_TYPE
        if (selectedFileOptions.value === fileTypes.XLS || selectedFileOptions.value === fileTypes.CSV) {
            return handleXLSCSVDownload(criteriaForExport, selectedFileOptions.value.toLowerCase())
        } else if (selectedFileOptions.value === fileTypes.PDF) {
            return handlePDFDownload(criteriaForExport)
        }
    }

    return (
        <Container fluid>
            <div className='search-result-table'>
                <Row className="search-result-table-file-export m-0 p-0">
                    <Col md={12} mauto="maThFileExport" className="py-1 pr-2 d-flex justify-content-end top-padding-sm">
                        <Space>
                            <span className="pt-1 modal-sm modal-header-title font-size-md">
                                {t(
                                    'LABEL.REQUEST_HISTORY.RESULT_TABLE.FILE'
                                )}
                            </span>
                            <Dropdown
                                mauto="maDropdownOptions"
                                options={showExportFileOptions}
                                selectedOption={selectedFileOptions}
                                onChange={onChangeDropdownOptions}
                                disabled={null}
                            />
                            <Button
                                mauto="maExportBtn"
                                type="button"
                                color="primary"
                                onClick={() => {
                                    handleExportFile()
                                }}
                                className=""
                            >
                                {t(
                                    'LABEL.REQUEST_HISTORY.RESULT_TABLE.EXPORT'
                                )}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </div>
            <div className="run-history-result-items mt-1 table-sticky-wrapper" ref={runHistoryResultItemsRef}>
                <table
                    className={classNames('table')}
                    mauto="maTblResultTable">
                    <thead
                        className={classNames(
                                    'table-head sticky-top table-single-sort-row-label'
                        )}>
                        <tr className="table color-header">
                            <th mauto="maThYear" onClick={() => updateSortObject(REQUEST_HISTORY_SORT_FIELD.SALE_YEAR)} className='cursor-pointer'>
                                <span>
                                    {t('LABEL.REQUEST_HISTORY.RESULT_TABLE.YEAR')}
                                    {sortObject.sortField === REQUEST_HISTORY_SORT_FIELD.SALE_YEAR && (
                                        sortObject.isAsc ? <span className="icon prism-icon-arrow-up ml-lg-1" /> : <span className="icon prism-icon-arrow-down ml-lg-1" />
                                    )}
                                </span>
                            </th>
                            <th mauto="maThSale" onClick={() => updateSortObject(REQUEST_HISTORY_SORT_FIELD.SALE_NUMBER)} className='cursor-pointer'>
                                <span>
                                    {t('LABEL.REQUEST_HISTORY.RESULT_TABLE.SALE')}
                                    {sortObject.sortField === REQUEST_HISTORY_SORT_FIELD.SALE_NUMBER && (
                                        sortObject.isAsc ? <span className="icon prism-icon-arrow-up ml-lg-1" /> : <span className="icon prism-icon-arrow-down ml-lg-1" />
                                    )}
                                </span>
                            </th>
                            <th mauto="maThLane" onClick={() => updateSortObject(REQUEST_HISTORY_SORT_FIELD.LANE_NUMBER)} className='cursor-pointer'>
                                <span>
                                    {t('LABEL.REQUEST_HISTORY.RESULT_TABLE.LANE')}
                                    {sortObject.sortField === REQUEST_HISTORY_SORT_FIELD.LANE_NUMBER && (
                                        sortObject.isAsc ? <span className="icon prism-icon-arrow-up ml-lg-1" /> : <span className="icon prism-icon-arrow-down ml-lg-1" />
                                    )}
                                </span>
                            </th>
                            <th mauto="maThSellerNumber" onClick={() => updateSortObject(REQUEST_HISTORY_SORT_FIELD.SELLER_NUMBER)} className='cursor-pointer'>
                                <span>
                                    {t('LABEL.REQUEST_HISTORY.RESULT_TABLE.SELLER_NUMBER')}
                                    {sortObject.sortField === REQUEST_HISTORY_SORT_FIELD.SELLER_NUMBER && (
                                        sortObject.isAsc ? <span className="icon prism-icon-arrow-up ml-lg-1" /> : <span className="icon prism-icon-arrow-down ml-lg-1" />
                                    )}
                                </span>
                            </th>
                            <th mauto="maThName">
                                <span>
                                    {t('LABEL.REQUEST_HISTORY.RESULT_TABLE.SELLER_NAME')}
                                </span>
                            </th>
                            <th mauto="maThRunCount" onClick={() => updateSortObject(REQUEST_HISTORY_SORT_FIELD.RUN_COUNT)} className='cursor-pointer'>
                                <span>
                                    {t(
                                        'LABEL.REQUEST_HISTORY.RESULT_TABLE.RUN_COUNT'
                                    )}
                                    {sortObject.sortField === REQUEST_HISTORY_SORT_FIELD.RUN_COUNT && (
                                        sortObject.isAsc ? <span className="icon prism-icon-arrow-up ml-lg-1" /> : <span className="icon prism-icon-arrow-down ml-lg-1" />
                                    )}
                                </span>
                            </th>
                            <th mauto="maThCreatedAt" onClick={() => updateSortObject(REQUEST_HISTORY_SORT_FIELD.CREATED_AT)} className='cursor-pointer'>
                                <span>
                                    {t(
                                        'LABEL.REQUEST_HISTORY.RESULT_TABLE.CREATED_AT'
                                    )}
                                    {sortObject.sortField === REQUEST_HISTORY_SORT_FIELD.CREATED_AT && (
                                        sortObject.isAsc ? <span className="icon prism-icon-arrow-up ml-lg-1" /> : <span className="icon prism-icon-arrow-down ml-lg-1" />
                                    )}
                                </span>
                            </th>
                            <th mauto="maThUpdatedAt" onClick={() => updateSortObject(REQUEST_HISTORY_SORT_FIELD.UPDATED_AT)} className='cursor-pointer'>
                                <span>
                                    {t(
                                        'LABEL.REQUEST_HISTORY.RESULT_TABLE.UPDATED_AT'
                                    )}
                                    {sortObject.sortField === REQUEST_HISTORY_SORT_FIELD.UPDATED_AT && (
                                        sortObject.isAsc ? <span className="icon prism-icon-arrow-up ml-lg-1" /> : <span className="icon prism-icon-arrow-down ml-lg-1" />
                                    )}
                                </span>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!!runRequests &&
                            runRequests.map((runRequest,index) => {
                                return (<RequestHistoryItem key={index} runRequest={runRequest} itemIndex={index} setSaleNumber={setSaleNumber} setLaneNumber={setLaneNumber}/>)
                            })}
                    </tbody>
                </table>
            </div>
            {paginationData && paginationData.totalRecord > 0 && (
                    <TablePagination
                        isEnabledPageDetails={true}
                        pageNumber={currentPage}
                        numberOfItemOnPage={ConstraintConstant.PAGE_SIZE}
                        numberOfTotalItems={paginationData.totalRecord}
                        updateActivePageNumber={onUpdateActivePageNumber}
                    />
            )}
            
        </Container>
    )
}
