import {isBlank} from '../../../utils/utils';
import {isNull} from 'lodash';

export default function compareVehicles(left, right) {
    if (left === null && right === null) {
        return true
    }

    if (left === null || right === null) {
        return false
    }

    return left.runNumber === right.runNumber
        && left.laneNumber === right.laneNumber
        && left.workOrderNumber === right.workOrderNumber
        && left.sblu === right.sblu
        && left.vin === right.vin
        && left.resultType === right.resultType
}

export function resetVehicleDetailInput(vehicles) {
    const index = vehicles.findIndex(vehicle => vehicle.vehicleDetail && !isNull(vehicle.vehicleDetail.input))
    if (index !== -1) {
        vehicles[index].vehicleDetail.input = null
    }
}