import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ceil, concat, floor, isNil, min } from 'lodash'
import { PaginationWithDiv } from './PaginationWithDiv'

import './table-pagination.scss'

export function TablePagination({
    pageNumber,
    numberOfItemOnPage,
    numberOfTotalItems,
    updateActivePageNumber,
    isEnabledPageDetails
}) {
    const MAX_NUMBER_OF_DISPLAY_PAGE = 5
    const FIRST_PAGE_NUMBER = 1

    const { t } = useTranslation()

    const activePageNumber = useMemo(() => {
        return isNil(pageNumber) ? FIRST_PAGE_NUMBER : pageNumber
    }, [pageNumber])
    const numberOfTotalPage = useMemo(() => {
        return ceil(numberOfTotalItems / numberOfItemOnPage)
    }, [numberOfTotalItems, numberOfItemOnPage])

    const isFirstLastPage = useMemo(() => {
        return FIRST_PAGE_NUMBER === numberOfTotalPage
    }, [numberOfTotalPage, FIRST_PAGE_NUMBER])

    const isInScopeOfDisplay = useMemo(() => {
        return numberOfTotalPage <= MAX_NUMBER_OF_DISPLAY_PAGE + 1
    }, [numberOfTotalPage, MAX_NUMBER_OF_DISPLAY_PAGE])

    const isFirst = useMemo(() => {
        return activePageNumber === FIRST_PAGE_NUMBER
    }, [activePageNumber])

    const isLast = useMemo(() => {
        return activePageNumber === numberOfTotalPage
    }, [activePageNumber, numberOfTotalPage])

    const pageNumbers = useMemo(() => {
        const padding = floor(MAX_NUMBER_OF_DISPLAY_PAGE / 2)
        let pageNumbers = []

        let tooLeft = activePageNumber <= padding
        let tooRight = numberOfTotalPage - activePageNumber <= padding

        let startDisplayPageNumber
        let endDisplayPageNumber

        if (isInScopeOfDisplay) {
            startDisplayPageNumber = FIRST_PAGE_NUMBER
            endDisplayPageNumber = min([
                numberOfTotalPage,
                MAX_NUMBER_OF_DISPLAY_PAGE + 1
            ])
        } else {
            if (!tooLeft && !tooRight) {
                startDisplayPageNumber = activePageNumber - padding
                endDisplayPageNumber = activePageNumber + padding
            } else {
                if (tooLeft && !tooRight) {
                    startDisplayPageNumber = FIRST_PAGE_NUMBER
                    endDisplayPageNumber = MAX_NUMBER_OF_DISPLAY_PAGE
                } else if (!tooLeft && tooRight) {
                    startDisplayPageNumber =
                        numberOfTotalPage - MAX_NUMBER_OF_DISPLAY_PAGE + 1
                    endDisplayPageNumber = numberOfTotalPage
                }
            }
        }

        for (let i = startDisplayPageNumber; i <= endDisplayPageNumber; ++i) {
            pageNumbers = concat(pageNumbers, i)
        }

        return pageNumbers
    }, [activePageNumber, numberOfTotalPage])

    const pageDetails = useMemo(() => {
        const fromItemNumber = (activePageNumber - 1) * numberOfItemOnPage + 1
        const toItemNumber = fromItemNumber + numberOfItemOnPage - 1

        return t('LABEL.REMAINING_INVENTORY.PAGINATION_DETAIL')
            .replace('{fromItemNumber}', fromItemNumber)
            .replace(
                '{toItemNumber}',
                toItemNumber <= numberOfTotalItems
                    ? toItemNumber
                    : numberOfTotalItems
            )
            .replace('{totalItems}', numberOfTotalItems)
    }, [pageNumber, numberOfItemOnPage, numberOfTotalItems])

    return (
        <>
            <PaginationWithDiv
                isFirstLastPage={isFirstLastPage}
                isFirst={isFirst}
                isLast={isLast}
                pageNumbers={pageNumbers}
                numberOfTotalPage={numberOfTotalPage}
                activePageNumber={activePageNumber}
                isEnabledPageDetails={isEnabledPageDetails}
                pageDetails={pageDetails}
                updateActivePageNumber={updateActivePageNumber}
            />
        </>
    )
}
