import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import { VehicleVcf } from '../common/vehicle-info/VehicleVcf'
import { AssignInventoryVehicleItem } from './AssignInventoryVehicleItem'
import { useDispatch, useSelector } from 'react-redux'
import { getDnDVehicleData } from '../../actions/AssignInventoryAction'
import DynamicSizeList from '../common/dynamic-size-list/DynamicSizeList';
import { DnDDragTypeConstant } from '../../utils/constant'
import classNames from 'classnames';
import {useVehicleDraggingMonitor} from '../../hooks/assign-inventory/UseVehicleDraggingMonitor';
import compareVehicles, { resetVehicleDetailInput } from './helper/VehicleHelper';

export function UnAssignVehicleList({
    auctionCode,
    vehicles,
    setVehicles,
    scrollToItem
}) {
    const [sblu, setSblu] = useState(null)
    const [isVcfOpen, setIsVcfOpen] = useState(false)
    const [messageUpdateVehicleSuccessful, setMessageUpdateVehicleSuccessful] = useState([])
    const { isDragging, draggingItem } = useVehicleDraggingMonitor()
    const dispatch = useDispatch()
    const { search } = useSelector(states => states.resetStateObservingContext.assignInventory)
    const unAssignedVehiclesRef = useRef(null)
    const quickSearchContext = useSelector(states => states.inventoryQuickSearchContext.quickSearchContext)

    useEffect(() => {
        setMessageUpdateVehicleSuccessful([])
    }, [search])

    function updateVehicleData(newVehicleData) {
        let existedVehicle = vehicles.find(p => compareVehicles(p, newVehicleData))
        if (existedVehicle) {
            resetVehicleDetailInput(vehicles)
            let index = vehicles.indexOf(existedVehicle)
            existedVehicle = {...existedVehicle, ...newVehicleData}
            vehicles[index] = existedVehicle
            setVehicles((prev) => [...vehicles])
        }

        window.updateVehiclesInAssignInventory(newVehicleData)
    }

    function updateVehicles(vehicles) {
        setVehicles(() => {
            const newVehicles = [...vehicles]
            return newVehicles
        })
    }

    //#region Drag and Drop
    /**
     * Remove the vehicle in the Unassigned column after user drag and drop a vehicle
     * from the Unassigned to the Assigned column (move from right to left)
     */
    window.removeUnassignedVehicleAfterDnD = function(dragData) {
        let index = vehicles.findIndex(p => p.vin === dragData.vin && p.workOrderNumber === dragData.workOrderNumber && p.sblu === dragData.sblu)
        if (index !== -1) {
            vehicles.splice(index, 1)
            updateVehicles(vehicles)
            window.window.removeUnAssignVehicles(dragData)
        }

        removeMessageUpdateSuccessful(dragData);
    }

    /**
     * Remove unassigned vehicle (right column) after perform auto assign function
     */
    window.removeUnassignedVehiclesAfterAutoAssign = function(vehicleList) {
        const vehiclesCloned = [...vehicles]
        vehicleList.forEach(item => {
            vehiclesCloned.removeAnElementIf(p => p.vin === item.vin && p.workOrderNumber === item.workOrderNumber && p.sblu === item.sblu)
        })
        updateVehicles(vehiclesCloned)
    }

    function removeMessageUpdateSuccessful(dragData) {
        setMessageUpdateVehicleSuccessful((prev) => {
            const newMessageUpdateVehicleSuccessful = [...(prev || [])]
            const index = newMessageUpdateVehicleSuccessful.findIndex(message => message.vin === dragData.vin
                && message.workOrderNumber === dragData.workOrderNumber)
            if (index > -1) {
                newMessageUpdateVehicleSuccessful.splice(index, 1)
            }

            return newMessageUpdateVehicleSuccessful
        });
    }

    /**
     * Put the vehicle to Unassigned column when user remove it from Assigned column
     */
    window.putUnassignedVehicleAfterDnD = function(vehicle) {
        vehicles.unshift(vehicle)
        updateVehicles(vehicles)
        window.addUnAssignVehicles(vehicle)

        dispatch(getDnDVehicleData({id: `vehicle-item-${vehicle.vin}-${vehicle.workOrderNumber}-${vehicle.runNumber}`}))
    }
    //#endregion

    const isDisplayNone = (vehicle) => {
        return  isDragging
            && draggingItem
            && draggingItem.vin === vehicle.vin
            && draggingItem.workOrderNumber === vehicle.workOrderNumber
    }

    // Remove item from the list help to decrease re-render items
    // Support to improve performance
    const visibleVehicles = useMemo(() => {
        return [...vehicles].filter(vehicle => !isDisplayNone(vehicle))
    }, [vehicles, isDisplayNone])

    useEffect(() => {
        window.updateQuickSearchData(visibleVehicles, false)
    }, [visibleVehicles]);

    useEffect(() => {
        if (scrollToItem > -1) {
            unAssignedVehiclesRef.current?.scrollToItem(scrollToItem)
        }
    }, [scrollToItem]);

    return (
        <>
            {!!vehicles && !isEmpty(vehicles) &&
                <DynamicSizeList data={visibleVehicles} ref={unAssignedVehiclesRef}>
                    {(vehicle, index) => (
                        <div className={classNames(
                            {'dsp-n': isDisplayNone(vehicle)}
                        )}>
                            <AssignInventoryVehicleItem
                                key={`unassign-vehicle-${vehicles.indexOf(vehicle)}`}
                                vehicle={vehicle}
                                messageUpdateVehicleSuccessful={messageUpdateVehicleSuccessful}
                                handleVcfOnClick={(sblu) => {
                                    setSblu(sblu)
                                    setIsVcfOpen(true)
                                }}
                                isUnassign={true}
                                dragData={{dragType: DnDDragTypeConstant.UNASSIGN_VEHICLE, itemIndex: index}}
                                handleRemoveVehicleEvent={() => {}}
                                setMessageUpdateVehicleSuccessful={setMessageUpdateVehicleSuccessful}
                                allowQuickSearch={true}
                                quickSearchText={quickSearchContext.quickSearchValue}
                                index={index}
                                quickSearchIndex={scrollToItem}
                                left={false}
                                updateVehicleData={updateVehicleData}
                                isVehicleDetailParticular={true}
                                setGetVehicleError={() => {}}
                                isShownSellerSearchButton={false}
                            />
                        </div>

                        )
                    }
                </DynamicSizeList>
            }
            {!!isVcfOpen && (
                <VehicleVcf
                    isVcfOpen={isVcfOpen}
                    setIsVcfOpen={setIsVcfOpen}
                    auctionCode={auctionCode}
                    sbluNumber={sblu}
                />
            )}
        </>
    )
}
