import axiosInstance from '../axios'

export function getSaleYears(auctionCode) {
    return axiosInstance.get('/sales/sale-years', {
        params: { 'auction-code': auctionCode }
    })
}

export function getCurrentSale(auctionCode) {
    return axiosInstance.get('/sales/current-sale', {
        params: {
            'auction-code': auctionCode
        }
    })
}

export function getSaleNumbersBySaleYearAndAuctionCode(saleYear, auctionCode) {
    return axiosInstance.get('/sales/sale-numbers', {
        params: {
            'sale-year': saleYear,
            'auction-code': auctionCode
        }
    })
}

export function getLaneNumbersBySaleYearAndSaleNumberAndAuctionCode(saleYear, saleNumber, auctionCode) {
    return axiosInstance.get('/sales/lane-numbers', {
        params: {
            saleYear: saleYear,
            saleNumber: saleNumber,
            auctionCode: auctionCode
        }
    })
}

export function getSaleSummary(auctionCode, saleYear, saleNumber, sellerNumber) {
    return axiosInstance.get('/sales/sale-summary', {
        params: {
            'sale-year': saleYear,
            'sale-number': saleNumber,
            'seller-number': sellerNumber,
            'auction-code': auctionCode
        }
    })
}

export function getSaleOverviews(
    auctionCode,
    saleYear,
    saleNumber,
    pageNumber,
    pageSize
) {
    return axiosInstance.post('/sales/sale-overviews', {
        auctionCode: auctionCode,
        saleYear: saleYear,
        saleNumber: saleNumber,
        pageNumber: pageNumber,
        pageSize: pageSize
    })
}

export function getNextAvailableRuns(auctionCode, saleYear, saleNumber) {
    return axiosInstance.get('/sales/next-available', {
        params: {
            'auction-code': auctionCode,
            'sale-year': saleYear,
            'sale-number': saleNumber
        }
    })
}
