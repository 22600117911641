import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import { Col, Row, Input, Button, Container } from '@prism/library'
import SlideOut from '@prism/slideout'

import { ConstraintConstant, EMPTY_STRING, MessageTypeConstant } from '../../../utils/constant'
import { getDealerShipSearch } from '../../../utils/data-source/remaining-inventory-datasource'
import { NotificationMessage } from '../notification-message/NotificationMessage'
import { NoDataFound } from '../data-list-status/NoDataFound'
import { SearchSellerItem } from './SearchSellerItem'

import './search-seller-panel.scss'

export function SearchSellerPanel({
    searchSellerOpen,
    auctionCode,
    setSearchSellerOpen,
    setSellerNumber,
    customers,
    isVehicleDetailParticular,
    setCustomers,
    searchData,
    setSearchData,
    setHasDataChanged
}) {
    const { t } = useTranslation()
    const [sellerName, setSellerName] = useState('')
    const [groupCode, setGroupCode] = useState('')
    const [altGroupCode, setAltGroupCode] = useState('')
    const [customerList, setCustomerList] = useState(null)
    const [isSellerSearchEmpty, setIsSellerSearchEmpty] = useState(false)
    const [showNoData, setShowNoData] = useState(false)

    const searchDealerShips = () => {
        setCustomerList([])
        if (sellerName || groupCode || altGroupCode) {
            if (
                isBlank(sellerName) &&
                isBlank(groupCode) &&
                isBlank(altGroupCode)
            ) {
                setCustomers(null)
                setSearchData(null)
                setIsSellerSearchEmpty(true)
            } else {
                setSearchData({
                    sellerName,
                    groupCode,
                    altGroupCode
                })
                getDealerShipSearch(
                    auctionCode,
                    sellerName,
                    groupCode,
                    altGroupCode
                ).then((response) => {
                    setIsSellerSearchEmpty(false)
                    if (!isEmpty(response.data.customers)) {
                        setCustomerList(response.data.customers)
                        setCustomers(response.data.customers)
                    } else {
                        setShowNoData(true)
                        setCustomers([])
                    }
                })
            }
        } else {
            setCustomers(null)
            setSearchData(null)
            setIsSellerSearchEmpty(true)
        }
    }
    const isBlank = (str) => {
        return (!str || /^\s*$/.test(str));
    }
    const toggle = () => {
        setSearchSellerOpen(!searchSellerOpen)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchDealerShips()
        }
    }
    useEffect(() => {
        if (searchData) {
            setSellerName(searchData.sellerName)
            setGroupCode(searchData.groupCode)
            setAltGroupCode(searchData.altGroupCode)
        }
        if (customers) {
            setCustomerList(customers)
        } else if (searchData && !customers) {
            setShowNoData(true)
        }
        window.addEventListener('beforeunload', () => {
            setCustomers(null)
            setSearchData(null)
        })
    }, [])

    return (
        <>
            <div className="search-seller-panel">
                <SlideOut
                    actionable={true}
                    placement="right"
                    open={searchSellerOpen}
                    triggerClassName={'search-seller'}
                    onCloseButtonClick={toggle}
                    className="search-seller-slide-out">
                    <SlideOut.Header>
                        <div className="search-seller-header">
                            <Button
                                className={`btn btn-outline`}
                                onClick={toggle}>
                                <i className="icon prism-icon-chevron-left ml-1 mx-1"></i>
                            </Button>
                            <h1>
                                {t(
                                    'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.SEARCH_SELLER'
                                )}
                            </h1>
                        </div>
                    </SlideOut.Header>
                    <div className='search-seller-input-group'>
                        <Row className="mt-ctn">
                            <Col
                                md={2}
                                sm={3}
                                xs={3}
                                className="text-muted mt-2 search-seller-name"
                                mauto="maLblSellerName">
                                {t(
                                    'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.INPUT.NAME'
                                )}
                            </Col>
                            <Col
                                md={2}
                                sm={3}
                                xs={3}
                                className="text-muted mt-2 search-seller-gp-code"
                                mauto="maLblGroupCode">
                                {t(
                                    'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.INPUT.GROUP_CODE'
                                )}
                            </Col>
                            <Col
                                md={4}
                                sm={6}
                                xs={6}
                                className="text-muted mt-2 search-seller-alt-code"
                                mauto="maLblAltGroupCode">
                                {t(
                                    'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.INPUT.ALT_GROUP_CODE'
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={2}
                                sm={3}
                                xs={3}
                                className="search-seller-name"
                                mauto="maTxtSellerName">
                                <Row>
                                    <Col md={10} sm={10} xs={10}>
                                        <Input
                                            placeholder={t(
                                                'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.INPUT.PLACE_HOLDER'
                                            )}
                                            onChange={(e) =>
                                                setSellerName(e.target.value)
                                            }
                                            onKeyPress={handleKeyPress}
                                            value={sellerName}
                                        />
                                    </Col>
                                    <Col
                                        md={2} sm={2} xs={2}
                                        className="text-muted mt-1 p-0">
                                        {t(
                                            'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.OR'
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                md={2}
                                sm={3}
                                xs={3}
                                className="search-seller-gp-code"
                                mauto="maTxtGroupCode">
                                <Row>
                                    <Col md={10} sm={10} xs={10}>
                                        <Input
                                            maxLength={
                                                isVehicleDetailParticular
                                                ? ConstraintConstant.GROUP_CODE_M4X_LENGTH
                                                : ConstraintConstant.GROUP_CODE_MAX_LENGTH
                                            }
                                            placeholder={t(
                                                'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.INPUT.PLACE_HOLDER'
                                            )}
                                            onChange={(event) =>
                                                setGroupCode(event.target.value)
                                            }
                                            onKeyPress={handleKeyPress}
                                            value={groupCode}
                                        />
                                    </Col>
                                    <Col
                                        md={2} sm={2} xs={2}
                                        className="text-muted mt-1 p-0">
                                        {t(
                                            'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.OR'
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                md={2}
                                sm={3}
                                xs={3}
                                className="search-seller-alt-code"
                                mauto="maTxtAltGroupCode">
                                <Input
                                    mauto="maInputAltGroupCode"
                                    maxLength={
                                        isVehicleDetailParticular
                                        ? ConstraintConstant.ALT_GROUP_CODE_M4X_LENGTH
                                        : ConstraintConstant.GROUP_CODE_MAX_LENGTH
                                    }
                                    placeholder={t(
                                        'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.INPUT.PLACE_HOLDER'
                                    )}
                                    onChange={(event) =>
                                        setAltGroupCode(event.target.value)
                                    }
                                    onKeyPress={handleKeyPress}
                                    value={altGroupCode}
                                />
                            </Col>
                            <Col md={3} sm={3} xs={3} className='search-seller-btn'>
                                <Button
                                    color="primary"
                                    className="btn-update dearler-search"
                                    mauto="maBtnSearchDealer"
                                    onClick={() => searchDealerShips()}>
                                    {t(
                                        'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.SEARCH'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    {isSellerSearchEmpty && (
                        <NotificationMessage type={'error'} isOpen={true}>
                            {t('MESSAGE.COMMON.SEARCH_SELLER_EMPTY')}
                        </NotificationMessage>
                    )}
                    {isEmpty(customerList) &&
                        showNoData &&
                        !isSellerSearchEmpty && (
                            <Container
                                fluid
                                className="prism-overridden search-seller-item px-0">
                                <NoDataFound />
                            </Container>
                        )}

                    <div className="search-seller-table">
                        {!isEmpty(customerList) && (
                            <table
                                className={classNames('table table-borderless')}
                                mauto="maTblVCFTable">
                                <thead
                                    className={classNames(
                                        'table-head sticky-top table-single-sort-row-label'
                                    )}>
                                    <tr>
                                        <th mauto="mautoThStatus">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.STATUS'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="mautoThCanReg">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.CAN_REG'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="mautoThSellerNo">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.SELLER_NO'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="mautoThName">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.NAME'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="mautoThStreet">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.STREET'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="mautoThCity">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.CITY'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="mautoThState">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.STATE'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="maThManual">
                                            <span className="font-weight-bold"></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isEmpty(customerList) &&
                                        customerList.map((dealer, index) => {
                                            return (
                                                <SearchSellerItem
                                                    key={index}
                                                    dealer={dealer}
                                                    setSellerNumber={
                                                        setSellerNumber
                                                    }
                                                    setSearchSellerOpen={
                                                        setSearchSellerOpen
                                                    }
                                                    setHasDataChanged={setHasDataChanged}
                                                />
                                            )
                                        })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </SlideOut>
            </div>
        </>
    )
}
