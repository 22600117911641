import React, {useState, useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {SlideOut, Checkbox, RadioButton, Space, Button, Row, Col, Input, Container} from '@prism/library'

import "./advanced-filters.scss"
import {Dropdown} from "../custom/prism/dropdown/Dropdown";
import {v4 as uuid} from "uuid";
import {first} from "lodash";
import { handleEventOnKeyDownForNumberAlphabet } from '../../../utils/utils'
import {ConstraintConstant} from "../../../utils/constant";
export function AdvancedFilters({
                                    isAdvancedFiltersOpen,
                                    setIsAdvancedFiltersOpen,
                                    setIsAdvancedFilters,
                                    setFilterObject,
                                    filter,
                                    setFilter,
                                    increaseSubmitCount,
                                    isOnchangeAdvanceFilter,
                                    setIsOnchangeAdvanceFilter,
                                    isEnableFilterByGroup,
                                    clearAdvanceFilter,
                                }) {
    const {t} = useTranslation()

    const [ageInventoryOption, setAgeInventoryOption] = useState(
        buildDropdownOptionsAgedInventory()
    )

    const [selectedAgeInventory, setSelectedAgeInventory] = useState(
        first(ageInventoryOption)
    )

    const [ageInventoryNumberOption, setAgeInventoryNumberOption] = useState(
        buildDropdownOptionsAgedInventoryNumber()
    )

    const [selectedAgeInventoryNumber, setSelectedAgeInventoryNumber] = useState(
        first(ageInventoryNumberOption)
    )

    const [runHistoryOption, setRunHistoryOption] = useState(
        buildDropdownOptionsRunHistory()
    )

    const [selectedRunHistory, setSelectedRunHistory] = useState(
        first(runHistoryOption)
    )

    const [runHistoryOptionNumber, setRunHistoryOptionNumber] = useState(
        buildDropdownOptionsRunHistoryNumber()
    )

    const [selectedRunHistoryNumber, setSelectedRunHistoryNumber] = useState(
        first(runHistoryOptionNumber)
    )

    const [isValidInput, setIsValidInput] = useState(true)

    const [isClearAdvanced, setIsClearAdvanced] = useState(true)

    function handleClearAdvanceFilter(){
        setSelectedAgeInventory(first(ageInventoryOption))
        setSelectedAgeInventoryNumber(first(ageInventoryNumberOption))
        setSelectedRunHistory(first(runHistoryOption))
        setSelectedRunHistoryNumber(first(runHistoryOptionNumber))
        clearAdvanceFilter()
        setIsClearAdvanced(false)
    }
    function handleAdvanceFilter() {
        setIsAdvancedFilters(true)
        setFilterObject(filter)
        increaseSubmitCount()
        setIsAdvancedFiltersOpen(false)
    }

    function buildDropdownOptionsAgedInventory() {
        let optionsData = [
            {
                name: t( 'LABEL.COMMON.ADVANCED_FILTERS.AGED_INVENTORY_OPTION.OLDER_THAN'),
                disable: false,
                value: "older_than",
                key: uuid()
            },
            {
                name: t( 'LABEL.COMMON.ADVANCED_FILTERS.AGED_INVENTORY_OPTION.NEWER_THAN'),
                disable: false,
                value: "newer_than",
                key: uuid()
            }
        ]
        return optionsData
    }

    function buildDropdownOptionsAgedInventoryNumber() {
        let optionsData = [

            {
                name: t( 'LABEL.COMMON.ADVANCED_FILTERS.SELECT'),
                disable: false,
                value: null,
                key: uuid()

            },
            {
                name: 60,
                disable: false,
                value: 60,
                key: uuid()
            },
            {
                name: 90,
                disable: false,
                value: 90,
                key: uuid()
            },
            {
                name: 180,
                disable: false,
                value: 180,
                key: uuid()
            },
            {
                name: 360,
                disable: false,
                value: 360,
                key: uuid()
            }
        ]
        return optionsData
    }

    function buildDropdownOptionsRunHistory() {
        let optionsData = [
            {
                name: t( 'LABEL.COMMON.ADVANCED_FILTERS.RUN_HISTORY_OPTION.RAN_GREATER_THAN'),
                disable: false,
                value: "greater_than",
                key: uuid()
            },
            {
                name: t( 'LABEL.COMMON.ADVANCED_FILTERS.RUN_HISTORY_OPTION.RAN_LESS_THAN'),
                disable: false,
                value: "less_than",
                key: uuid()
            }
        ]
        return optionsData
    }

    function buildDropdownOptionsRunHistoryNumber() {
        let optionsData = [
            {
                name: t( 'LABEL.COMMON.ADVANCED_FILTERS.SELECT'),
                disable: false,
                value: null,
                key: uuid()

            },
            {
                name: 2,
                disable: false,
                value: 2,
                key: uuid()
            },
            {
                name: 3,
                disable: false,
                value: 3,
                key: uuid()
            },
            {
                name: 4,
                disable: false,
                value: 4,
                key: uuid()
            },
            {
                name: 5,
                disable: false,
                value: 5,
                key: uuid()
            }
        ]
        return optionsData
    }

    //#region Handle event onChange for fields
    function onChangeNoSale() {
        setFilter({
            ...filter,
            noSale: !filter.noSale
        })
        setIsOnchangeAdvanceFilter(true)
    }

    function onChangeIfSale(value) {
        setFilter({
            ...filter,
            ifSale: value
        })
        setIsOnchangeAdvanceFilter(true)
    }

    function onChangeTitleStatus(value) {
        setFilter({
            ...filter,
            title: value
        })
        setIsOnchangeAdvanceFilter(true)
    }

    function onChangeAgedInventory(e) {
        setFilter({
            ...filter,
            olderNewerSelect: e.value
        })
        setSelectedAgeInventory(e)
        setIsOnchangeAdvanceFilter(true)
    }

    function onChangeAgeInventoryNumberOption(e) {
        setFilter({
            ...filter,
            numberOfDaysSelect: e.value
        })
        setSelectedAgeInventoryNumber(e)
        setIsOnchangeAdvanceFilter(true)
    }

    function onChangeRunHistory(e) {
        setFilter({
            ...filter,
            greaterLessSelect: e.value
        })
        setSelectedRunHistory(e)
        setIsOnchangeAdvanceFilter(true)
    }

    function onChangeRunHistoryNumber(e) {
        setFilter({
            ...filter,
            gtLtNumberSelect: e.value
        })
        setSelectedRunHistoryNumber(e)
        setIsOnchangeAdvanceFilter(true)
    }

    function onChangeWithDisp(e) {
        setFilter({
            ...filter,
            withDisp: e.target.value
        })

        setIsOnchangeAdvanceFilter(true)
    }

    function onChangeWithOutDisp(e) {
        setFilter({
            ...filter,
            withOutDisp: e.target.value
        })

        setIsOnchangeAdvanceFilter(true)
    }

    function onChangeWithBusinessUnits(e) {
        setFilter({
            ...filter,
            withBusinessUnits: e.target.value
        })

        setIsOnchangeAdvanceFilter(true)
    }

    function onChangeWithOutBusinessUnits(e) {
        setFilter({
            ...filter,
            withOutBusinessUnits: e.target.value
        })

        setIsOnchangeAdvanceFilter(true)
    }
    //#endregion

    // Key code [comma: 188, white space: 32]
    const actionOnKeyDown = useCallback((e) => handleEventOnKeyDownForNumberAlphabet(e, [188, 32]), [])

    useEffect(() => {
        validateInput()
    }, [filter])

    useEffect(() => {
        if(!isClearAdvanced) {
            setIsClearAdvanced(true)
        }
    }, [isClearAdvanced])

    function validateInput(){
        if(ConstraintConstant.PATTERN_REGEX_SPECIAL_CHARACTER.test(filter.withDisp)
        || ConstraintConstant.PATTERN_REGEX_SPECIAL_CHARACTER.test(filter.withOutDisp)
        || ConstraintConstant.PATTERN_REGEX_SPECIAL_CHARACTER.test(filter.withBusinessUnits)
        || ConstraintConstant.PATTERN_REGEX_SPECIAL_CHARACTER.test(filter.withOutBusinessUnits)
        ) {
            setIsValidInput(false)
        } else {
            setIsValidInput(true)
        }
    }
    return (
        <>
            <Container className="prism-overridden advanced-filters">
                <SlideOut
                    actionable={true}
                    onCloseButtonClick={() => {
                        setIsAdvancedFiltersOpen(false)
                    }}
                    open={isAdvancedFiltersOpen}
                    placement="right"
                    headerText={t(
                        'LABEL.COMMON.ADVANCED_FILTERS.TITLE_ADVANCED_FILTERS'
                    )}>
                   <div className="mr- mr-2">
                       { isClearAdvanced &&
                           <Space vertical >
                               <Row className="ml-1">
                                   <div>
                                       <p className="mb-0"
                                          mauto="maLabelNoSale"
                                       >
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.NO_SALES')}
                                       </p>
                                       <Checkbox
                                           className="prism-checkbox ml-0"
                                           checked={filter.noSale}
                                           id="showOnlyNoSale"
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.SHOW_ONLY_NO_SALES')}
                                           onChange={onChangeNoSale}
                                           mauto="maCheckBoxNoSale"
                                       />
                                   </div>
                               </Row>
                               <Row className="ml-1">
                                   <div>
                                       <p className="mb-0 pb-0"
                                          mauto="maLabelIfSale"
                                       >
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.IF_SALES')}
                                       </p>
                                       <RadioButton
                                           className="prism-radio mt-0"
                                           value="include_if_sale"
                                           name="if_sale"
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.SHOW_ONLY_VEHICLES_ON_IF')}
                                           onChange={e => {
                                               onChangeIfSale(e.target.value)
                                           }}
                                           checked={filter.ifSale==="include_if_sale"}
                                           mauto="maIncludeIfSale"
                                       />
                                       <RadioButton
                                           className="prism-radio"
                                           value="exclude_if_sale"
                                           name="if_sale"
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.NOT_INCLUDE_VEHICLES_ON_IF')}
                                           onChange={e => {
                                               onChangeIfSale(e.target.value)
                                           }}
                                           checked={filter.ifSale==="exclude_if_sale"}
                                           mauto="maExcludeIfSale"
                                       />
                                   </div>
                               </Row>
                               <Row className="ml-1">
                                   <div>
                                       <p className="mb-0"
                                          mauto="maLabelTitleStatus"
                                       >
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.TITLE_STATUS')}
                                       </p>
                                       <RadioButton
                                           className="prism-radio"
                                           value="title_present"
                                           name="title"
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.SHOW_ONLY_TITLE_PRESENT')}
                                           onChange={e => {
                                               onChangeTitleStatus(e.target.value)
                                           }}
                                           checked={filter.title==="title_present"}
                                           mauto="maRdoTitlePresent"
                                       />
                                       <RadioButton
                                           className="prism-radio"
                                           value="title_absent"
                                           name="title"
                                           label={t('LABEL.COMMON.ADVANCED_FILTERS.SHOW_ONLY_TITLE_ABSENT')}
                                           onChange={e => {
                                               onChangeTitleStatus(e.target.value)
                                           }}
                                           checked={filter.title==="title_absent"}
                                           mauto="maRdoTitleAbsent"
                                       />
                                   </div>
                               </Row>
                               <Row className="ml-1">
                                   <div>
                                       <p className="mb-0"
                                          mauto="maLabelAgeInventory"
                                       >
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.AGED_INVENTORY')}
                                       </p>
                                       <Row>
                                           <Col>
                                               <div className="prism-select-lg">
                                                   <Dropdown
                                                       options={ageInventoryOption}
                                                       onChange={e => {
                                                           onChangeAgedInventory(e)
                                                       }}
                                                       selectedOption={selectedAgeInventory}
                                                       mauto="maDropdownAgeInventory"
                                                   />
                                               </div>

                                           </Col>
                                           <Col className="pl-0 pr-1">
                                               <div className="prism-select-sm">
                                                   <Dropdown
                                                       options={ageInventoryNumberOption}
                                                       onChange={onChangeAgeInventoryNumberOption}
                                                       selectedOption={selectedAgeInventoryNumber}
                                                       mauto="maDropdownAgeInventoryNumber"
                                                   />
                                               </div>
                                           </Col>
                                           <Col className="pl-0">
                                               <p className="mb-0 mt-1"
                                                  mauto="maLabelDays"
                                               >
                                                   {t('LABEL.COMMON.ADVANCED_FILTERS.DAYS')}
                                               </p>
                                           </Col>
                                       </Row>

                                   </div>
                               </Row>
                               <Row className="ml-1">
                                   <div>
                                       <p className="mb-0"
                                          mauto="maLabelRunHistory"
                                       >
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.RUN_HISTORY')}
                                       </p>
                                       <Row>
                                           <Col>
                                               <div className="prism-select-lg">
                                                   <Dropdown
                                                       options={runHistoryOption}
                                                       onChange={onChangeRunHistory}
                                                       selectedOption={selectedRunHistory}
                                                       mauto="maDropdownRunHistory"
                                                   />
                                               </div>
                                           </Col>
                                           <Col className="pl-0 pr-1">
                                               <div className="prism-select-sm">
                                                   <Dropdown
                                                       options={runHistoryOptionNumber}
                                                       onChange={onChangeRunHistoryNumber}
                                                       selectedOption={selectedRunHistoryNumber}
                                                       mauto="maDropdownRunHistoryNumber"
                                                   />
                                               </div>
                                           </Col>
                                           <Col className="pl-0">
                                               <p className="mb-0 mt-1"
                                                  mauto="maLabelTimes">
                                                   {t('LABEL.COMMON.ADVANCED_FILTERS.TIMES')}
                                               </p>
                                           </Col>
                                       </Row>
                                   </div>
                               </Row>
                               <Row className="ml-1">
                                   <div>
                                       <p className="mb-0"
                                          mauto="maLabelWithDisp">
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.SHOW_ONLY_VEHICLE_DISPOSITION')}
                                       </p>
                                       <Input.TextCounter
                                           value={filter.withDisp}
                                           placeholder={t('LABEL.COMMON.ADVANCED_FILTERS.COMMA_SEPARATED')}
                                           rows="3"
                                           onKeyDown={actionOnKeyDown}
                                           onChange={onChangeWithDisp}
                                           maxlength={250}
                                           mauto="maInputWithDisp"/>
                                   </div>
                               </Row>
                               <Row className="ml-1">
                                   <div>
                                       <p className="mb-0"
                                          mauto="maLabelWithOutDisp">
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.NOT_SHOW_VEHICLE_DISPOSITION')}
                                       </p>
                                       <Input.TextCounter
                                           value={filter.withOutDisp}
                                           placeholder={t('LABEL.COMMON.ADVANCED_FILTERS.COMMA_SEPARATED')}
                                           rows="3"
                                           onKeyDown={actionOnKeyDown}
                                           onChange={onChangeWithOutDisp}
                                           maxlength={250}
                                           mauto="maInputWithOutDisp"/>
                                   </div>
                               </Row>
                               <Row className="ml-1">
                                   <div>
                                       <p className="mb-0"
                                          mauto="maLabelWithBusinessUnits">
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.SHOW_ONLY_VEHICLE_BUSINESS')}
                                       </p>
                                       <Input.TextCounter
                                           value={filter.withBusinessUnits}
                                           placeholder={t('LABEL.COMMON.ADVANCED_FILTERS.COMMA_SEPARATED')}
                                           rows="3"
                                           onKeyDown={actionOnKeyDown}
                                           onChange={onChangeWithBusinessUnits}
                                           maxlength={250}
                                           mauto="maInputWithBusinessUnits"
                                       />
                                   </div>
                               </Row>
                               <Row className="ml-1">
                                   <div>
                                       <p className="mb-0"
                                          mauto="maLabelWithOutBusinessUnits">
                                           {t('LABEL.COMMON.ADVANCED_FILTERS.NOT_SHOW_VEHICLE_BUSINESS')}
                                       </p>
                                       <Input.TextCounter
                                           value={filter.withOutBusinessUnits}
                                           placeholder={t('LABEL.COMMON.ADVANCED_FILTERS.COMMA_SEPARATED')}
                                           rows="3"
                                           onKeyDown={actionOnKeyDown}
                                           onChange={onChangeWithOutBusinessUnits}
                                           maxlength={250}
                                           mauto="maInputWithOutBusinessUnits"
                                       />
                                   </div>
                               </Row>
                           </Space>}
                   </div>
                    <div className="row advanced-filter-footer">
                        <div className="control-buttons">
                            <Space size={0} className={'ml-auto mr-0'}>
                                <Button className="m-2"
                                        onClick={() => {
                                            handleClearAdvanceFilter()
                                        }}
                                        mauto="maButtonClearAdvanceFilter">
                                    <i className="icon prism-icon-cancel-circle p-1" ></i>
                                    {t('LABEL.COMMON.ADVANCED_FILTERS.CLEAR')}
                                </Button>
                                <Button
                                    className="m-2"
                                    mauto="maButtonApplyAdvanceFilter"
                                    onClick={() => {
                                        handleAdvanceFilter()
                                    }}
                                    disabled={!(isEnableFilterByGroup && isOnchangeAdvanceFilter && isValidInput)}
                                    size="sm"
                                    color="primary">
                                    {t('LABEL.COMMON.ADVANCED_FILTERS.APPLY_FILTERS')}
                                </Button>
                            </Space>

                        </div>
                    </div>
                </SlideOut>
            </Container>
        </>
    )
}
