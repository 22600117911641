import React from 'react'
import { MessageCommon } from '../messages/Message'
import { MessageTypeConstant } from '../../../utils/constant'
import './notification-message.scss'

export function NotificationMessage({
    type,
    children,
    isOpen,
    className,
    onToggleCallBack
}) {

    function getColorByType(type) {
        let color = 'info'
        switch (type) {
            case MessageTypeConstant.WARNING:
                color = 'warning'; break;
            case MessageTypeConstant.ERROR:
                color = 'danger'; break;
            case MessageTypeConstant.SUCCESS:
                color = 'success'; break;
            default:
                color = 'info';
        }
        return color;
    }

    return (
        <MessageCommon
            isOpen={isOpen}
            color={getColorByType(type)}
            className={className}
            onToggleCallBack={onToggleCallBack}>
            <>
                {children}
            </>
        </MessageCommon>
    )
}
