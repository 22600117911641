import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Col, Row } from '@prism/library'
import { Link, useNavigate } from 'react-router-dom'
import {
    getDealershipStatusClass,
    getFormattedDealerInfo
} from '../../utils/utils'
import { RemainingVehicleCount } from './RemainingVehicleCount'
import { RemainingVehicleList } from './RemainingVehicleList'
import { PathUrlConstant } from "../../utils/constant";
import { useDispatch } from "react-redux";
import './remaining-inventory-item.scss'
import {getAssignInventorySearchContext} from "../../actions/AssignInventoryAction";

export function RemainingInventoryItem({
    auctionCode,
    dealer,
    itemIndex,
    submitCount,
    setLoadVehiclesError,
    setMessageUpdateVehicleSuccessful,
    getAdvancedFilterParams,
    isAuctionCodeChange,
    setIsAuctionCodeChange
}) {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const [isOpenedVehicleList, setIsOpenedVehicleList] = useState(false)

    const updateOpenedVehicleListState = useCallback(
        (e) => {
            setIsOpenedVehicleList(!isOpenedVehicleList)
        },
        [isOpenedVehicleList]
    )

    useEffect(() => {
        setIsOpenedVehicleList(false)
    }, [submitCount])

    function handleOnClickAssignInventory(sellerNumber) {
        dispatch(getAssignInventorySearchContext({
            searchKeywords: sellerNumber
        }))
    }

    return (
        <>
            <div
                key={itemIndex}
                mauto={`maInventoryInfo${itemIndex + 1}`}
                className={classNames(
                    'card-header',
                    getDealershipStatusClass(dealer.status),
                    'remaining-inventory-item-info'
                )}>
                <Row>
                    <Col md={8} sm={7}>
                        <span>{getFormattedDealerInfo(dealer)}</span>
                    </Col>
                    <Col md={4} sm={5} className="p-0 assign-inventory-item">
                        <Row>
                            <Col
                                mauto={`maAdvanceFilter${itemIndex + 1}`}
                                md={6}
                                sm={6}
                                xs={6}
                                className="text-left">
                                <Link to={`/${PathUrlConstant.AssignInventory}?searchKeywords=${dealer.id}`}
                                      onClick={() => {
                                          handleOnClickAssignInventory(dealer.id)
                                      }}
                                >
                                    {t(
                                        'LABEL.REMAINING_INVENTORY.ASSIGN_INVENTORY'
                                    )}
                                </Link>
                            </Col>
                            <Col
                                md={6}
                                sm={6}
                                xs={6}
                                mauto={`maVehicle${itemIndex + 1}`}
                                className="px-0 vehicle-count">
                                <RemainingVehicleCount
                                    isOpenedVehicleList={isOpenedVehicleList}
                                    numberOfRemainingVehicle={dealer.count}
                                    updateOpenedVehicleListState={
                                        updateOpenedVehicleListState
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            {(
                <div className={classNames('card-header', {
                        'd-none': !isOpenedVehicleList
                    })}
                     mauto="vehicleDetailItem">
                    <Row>
                        <RemainingVehicleList
                            auctionCode={auctionCode}
                            sellerNumber={dealer.id}
                            setLoadVehiclesError={setLoadVehiclesError}
                            setMessageUpdateVehicleSuccessful={setMessageUpdateVehicleSuccessful}
                            getAdvancedFilterParams={getAdvancedFilterParams}
                            isAuctionCodeChange={isAuctionCodeChange}
                            setIsAuctionCodeChange={setIsAuctionCodeChange}
                            isOpenedVehicleList={isOpenedVehicleList}
                        />
                    </Row>
                </div>
            )}
        </>
    )
}
