import {Modal} from "@prism/library";
import {useCallback, useEffect, useMemo, useState, useRef} from "react";
import './default-modal.scss'
import { useTranslation } from 'react-i18next'
import { getVehicleNotes } from '../../../utils/data-source/remaining-inventory-datasource'
import { handleErrorException } from '../../../utils/utils'
import { NotificationMessage } from '../notification-message/NotificationMessage'
import { MessageTypeConstant } from '../../../utils/constant'
import { keys } from 'lodash'
import './vehicle-notes-modal.scss'
import { useSelector } from 'react-redux'
import useVehicleNotesModal from '../../../hooks/common/UseVehicleNotesModal'
import Draggable from 'react-draggable'

export default function VehicleNotesModal() {
    const {isOpen, auctionCode, workOrder} = useSelector(state => state.vehicleNotesModalContext)

    const {t} = useTranslation()
    const [showModal, setShowModal] = useState(isOpen)
    const [notes, setNotes] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [isDraggable, setIsDraggable] = useState(true)
    const { closeVehicleNotesModal } = useVehicleNotesModal()
    const modalRef = useRef()
    useEffect(() => {
        setShowModal(isOpen)
    }, [isOpen])

    useEffect(() => {
        if (isOpen) {
            getVehicleNotes(auctionCode, workOrder).then((response) => {
                if (response.data) {
                    setNotes(response.data.notes)
                }
            }).catch((ex) => {
                closeVehicleNotesModal()
                setNotes(null)
                const message = handleErrorException(ex)
                setErrorMessage(message)
            })
        } else {
            setNotes(null)
        }

    }, [isOpen])

    const closeModal = useCallback(() => {
        setShowModal(false)
        closeVehicleNotesModal()
    }, [])

    const isOpenVehicleNotesModal = useMemo(() => {
        return (!!notes && isOpen)
            || (!!errorMessage && isOpen)
    }, [notes, isOpen, errorMessage])
    
    function handleClickOutside(event) {
        if(modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal()
        }
    }
    useEffect(() => {
        document.body.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.body.removeEventListener('mousedown', handleClickOutside)
        }
    }, [modalRef])

    return (
        isOpenVehicleNotesModal &&
             <Draggable disabled={!isDraggable}>
                 <Modal
                     className='default-modal vehicle-notes'
                        isOpen={showModal}
                        toggle={closeModal}
                        keyboard={false}
                        backdrop={"static"}
                 >
                     <div ref={modalRef} >
                         <Modal.Header test-data-id="header"  toggle={closeModal}>
                             {t('LABEL.COMMON.VEHICLE.VEHICLE_NOTES')}
                         </Modal.Header>
                         <Modal.Body test-data-id="body">
                             {!!errorMessage &&
                                 <NotificationMessage type={MessageTypeConstant.ERROR}>
                                     {errorMessage}
                                 </NotificationMessage>
                             }

                             <div className="list-notes" mauto="listNotes"
                                  onMouseEnter={() => { setIsDraggable(false) }}
                                  onMouseLeave={() => { setIsDraggable(true) }}
                             >
                                 {keys(notes).map((key) => {
                                     const value = notes[key]
                                     return (
                                         <div
                                             key={key}
                                             id={key}
                                             className="mt-1">
                                             {key}. {value}
                                         </div>
                                     )
                                 })}
                             </div>
                         </Modal.Body>
                     </div>
                 </Modal>
             </Draggable>
    );
}