import axiosInstance from '../axios'

export function getVehicles(filters) {
    return axiosInstance.post('/vehicle', filters)
}

export function getVehicleVcfs(auctionCode, sbluNumber, type) {
    return axiosInstance.get('/vehicle/vcfs', {
        params: {
            'auction-code': auctionCode,
            'sblu-number': sbluNumber,
            'type': type || ''
        }
    })
}

export function updateVehicleVcf(request) {
    return axiosInstance.put('/vehicle/update-vcf', request)
}

export function updateVehicle(payload) {
    return axiosInstance.put('/vehicle', payload)
}

export function updateInventory(payload) {
    return axiosInstance.post('/vehicle/update-inventory', payload)
}
