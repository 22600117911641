import {Button, Chip, Col, Row,} from "@prism/library";
import {useTranslation} from "react-i18next";
import classNames from 'classnames'
import React, { useMemo } from "react";
import {CircularProgressbarWithChildren} from "react-circular-progressbar";
import RadialSeparators from "./RadialSeparators";
import {getFormattedVehicleSellerInfo, handleErrorException} from "../../utils/utils";
import { isEmpty } from "lodash";
import {deleteRunRequest, getRunRequestSummary} from "../../utils/data-source/lane-management-datasource";
import { useDrag, useDrop } from "react-dnd";
import { DnDDragTypeConstant } from "../../utils/constant";

import './lane-management-vehicle-metric.scss'

const DEFAULT_SALE_PROPERTY = {
    totalRegistered: 0,
    totalSold: 0,
    totalOnProperty: 0,
    noShows: 0,
    totalBought: 0,
    totalArbed: 0,
    salesPercentage: 0,
    totalNoSale: 0
}

export function LaneManagementVehicleMetric({vehicle, isMapped, setIsAddRequestError, isNewRequestAdded, laneSavedToAS400, index, setIsEditRequestOpen, setEditingRequest}) {
    const {t} = useTranslation()

    const saleProperty = useMemo(() => {
        if(vehicle.seller && vehicle.seller.stats){
            return vehicle.seller.stats
        }

        return DEFAULT_SALE_PROPERTY
    }, [vehicle.seller])

    const sellerInfo = useMemo(() => {
        if(vehicle.seller){
            const {id,name,city,province} = vehicle.seller
            return {
                sellerNumber: id,
                sellerName: name,
                sellerCity: city,
                sellerProvince: province
            }
        }

        return null
    }, [vehicle.seller])

    function handleOnClickAdditionRequest() {
        setIsAddRequestError(false)
        const request = {
            auctionCode: vehicle.auctionCode,
            saleYear: vehicle.saleYear,
            saleNumber: vehicle.saleNumber,
            laneNumber: vehicle.laneNumber,
            sellerNumber: vehicle.sellerNumber
        }
        getRunRequestSummary(request)
            .then((response) => {
                window.updateAdditionalRequests(index, response.data)
            }).catch((ex) => {
            setIsAddRequestError(true)
        })
    }

    function handleDeleteRunRequest() {
        deleteRunRequest(vehicle.globalId).then(() => {
            window.deleteUnMappedRequest(vehicle.globalId)
            window.setLaneManagementErrorMessage(null)
        }).catch((ex) => {
            const error = handleErrorException(ex)
            const errorMessage = 'MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.DELETE_RUN_REQUEST_ERROR.PRE_TEXT'
            window.setLaneManagementErrorMessage(errorMessage)
        })
    }

    //#region Handle Drag and Drop
    // Use the useDrag hook to make the component draggable
    const [{ isDragging }, drag] = useDrag(() => ({
        type: DnDDragTypeConstant.LANE_MANAGEMENT,
        item: { vehicle, isMapped, index},
        collect: (monitor) => ({
            isDragging: monitor.isDragging() && (vehicle.id === monitor.getItem().vehicle.id),
        }),
        end: (item, monitor) => {
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                window.revertMoveRequest(item.vehicle, item.index, item.isMapped)
            }
        }
    }), [vehicle])

    const [{ isHovering }, drop] = useDrop(
        () => ({
            accept: DnDDragTypeConstant.LANE_MANAGEMENT,
            hover: (item, monitor) => {
                window.updateRunRequestWhileDropHover(item.vehicle, index, isMapped)
            },
            collect: (monitor) => ({
                isHovering: monitor.getItem()?.vehicle.id === vehicle.id
                    && monitor.canDrop()
                    && !vehicle.isCustomDragLayer
            }),
            drop: (item, monitor) => {
                window.updateRunRequestAfterDnD(item.vehicle, isMapped, item.isMapped, item.index)
            }
        })
    )
    //#endregion

    function renderAdditionRequestComponent() {
        return (
            <>
                {!isEmpty(vehicle.requestSummary) && vehicle.requestSummary.map(request => {
                        return (
                            <div className="addt-request-summary">
                                <span  mauto="maLabelRequestSummaryLane">{t('LABEL.LANE_MANAGEMENT.LANE')} {request.laneNumber}</span>
                                <span className="w-40">{request.runCount}</span>
                            </div>)
                    }
                )}
                {isEmpty(vehicle.requestSummary) &&
                    <p className="no-addt-request ml-2"  mauto="maLabelNoAddRequest">
                        {t('LABEL.COMMON.VEHICLE.NO_ADD_REQUESTS')}
                    </p>
                }
            </>
        )
    }

    function renderVehicleComponent(vehicle) {
        return (
            <>
                <Row>
                    <Col md={9} className="pl-2">
                        <Row>
                             <span
                                       title={t(
                                           'LABEL.REMAINING_INVENTORY.VEHICLE_TITLE'
                                       )}
                                       className="text-secondary vehicle-title ml-2">
                                        {sellerInfo ? getFormattedVehicleSellerInfo(
                                            sellerInfo
                                        ) : vehicle.alternateSellerName}
                             </span>
                        </Row>
                        <Row>
                            {sellerInfo && (
                                <>
                                    <Col md={3} className="vehicle-metric-wrapper">
                                        <div className={"vehicle-metric mt-2 mb-2"}>
                                            <CircularProgressbarWithChildren value={saleProperty.salesPercentage}>
                                                <RadialSeparators
                                                    count={2}
                                                    percent={saleProperty.salesPercentage}
                                                />
                                                <div className="text-center"  mauto="maLabelSaleEfficiency">
                                                    {t(
                                                        'LABEL.REQUEST_HISTORY.RESULT_TABLE.SALE_EFFICIENCY'
                                                    )}
                                                </div>
                                                <div className="vehicle-sale-percent">
                                                    <strong>{saleProperty.salesPercentage}%</strong>
                                                </div>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mt-1 arb-bought-wrapper">
                                        <>
                                            <Row className="mt-2">
                                                <div className="vehicle-odometer">
                                                    <Chip color="light" bold>
                                                        {saleProperty.totalArbed}
                                                    </Chip>
                                                    <span className="text-muted mr-1-rem ml-1"  mauto="maLabelArb">
                                                        {t('LABEL.COMMON.VEHICLE.ARB')}
                                                </span>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div></div>
                                            </Row>
                                        </>
                                        <Row className="mt-2">
                                            <div className="vehicle-odometer">
                                                <Chip color="light" bold>
                                                    {saleProperty.totalBought}
                                                </Chip>
                                                <span className="text-muted mr-1-rem ml-1"  mauto="maLabelBought">
                                                    {t('LABEL.COMMON.VEHICLE.BOUGHT')}
                                                </span>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col md={3} className="mt-1 no-sale-sold-wrapper">
                                        <Row className='mt-0'>
                                            <div></div>
                                        </Row>
                                        <>
                                            <Row className="mt-2">
                                                <div className="vehicle-odometer">
                                                    <Chip color="light" bold>
                                                        {saleProperty.totalNoSale}
                                                    </Chip>
                                                    <span className="text-muted mr-1-rem ml-1"  mauto="maLabelNoSale">
                                                        {t('LABEL.COMMON.VEHICLE.NO_SALE')}
                                                    </span>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div></div>
                                            </Row>
                                        </>
                                        <Row className="mt-2">
                                            <div className="vehicle-odometer">
                                                <Chip color="light" bold>
                                                    {saleProperty.totalSold}
                                                </Chip>
                                                <span className="text-muted mr-1-rem ml-1"  mauto="maLabelSold">
                                                    {t('LABEL.COMMON.VEHICLE.SOLD')}
                                                </span>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col md={3} className="mt-1 no-show-on-property-wrapper">
                                        <Row className='mt-0'>
                                            <div></div>
                                        </Row>
                                        <>
                                            <Row className="mt-2">
                                                <div className="vehicle-odometer">
                                                    <Chip color="light" bold>
                                                        {saleProperty.noShows}
                                                    </Chip>
                                                    <span className="text-muted mr-1-rem ml-1"  mauto="maLabelNoShow">
                                                        {t('LABEL.COMMON.VEHICLE.NO_SHOW')}
                                                    </span>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div></div>
                                            </Row>
                                        </>
                                        <Row className="mt-2">
                                            <div className="vehicle-odometer">
                                                <Chip color="light" bold>
                                                   {saleProperty.totalOnProperty}
                                                </Chip>
                                                <span className="text-muted mr-1-rem ml-1"  mauto="maLabelOnProperty">
                                                    {t('LABEL.COMMON.VEHICLE.ON_PROPERTY')}
                                                </span>
                                            </div>
                                        </Row>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Col>
                    <Col md={3} className="pl-1 pr-3">
                        <Row>
                            {isMapped && (
                                <div className={classNames('mt-0', 'w-100', 'text-right')}>
                                <span className="text-muted mr-1 modify-content"  mauto="maLabelRuns">
                                    {t('LABEL.LANE_MANAGEMENT.LABEL.RUNS')}
                                </span>
                                    <span className="text-muted modify-content">
                                   {vehicle.startingRun} - {vehicle.startingRun + vehicle.runCount - 1}
                                </span>
                                </div>
                            )}
                            {!isMapped && (
                                <div className={classNames('mt-0', 'w-100', 'text-right')}>
                                <span className="modify-content text-muted"  mauto="maLabelRequested">
                                   {t('LABEL.COMMON.VEHICLE.REQUESTED')}
                                </span>
                                </div>
                            )}
                        </Row>
                        <Row>
                            <div
                                className={classNames('mt-1', 'w-100', 'text-right', 'rans-served'
                                )}>
                                <span className="run-count">
                                   {vehicle.runCount}
                                </span>
                                {isMapped && checkRunInfoToRenderPlusMinus(vehicle) && (
                                    <>
                                        <div>
                                                <Button className="plus-minus-btn visible-button btn btn-outline" type="button" variant="outline" mauto="maPlusBtn"
                                                        onClick={() => {
                                                            window.increaseDecreaseRunCount(index,1)
                                                        }}>
                                                        <i className="icon prism-icon-plus"/>
                                                </Button>
                                                <Button className="plus-minus-btn visible-button btn btn-outline ml-1" type="button" variant="outline" mauto="maMinusBtn"
                                                        onClick={() => {
                                                            if(vehicle.runCount > 1) {
                                                                window.increaseDecreaseRunCount(index, -1)
                                                            }
                                                        }}>
                                                        <i className="icon prism-icon-minus3"/>
                                                </Button>
                                            <div className="mt-1"/>
                                        </div>
                                    </>
                                )}
                                {!isMapped && (
                                    <>
                                        <div className={classNames(!vehicle.sellerNumber ? 'addt-cursor' : '')}
                                        >
                                                <span
                                                    title={t(
                                                        'LABEL.REMAINING_INVENTORY.VEHICLE_TITLE'
                                                    )}
                                                    className={classNames('text-secondary cursor-pointer ml-2 addt-request', !vehicle.sellerNumber ? 'addt-request-disable' : '')}
                                                    onClick={handleOnClickAdditionRequest}
                                                    mauto="maLabelAddRequest">
                                                   {t('LABEL.COMMON.VEHICLE.ADD_REQUEST')}
                                             </span>
                                        </div>
                                        {!!vehicle.isShowAdditionalRequests &&
                                            renderAdditionRequestComponent()
                                        }

                                    </>
                                )}
                            </div>
                        </Row>
                        <Row>
                            <div className={classNames( "w-100 text-right ml-2")}>
                                {!isMapped && vehicle.source!=="as400" && (
                                    <div>
                                        <Button className="py-0 my-0" type="button" onClick={() => {
                                            setIsEditRequestOpen(true)
                                            setEditingRequest(vehicle)
                                        }}>
                                            <i className="icon prism-icon-edit  sm-1 text-secondary"/>
                                            <span className="text-secondary modify-content pl-1"  mauto="maLabelEdit">
                                                {t('LABEL.COMMON.VEHICLE.EDIT')}
                                            </span>
                                        </Button>
                                        <Button className="pr-0 py-0 my-0" type="button" onClick={handleDeleteRunRequest} mauto='maBtnDelete'>
                                            <i className="icon prism-icon-trash sm-1 text-secondary"/>
                                            <span className="text-secondary modify-content  pl-1"  mauto="maLabelDelete">
                                                {t('LABEL.COMMON.VEHICLE.DELETE')}
                                            </span>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        {vehicle.comments && (
                            <span className='vehicle-adj-mmr-label' mauto="maLabelComment">
                               {vehicle.comments}
                        </span>
                        )}
                    </Col>
                    <Col md={6} className='text-right'>
                        {vehicle.requestedBy && (
                            <span className='vehicle-adj-mmr-label'  mauto="maLabelRequestedBy">
                            {t('LABEL.REQUEST_HISTORY.RESULT_TABLE.REQUESTED_BY')} {vehicle.requestedBy}
                        </span>
                        )}
                    </Col>
                </Row>
            </>
        )
    }

    function checkRunInfoToRenderPlusMinus(vehicle){
        return !laneSavedToAS400 && vehicle.source === 'cache' && vehicle.startingRun !== 0;
    }

    const opacity = (isHovering || isDragging) ? 0 : 1

    return (
        !!vehicle && (
            <>
                <div id={`vehicle-item-${vehicle.id}`} ref={(node) => vehicle.savedToAs400 === 'true' ? drop(node) : drag(drop(node))}
                    style={{opacity}}
                    className={classNames('lane-vehicle-item', {
                        'bg-wht dragable': vehicle.savedToAs400 === 'false' && !isNewRequestAdded,
                        'sent-to-as400': vehicle.savedToAs400 === 'true',
                        'highlight': isNewRequestAdded,
                        'h-7': (isHovering || isDragging)
                    }
                    )}>
                    {renderVehicleComponent(vehicle)}
                </div>
                {(isHovering || isDragging) && (
                    <div className='bd-t'></div>
                )}
            </>
        ))
}