import React, {useCallback, useEffect, useMemo, useState} from 'react'
import { Checkbox, Chip, Col, Container, Row } from '@prism/library'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { isEmpty, isNil } from 'lodash'
import classNames from 'classnames'
import { BLANK_HOLDER, DnDDragTypeConstant, MessageTypeConstant, PathUrlConstant } from '../../utils/constant'
import {
    getFormattedVehicleAdjMmr,
    getFormattedVehicleOdometer,
    getFormattedVehicleSellerInfo,
    getFormattedWo,
    getVehicleFormattedVin, getVehicleFormattedVinWithHighlightText,
    getVehicleTitle
} from '../../utils/utils'
import { VehicleImageIcon } from '../remaining-inventory/VehicleImageIcon'
import { VehicleTextIcon } from '../remaining-inventory/VehicleTextIcon'
import { useDrag, useDrop } from 'react-dnd'

import './assign-inventory-vehicle-item.scss'
import {
    getPreregisteredTitle,
    isPreRegisterVehicle,
    isReserveVehicle,
    isSentToAs400,
    preregisteredVehicleDescription
} from '../../utils/vehicle-utils'
import { useDispatch, useSelector } from 'react-redux'
import { getDnDVehicleData } from '../../actions/AssignInventoryAction'
import { getVehicleAfterUpdatePreRegist } from '../../actions/PreRegistAction'
import { buildMessage } from '../../utils/MessageUtil'
import NotificationMessages from '../common/notification-messages/NotificationMessages'
import HighlightText from '../common/highlight-text/HighlightText';
import {VehicleDetail} from '../common/vehicle-info/VehicleDetail';
import {getAuctionCodeFromCookie} from '../../utils/data-source/auction-code-datasource';
import compareVehicles from './helper/VehicleHelper';

export function AssignInventoryVehicleItem({
    vehicle,
    messageUpdateVehicleSuccessful,
    isUnassign,
    handleVcfOnClick,
    handleOnClickOpenPregistTration,
    dragData,
    canRemove,
    handleRemoveVehicleEvent,
    index,
    updateAssignedVehicleAfterDrop,
    setMessageUpdateVehicleSuccessful,
    isLaneManagement,
    allowQuickSearch = false,
    quickSearchText = '',
    quickSearchIndex = -1,
    allowDrop = true,
    allowDrag = true,
    updateVehicleData,
    handleTitleClicking,
    isVehicleDetailParticular,
    setGetVehicleError,
    isShownSellerSearchButton,
    isAlwaysShowUpdateParticularSuccessfulMessage
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const dndVehicleData = useSelector(states => states.dndVehicle.dndVehicleData)
    const preRegistVehicleData = useSelector(states => states.preRegist.preRegistVehicleData)
    const [messageUpdateSuccessful, setMessageUpdateSuccessful] = useState(
        messageUpdateVehicleSuccessful?.find(message => compareVehicles(message.vehicle, vehicle))
    )

    const [inputFocused, setInputFocused] = useState(false);

    //set successful message when update vehicle detail successfully
    useEffect(() => {
        setMessageUpdateSuccessful(messageUpdateVehicleSuccessful?.find(message =>compareVehicles(message.vehicle, vehicle)))
    }, [messageUpdateVehicleSuccessful])

    useEffect(() => {
        const id = `vehicle-item-${vehicle.vin}-${vehicle.workOrderNumber}-${vehicle.runNumber}`
        if (dndVehicleData && id === dndVehicleData.id && isUnassign) {
            setTimeout(() => {
                document.getElementById(id).classList.add('highlight-vehicle')
            }, 0)
            setTimeout(() => {
                document.getElementById(id)?.classList.remove('highlight-vehicle')
            }, 3000)
            dispatch(getDnDVehicleData(null))
        }
    }, [dndVehicleData])

    // highlight vehicle after update pre-regist
    useEffect(() => {
        const id = `vehicle-item-${vehicle.vin}-${vehicle.workOrderNumber}-${vehicle.runNumber}`
        if (preRegistVehicleData && id === preRegistVehicleData.id) {
            document.getElementById(id).classList.add('highlight-vehicle')
            setTimeout(() => {
                document.getElementById(id)?.classList.remove('highlight-vehicle')
            }, 3000)
            dispatch(getVehicleAfterUpdatePreRegist(null))
        }
    }, [preRegistVehicleData])

    // Use the useDrag hook to make the component draggable
    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: dragData.dragType,
        item: vehicle,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: !inputFocused
    }), [vehicle, inputFocused])

    const [{ isActive }, drop] = useDrop(
        () => ({
            accept: [DnDDragTypeConstant.ASSIGN_VEHICLE, DnDDragTypeConstant.UNASSIGN_VEHICLE],
            drop: (item, monitor) => {
                let dragData = {
                    vehicle: item,
                    itemIndex: index
                }
                updateAssignedVehicleAfterDrop(dragData)
            },
            collect: (monitor) => ({
                isActive: monitor.isOver() && monitor.canDrop()
            }),
        }),
        [vehicle]
    )

    function displayFormattedVehicleSellerInfo() {
        const sellerInfo = getFormattedVehicleSellerInfo(vehicle)

        if(isLaneManagement) {
            return (
                <Link
                    className={"font-weight-normal"}
                    to={`/${PathUrlConstant.AssignInventory}`
                        + `?saleYear=${vehicle?.saleYear}`
                        + `&saleNumber=${vehicle?.saleNumber}`
                        + `&laneNumber=${vehicle?.laneNumber}`
                        + `&sellerNumber=${vehicle?.sellerNumber}`
                    }
                >
                    {sellerInfo}
                </Link>
            )
        }
        return sellerInfo
    }

    const onTitleClicking = useCallback(() => {
        const newVehicle = {...vehicle, isVehicleDetailOpen: !vehicle.isVehicleDetailOpen}
        updateVehicleData(newVehicle)
        handleTitleClicking && handleTitleClicking(vehicle.sblu)
    },[vehicle])

    function renderVehicleComponent(vehicle) {
        return (
            <>
            <Row>
                <Col md={6} sm={6} xs={6}>
                    <Container fluid>
                        <Row>
                            {isUnassign && (
                                <div className="">
                                    {dragData.dragType === DnDDragTypeConstant.UNASSIGN_VEHICLE && (
                                        <Checkbox
                                            className="text-secondary custom-checkbox ml-0"
                                            onClick={() => {
                                                window.updateCheckedRuns(vehicle)
                                            }}
                                            checked={vehicle.checked}
                                        />
                                    )}
                                    <span
                                        title={t(
                                            'LABEL.REMAINING_INVENTORY.VEHICLE_TITLE'
                                        )}
                                        className="text-secondary vehicle-title cursor-pointer mb-0"
                                        onClick={onTitleClicking}>
                                        {getVehicleTitle(vehicle)}
                                    </span>
                                </div>

                                )}
                                {!isUnassign && (
                                    <div className="align-center">
                                    <span
                                        title={t(
                                            'LABEL.REMAINING_INVENTORY.VEHICLE_TITLE'
                                        )}
                                        className="text-secondary vehicle-title cursor-pointer"
                                        onClick={onTitleClicking}>
                                        {getVehicleTitle(vehicle)}
                                    </span>
                                    </div>
                                )}
                            </Row>
                        <Row className='vin-work-order-group'>
                            <Col md={10} className="mt-0 px-0 vehicle-vin pl-0 mr-2">
                                {allowQuickSearch && index === quickSearchIndex ? (
                                        getVehicleFormattedVinWithHighlightText(vehicle, quickSearchText)
                                    ) : (
                                        <div dangerouslySetInnerHTML={{__html: getVehicleFormattedVin(vehicle)}}></div>
                                    )
                                }
                            </Col>
                            <Col md={2} className="mt-0 pl-0 text-nowrap">
                                <span className="text-muted">
                                    {`${t('LABEL.COMMON.VEHICLE.WORK_ORDER_NUMBER')} `}
                                </span>
                                <span className="font-weight-bold">
                                    {allowQuickSearch && index === quickSearchIndex ? (
                                            <HighlightText
                                                text={getFormattedWo(vehicle)}
                                                highlight={quickSearchText}
                                            />
                                        ) : (
                                            getFormattedWo(vehicle)
                                        )
                                    }

                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <div className="mt-0 seller-info">
                                {displayFormattedVehicleSellerInfo()}
                            </div>
                        </Row>
                        <Row>
                            <Col className="mt-0 left-align">
                                <div>
                                    <span className="mr-2">
                                        {vehicle.status}
                                    </span>
                                    <Link
                                        to={'javascript:void(0)'}
                                        className="font-weight-bold"
                                        onClick={() => {
                                            handleVcfOnClick(
                                                vehicle.sblu
                                            )
                                        }}>
                                        {t(
                                            'LABEL.COMMON.VEHICLE.VCF'
                                        )}
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={3} sm={3} xs={3} className="pl-0 pr-0">
                    <Container fluid>
                        <Row className='mt-0'>
                            <div className="vehicle-odometer">
                                <span className="text-muted mr-1">
                                    {t('LABEL.COMMON.VEHICLE.ODOMETER')}
                                </span>
                                <Chip color="light" bold>
                                    {getFormattedVehicleOdometer(
                                        vehicle
                                    )}
                                </Chip>
                            </div>
                        </Row>
                        <>
                            <Row>
                                <div className="mt-0 vehicle-adj-mmr mr-1">
                                    <span className="text-muted mr-1 vehicle-adj-mmr-label">
                                        {t(
                                            'LABEL.COMMON.VEHICLE.ADJ_MMR'
                                        )}
                                    </span>
                                    <span className="vehicle-adj-mmr-value">
                                        {getFormattedVehicleAdjMmr(
                                            vehicle
                                        )}
                                    </span>
                                </div>
                                <div>
                                    {!!vehicle.hasMileageMmrIndicator && (
                                        <VehicleImageIcon
                                            src="image/mileage-mmr-indicator.png"
                                            className="mr-1 pb-0" />
                                    )}
                                    {!!vehicle.hasColorsMmrIndicator && (
                                        <VehicleImageIcon
                                            src="image/colors-mmr-indicator.png"
                                            className="mr-1 pb-0" />
                                    )}
                                    {!!vehicle.hasConditionMmrIndicator && (
                                        <VehicleImageIcon
                                            src="image/condition-mmr-indicator.png"
                                            className="pb-0" />
                                    )}
                                </div>
                            </Row>
                            <Row>
                                <span className="text-muted mr-1">
                                    {t(
                                        'LABEL.COMMON.VEHICLE.LOT_NUMBER'
                                    )}
                                </span>
                                <span>{vehicle.lotNumber}</span>
                            </Row>
                        </>
                        <Row className="float-left">
                            {vehicle.hasGatePass && (
                                <VehicleTextIcon
                                    text={t(
                                        'LABEL.COMMON.VEHICLE.ICON.GATE_PASS.TEXT'
                                    )}
                                    title={t(
                                        'LABEL.COMMON.VEHICLE.ICON.GATE_PASS.TITLE'
                                    )}
                                    bgClass="bg-success"
                                    fgClass="text-white font-weight-bold"
                                    className='mb-1'
                                />
                            )}
                            {vehicle.hasProblem && (
                                <VehicleTextIcon
                                    text={t(
                                        'LABEL.COMMON.VEHICLE.ICON.PROBLEM.TEXT'
                                    )}
                                    title={t(
                                        'LABEL.COMMON.VEHICLE.ICON.PROBLEM.TITLE'
                                    )}
                                    bgClass={classNames(
                                        !!vehicle.problemAlert
                                            ? 'bg-danger'
                                            : 'bg-warning'
                                    )}
                                    fgClass="text-white font-weight-bold"
                                    className='mb-1'
                                />
                            )}
                            {vehicle.hasIfSaleAlert && (
                                <VehicleTextIcon
                                    text={t(
                                        'LABEL.COMMON.VEHICLE.ICON.IF_SALE_ALERT.TEXT'
                                    )}
                                    title={t(
                                        'LABEL.COMMON.VEHICLE.ICON.IF_SALE_ALERT.TITLE'
                                    )}
                                    bgClass="bg-danger"
                                    fgClass="text-white font-weight-bold"
                                    className='mb-1'
                                />
                            )}
                        </Row>
                    </Container>
                </Col>
                <Col md={3} sm={3} xs={3} className="pl-1 pr-3">
                    {!!vehicle.previouslyRan && (
                        <Row>
                            <div className={classNames('mt-0', 'w-100', 'text-right', 'previous-lane-run')}>
                                <span className="text-muted mr-1">
                                    {t('LABEL.COMMON.VEHICLE.RAN')}
                                </span>
                                <span>
                                    {vehicle.previousLaneAndRun}
                                </span>
                                <span className="mx-1">x</span>
                                <span>
                                    {vehicle.timesThroughLane}
                                </span>
                            </div>
                        </Row>
                    )}
                    <Row>
                        <div className={"mt-0 w-100 text-right"}>
                        {!isUnassign && (isNil(dragData.origin) || dragData.origin === DnDDragTypeConstant.ASSIGN_VEHICLE) && (
                                <span className="text-muted font-weight-bold current-ran">
                                {String(vehicle.laneNumber).padStart(2, '0') + '-' + String(vehicle.runNumber).padStart(3, '0')}
                                </span>
                        )}
                            </div>
                    </Row>
                    <>
                        <Row>
                            <div
                                className={classNames(
                                    !!vehicle.dispositionCode
                                        ? 'mt-0'
                                        : 'mt-0', 'w-100', 'text-right'
                                )}>
                                <span className="text-muted mr-1">
                                    {t('LABEL.COMMON.VEHICLE.DISP')}
                                </span>
                                {!!vehicle.dispositionCode && (
                                    <span
                                        className="text-muted font-weight-bold vehicle-disposition-code"
                                        mauto="dispositionCode">
                                        {vehicle.dispositionCode}
                                    </span>
                                )}
                                {!vehicle.dispositionCode && (
                                    <span
                                        className="text-muted font-weight-bold"
                                        mauto="dispositionCode">
                                        {BLANK_HOLDER}
                                    </span>
                                )}
                            </div>
                        </Row>
                        <Row>
                            <div
                                className={classNames(
                                    !!vehicle.dispositionCode
                                        ? 'mt-0'
                                        : 'mt-0', 'w-100', 'text-right'
                                )}>

                                {!!vehicle.runningToday && (
                                    <div className="mt-0">
                                        <span className="bg-warning text-white px-1 mr-1">
                                            !
                                        </span>
                                        <span className="text-warning font-weight-bold">
                                            {t(
                                                'LABEL.COMMON.VEHICLE.RUNNING_TODAY'
                                            )}
                                        </span>
                                    </div>
                                )}
                                {!!vehicle.noSale && (
                                    <div className="mt-0">
                                        <span className="text-danger font-weight-bold">
                                            {t(
                                                'LABEL.COMMON.VEHICLE.NO_SALE'
                                            )}
                                        </span>
                                    </div>
                                )}
                                {!vehicle.hasTitle && (
                                    <div className="mt-0">
                                        <span className="text-danger font-weight-bold">
                                            {t(
                                                'LABEL.COMMON.VEHICLE.TITLE_ABSENT'
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </Row>
                        {isDisplayRemoveButton(vehicle) && (
                            <Row>
                                <Col md={12} sm={12} xs={12} className="btn-remove mr-0 pr-0">
                                    <Link
                                        to={'javascript:void(0)'}
                                        className="font-weight-bold"
                                        onClick={onRemoveVehicleEvent}>
                                        {t(
                                            'LABEL.COMMON.VEHICLE.REMOVE'
                                        )}
                                    </Link>
                                </Col>
                            </Row>
                        )}
                    </>
                </Col>
            </Row>
                {!!vehicle.isVehicleDetailOpen && (
                    <VehicleDetail
                        isShownSellerSearchButton={isShownSellerSearchButton}
                        isVehicleDetailParticular={isVehicleDetailParticular}
                        isVehicleDetailOpen={vehicle?.isVehicleDetailOpen}
                        auctionCode={getAuctionCodeFromCookie()}
                        sblu={vehicle.sblu}
                        setGetVehicleError={setGetVehicleError}
                        setMessageUpdateVehicleSuccessful={setMessageUpdateVehicleSuccessful}
                        updateVehicleData={updateVehicleData}
                        vehicle={vehicle}
                        setInputFocused={setInputFocused}
                        isAlwaysShowUpdateParticularSuccessfulMessage={isAlwaysShowUpdateParticularSuccessfulMessage}
                    />
                )}
            </>
        )
    }

    function isVehicleUpdatedInAS400(vehicle) {
        return !isReserveVehicle(vehicle) && !isPreRegisterVehicle(vehicle) && !vehicle.draftVehicle && !vehicle.isUnsave && !isUnassign
    }

    function isDraggableVehicle(vehicle) {
        return !isReserveVehicle(vehicle) && !isPreRegisterVehicle(vehicle) && (vehicle.draftVehicle || vehicle.isUnsave || isUnassign)
    }

    function isDisplayRemoveButton(vehicle) {
        return canRemove && !isReserveVehicle(vehicle) && !isPreRegisterVehicle(vehicle) && (vehicle.draftVehicle || vehicle.isUnsave)
    }

    function successMessageToggleCallBackHandler() {
        removeMessageUpdateSuccessful()
    }

    function onRemoveVehicleEvent() {
        removeMessageUpdateSuccessful()
        handleRemoveVehicleEvent && handleRemoveVehicleEvent(
            {
                runNumber: vehicle.runNumber,
                itemIndex: dragData.itemIndex,
                laneNumber: vehicle.laneNumber,
                sblu: vehicle.sblu
            })
    }

    function removeMessageUpdateSuccessful() {
        setMessageUpdateVehicleSuccessful((prev) => {
            const newMessageUpdateVehicleSuccessful = [...(prev || [])]
            const index = newMessageUpdateVehicleSuccessful.findIndex(message => message.vin === vehicle.vin
                && message.workOrderNumber === vehicle.workOrderNumber)
            if (index > -1) {
                newMessageUpdateVehicleSuccessful.splice(index, 1)
            }

            return newMessageUpdateVehicleSuccessful
        });
    }

    const messages = useMemo(() => {
        const vehicleHasError = vehicle.errorMessage
            && (vehicle.errorMessage !== ''
            && vehicle.errorMessage !== '*')
        if (vehicleHasError) {
            return [buildMessage(vehicle.errorMessage, MessageTypeConstant.ERROR)]
        }

        const vehicleHasUpdateSuccessfulMessage = !!messageUpdateSuccessful
            && compareVehicles(messageUpdateSuccessful.vehicle, vehicle)
        if (vehicleHasUpdateSuccessfulMessage) {
            return [buildMessage((<div dangerouslySetInnerHTML={{__html: messageUpdateSuccessful.message}}/>), MessageTypeConstant.SUCCESS)]
        }

        return []
    }, [vehicle.errorMessage, messageUpdateSuccessful])

    return (
        !!vehicle && (
            <>
                <div id={`vehicle-item-${vehicle.vin}-${vehicle.workOrderNumber}-${vehicle.runNumber}`}
                    className={classNames('assign-vehicle-item',
                                {'prism-message--info': isPreRegisterVehicle(vehicle),
                                'bg-wht': isReserveVehicle(vehicle) || isUnassign || vehicle.draftVehicle,
                                'bg-sent-to-as400': isSentToAs400(vehicle),
                                'bg-cerulean': isActive,
                                'bg-dropped': (vehicle.isUnsave && !vehicle.draftVehicle),
                                'dsp-n': isDragging}
                )}>
                   <Row className="d-block">
                        <NotificationMessages
                            messages={messages}
                            onSuccessMessageToggleCallBack={successMessageToggleCallBackHandler}
                        />
                   </Row>

                   <div ref={allowDrop ? drop : null}>
                        {/* SHOW RESERVED RECORD */}
                        {isReserveVehicle(vehicle) && (
                            <Row>
                                <Col md={6} sm={6} xs={6}>
                                    <Container fluid>
                                        <Row>
                                            <div>
                                                <span
                                                    title={t(
                                                        'LABEL.REMAINING_INVENTORY.VEHICLE_TITLE'
                                                    )}
                                                    className="vehicle-title">
                                                    {t(
                                                        'LABEL.COMMON.VEHICLE.RESERVED'
                                                    )}
                                                </span>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="mt-0">
                                                {displayFormattedVehicleSellerInfo()}
                                            </div>
                                        </Row>
                                        <Row></Row>
                                    </Container>
                                </Col>
                                <Col md={3} sm={3} xs={3}>
                                    <Container fluid>
                                        <Row>
                                            {vehicle.hasGatePass}
                                            {vehicle.hasGatePass && (
                                                <Col className="mr-0">
                                                    <VehicleTextIcon
                                                        text={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.GATE_PASS.TEXT'
                                                        )}
                                                        title={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.GATE_PASS.TITLE'
                                                        )}
                                                        bgClass="bg-success"
                                                        fgClass="text-white font-weight-bold"
                                                    />
                                                </Col>
                                            )}
                                            {vehicle.hasProblem && (
                                                <Col className="mr-0">
                                                    <VehicleTextIcon
                                                        text={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.PROBLEM.TEXT'
                                                        )}
                                                        title={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.PROBLEM.TITLE'
                                                        )}
                                                        bgClass={classNames(
                                                            !!vehicle.problemAlert
                                                                ? 'bg-danger'
                                                                : 'bg-warning'
                                                        )}
                                                        fgClass="text-white font-weight-bold"
                                                        className="mb-1"
                                                    />
                                                </Col>
                                            )}
                                            {vehicle.hasIfSaleAlert && (
                                                <Col className="mr-0">
                                                    <VehicleTextIcon
                                                        text={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.IF_SALE_ALERT.TEXT'
                                                        )}
                                                        title={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.IF_SALE_ALERT.TITLE'
                                                        )}
                                                        bgClass="bg-danger"
                                                        fgClass="text-white font-weight-bold"
                                                        className="mb-1"
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </Container>
                                </Col>
                                <Col md={3} sm={3} xs={3} className="pr-3">
                                    <Row>
                                        <div className="mt-0 w-100 text-right">
                                            <span className="text-muted font-weight-bold current-ran">
                                                {String(vehicle.laneNumber).padStart(2, '0') + '-' + String(vehicle.runNumber).padStart(3, '0')}
                                            </span>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="mt-0 w-100 text-right">
                                            <a
                                                mauto="maPreRegisterIcon"
                                                href="javascript:void(0)"
                                                className="font-weight-bold"
                                                onClick={() => handleOnClickOpenPregistTration(vehicle.sblu)}>
                                                {t(
                                                    'LABEL.COMMON.VEHICLE.PRE_REGISTRATION'
                                                )}
                                            </a>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        )}

                        {/* SHOW PREREGISTER RECORD */}
                        {isPreRegisterVehicle(vehicle) && (
                            <Row>
                                <Col md={6} sm={6} xs={6}>
                                    <Container fluid>
                                        <Row>
                                            <div className="">
                                                <span
                                                    title={t(
                                                        'LABEL.REMAINING_INVENTORY.VEHICLE_TITLE'
                                                    )}
                                                    className="text-secondary vehicle-title cursor-pointer"
                                                    onClick={() => {
                                                        handleOnClickOpenPregistTration(vehicle.sblu, false, getPreregisteredTitle(vehicle))
                                                    }}>
                                                    {t('LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.PRE_REGISTERED')} - {preregisteredVehicleDescription(vehicle)}
                                                </span>
                                            </div>
                                        </Row>
                                        <Row className='vin-work-order-group'>
                                            <Col md={10} sm={10} xs={10} className="mt-0 px-0 vehicle-vin mr-2">
                                                {allowQuickSearch && index === quickSearchIndex ? (
                                                    getVehicleFormattedVinWithHighlightText(vehicle, quickSearchText)
                                                ) : (
                                                    <div dangerouslySetInnerHTML={{__html: getVehicleFormattedVin(vehicle)}}></div>
                                                )
                                                }
                                            </Col>
                                            <Col md={2} sm={2} xs={2} className="mt-0 pl-0 text-nowrap">
                                                <span className="text-muted">
                                                    {`${t('LABEL.COMMON.VEHICLE.WORK_ORDER_NUMBER')} `}
                                                </span>
                                                <span className="font-weight-bold">
                                                    {allowQuickSearch && index === quickSearchIndex ? (
                                                        <HighlightText
                                                            text={getFormattedWo(vehicle)}
                                                            highlight={quickSearchText}
                                                        />
                                                    ) : (
                                                        getFormattedWo(vehicle)
                                                    )
                                                    }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="mt-0 seller-info">
                                                {displayFormattedVehicleSellerInfo()}
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col className="mt-0 left-align">
                                                <div>
                                                    <span className="mr-2">
                                                        {vehicle.status}
                                                    </span>
                                                    <Link
                                                        to={'javascript:void(0)'}
                                                        className="font-weight-bold"
                                                        onClick={() => {
                                                            handleVcfOnClick(
                                                                vehicle.sblu
                                                            )
                                                        }}>
                                                        {t(
                                                            'LABEL.COMMON.VEHICLE.VCF'
                                                        )}
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col md={3} sm={3} xs={3} className="pl-0 pr-0">
                                    <Container fluid>
                                        <Row>
                                            <div className="vehicle-odometer">
                                                <span className="text-muted mr-1">
                                                    {t('LABEL.COMMON.VEHICLE.ODOMETER')}
                                                </span>
                                                <Chip color="light" bold>
                                                    {getFormattedVehicleOdometer(
                                                        vehicle
                                                    )}
                                                </Chip>
                                            </div>
                                        </Row>
                                        <Row className="float-left">
                                            {vehicle.hasGatePass && (
                                                <Col className="pl-0 ml-0 mr-0 mt-0">
                                                    <VehicleTextIcon
                                                        text={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.GATE_PASS.TEXT'
                                                        )}
                                                        title={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.GATE_PASS.TITLE'
                                                        )}
                                                        bgClass="bg-success"
                                                        fgClass="text-white font-weight-bold"
                                                        className="mb-1"
                                                    />
                                                </Col>
                                            )}
                                            {vehicle.hasProblem && (
                                                <Col className="pl-0 ml-0 mr-0 mt-0">
                                                    <VehicleTextIcon
                                                        text={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.PROBLEM.TEXT'
                                                        )}
                                                        title={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.PROBLEM.TITLE'
                                                        )}
                                                        bgClass={classNames(
                                                            !!vehicle.problemAlert
                                                                ? 'bg-danger'
                                                                : 'bg-warning'
                                                        )}
                                                        fgClass="text-white font-weight-bold"
                                                        className="mb-1"
                                                    />
                                                </Col>
                                            )}
                                            {vehicle.hasIfSaleAlert && (
                                                <Col className="pl-0 ml-0 mr-0 mt-0">
                                                    <VehicleTextIcon
                                                        text={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.IF_SALE_ALERT.TEXT'
                                                        )}
                                                        title={t(
                                                            'LABEL.COMMON.VEHICLE.ICON.IF_SALE_ALERT.TITLE'
                                                        )}
                                                        bgClass="bg-danger"
                                                        fgClass="text-white font-weight-bold"
                                                        className="mb-1"
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </Container>
                                </Col>
                                <Col md={3} sm={3} xs={3} className="pl-1 pr-3">
                                    {!!vehicle.previouslyRan && (
                                        <Row>
                                            <div className={classNames('mt-0', 'w-100', 'text-right')}>
                                                <span className="text-muted mr-1">
                                                    {t('LABEL.COMMON.VEHICLE.RAN')}
                                                </span>
                                                <span>
                                                    {vehicle.previousLaneAndRun}
                                                </span>
                                                <span className="mx-1">x</span>
                                                <span>
                                                    {vehicle.timesThroughLane}
                                                </span>
                                            </div>
                                        </Row>
                                    )}
                                    <Row>
                                        <div className={"mt-0 w-100 text-right"}>
                                        {!isUnassign &&(
                                                <span className="text-muted font-weight-bold current-ran">
                                                {String(vehicle.laneNumber).padStart(2, '0') + '-' + String(vehicle.runNumber).padStart(3, '0')}
                                                </span>
                                        )}
                                            </div>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>

                    {/* SHOW RECORD WHICH SENT TO AS400 */}
                    {isVehicleUpdatedInAS400(vehicle) && (
                        renderVehicleComponent(vehicle)
                    )}

                    {/* SHOW DRAF RECORD */}
                    {isDraggableVehicle(vehicle) && (
                    <div ref={allowDrag ? drag : null}  className={classNames({'dragable': !isEmpty(dragData.dragType)})}>
                        {renderVehicleComponent(vehicle)}
                    </div>
                    )}
                </div>
            </>
        )
    )
}
