import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { SlideOut, Button, Container, Row, Col} from '@prism/library'
import { getVehicleVcfs, updateVehicleVcf } from '../../../utils/data-source/vehicle-datasource'
import {handleErrorException, isBlank} from '../../../utils/utils'
import { VehicleVcfItem } from './VehicleVcfItem'
import {first, isEmpty, isNil} from 'lodash'
import { VCF_CONSTANT } from '../../../utils/constant'
import { NotificationMessage } from '../notification-message/NotificationMessage'
import { useToggleAS400 } from '../../../hooks/common/UseToggleAS400'

import './vehicle-vcf-edit.scss'

export function VehicleVcfEdit({
                                   isVcfEditOpen,
                                   setIsVcfEditOpen,
                                   auctionCode,
                                   sbluNumber
                               }) {
    const {t} = useTranslation()
    const vcfRef = useRef(null)

    const {isEnableUpdateAS400Flag} = useToggleAS400(auctionCode)
    const [vehicleVcfData, setVehicleVcfData] = useState([])
    const [remainingCategory, setRemainingCategory] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [categoriesHasChanged, setCategoriesHasChanged] = useState({})
    const [errorMessages, setErrorMessages] = useState([])
    const [hasChanged, setHasChanged] = useState(false)

    useEffect(() => {
        getVehicleVcfs(auctionCode, sbluNumber, VCF_CONSTANT.TYPE).then((response) => {
            const responseData = response.data
            //getSavedData
            const data = getVehicleVcfWithStatus(responseData)
            const savedCategories = getCategoryList(data)
            setAllCategories(responseData)

            //vehicle data to render
            if (!isEmpty(data)) {
                setVehicleVcfData(data)
            } else {
                const placeHolder = buildNewCategory()
                setVehicleVcfData([placeHolder])
            }

            //Check remaining category
            const remainingCategories = responseData.filter((element) => !savedCategories.includes(element.categoryName))
            setRemainingCategory(remainingCategories)

        }).catch(() => {
            setIsVcfEditOpen(false)
        })
    }, [isVcfEditOpen])

    function handleAddNewCategory() {
        const newCategory = buildNewCategory()
        setVehicleVcfData([...vehicleVcfData, newCategory])
    }

    function getVehicleVcfWithStatus(data) {
        const savedData = data.filter(item => !!item.statusCode)
        return savedData.map(item => {
            return {...item, isSaved: true}
        })
    }

    function getCategoryList(data) {
        return data.map(item => item.categoryName)
    }

    function updateCategoryHasChanged(category) {
        //Add category to change list
        let categories = categoriesHasChanged
        let categoryName = first(Object.keys(category))
        categories[categoryName] = category[categoryName]
        setCategoriesHasChanged(categories)
        updateRemainingCategories()
    }

    function removeCategoryHasChanged(categoryName) {
        //Remove category from change list
        let categories = categoriesHasChanged
        delete categories[categoryName]
        setCategoriesHasChanged(categories)
    }

    function updateRemainingCategories() {
        //Remove category from change list
        if (isEmpty(categoriesHasChanged)) {
            return
        }
        const changeList = Object.keys(categoriesHasChanged)
        const remainingCategories = allCategories.filter((element) => !changeList.includes(element.categoryName))
        setRemainingCategory(remainingCategories)
    }

    function getRemainingCategories() {
        //Remove category from change list
        if (isEmpty(categoriesHasChanged)) {
            return allCategories
        }
        const changeList = Object.keys(categoriesHasChanged)
        const remainingItems = allCategories.filter((element) => !changeList.includes(element.categoryName))
        return remainingItems
    }

    function validateBeforeSubmit(request) {
        let errorMessages = []
        const categories = request.vcf
        Object.keys(categories).map(category => {
            if(isNil(categories[category]) || isBlank(categories[category].statusCode.new)) {
                const message = `${category}: ${t('LABEL.COMMON.EDIT_VCF.ERROR')}`
                errorMessages.push(message)
            }
        })

        return errorMessages
    }

    const scrollToErrorMessage = () => {
        if (vcfRef.current) {
            vcfRef.current.scrollIntoView()
        }
    }

    function handleUpdateVcf() {
        //Delete null key if existed
        delete categoriesHasChanged[null]
        const request = {vcf: {...categoriesHasChanged}, auctionCode: auctionCode, sblu: sbluNumber}

        //Check request status code is not blank before submit
        const errors = validateBeforeSubmit(request)
        if (!isEmpty(errors)) {
            setErrorMessages(errors)
            return
        }

        updateVehicleVcf(request).then((response) => {
            //close slide out
            setIsVcfEditOpen(false)
        }).catch((ex) => {
            //Handle show error messages
            let errors = handleErrorException(ex)
            setErrorMessages(errors.message.split(';'))
        })
    }

    function buildNewCategory() {
        return {
            sblu: `${sbluNumber}`,
            categoryName: "",
            categoryType: "",
            statusCode: "",
            team: "",
            statusDate: "",
            primaryNote: "",
            secondaryNote: "",
            isManual: "",
            statusChoices: []
        }
    }

    useEffect(() => {
        if (!isEmpty(errorMessages)) {
            scrollToErrorMessage()
        }
    }, [errorMessages])

    return (
        <>
            <Container className="prism-overridden vehicle-vcf-edit">
                <SlideOut
                    actionable={true}
                    onCloseButtonClick={() => {
                        setIsVcfEditOpen(false)
                    }}
                    open={isVcfEditOpen}
                    placement="right"
                    headerText={t(
                        'LABEL.COMMON.VEHICLE_VCF_TABLE.TABLE_TITLE'
                    )}>

                    {!isEmpty(errorMessages) && (
                        <div ref={vcfRef}>
                            <NotificationMessage type={'error'} isOpen={true} className='error-message mr-0 ml-0'>
                                {
                                    errorMessages.map(mess => {
                                        return (
                                            <div>{mess}</div>
                                        )
                                    })
                                }
                            </NotificationMessage>
                        </div>

                    )}

                    {!!vehicleVcfData && vehicleVcfData.map((item) => {
                        return (
                            <VehicleVcfItem
                                vcfData={item}
                                remainingCategories={remainingCategory}
                                updateRemainingCategories={updateRemainingCategories}
                                updateCategoryHasChanged={updateCategoryHasChanged}
                                removeCategoryHasChanged={removeCategoryHasChanged}
                                getRemainingCategories={getRemainingCategories}
                                setHasChanged={setHasChanged}
                            />
                        )
                    })}

                    <div className="row pb-5 add-new-category-button">
                        <div className="flex-sm-row-reverse control-buttons">
                            <Button
                                variant="textonly"
                                className="m-2"
                                mauto="maButtonAddCategory"
                                disabled={vehicleVcfData.length >= allCategories.length}
                                onClick={(e) => {
                                    handleAddNewCategory()
                                    e.currentTarget.blur()
                                }}
                                size="sm">
                                <span className={"mr-1"}><i className="icon prism-icon-plus"/></span>
                                {t('LABEL.COMMON.EDIT_VCF.ADD_ANOTHER_CATEGORY')}
                            </Button>
                        </div>
                    </div>

                    <Row className="ml-1 mt-1 update-vcf-footer">
                        <Col md={12} sm={12} xl={12} className="pl-0 update-vcf-col">
                            <Button
                                className="mt-2 mb-2 ml-2"
                                mauto="maButtonUpdateVcf"
                                disabled={!isEnableUpdateAS400Flag || !hasChanged}
                                onClick={() => {
                                    handleUpdateVcf()
                                }}
                                size="sm"
                                color="primary">
                                {t('LABEL.COMMON.EDIT_VCF.UPDATE')}
                            </Button>
                        </Col>
                    </Row>
                </SlideOut>
            </Container>

        </>
    )
}
