import {MessageTypeConstant} from "../../../utils/constant";
import {NotificationMessage} from "../notification-message/NotificationMessage";
import React, {useEffect, useMemo} from "react";
import {sortBy} from "lodash/collection";
import {isEmpty, orderBy} from "lodash";
import {SortDirection} from "react-virtualized";

export default function NotificationMessages({
    messages,
    onInformationMessageToggleCallBack,
    onWarningMessageToggleCallBack,
    onErrorMessageToggleCall,
    onSuccessMessageToggleCallBack
}) {

    const messageTypeCallBacks = useMemo(() => {
        return {
            [MessageTypeConstant.INFO]: onInformationMessageToggleCallBack,
            [MessageTypeConstant.WARNING]: onWarningMessageToggleCallBack,
            [MessageTypeConstant.ERROR]: onErrorMessageToggleCall,
            [MessageTypeConstant.SUCCESS]: onSuccessMessageToggleCallBack,
        }
    }, [messages])

    // sort messages by type
    const messageSorted = useMemo(() => {
        return sortBy(messages, (element) => {
            const rank = {
                [MessageTypeConstant.INFO] : 0,
                [MessageTypeConstant.WARNING] : 1,
                [MessageTypeConstant.ERROR] : 2,
                [MessageTypeConstant.SUCCESS] : 3,
            }

            return rank[element.type]
        })
    }, [messages])

    return (
        <>
            { !isEmpty(messageSorted) &&
                messageSorted.map((message, index) =>
                    <NotificationMessage
                        isOpen={true}
                        key={index}
                        type={message.type}
                        onToggleCallBack={messageTypeCallBacks[message.type]}>
                        {message.message}
                    </NotificationMessage>
                )
            }
        </>
    )
}