import React, { useCallback } from 'react'
import { Button, Input, Form, Row, Container } from '@prism/library'
import { t } from 'i18next'
import { isEqual, toUpper } from 'lodash'
import {
    ConstraintConstant,
    SellerSearchTypeConstant
} from '../../../utils/constant'
import './show-inventory-picker.scss'
import { handleEventOnKeyDownForNumberAlphabet } from '../../../utils/utils'

export function ShowInventoryGroup({
    selectedShowInventoryBy,
    groupCode,
    handleOnClick,
    setGroupCode,
    isDisableButtonGo,
    updateIsDisableButtonGo,
    setIsEnableFilterByGroup
}) {
    function handleOnChangeGroupCode(value) {
        setGroupCode(value)
        if (value.length === 0) {
            updateIsDisableButtonGo(true)
            setIsEnableFilterByGroup(false)
        } else {
            updateIsDisableButtonGo(false)
            setIsEnableFilterByGroup(true)
        }
    }

    return (
        <>
            <Form
                className="input-inventory-group"
                onSubmit={(e) => {
                    handleOnClick(toUpper(groupCode))
                    e.preventDefault()
                }}>
                <Container fluid className="p-0">
                    <Row className='flex-nowrap'>
                        <Input
                            className="prism-input ml-2"
                            name="groupCode"
                            placeholder={
                                isEqual(
                                    selectedShowInventoryBy,
                                    SellerSearchTypeConstant.GROUP_CODE
                                )
                                    ? t(
                                          'LABEL.REMAINING_INVENTORY.INVENTORY_PICKER.INPUT_GROUP_CODE'
                                      )
                                    : t(
                                          'LABEL.REMAINING_INVENTORY.INVENTORY_PICKER.INPUT_ALT_GROUP_CODE'
                                      )
                            }
                            maxLength={ConstraintConstant.GROUP_CODE_MAX_LENGTH}
                            onChange={(e) => {
                                handleOnChangeGroupCode(e.target.value.trim())
                            }}
                            value={groupCode}
                            mauto="maInputGroupCode"
                        />
                        <Button
                            className="ml-1"
                            size="sm"
                            color="primary"
                            mauto="maBtnGo"
                            type="submit"
                            disabled={isDisableButtonGo}>
                            {t('LABEL.REMAINING_INVENTORY.INVENTORY_PICKER.GO')}
                        </Button>
                    </Row>
                </Container>
            </Form>
        </>
    )
}
