import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { SlideOut } from '@prism/library'
import { getVehicleVcfs } from '../../../utils/data-source/vehicle-datasource'
import { convertDateFromAS400, displayString } from '../../../utils/utils'

import './vehicle-vcf-view.scss'

export function VehicleVcf({
    isVcfOpen,
    setIsVcfOpen,
    auctionCode,
    sbluNumber
}) {
    const { t } = useTranslation()

    const [vehicleVcfs, setVehicleVcfs] = useState([])
    useEffect(() => {
        getVehicleVcfs(auctionCode, sbluNumber).then((response) => {
            setVehicleVcfs(response.data)
        }).catch(() => { setIsVcfOpen(false) })
    }, [isVcfOpen])

    return (
        <>
            {!!vehicleVcfs && vehicleVcfs.length > 0 && (
                <div className="prism-overridden vehicle-vcf-table">
                    <SlideOut
                        actionable={true}
                        onCloseButtonClick={() => {
                            setIsVcfOpen(false)
                        }}
                        triggerClassName={`d-none`}
                        open={isVcfOpen}
                        placement="right"
                        headerText={t(
                            'LABEL.COMMON.VEHICLE_VCF_TABLE.TABLE_TITLE'
                        )}>
                        <div id="vehicleVcf" className='table-sticky-wrapper'>
                            <table
                                className={classNames('table table-borderless')}
                                mauto="maTblVCFTable">
                                <thead
                                    className={classNames(
                                        'table-head sticky-top table-single-sort-row-label'
                                    )}>
                                    <tr>
                                        <th mauto="maThCategory">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.COMMON.VEHICLE_VCF_TABLE.COLUMN_HEADER.CATEGORY'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="maThStatus">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.COMMON.VEHICLE_VCF_TABLE.COLUMN_HEADER.STATUS'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="maThTeam">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.COMMON.VEHICLE_VCF_TABLE.COLUMN_HEADER.TEAM'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="maThDate">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.COMMON.VEHICLE_VCF_TABLE.COLUMN_HEADER.DATE'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="maThNotes">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.COMMON.VEHICLE_VCF_TABLE.COLUMN_HEADER.NOTES'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="maThECRDtl">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.COMMON.VEHICLE_VCF_TABLE.COLUMN_HEADER.ECR'
                                                )}
                                            </span>
                                        </th>
                                        <th mauto="maThManual">
                                            <span className="font-weight-bold">
                                                {t(
                                                    'LABEL.COMMON.VEHICLE_VCF_TABLE.COLUMN_HEADER.MANUAL'
                                                )}
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!vehicleVcfs &&
                                        vehicleVcfs.map((item) => {
                                            return (
                                                <tr
                                                    key={item.categoryName}
                                                    mauto="maTrResult">
                                                    <td
                                                        mauto="maTdCategory"
                                                        className="border-0">
                                                        {displayString(
                                                            item.categoryName
                                                        )}
                                                    </td>
                                                    <td
                                                        mauto="maTdStatus"
                                                        className="border-0">
                                                        {displayString(
                                                            [
                                                                item.statusCode,
                                                                item.statusDescription
                                                            ].join(' ')
                                                        )}
                                                    </td>
                                                    <td
                                                        mauto="maTdTeam"
                                                        className="border-0">
                                                        {displayString(
                                                            item.team
                                                        )}
                                                    </td>
                                                    <td
                                                        mauto="maTdDate"
                                                        className="border-0">
                                                        {convertDateFromAS400(
                                                            item.statusDate,
                                                            'MM/DD/YYYY'
                                                        )}
                                                    </td>
                                                    <td
                                                        mauto="maTdNotes"
                                                        className="border-0">
                                                        {displayString(
                                                            [
                                                                item.primaryNote,
                                                                item.secondaryNote
                                                            ].join(' ')
                                                        )}
                                                    </td>
                                                    <td
                                                        mauto="maTdEcr"
                                                        className="border-0">
                                                        {displayString(
                                                            item.ecrDetail
                                                        )}
                                                    </td>
                                                    <td
                                                        mauto="maTdManual"
                                                        className="border-0">
                                                        {displayString(
                                                            item.isManual
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </SlideOut>
                </div>
            )}
        </>
    )
}
