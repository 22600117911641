import {AssignInventoryVehicleItem} from '../assign-inventory/AssignInventoryVehicleItem'
import {handleNotificationMessage} from '../../utils/utils'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isNull } from 'lodash'

export default function RemainingVehicleItem({
    itemIndex,
    vehicle,
    handleTitleClicking,
    setMessageUpdateVehicleSuccessful,
    handleVcfOnClick,
    handleGetVehicleError,
    updateVehicleData
}) {
    const { t } = useTranslation()

    // Notify change to every single vehicle instead of list to prevent unnecessary re-rendering of whole list
    const { updatedVehicle } = useSelector(states => states.remainingInventory)
    const { getVehicleError } = useSelector(states => states.remainingInventory)

    // Only re-render the component if the vehicle is updated
    const isVehicleUpdated = useMemo(() => {
        return !isNull(updatedVehicle)
            && updatedVehicle.vin === vehicle.vin
            && updatedVehicle.workOrderNumber === vehicle.workOrderNumber
            && updatedVehicle.sblu === vehicle.sblu;
    }, [updatedVehicle, vehicle])

    return (
        <>
            <RemainingVehicleItemClass
                key={itemIndex}
                vehicle={vehicle}
                handleTitleClicking={handleTitleClicking}
                setMessageUpdateVehicleSuccessful={setMessageUpdateVehicleSuccessful}
                handleVcfOnClick={handleVcfOnClick}
                getVehicleError={getVehicleError}
                isVehicleUpdated={isVehicleUpdated}
                updatedVehicle={updatedVehicle}
                handleGetVehicleError={handleGetVehicleError}
                updateVehicleData={updateVehicleData}
                t={t}
            />
        </>

    )
}

// Extend from React Component allow us to override React component lifecycle methods
class RemainingVehicleItemClass extends React.Component {
    constructor(props) {
        super(props)
        this.state = {...props}
    }

    // Override react component method to Prevent unnecessary re-rendering of the component
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.isVehicleUpdated
            || this.isGetVehicleHasError(nextProps.getVehicleError)
            || this.removeGetVehicleHasError(nextProps.getVehicleError)
    }

    isGetVehicleHasError = (error) => {
        return error && error.hasError && error?.vin === this.props.vehicle.vin
    }

    removeGetVehicleHasError = (error) => {
        return error
            && error.prev
            && error.vin !== error.prev.vin
            && error.prev?.vin === this.props.vehicle.vin
    }

    render() {
        return (
            <>
                <AssignInventoryVehicleItem
                    key={this.props.itemIndex}
                    vehicle={this.props.isVehicleUpdated ? this.props.updatedVehicle : this.props.vehicle}
                    dragData={{dragType: ''}}
                    canRemove={false}
                    isUnassign={true}
                    handleTitleClicking={this.props.handleTitleClicking}
                    setMessageUpdateVehicleSuccessful={this.props.setMessageUpdateVehicleSuccessful}
                    handleVcfOnClick={this.state.handleVcfOnClick}
                    setGetVehicleError={this.props.handleGetVehicleError}
                    isLaneManagement={false}
                    allowDrag={false}
                    allowDrop={false}
                    updateVehicleData={this.props.updateVehicleData}
                    isShownSellerSearchButton={true}
                />

                {this.isGetVehicleHasError(this.props.getVehicleError) && (
                    handleNotificationMessage(this.props.t('MESSAGE.COMMON.GET_VEHICLE_DETAILS_ERROR'))
                )}
            </>
        )
    }
}