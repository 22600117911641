import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import { includes, isEmpty, isNil, orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { VehicleVcf } from '../common/vehicle-info/VehicleVcf'
import { AssignInventoryVehicleItem } from './AssignInventoryVehicleItem'
import { PreRegistration } from '../common/pre-registration/PreRegistration'
import { EmptyRun } from './EmptyRun'
import {
    DnDDragTypeConstant,
    EMPTY_STRING,
    RUN_LANE_MAX_LENGTH,
    RUN_LANE_NUMBER_OF_SHOW_MORE
} from '../../utils/constant'
import {isPreRegisterVehicle, isReserveVehicle, isSentToAs400} from '../../utils/vehicle-utils'
import { getPreRegistrations } from '../../utils/data-source/pre-registrations-datasource'
import DynamicSizeList from '../common/dynamic-size-list/DynamicSizeList'
import AssignVehicleListFooter from './AssignVehicleListFooter'
import classNames from 'classnames'
import { useVehicleDraggingMonitor } from '../../hooks/assign-inventory/UseVehicleDraggingMonitor'
import { useDispatch, useSelector } from 'react-redux'
import { getVehicleAfterUpdatePreRegist } from '../../actions/PreRegistAction'
import usePushQuickSearchValue from '../../hooks/common/UsePushQuickSearchValue'
import compareVehicles, { resetVehicleDetailInput } from './helper/VehicleHelper'
import { VehicleVcfEdit } from '../common/vehicle-info/VehicleVcfEdit'

export function AssignVehicleList({
    auctionCode,
    isShowChecked,
    draftVehicles,
    setDraftVehicles,
    laneInventory,
    setLaneInventory,
    runRanges,
    laneNumber,
    setIsGetPreRegistError,
    bulkUpdateVehicleErrors,
    scrollToItem
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [emptyRuns, setEmptyRuns] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [sblu, setSblu] = useState(null)
    const [isVcfOpen, setIsVcfOpen] = useState(false)
    const [isVcfEditOpen, setIsVcfEditOpen] = useState(false)
    const [messageUpdateVehicleSuccessful, setMessageUpdateVehicleSuccessful] = useState([])
    const [isPreRegistrationOpen, setIsPreRegistrationOpen] = useState(false)
    const [preRegistrationData, setPreRegistrationData] = useState(null)
    const [holderVehicles, setHolderVehicles] = useState(new Map())
    const [titleVehicle, setTitleVehicle] = useState(null)
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const { isDragging, draggingItem } = useVehicleDraggingMonitor()
    const assignedVehiclesRef = useRef(null)
    const { search } = useSelector(states => states.resetStateObservingContext.assignInventory);
    const quickSearchContext = useSelector(states => states.inventoryQuickSearchContext.quickSearchContext)
    const { pushQuickSearchValue } = usePushQuickSearchValue()

    useEffect(() => {
        setMessageUpdateVehicleSuccessful([])
    }, [search])

    useEffect(() => {
        initRuns()
    }, [runRanges])

    // Reset show all when next available button state is false
    useEffect(() => {
        if (!isShowChecked) {
            const emptySpots = buildEmptySpots()
            setEmptyRuns(emptySpots)
            const vehicleWithoutEmptySpots = vehicles.filter(vehicle => vehicle.status !== "EMPTY")
            initVehicles(vehicleWithoutEmptySpots, emptySpots)
        }
    }, [isShowChecked])

    function initRuns() {
        var emptySpots = buildEmptySpots()
        setEmptyRuns(emptySpots)

        laneInventory.forEach((vehicle) => {
            if (isPreRegisterVehicle(vehicle) || isReserveVehicle(vehicle) || isSentToAs400(vehicle)) {
                holderVehicles.set(vehicle.runNumber, vehicle)
            }
        })
        setHolderVehicles(holderVehicles)

        let laneVehicles = laneInventory.filter((l)=>{
            return draftVehicles.map((d)=>d.runNumber).indexOf(l.runNumber) == -1
        })

        let mergedLaneVehicles = []
        draftVehicles.forEach(draftVehicle => {
            let newVehicle = {...draftVehicle}
            newVehicle.replacedItem = laneInventory.find(p => p.runNumber === newVehicle.runNumber)
            mergedLaneVehicles.push(newVehicle)
        })

        setDraftVehicles([...mergedLaneVehicles])

        let orderedVehicleList = [
            ...laneVehicles,
            ...mergedLaneVehicles
        ].sort((a, b) => a.runNumber - b.runNumber)

        initVehicles(orderedVehicleList, emptySpots)
    }

    function buildEmptySpots() {
        var emptySpots = []
        for (var i = 0; i < runRanges.length; i++) {
            if (runRanges[i].type === 'EMPTY') {
                for (
                    var j = runRanges[i].startingRunNumber;
                    j <= runRanges[i].endingRunNumber;
                    j++
                ) {
                    emptySpots.push(j)
                }
            }
        }
        return emptySpots
    }

    function initVehicles(orderedVehicleList, emptySpots) {
        var updatedList = []
        let lastEmptySpotPosition = 0
        let last_vehicle_position = 0
        while (last_vehicle_position < orderedVehicleList.length) {
            var vehicle = orderedVehicleList[last_vehicle_position]
            vehicle = {...vehicle,
                fromVehicle: {
                    saleYear: vehicle.origSaleYear,
                    saleNumber: vehicle.origSaleNumber,
                    laneNumber: vehicle.origLaneNumber,
                    runNumber: vehicle.origRunNumber
                }
            }
            var emptySpot = emptySpots[lastEmptySpotPosition]
            if (vehicle && Number(emptySpot) < Number(vehicle.runNumber)) {
                updatedList.push({status: "EMPTY", runNumber: emptySpot + ''})
                lastEmptySpotPosition++
            } else if (
                vehicle &&
                Number(emptySpot) > Number(vehicle.runNumber)
            ) {
                updatedList.push(vehicle)
                last_vehicle_position++
            } else {
                updatedList.push(vehicle)
                lastEmptySpotPosition++
                last_vehicle_position++
            }
        }

        addEmptyRuns(updatedList, emptySpots, lastEmptySpotPosition, RUN_LANE_NUMBER_OF_SHOW_MORE)
        setVehicles(updatedList)
    }

    function addEmptyRuns(updatedList, emptySpots, lastEmptySpotPosition, numberOfRun) {
        Array.from({length: numberOfRun}, (_, i) => {
            if(Number(emptySpots[lastEmptySpotPosition + i]) <= RUN_LANE_MAX_LENGTH) {
                updatedList.push({status: "EMPTY", runNumber: emptySpots[lastEmptySpotPosition + i] + ''})
            } else {
                return
            }
        })
    }

    function updateVehicleData(newVehicleData) {
        updateElementInList(vehicles, setVehicles, newVehicleData)
        updateElementInList(laneInventory, setLaneInventory, newVehicleData)
        updateElementInList(draftVehicles, setDraftVehicles, newVehicleData)

        window.updateVehiclesInAssignInventory(newVehicleData)
    }

    function updateElementInList(list, setList, newVehicleData) {
        let existedVehicle = list.find(p => compareVehicles(p, newVehicleData))
        if (existedVehicle) {
            resetVehicleDetailInput(list)
            let index = list.indexOf(existedVehicle)
            existedVehicle = {...existedVehicle, ...newVehicleData}
            list[index] = existedVehicle
            setList((oldVehicles) =>  [...list])
        }
    }

    //#region Drag and Drop
    /**
     * Replace the vehicle in the Assigned column after user drag and drop the vehicle
     */
    function updateAssignedVehicleAfterDrop(dragData, dropData) {
        let vehicle = dragData.vehicle
        //Collapse vehicleDetail after perform drop
        vehicle.isVehicleDetailOpen = false

        //Update draftVehicles for save
        let destinationRun = vehicles.find(p => p.runNumber === dropData.runNumber && p.laneNumber === dropData.laneNumber)
        let newVehicle = { ...vehicle, laneNumber: laneNumber, runNumber: destinationRun.runNumber, isUnsave: true, replacedItem: destinationRun, errorMessage: null }
        window.updateDraftVehicles(newVehicle)

        setVehicles((oldVehicles) => {
            let newVehicles = [...oldVehicles]
            let oldPosition = newVehicles.findIndex(p => compareVehicles(p, vehicle))
            let newPosition = oldVehicles.findIndex(p => p.runNumber === dropData.runNumber && p.laneNumber === dropData.laneNumber)

            //The destination item after dropped
            let destinationRun = newVehicles[newPosition]

            //The vehicle data with new runNumber
            let newVehicle = { ...vehicle, laneNumber: laneNumber, runNumber: destinationRun.runNumber, isUnsave: true, replacedItem: destinationRun, errorMessage: null }
            let oldVehicle

            const isHolderVehicle = holderVehicles.has(vehicle.runNumber)
            if(isHolderVehicle) {
                oldVehicle = holderVehicles.get(vehicle.runNumber)
            } else {
                oldVehicle = {status: "EMPTY", runNumber: vehicle.runNumber}
            }

            newVehicles[oldPosition] = oldVehicle
            newVehicles[newPosition] = newVehicle
            return newVehicles
        })

        //Remove the vehicle in Unassigned column if user drag vehicle from there
        window.removeUnassignedVehicleAfterDnD({vin: vehicle.vin, workOrderNumber: vehicle.workOrderNumber, sblu: vehicle.sblu})
        pushQuickSearchValue(EMPTY_STRING)
    }

    /**
     * Move the vehicle from Assigned to Unassigned column when user click on button REMOVE
     */
    function handleRemoveVehicleEvent(data) {
        let itemIndex =  vehicles.findIndex(p => p.runNumber === data.runNumber
                                                    && p.laneNumber === data.laneNumber
                                                    && p.sblu === data.sblu)
        let vehicle = vehicles[itemIndex]

        let {isUnsave, ...restVehicle} = vehicle
        restVehicle = {...restVehicle, draftVehicle: false, errorMessage: null, isVehicleDetailOpen: false}
        window.putUnassignedVehicleAfterDnD(restVehicle)
        window.resetUnassignVehicleCheckedStatus(restVehicle.checked)
        window.resetSortCriterias()

        //Remove the vehicle from draft list
        window.removeFromDraftVehicles(vehicle)

        const newVehicles = [...vehicles]
        const isHolderVehicle = holderVehicles.has(vehicle.runNumber)
        if(isHolderVehicle) {
            newVehicles[itemIndex] = holderVehicles.get(vehicle.runNumber)
        } else {
            newVehicles[itemIndex] = {status: "EMPTY", runNumber: data.runNumber}
        }

        setVehicles(newVehicles)
        pushQuickSearchValue(EMPTY_STRING)
    }

    //#endregion

    function handleShowMoreEmptyRuns() {
        addEmptyRunToExistingVehicles(RUN_LANE_NUMBER_OF_SHOW_MORE)
    }

    function handleShowAllEmptyRuns() {
        addEmptyRunToExistingVehicles(RUN_LANE_MAX_LENGTH)
    }

    function addEmptyRunToExistingVehicles(numberOfRun) {
        setVehicles((oldVehicles) => {
            let newVehicles = [...oldVehicles]
            let lastVehicle = newVehicles.slice(-1)
            let lastRunNumber = lastVehicle[0]?.runNumber
            let lastEmptySpotPosition = emptyRuns.findIndex(p => Number(p) === Number(lastRunNumber))
            if (lastEmptySpotPosition > 0) {
                addEmptyRuns(newVehicles, emptyRuns, lastEmptySpotPosition + 1, numberOfRun)
            }
            return newVehicles
        })
    }

    function canLoadMoreEmptyVehicle() {
        const maxRun = vehicles.slice(-1)[0]?.runNumber
        return isShowChecked && !isEmpty(runRanges) && Number(maxRun) < RUN_LANE_MAX_LENGTH
    }

    function handleOnClickOpenPregistTration(sblu, linktoSeller, title) {
        setIsGetPreRegistError(false)
        getPreRegistrations(auctionCode, sblu, linktoSeller)
            .then((response) => {
                setIsPreRegistrationOpen(true)
                setTitleVehicle(title)
                setPreRegistrationData(response.data)
            }).catch((ex) => {
            setIsGetPreRegistError(true)
        })
    }

    const onUpdatePreRegistrationInAS400 = (newVehicleData) => {
        updatePreRegistrationData(newVehicleData);
        setIsPreRegistrationOpen(false);
    }

    const onDeletePreRegistrationFromAS400 = () => {
        updatePreRegistrationData({vin:""});
        setIsPreRegistrationOpen(true);
    }

    useEffect(()=>{
        if(bulkUpdateVehicleErrors.length){
            const errorVehicles = [...vehicles]
            bulkUpdateVehicleErrors.forEach((error)=>{
                    const index =  errorVehicles.findIndex(p => p.workOrderNumber && (p.workOrderNumber.toString() === error.workOrderNumber.toString()))
                    if (index > -1) {
                        errorVehicles[index].errorMessage = error.errorMessage
                    }
            })
            setVehicles(errorVehicles)
        }
    },[bulkUpdateVehicleErrors])
    
    function updateHolderVehicles(vehicle) {
        holderVehicles.set(selectedVehicle.runNumber, vehicle)
        setHolderVehicles(holderVehicles)
    }

    /**
     * Reset status isUnsave for dropped vehicles
     */
    window.resetDroppedVehicles = function() {
        let hasChange = false
        const resetedVehicles = vehicles.map(vehicle => {
                                    if (vehicle.isUnsave) {
                                        let {isUnsave, ...restVehicle} = vehicle
                                        // update vehicle after save as draft
                                        restVehicle = {...restVehicle,
                                            draftVehicle: true,
                                            origSaleYear: (vehicle.draftVehicle ? vehicle.origSaleYear : vehicle.fromVehicle.saleYear) || 0,
                                            origSaleNumber: (vehicle.draftVehicle ? vehicle.origSaleNumber : vehicle.fromVehicle.saleNumber) || 0,
                                            origLaneNumber: (vehicle.draftVehicle ? vehicle.origLaneNumber : vehicle.fromVehicle.laneNumber) || 0,
                                            origRunNumber: (vehicle.draftVehicle ? vehicle.origRunNumber : vehicle.fromVehicle.runNumber) || 0,
                                        }
                                        hasChange = true
                                        return restVehicle
                                    }
                                    return vehicle
                                })

        if(hasChange) {
            const newDraftVehicles = resetedVehicles.filter(vehicle => vehicle.draftVehicle === true)
            setVehicles(resetedVehicles)
            setDraftVehicles(newDraftVehicles)
        }
    }
    
    /**
     * Reset status for vehicles sent to AS400
     */
    window.resetVehiclesSentToAS400 = function(sbluList) {
        let hasChange = false
        const resetVehicles = vehicles
            .map(vehicle => {
                                    if (includes(sbluList, vehicle.sblu)) {
                                        let restVehicle = {...vehicle, draftVehicle: false, isUnsave: false, resultType: 'R'}
                                        hasChange = true
                                        return restVehicle
                                    }
                                    return vehicle
                                })

        if(hasChange) {
            setVehicles(resetVehicles)
        }
    }

    const isDisplayNone = useCallback((vehicle) => {
        return isDragging
            && draggingItem
            && compareVehicles(draggingItem, vehicle)
    }, [isDragging, draggingItem]);

    // Remove item from the list help to decrease re-render items
    // Support to improve performance
    const visibleVehicles = useMemo(() => {
        const isEmptyVehicleVisible = (vehicle) => !isNil(vehicle)
            && vehicle.status === "EMPTY"
            && !!isShowChecked
            && !isEmpty(runRanges)

        const isAssignVehicleVisible = (vehicle) => !isNil(vehicle)
            && vehicle.status !== "EMPTY"

        return [...vehicles].filter(vehicle => isEmptyVehicleVisible(vehicle) || isAssignVehicleVisible(vehicle))
    }, [vehicles, isShowChecked, runRanges])

    useEffect(() => {
        window.updateQuickSearchData(visibleVehicles, true)

    }, [visibleVehicles])

    useEffect(() => {
        if (scrollToItem > -1) {
            assignedVehiclesRef.current?.scrollToItem(scrollToItem)
        }
    }, [scrollToItem]);

    //#region Auto assign selected runs
    window.replaceReserveVehicleWhenAutoAssign = function(reserveVehicleSpots) {
        let newVehicles = [...vehicles]
        reserveVehicleSpots.forEach((vehicleToMove, runNumber) => {
            let index = newVehicles.findIndex(p => p.runNumber === runNumber)
            if (index > -1) {
                newVehicles[index] = vehicleToMove
            }
        })

        setVehicles(newVehicles)
    }

    window.getReservedSpots = function(maxLength, startingRun) {
        let vehiclesCloned = [...vehicles]
        vehiclesCloned = orderBy(vehiclesCloned, ['runNumber'], 'asc')
        let reservedVehicles = []
        if (maxLength > vehiclesCloned.length) {
            maxLength = vehiclesCloned.length
        }

        const availableRunNumber = getAvailableReserveForAssign()
        for (let i = 0; i < vehiclesCloned.length; i++) {
            const currentVehicle = vehiclesCloned[i]
            let status = currentVehicle.type ? currentVehicle.type : currentVehicle.status
            if (status === 'SP' && currentVehicle.vin === ''
                    && Number(currentVehicle.runNumber) >= Number(startingRun) &&
                    availableRunNumber.includes(currentVehicle.runNumber)) {
                if (reservedVehicles.length < maxLength) {
                    reservedVehicles.push(currentVehicle)
                } else {
                    return reservedVehicles
                }
            }
        }

        return reservedVehicles
    }

    function getAvailableReserveForAssign() {
        return vehicles.filter(p => isReserveVehicle(p)).map(p => p.runNumber)
    }
    //#endregion

    return (
        <>
            {!!vehicles && !isEmpty(vehicles) &&
                <DynamicSizeList data={visibleVehicles} isAllowAutoScroll={true} ref={assignedVehiclesRef}>
                    {(vehicle, index) => (
                        <>
                            {!isNil(vehicle) && vehicle.status === "EMPTY" && !!isShowChecked && !isEmpty(runRanges) &&
                                <EmptyRun
                                    laneNumber={laneNumber}
                                    vehicle={vehicle}
                                    index={index}
                                    updateAssignedVehicleAfterDrop={(dragData) => updateAssignedVehicleAfterDrop(dragData, vehicle)}
                                />
                            }
                            {!isNil(vehicle) && vehicle.status !== "EMPTY" && (
                                <div className={classNames(
                                    {'dsp-n': isDisplayNone(vehicle)}
                                )}>
                                    <AssignInventoryVehicleItem
                                        vehicle={vehicle}
                                        messageUpdateVehicleSuccessful={messageUpdateVehicleSuccessful}
                                        handleVcfOnClick={(sblu) => {
                                            setSblu(sblu)
                                            if(isPreRegisterVehicle(vehicle)) {
                                                setIsVcfEditOpen(true)
                                            } else {
                                                setIsVcfOpen(true)
                                            }
                                        }}
                                        handleOnClickOpenPregistTration={(sblu, linktoSeller, title) => {
                                            setSelectedVehicle(vehicle);
                                            return handleOnClickOpenPregistTration(sblu, linktoSeller, title)}}
                                        dragData={{dragType: DnDDragTypeConstant.ASSIGN_VEHICLE, itemIndex: index}}
                                        canRemove={true}
                                        handleRemoveVehicleEvent={handleRemoveVehicleEvent}
                                        index={index}
                                        updateAssignedVehicleAfterDrop={(dragData) => updateAssignedVehicleAfterDrop(dragData, vehicle)}
                                        setMessageUpdateVehicleSuccessful={setMessageUpdateVehicleSuccessful}
                                        allowQuickSearch={true}
                                        quickSearchText={quickSearchContext.quickSearchValue}
                                        quickSearchIndex={scrollToItem}
                                        updateVehicleData={updateVehicleData}
                                        isVehicleDetailParticular={true}
                                        setGetVehicleError={() => {}}
                                        isShownSellerSearchButton={false}
                                    />
                                </div>

                            )}
                            {canLoadMoreEmptyVehicle() && (index === vehicles.length - 1) &&
                                <AssignVehicleListFooter
                                    onShowMoreEmptyRuns={handleShowMoreEmptyRuns}
                                    onShowAllEmptyRuns={handleShowAllEmptyRuns}
                                />
                            }
                        </>
                    )}
                </DynamicSizeList>
            }

            {!!isVcfOpen && (
                <VehicleVcf
                    isVcfOpen={isVcfOpen}
                    setIsVcfOpen={setIsVcfOpen}
                    auctionCode={auctionCode}
                    sbluNumber={sblu}
                />
            )}
            {!!isVcfEditOpen && (
                <VehicleVcfEdit
                    isVcfEditOpen={isVcfEditOpen}
                    setIsVcfEditOpen={setIsVcfEditOpen}
                    auctionCode={auctionCode}
                    sbluNumber={sblu}
                />
            )}

            {!!isPreRegistrationOpen && (
                <PreRegistration
                    isPreRegistrationOpen={isPreRegistrationOpen}
                    setIsPreRegistrationOpen={setIsPreRegistrationOpen}
                    auctionCode={auctionCode}
                    preRegistrationData={preRegistrationData}
                    titleVehicle={titleVehicle}
                    onDeleteFromAS400={onDeletePreRegistrationFromAS400}
                    onUpdateInAS400={onUpdatePreRegistrationInAS400}
                />
            )}
        </>
    )

    function updatePreRegistrationData(newPreRegistrationData) {
        const index = vehicles.findIndex(vehicle =>
            vehicle.laneNumber === selectedVehicle.laneNumber &&
            vehicle.runNumber === selectedVehicle.runNumber &&
            vehicle.sblu === selectedVehicle.sblu
        );
        if (index > -1) {
            vehicles[index] = {...vehicles[index], ...newPreRegistrationData};
            updateHolderVehicles(vehicles[index])
            setVehicles((prevState) => [...vehicles]);
            dispatch(getVehicleAfterUpdatePreRegist({id: `vehicle-item-${vehicles[index].vin}-${vehicles[index].workOrderNumber}-${vehicles[index].runNumber}`}))
        }

        pushQuickSearchValue(EMPTY_STRING)
    }
}
