import {useDragDropManager} from "react-dnd";
import {useEffect, useRef, useState} from "react";

export const useVehicleDraggingMonitor = () => {
    const dragDropManager = useDragDropManager()
    const monitor = dragDropManager.getMonitor()
    const [isDragging, setIsDragging] = useState(false)
    const [didDrop, setDidDrop] = useState(false)
    const itemRef = useRef()
    useEffect(() => {
        const unsubscribe = monitor.subscribeToOffsetChange(() => {
            if (monitor.isDragging() !== isDragging) {
                setIsDragging(monitor.isDragging())
            }
            itemRef.current = monitor.getItem()

            if (monitor.didDrop() !== didDrop) {
                setDidDrop(monitor.didDrop())
            }

        })
        return unsubscribe
    }, [monitor, setIsDragging, isDragging, didDrop, setDidDrop])

    return {isDragging: monitor.isDragging(),
            draggingItem: itemRef.current,
            didDrop: monitor.didDrop(),
    }
}