import {EMPTY_STRING, VEHICLE_NOTES} from '../utils/constant';

export function close() {
    return {
        type: VEHICLE_NOTES.UPDATE,
        state: {
            isOpen: false,
            auctionCode: EMPTY_STRING,
            workOrder: EMPTY_STRING
        }
    }
}

export function open(auctionCode, workOrderNumber) {
    return {
        type: VEHICLE_NOTES.UPDATE,
        state: {
            isOpen: true,
            auctionCode: auctionCode,
            workOrder: workOrderNumber
        }
    }
}