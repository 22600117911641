import { INVENTORY } from '../utils/constant';

export function getDnDVehicleData(data) {
    return {
        type: "GET_DRAG_AND_DROP_VEHICLE",
        dndVehicleData: data
    }
}

export function getAssignInventorySearchContext(data) {
    return {
        type: "GET_ASSIGN_INVENTORY_SEARCH_CONTEXT",
        searchContext: data
    }
}

export function resetAssignInventorySearchContext() {
    return {
        type: "GET_ASSIGN_INVENTORY_SEARCH_CONTEXT",
        searchContext: {
            searchKeywords: null
        }
    }
}

export function getSaleYearLane(data) {
    return {
        type: "GET_SALE_YEAR_LANE_SEARCH_CONTEXT",
        saleYearLaneContext: data
    }
}

export function resetSaleYearLane() {
    return {
        type: "GET_SALE_YEAR_LANE_SEARCH_CONTEXT",
        saleYearLaneContext: {
            saleYearLaneContext: {
                saleYear: null,
                saleNumber: null,
                laneNumber: null,
                isClickedOnLink: false
            }
        }
    }
}

export function inventoryQuickSearch(data) {
    return {
        type: INVENTORY.QUICK_SEARCH,
        quickSearchContext: data
    }
}

export function inventoryScrollToItem(data) {
    return {
        type: INVENTORY.SCROLL_TO_ITEM,
        scrollToItemContext: data
    }
}