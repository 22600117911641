import React from'react'
import {EMPTY_STRING} from '../../../utils/constant';
import {isEmpty} from 'lodash';

const MAX_WIN_BOLT_COUNT = 8;

export default function HighlightText({
    text,
    highlight
}) {
    const textToString = text.toString()
    const parts = textToString.split(new RegExp(`(${highlight})`, "gi"))

    return (
        <>
            {
                parts.map((part, index) => (
                    <React.Fragment key={index}>
                        { part.toLowerCase() === highlight.toLowerCase() ? (
                            <span className='bg-primary'>{part}</span>
                        ) : (
                            part
                        )}
                    </React.Fragment>
                ))
            }
        </>
    )
}

export function FormattedVinWithHighlightText({
    text,
    highlight
}) {
    const regex = new RegExp(`(${highlight})`, "gi")
    let count = 0;
    const result = text.replace(regex, highlightMatch);
    const parts = result.split(/(<\/?[^>]+>|.)/).filter(Boolean);

    // keep bold for last MAX_WIN_BOLT_COUNT matches
    for (let i = parts.length - 1; i >= 0; i--) {
        if (!parts[i].includes("<") && count < MAX_WIN_BOLT_COUNT) {
            count++;
            parts[i] = `<b>${parts[i]}</b>`;
        }
    }

    return (
        <span dangerouslySetInnerHTML={{ __html: parts.join('') }} />
    )
}

const highlightMatch = (match, index) => {
    if (isEmpty(match)) {
        return EMPTY_STRING
    }
    return `<span class='bg-primary'>${match}</span>`
}