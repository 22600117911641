import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { toUpper, isNil } from 'lodash'
import { Button, Form, Input, Modal, Col, Row } from '@prism/library'
import { ConstraintConstant } from '../../../utils/constant'

import './auction-input.scss'
import classNames from 'classnames'
import { NotificationMessage } from '../notification-message/NotificationMessage'
import { useSelector } from "react-redux"

export function AuctionInput({
    auctionCodeFromState,
    auctionCodeFromCookie,
    closeAuctionInput,
    changeAuctionCode,
    setIsAuctionCodeChange
}) {
    const { t } = useTranslation()
    const [isDisabled, setIsDisabled] = useState(false)
    const [isValidCode, setIsValidCode] = useState(true)

    const auctionCodes = useSelector(states => states.session.validAuctionCodes)

    const inputRef = useRef(null)
    const initialValues = useMemo(
        () => ({
            auctionCode: auctionCodeFromState || auctionCodeFromCookie
        }),
        [auctionCodeFromState, auctionCodeFromCookie]
    )

    const { values, handleSubmit, setFieldValue } = useFormik({
        onSubmit: (values) => submitSelectAuctionCode(values),
        initialValues
    })

    function submitSelectAuctionCode(values) {
        const auctionCode = values.auctionCode?.trim() || null

        const existedItem = auctionCodes.filter(p => p.trim().toUpperCase() === auctionCode.toUpperCase())
        if (!isNil(existedItem) && existedItem.length > 0) {
            setIsAuctionCodeChange(true)
            setIsValidCode(true)
            changeAuctionCode(toUpper(auctionCode))
            closeAuctionInput()
        } else {
            setIsDisabled(true)
            setIsValidCode(false)
            inputRef.current.focus()
        }
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const auctionInputOnChange =  (e) => {
            setFieldValue(
                e.target.name,
                e.target.value
                    .toUpperCase()
                    .trim()
            )
            if (
                isDisabled &&
                e.target.value.trim().length >=
                    ConstraintConstant.AUCTION_CODE_MIN_LENGTH
            ) {
                setIsDisabled(false)
            } else if (
                e.target.value.trim().length <
                ConstraintConstant.AUCTION_CODE_MIN_LENGTH
            ) {
                setIsDisabled(true)
            }
        }
    

    return (
        <>
            <div>
                <Modal isOpen={true} size="sm" className="auction-code-popup">
                    <Modal.Header>
                        <span mauto="maEnterAuctionCode">
                            {t('LABEL.AUCTION_INPUT.ENTER_AUCTION_CODE')}
                        </span>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            onSubmit={(e) => {
                                handleSubmit()
                                e.preventDefault()
                            }}>
                            <Row>
                                <Col md={3} sm={3} xs={3} className="auction-code-lbl">
                                    {t('LABEL.MENU_BAR.AUCTION.NAME') + ':'} 
                                </Col>
                                <Col md={9} sm={9} xs={9} className="pl-0">
                                    <Input
                                        className={classNames(
                                            'initialism auctionInput',
                                            isValidCode ? '' : 'is-invalid'
                                        )}
                                        name="auctionCode"
                                        maxLength={
                                            ConstraintConstant.AUCTION_CODE_MAX_LENGTH
                                        }
                                        value={values.auctionCode}
                                        mauto="maAuctionCode"
                                        onChange={auctionInputOnChange}
                                        innerRef={inputRef}
                                        autoFocus
                                    />
                                </Col>
                            </Row>
                            {isValidCode === false && (
                                <Row>
                                    <Col md={3} sm={3} xs={3}></Col>
                                    <Col md={9} sm={9} xs={9} className="pl-0">
                                    <NotificationMessage
                                        type={'error'}
                                        isOpen={true}>
                                        {t(
                                            'MESSAGE.COMMON.ERROR.INVALID_AUCTION_CODE'
                                        )}
                                    </NotificationMessage>
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col md={12} className="auction-btn btn-cancel-wrapper">
                                    <Button
                                        variant="textonly"
                                        mauto="maBtnCancel"
                                        className="btn-cancel"
                                        onClick={closeAuctionInput}>
                                        {t('LABEL.AUCTION_INPUT.CANCEL')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        mauto="maBtnChange"
                                        className="btn-update"
                                        color="primary"
                                        disabled={isDisabled}>
                                        {t('LABEL.AUCTION_INPUT.CHANGE')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
