const initialState = {
    advancedFilter: null
}

export const asgIvtAdvancedFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ASSIGN_INVENTORY_ADVANCED_FILTER_REDUCER":
            return{
                ...state,
                advancedFilter: action.payload.advancedFilter
            }
        default:
            return state;
    }
}