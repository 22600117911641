const initialState = {
    toggleConfigs: null,
    userName: '',
    validAuctionCodes: [],
    allowedApps: [],
    userInfo: null
}

export const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SESSION":
            return{
                ...state,
                toggleConfigs: action.payload.data.toggleConfigs,
                userName: action.payload.data.userName,
                validAuctionCodes: action.payload.data.validAuctionCodes,
                allowedApps: action.payload.data.allowedApps,
                userInfo: action.payload.data.userInfo
            }
        default:
            return state;
    }
}