import React, { useEffect, useMemo } from 'react'
import { isNumber } from 'lodash'
import { useTranslation } from 'react-i18next'

import { ConstraintConstant } from '../../utils/constant'
import { TablePagination } from '../common/table-pagination/TablePagination'
import classNames from 'classnames'

export function ResultTable({
    saleOverviews,
    activePageNumber,
    totalSaleOverviewRecord,
    auctionCodeFromState,
    saleYearFromState,
    saleNumberFromState,
    auctionCodeFromCookie,
    updateSaleOverviews,
    updateActivePageNumber
}) {
    const { t } = useTranslation()

    const auctionCode = useMemo(() => {
        return auctionCodeFromState || auctionCodeFromCookie
    }, [auctionCodeFromState, auctionCodeFromCookie])

    useEffect(() => {
        if (isNumber(activePageNumber)) {
            updateSaleOverviews(
                auctionCode,
                saleYearFromState,
                saleNumberFromState,
                activePageNumber,
                ConstraintConstant.PAGE_SIZE
            )
        }
    }, [activePageNumber, updateSaleOverviews])

    return (
        <>
            <div className="sale-overview-items table-sticky-wrapper">
                <table
                    className="table color-header mb-0 sale-overview-result-table"
                    mauto="maTblResultTable">
                    <thead className={classNames(
                               'table-head sticky-top table-single-sort-row-label'
                           )}>
                        <tr>
                            <th>
                                <span>
                                    {t(
                                        'LABEL.SALE_OVERVIEW.RESULT_TABLE.LANE_NUMBER'
                                    )}
                                </span>
                            </th>
                            <th>
                                <span>
                                    {t(
                                        'LABEL.SALE_OVERVIEW.RESULT_TABLE.LANE_TOTAL'
                                    )}
                                </span>
                            </th>
                            <th>
                                <span>
                                    {t(
                                        'LABEL.SALE_OVERVIEW.RESULT_TABLE.RUNS_RESERVED'
                                    )}
                                </span>
                            </th>
                            <th>
                                <span>
                                    {t(
                                        'LABEL.SALE_OVERVIEW.RESULT_TABLE.PRE_REGISTRATION'
                                    )}
                                </span>
                            </th>
                            <th>
                                <span>
                                    {t(
                                        'LABEL.SALE_OVERVIEW.RESULT_TABLE.REGISTERED'
                                    )}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {saleOverviews.map((saleOverview) => {
                            return (
                                <tr
                                    mauto="maTrResult"
                                    key={saleOverview.laneNumber}>
                                    <td
                                        className="table-data-cell">
                                        <span>{saleOverview.laneNumber}</span>
                                    </td>
                                    <td
                                        className="table-data-cell">
                                        <span>
                                            {saleOverview.totalReserved +
                                                saleOverview.totalPreRegistered +
                                                saleOverview.totalRegistered}
                                        </span>
                                    </td>
                                    <td
                                        className="table-data-cell">
                                        <span>
                                            {saleOverview.totalReserved}
                                        </span>
                                    </td>
                                    <td
                                        className="table-data-cell">
                                        <span>
                                            {saleOverview.totalPreRegistered}
                                        </span>
                                    </td>
                                    <td
                                        className="table-data-cell">
                                        <span>
                                            {saleOverview.totalRegistered}
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <TablePagination
                isEnabledPageDetails={true}
                pageNumber={activePageNumber}
                numberOfItemOnPage={ConstraintConstant.PAGE_SIZE}
                numberOfTotalItems={totalSaleOverviewRecord}
                updateActivePageNumber={updateActivePageNumber}
                updateTableItems={() => {}}
            />
        </>
    )
}
