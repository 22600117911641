import { useEffect, useRef, useState } from 'react'

export function useAsyncValue(asyncSupplier, deps) {
    const [state, setState] = useState({ loading: true })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => asyncEffect(state, setState, asyncSupplier), deps)
    return state
}

function asyncEffect({ loading }, setState, asyncSupplier) {
    if (!loading) setState({ loading: true })

    return cancellableAsyncState(setState, asyncSupplier)
}

function cancellableAsyncState(setState, asyncSupplier) {
    let cancelled = false

    asyncSupplier()
        .then((value) => !cancelled && setState({ value }))
        .catch((error) => !cancelled && setState({ error }))

    return () => (cancelled = true)
}

export function useTimeout(action, delay, deps) {
    const ref = useRef(NaN)

    useEffect(() => {
        ref.current = setTimeout(() => action((id) => (ref.current = id)), delay)

        return () => clearTimeout(ref.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}
