import { useAsyncValue } from './CustomHooksUtil'

export function useLoadScript(id, scriptPath) {
    return useAsyncValue(() => {
        const existingScript = document.getElementById(id)

        if (!existingScript) {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script')
                script.src = scriptPath
                script.id = id
                document.body.appendChild(script)

                script.onload = resolve
                script.onerror = reject
            })
        }
        return Promise.resolve({})
    }, [id, scriptPath])
}
