import { LANE_MANAGEMENT_ACTION_TYPE } from '../utils/constant';

export function laneManagementMessages(message) {
    return {
        type: LANE_MANAGEMENT_ACTION_TYPE.MESSAGES,
        message: message
    }
}

export function laneManagementRevertedMessage(revertedMessage) {
    return {
        type: LANE_MANAGEMENT_ACTION_TYPE.REVERTED_MESSAGE,
        revertedMessage: revertedMessage
    }
}

export function resetLaneManagementSearchContext() {
    return {
        type: "GET_LANE_MANAGEMENT_SEARCH_CONTEXT",
        laneManagementSearchData: {
            saleYear: null,
            saleNumber: null,
            laneNumber: null,
            isClickedOnLink: false
        }
    }
}
