import { useState, useLayoutEffect } from "react";

export const useWindowResize = () => {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener("resize", updateSize);
        updateSize();

        return () => {
            return window.removeEventListener("resize", updateSize);
        };
    }, []);

    return size;
};
