export const vehicleSortOptions = [
  {
    "code": "receivedDate",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.RECEIVED_DATE",
    "order": "asc"
  },
  {
    "code": "status",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.STATUS",
    "order": "asc"
  },
  {
    "code": "year",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.YEAR",
    "order": "desc"
  },
  {
    "code": "make",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.MAKE",
    "order": "asc"
  },
  {
    "code": "model",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.MODEL",
    "order": "asc"
  },
  {
    "code": "dispositionCode",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.DISPOSITION_CODE",
    "order": "asc"
  },
  {
    "code": "odometer",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.ODOMETER",
    "order": "asc"
  },
  {
    "code": "crGrade",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.CR_GRADE",
    "order": "desc"
  },
  {
    "code": "postCrGrade",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.POST_CR_GRADE",
    "order": "desc"
  },
  {
    "code": "workOrderNumber",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.WORK_ORDER_NUMBER",
    "order": "asc"
  },
  {
    "code": "lastSixVin",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.LAST_SIX_WIN",
    "order": "asc"
  },
  {
    "code": "mmr",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.MMR",
    "order": "desc"
  },
  {
    "code": "lotNumber",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.LOT_NUMBER",
    "order": "asc"
  },
  {
    "code": "lrn",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.LRN",
    "order": "asc"
  },
  {
    "code": "greenLight",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.GREEN_LIGHT",
    "order": "asc"
  },
  {
    "code": "redLight",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.RED_LIGHT",
    "order": "asc"
  },
  {
    "code": "yellowLight",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.YELLOW_LIGHT",
    "order": "asc"
  },
  {
    "code": "announcements",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.ANNOUNCEMENTS",
    "order": "asc"
  },
  {
    "code": "vehicleType",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.VEHICLE_TYPE",
    "order": "asc"
  },
  {
    "code": "sellerName",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.SELLER_NAME",
    "order": "asc"
  },
  {
    "code": "sellerNumber",
    "display": "LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.SELLER_NUMBER",
    "order": "asc"
  },
  {
    "code":"floor",
    "display":"LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.FLOOR",
    "order":"desc"
  }
];
