export const PathUrlConstant = {
    SaleOverview: 'sale-overview',
    LaneManagement: 'lane-management',
    AssignInventory: 'assign-inventory',
    RequestHistory: 'request-history',
    RemainingInventory: 'remaining-inventory',
    NextAvailable: 'next-available',
    Help: 'https://mainstreet2.manheim.com/docs/DOC-78304',
    Forbidden: 'forbidden',
    TrainingDocs: 'https://cox.policytech.com',
    ReleaseNotes: ''
}

export const ConstraintConstant = {
    AUCTION_CODE_MAX_LENGTH: 4,
    AUCTION_CODE_MIN_LENGTH: 3,
    GROUP_CODE_MAX_LENGTH: 7,
    FLOOR_MAX_LENGTH: 6,
    ANNOUNCEMENTS_MAX_LENGTH: 30,
    SELLER_NUMBER_MAX_LENGTH: 7,
    SALE_NUMBER_MAX_LENGTH: 3,
    LANE_NUMBER_MAX_LENGTH: 500,
    SALE_NUMBER_MIN: 1,
    SALE_NUMBER_MAX: 53,
    GROUP_CODE_M4X_LENGTH: 4,
    ALT_GROUP_CODE_M4X_LENGTH: 4,
    DISPOSITION_CODE_MAX_LENGTH: 2,
    INPUT_FILTER_MAX_LENGTH: 250,
    RUN_COUNT_MAX_LENGTH: 4,
    RUN_COUNT_MAX_VALUE: 2000,
    RUN_COUNT_MIN_VALUE: 1,
    LANE_MANAGEMENT: {
        LANE_NUMBER_MAX_LENGTH: 2,
    },
    STARTING_RUN_MAX_LENGTH: 4,
    STARTING_RUN_MAX_VALUE: 2000,
    STARTING_RUN_MIN_VALUE: 1,
    PS_MAX_LENGTH: 1,
    VIN_MAX_LENGTH: 17,
    YEAR_MAX_LENGTH: 4,
    MAKE_MAX_LENGTH: 10,
    SHORT_MODEL_MAX_LENGTH: 12,
    MODEL_MAX_LENGTH: 15,
    SUBSERIES_MAX_LENGTH: 10,
    COLOR_MAX_LENGTH: 8,
    ACCOUNT_MAX_LENGTH: 25,
    VCF_NOTE_MAX_LENGTH: 25,
    ODOMETER_MAX_LENGTH: 6,
    REQUESTED_BY_MAX_LENGTH: 20,
    COMMENTS_MAX_LENGTH: 125,
    PAGE_SIZE: 20,
    DEFAULT_PAGE_NUMBER: 1,
    PATTERN_REGEX_ALPHANUMERIC: /^[a-zA-Z0-9]{1,7}$/,
    PATTERN_REGEX_SPECIAL_CHARACTER: /[~`!@#$%^&*()\-+={}[\]:;"'<>.?\/|\\]/,
    NUMBERIC_PATTERN: /^[0-9]+$/,
    PATTERN_REGEX_VIN: /^[a-hj-npr-z0-9]{8}[0-9xX][a-hj-npr-z0-9]{8}$/i
}

export const EnvNameConstant = {
    PRODUCTION_ENVIRONMENT: 'production',
    UAT_ENVIRONMENT: 'uat',
    DEV_ENVIRONMENT: 'dev'
}

export const DealerStatusValueConstant = {
    OK: 'ok',
    INACTIVE: 'inactive',
    OUT_OF_BUSINESS: 'out of business',
    UNAUTH: 'unauthorized',
    UNKNOWN: 'unknown'
}

export const DealerStatusTextConstant = {
    OK: 'OK',
    INACTIVE: 'Inactive',
    OUT_OF_BUSINESS: 'Out Of Business',
    UNAUTH: 'Unauthorized',
    UNKNOWN: 'Unknown'
}

export const MessageTypeConstant = {
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning',
    SUCCESS: 'success'
}
export const SellerSearchTypeConstant = {
    ALL_DEALDER: 'dealer',
    ALL_COMMERCIAL: 'commercial',
    GROUP_CODE: 'seller_group_code',
    ALT_GROUP_CODE: 'seller_alt_group_code',
    SELLER_TYPE: 'seller_type',
    SELLER_NUMBER: 'seller_number'
}

export const ScopeConstant = {
    UNASSIGNED: 'unassigned'
}

export const LightColorConstant = {
    RED: 'red',
    GREEN: 'green',
    BLUE: 'blue',
    YELLOW: 'yellow'
}

export const DealerStatusTextShortConstant = {
    INACTIVE: 'I',
    OUT_OF_BUSINESS: 'U - OOB',
    UNAUTH: 'U',
    ACTIVE: 'A'
}

export const SearchHistoryRequest = {
    FILE_TYPE: {
        XLS: "XLS",
        CSV: "CSV",
        PDF: "PDF"
    }
}

export const RequestHistoryPDFHeader = ['Auct Code', 'Sale Year', 'Sale No.', 'Lane No.', 'Seller No.', 'Seller Name', 'Sale Efficiency'
    , 'Runs', 'Created At', 'Updated At', 'Requested By', 'Created By', 'Comments']

export const YES = 'Y'
export const NO = 'N'

export const  BREAKING_SPACE = '\u00A0'
export const BLANK_HOLDER = '--'
export const EMPTY_STRING = ''
export const SPACE_STRING = ' '
export const DOT_STRING = '.'

export const HYPHEN_STRING = "-"

export const COLON_STRING = ":"

export const ERR_NETWORK = "ERR_NETWORK"


export const ServerErrorMessage = {
    INTERNAL_ERROR: 'Internal Server Error'
}

export const ErrorTypes = {
    LOAD_VEHICLE_DETAIL: 'LOAD_VEHICLE_DETAIL'
}

export const ToggleFunction = {
    AUCTION_LIST_ALLOWED_DB_WRITE: 'AUCTION_LIST_ALLOWED_DB_WRITE',
}

export const VehicleDetailPattern = {
    DISPOSITION_CODE_PATTERN: /^[a-z0-9]{0,2}$/i
}

export const RUN_LANE_NUMBER_OF_SHOW_MORE = 20
export const RUN_LANE_MAX_LENGTH = 2000
export const RUN_LANE_LIMIT_BOTTOM = 80
export const RUN_LANE_LIMIT_TOP = 30

export const DnDDragTypeConstant = {
    ASSIGN_VEHICLE: 'AssignVehicle',
    UNASSIGN_VEHICLE: 'UnassignVehicle',
    LANE_MANAGEMENT: 'LaneManagement',
    CUSTOM_DRAG: 'customDrag'
}
export const SortConstant = {
    DESCENDING: 'desc',
    ASCENDING: 'asc',
    LAST_SIX_VIN: 'lastSixVin',
    MAX_ITEM_SELECTED: 5
}

export const DROP_PLACEHOLDER_ID = 'drop-placeholder-id'

export const RESET_OBSERVING_ACTION_TYPE = {
    ASSIGN_INVENTORY_SEARCH: "ASSIGN_INVENTORY_SEARCH",
    AUCTION_CODE_CHANGED: "AUCTION_CODE_CHANGED",
    SEARCH_HISTORY_CLEAR_ALL: "SEARCH_HISTORY_CLEAR_ALL",
    LANE_MANAGEMENT_REVERT_CONFIRMED: "REVERT_CONFIRMED",
    SHOW_SPINNER: "SHOW_SPINNER",
    LANE_MANAGEMENT_CLEAR_ALL_MESSAGES: "LANE_MANAGEMENT_CLEAR_ALL_MESSAGES"
}

export const SPINNER = {
    STATE: "STATE",
}

export const VEHICLE_NOTES = {
    UPDATE: 'UPDATE'
}

export const VEHICLE_DETAILS = {
    CHANGING: 'CHANGING'
}

export const REMAINING_INVENTORY_ACTION_TYPE = {
    MESSAGE_VEHICLE_UPDATED: "MESSAGE_VEHICLE_UPDATED",
    UPDATED_VEHICLE: "UPDATED_VEHICLE",
    GET_VEHICLE_ERROR: "GET_VEHICLE_ERROR",
}

export const LANE_MANAGEMENT_ACTION_TYPE = {
    MESSAGES: 'MESSAGES',
    REVERTED_MESSAGE: 'REVERTED_MESSAGE'
}

export const REQUEST_HISTORY_SORT_FIELD = {
    SALE_YEAR: 'SALE_YEAR',
    SALE_NUMBER: 'SALE_NUMBER',
    LANE_NUMBER: 'LANE_NUMBER',
    SELLER_NUMBER: 'SELLER_NUMBER',
    SELLER_NAME: 'SELLER_NAME',
    RUN_COUNT: 'RUN_COUNT',
    CREATED_AT: 'CREATED_AT',
    UPDATED_AT: 'UPDATED_AT'
}
export const DATE_FORMAT  = 'MM/DD/YYYY'

export const INVENTORY = {
    QUICK_SEARCH: 'QUICK_SEARCH',
    SCROLL_TO_ITEM: 'SCROLL_TO_ITEM'
}

export const QUICK_SEARCH = {
    CLEAR_QUICK_SEARCH_INDEX: -1
}

export const CHANGE_RUNS = {
    SINGLE: "single",
    RUN_RANGE: "runRange"
}
export const KEY_CODE = {
    ENTER: 13
}

export const RUN_REQUEST = {
    SELLER_NUMBER: 'sellerNumber',
    RUN_COUNT: 'runNumber',
    LANE_NUMBER: 'laneNumber',
    REQUEST_BY: 'requestedBy',
    COMMENTS: 'comments'
}

export const LANE_MANAGEMENT = {
    EDIT_REQUEST_SERVER_ERROR: 'Cannot find the not-mapped run request, globalId: ',
    EDIT_REQUEST_ERROR: 'MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.EDIT_RUN_REQUEST_ERROR.PRE_TEXT'
}

export const APP_CONSTANT = {
    APP_CODE: 'SSTPLUS',
    WORKBENCH_HEADER_SCRIPT: process.env.REACT_APP_WORKBENCH_HEADER_SCRIPT,
    WORKBENCH_FOOTER_SCRIPT: process.env.REACT_APP_WORKBENCH_FOOTER_SCRIPT
}

export const VCF_CONSTANT = {
    TYPE: 'prereg'
}

export const ERROR_STATUS_CODE = {
    CONFLICT: 409
}