import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useUserInfo } from '../user-datasource'

const UserContext = React.createContext(undefined)
UserContext.displayName = 'UserContext'

function UserProvider({ children }) {
    const { value: user = {}, loading, error } = useUserInfo()
    const [accessType, setAccessType] = useState(null)

    const setUserAccessType = useCallback((accessType) => {
        setAccessType(accessType)
    }, [])

    const clearUser = useCallback(() => {
        setAccessType(null)
    }, [])

    const provideValue = useMemo(() => {
        return Object.assign(
            { setUserAccessType, clearUser },
            user,
            accessType ? { accessType } : {}
        )
    }, [setUserAccessType, clearUser, user, accessType])

    return (
        <UserContext.Provider value={provideValue}>
            {!loading && !error && children}
        </UserContext.Provider>
    )
}

const useUserContext = () => {
    const context = React.useContext(UserContext)
    if (context === undefined) {
        throw new Error('useUserContext must be used within a UserContext Provider')
    }
    return context
}

export { UserProvider, useUserContext }
