import DefaultModal from "../common/modal/DefaultModal";
import React, { useCallback, useMemo } from "react";
import { revert } from "../../utils/data-source/lane-management-datasource";
import { useDispatch } from "react-redux";
import {laneManagementMessages, laneManagementRevertedMessage} from "../../actions/LaneManagementAction";
import { useTranslation } from "react-i18next";
import { MessageTypeConstant } from "../../utils/constant";
import {handleErrorException, openSnowModal} from "../../utils/utils";
import { notifyRevertConfirmed } from "../../actions/ResetStateObservingAction";
import useCustomLocation from '../../hooks/common/UseCustomLocation';

export default function RevertConfirmModal({
    runRequestData,
    setIsRevertConfirmOpen,
    isRevertConfirmOpen
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {pathName} = useCustomLocation()

    let errorMessage = useMemo(() => {
        return (
            <>
                {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.REVERT_RUN_REQUESTS_ERROR.PRE_TEXT')}
                <a href={pathName} onClick={openSnowModal}>
                    {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.REVERT_RUN_REQUESTS_ERROR.LINK_TEXT')}
                </a>
            </>
        )
    }, [])

    const handleRevertRunRequests = useCallback(() => {
        revert(runRequestData).then(() => {
            dispatch(laneManagementMessages(null))
            dispatch(notifyRevertConfirmed())
            dispatch(laneManagementRevertedMessage('LABEL.LANE_MANAGEMENT.REVERTED_SUCCESSFUL_MESSAGE'))
            window.setLaneManagementSaveMappedLaneError(null)
        }).catch((ex) => {
            const error = handleErrorException(ex);
            if (error.status !== 500) {
                errorMessage = error.message;
            }
            dispatch(laneManagementMessages({
                content: errorMessage,
                type: MessageTypeConstant.ERROR
            }))
        })

    }, [runRequestData])

    const yesClickHandler = useCallback(() => {
        handleRevertRunRequests();
        setIsRevertConfirmOpen(false)
    }, [handleRevertRunRequests, setIsRevertConfirmOpen])

    return (
        <DefaultModal data-test-id="revert-confirm-modal"
            header={t('LABEL.LANE_MANAGEMENT.REVERT_CONFIRM_DIALOG.TITLE')}
            message={t('LABEL.LANE_MANAGEMENT.REVERT_CONFIRM_DIALOG.MESSAGE')}
            onYesClick={yesClickHandler}
            onNoClick={() => setIsRevertConfirmOpen(false)}
            isOpen={isRevertConfirmOpen}
        />
    )
}