import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
    getDealershipStatusClass,
    getFormattedRegisterString,
    getFormattedStatus
} from '../../../utils/utils'

export function SearchSellerItem({
    dealer,
    setSellerNumber,
    setSearchSellerOpen,
    setHasDataChanged
}) {
    const { t } = useTranslation()

    return (
        <tr
            key={dealer.id}
            mauto="maTrResult"
            className={classNames('card-header')}>
            <td
                mauto="maDealerStatus"
                className={classNames(
                    'border-0',
                    getDealershipStatusClass(dealer.status)
                )}>
                {getFormattedStatus(dealer.status)}
            </td>
            <td mauto="maDealerCanRegister" className="border-0">
                {getFormattedRegisterString(dealer.can_register)}
            </td>
            <td mauto="maDealerId" className="border-0">
                {dealer.id}
            </td>
            <td mauto="maDealerName" className="border-0">
                {dealer.name}
            </td>
            <td mauto="maDealerAddress" className="border-0">
                {dealer.address}
            </td>
            <td mauto="maDealerCity" className="border-0">
                {dealer.city}
            </td>
            <td mauto="maDealerState" className="border-0">
                {dealer.state}
            </td>
            <td mauto="maSearchIconWrapper" className="border-0">
                <a
                    mauto="maSearchIcon"
                    href="javascript:void(0)"
                    onClick={() => {
                        setSellerNumber(dealer.id)
                        setHasDataChanged(true)
                        setSearchSellerOpen(false)
                    }}>
                    {t('LABEL.REMAINING_INVENTORY.SEARCH_SELLER.LABEL.SELECT')}
                </a>
            </td>
        </tr>
    )
}
