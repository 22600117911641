import { useEffect, useRef } from "react";
import {useWindowResize} from "../../../../hooks/common/UseWindowResize";

const DynamicSizeRow = ({
    data,
    index,
    setSize,
    setCurrentIndex,
    children
}) => {
    const rowRef = useRef();
    const [windowWidth] = useWindowResize();

    useEffect(() => {
        setSize(index, rowRef.current.getBoundingClientRect().height);
        setCurrentIndex(index);
    }, [setSize, index, windowWidth]);

    return (
        <div ref={rowRef}>
            {children(data[index], index)}
        </div>
    );
};

export default DynamicSizeRow;