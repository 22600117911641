import { Input, Space } from '@prism/library'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { EMPTY_STRING } from '../../utils/constant'
import { sortVehicleByCustomCriteria } from '../../utils/utils'
import { vehicleSortOptions } from './VehicleSortOptions'
import { Dropdown } from "../common/custom/prism/dropdown/Dropdown"
import { v4 as uuid } from "uuid"
import usePushQuickSearchValue from '../../hooks/common/UsePushQuickSearchValue'

import './assign-inventory-sort-by.scss'


export default function AssignInventorySortBy ({
    auctionCodeFromState,
    auctionCodeFromCookie,
    inventory,
    setInventory,
    vehicles,
    setVehicles,
    submitCount,
    sortItems,
    setSortItems
}) {
    const { t } = useTranslation();
    const [sortOption, setSortOption] = useState(buildVehicleSortOptions());
    const { pushQuickSearchValue }  = usePushQuickSearchValue()
    const [selectedOption, setSelectedOption] = useState({
        name: t("LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.SELECT"),
        value: null,
        key: uuid()
    });

    const auctionCode = useMemo(
        () => auctionCodeFromState || auctionCodeFromCookie,
        [auctionCodeFromState, auctionCodeFromCookie]
    )

    useEffect(() => {
        setSortItems([])
    }, [submitCount, auctionCode]);

    useEffect(() => {
        handleSort()
        let remainingSortOption = vehicleSortOptions.filter(function(item) {
            return !sortItems.find(function(sortItem) {
                return item.code === sortItem.value
            })
        }).map(item => {
            return {
                name: t(item.display),
                value: item.code,
                order: item.order,
                key: uuid()
            }
        })
        setSortOption(remainingSortOption)
    }, [sortItems]);

    function handleSort () {
        setInventory(sortVehicleByCustomCriteria(inventory, sortItems))
        setVehicles(sortVehicleByCustomCriteria(vehicles, sortItems))

        pushQuickSearchValue(EMPTY_STRING)
    }

    function handleSelectChange(event) {
        setSortItems(sortItems => [...sortItems, event]);
    }
    function buildVehicleSortOptions() {
        let optionsData = vehicleSortOptions.map(item => {
            return {
                name: t(item.display),
                value: item.code,
                order: item.order,
                key: uuid()
            }
        })
        return optionsData
    }
    return (
        <div className='assign-inventory-sort-by d-flex justify-content-end readonly pr-1 pl-1'>
            <Space size={1}>
                <Input.Label
                    className='p-0 m-0 quick-search-label'
                    htmlFor='dropdown-sort-by'>
                    {t(
                        'LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_BY'
                    )}
                </Input.Label>
                <Dropdown
                    id='dropdown-sort-by'
                    className='toggle-disable'
                    options={sortOption}
                    selectedOption={selectedOption}
                    onChange={handleSelectChange}
                    disabled={isEmpty(inventory)}
                    mauto='maDropdownSortBy'
                    placeholder={t('LABEL.ASSIGN_INVENTORY.ASSIGN_INVENTORY_DETAIL.SORT_OPTIONS.SELECT')}
                />
            </Space>

        </div>
    )
}