export const vehicleAdvancedFilters = {
    all: function(vehicle) {
      return true
    },

    noSale: function(vehicle) {
      return vehicle.noSale
    },

    freshInventory: function(vehicle) {
      return !vehicle.noSale
    },

    gatePassPrinted: function(vehicle) {
      return vehicle.gatePass
    },

    gatePassNotPrinted: function(vehicle) {
      return !vehicle.gatePass
    },

    titlePresent: function(vehicle) {
      return vehicle.hasTitle
    },

    titleAbsent: function(vehicle) {
      return !vehicle.hasTitle
    },

    crComplete: function(vehicle) {
      return vehicle.crGrade !== null && vehicle.crGrade !== ""
    },

    crIncomplete: function(vehicle) {
      return vehicle.crGrade === null || vehicle.crGrade === ""
    },

    floored: function(vehicle) {
      return vehicle.floor !== null && vehicle.floor > 0
    },

    notFloored: function(vehicle) {
      return isNaN(vehicle.floor) || vehicle.floor === 0
    },

    notRunningToday: function(vehicle) {
      return !vehicle.runningToday
    },

    drivableYes: function(vehicle) {
      return vehicle.drivable === "Y"
    },

    drivableNo: function(vehicle) {
      return vehicle.drivable === "N"
    },

    drivableNoStart: function(vehicle) {
      return vehicle.drivable === "S"
    },

    drivableUnknown: function(vehicle) {
      return vehicle.drivable === "U"
    },

    advancedDispositionCode: function (options) {
        var dispositionCodes = options.dispositionCodes.split(",").map(function (dispositionCode) {
          return dispositionCode.trim()
        })
        var exclude = options.exclude
  
        return function (vehicle) {
            for (var i = 0; i < dispositionCodes.length; i++) {
                if (vehicle.dispositionCode.toUpperCase() === dispositionCodes[i].toUpperCase()) {
                    if (exclude) { return false }
                    return true
                }
          }
  
          if(exclude) { return true }
  
          return false
        }
    },
  
    advancedLotLocation: function (options) {
        var lotLocations = options.lotLocations.split(",").map(function (lotNumber) {
          return lotNumber.trim()
        })
        var exclude = options.exclude
  
        return function (vehicle) {
            for (var i = 0; i < lotLocations.length; i++) {
                if (vehicle.lotNumber == lotLocations[i]) {
                    if (exclude) { return false }
                    return true
                }
            }
  
          if (exclude) { return true }
          return false
        }
    }
};

['A', 'B', 'C', 'D', 'F', 'G', 'H', 'I', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'V', 'X'].forEach(function(type) {
  vehicleAdvancedFilters['vehicleType' + type] = function(vehicle) {
    return vehicle.vehicleType === type;
  }
})