import React from 'react'
import classNames from 'classnames'

import './vehicle-text-icon.scss'

export function VehicleTextIcon({ text, title, bgClass, fgClass, className }) {
    return (
        <span
            title={title}
            className={classNames(
                bgClass,
                fgClass,
                className,
                'vehicle-alert-icon'
            )}>
            {text}
        </span>
    )
}
