import React from 'react'
import { useTranslation } from 'react-i18next'

export function NoDataFound() {
    const { t } = useTranslation()
    return (
        <>
            <div className="prism-overridden font-size-common w-100 text-center">
                <span>{t('LABEL.COMMON.NO_DATA_FOUND')}</span>
            </div>
        </>
    )
}
