import {INVENTORY} from '../utils/constant';

const initialState = {
    dndVehicleData: null,
    searchContext: {
        searchKeywords: null
    },
    saleYearLaneContext: {
        saleYear: null,
        saleNumber: null,
        laneNumber: null,
        isClickedOnLink: false
    },
    quickSearchContext: {
        quickSearchValue: '',
        id: '',
    },
    scrollToItemContext: {
        index: null
    }
}

export const dndVehicleDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DRAG_AND_DROP_VEHICLE":
            return{
                ...state,
                dndVehicleData: action.dndVehicleData
            }
        default:
            return state;
    }
}

export const searchContextReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ASSIGN_INVENTORY_SEARCH_CONTEXT":
            return{
                ...state,
                searchContext: action.searchContext
            }
        default:
            return state;
    }
}

export const saleYearLaneContextReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SALE_YEAR_LANE_SEARCH_CONTEXT":
            return{
                ...state,
                saleYearLaneContext: action.saleYearLaneContext
            }
        default:
            return state;
    }
}

export const quickSearchContextReducer = (state = initialState, action) => {
    switch (action.type) {
        case INVENTORY.QUICK_SEARCH:
            return{
               ...state,
                quickSearchContext: action.quickSearchContext
            }
        default:
            return state;
    }
}

export  const scrollToItemContextReducer = (state = initialState, action) => {
    switch (action.type) {
        case INVENTORY.SCROLL_TO_ITEM:
            return{
              ...state,
                scrollToItemContext: action.scrollToItemContext
            }
        default:
            return state;
    }
}