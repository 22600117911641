import React, { useEffect, useMemo } from "react";
import { MessageTypeConstant } from "../../utils/constant";
import { isEmpty } from "lodash";
import NotificationMessages from "../common/notification-messages/NotificationMessages";
import { useMessages } from "../../hooks/common/UseMessages";
import { useTranslation } from "react-i18next";
import {openSnowModal} from '../../utils/utils';
import useCustomLocation from '../../hooks/common/UseCustomLocation';

export default function AssignInventoryMessages({
    isCharLimitError,
    isInvalidCodeError,
    onlyNumberError,
    searchError,
    isEmptySearch,
    badRequestError,
    isProduction,
    laneSummary,
    isGetPreRegistError,
    saveDraftVehicleMessage,
    errorMessage,
    charLimitType,
    badPayload

}) {
    const { messages, putMessage, clearMessages } = useMessages();
    const { t } = useTranslation()
    const {pathName} = useCustomLocation()

    const displaySellerLaneSummary = useMemo(() => {
        const message = t(
            'MESSAGE.COMMON.INFO_MESSAGE_FOR_RESERVED_AND_REGISTERED'
        )

        let laneMessage = ''

        for (const lane in laneSummary) {
            if (laneSummary.hasOwnProperty(lane)) {
                laneMessage += 'Lane ' + lane + ': '
                laneMessage += '(' + laneSummary[lane].SP + '/'
                laneMessage += laneSummary[lane].SR + ') '
            }
        }

        return message + laneMessage
    }, [laneSummary])

    const searchEmptyErrorMsg = useMemo(() => (
        <>
            {t(
                'MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.SEARCH_DEALER.SEARCH_EMPTY_ERROR.PRE_TEXT')}
            <a href={pathName} onClick={openSnowModal}>
                {t(
                    'MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.SEARCH_DEALER.SEARCH_EMPTY_ERROR.LINK_TEXT')}
            </a>
        </>
    ), [])

    const processErrorMsg = useMemo(() => (
        <>
            {t('MESSAGE.COMMON.PROCESS_ERROR.PRE_TEXT')}
            <a href={pathName} onClick={openSnowModal}>
                {t('MESSAGE.COMMON.PROCESS_ERROR.LINK_TEXT')}
            </a>
        </>
    ), [])

    const chatLimitErrorMsg = useMemo(() =>
        t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.SEARCH_DEALER.CHAR_LIMIT_ERROR').replace('%TYPE%', charLimitType)
    , [charLimitType])

    const invalidCodeErrorMsg = useMemo(() =>
        t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.SEARCH_DEALER.INVLAID_CODE_ERROR').replace('%TYPE%', charLimitType)
    , [charLimitType])

    const numberOnlyErrorMsg = useMemo(() =>
        t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.SELL_NUMBER.NUMBER_ONLY')
    , [])

    const vehicleErrorMsg = useMemo(() =>
        t('MESSAGE.COMMON.ERROR.ASSIGN_INVENTORY.SEARCH_DEALER.VEHICLE_ERROR', badPayload)
    , [badPayload])

    const infoMessageForTestingMsg = useMemo(() =>
            t('MESSAGE.COMMON.INFO_MESSAGE_FOR_TESTING')
    , [])

    const messageConditionMapping = useMemo(() => {
        return [
            {condition: () => !!isCharLimitError, message: chatLimitErrorMsg, type: MessageTypeConstant.ERROR},
            {condition: () => !!isInvalidCodeError, message: invalidCodeErrorMsg, type: MessageTypeConstant.ERROR},
            {condition: () => !!onlyNumberError, message: numberOnlyErrorMsg, type: MessageTypeConstant.ERROR},
            {condition: () => !!searchError, message: errorMessage, type: MessageTypeConstant.ERROR},
            {condition: () => !!isEmptySearch, message: searchEmptyErrorMsg, type: MessageTypeConstant.ERROR},
            {condition: () => !!badRequestError, message: vehicleErrorMsg, type: MessageTypeConstant.ERROR},
            {condition: () => !isProduction() && !isEmpty(laneSummary), message: infoMessageForTestingMsg, type: MessageTypeConstant.INFO},
            {condition: () => !isEmpty(laneSummary), message: displaySellerLaneSummary, type: MessageTypeConstant.INFO},
            {condition: () => !!isGetPreRegistError, message: processErrorMsg, type: MessageTypeConstant.ERROR},
            {condition: () => !!saveDraftVehicleMessage, message: saveDraftVehicleMessage?.message, type: saveDraftVehicleMessage?.type}
        ]
    }, [isCharLimitError, isInvalidCodeError, onlyNumberError,
        searchError, isEmptySearch, badRequestError, isProduction,
        laneSummary, isGetPreRegistError, saveDraftVehicleMessage,
        errorMessage, charLimitType, badPayload])

    useEffect(() => {
        clearMessages()
        messageConditionMapping.forEach(({condition, message, type}) => {
            if (condition() === true) {
                putMessage(message, type)
            }
        })

    }, [messageConditionMapping])

    return (
        <NotificationMessages messages={messages} ></NotificationMessages>
    )
}