import React, {useCallback, useEffect, useRef} from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import './custom-scrollbars-virtual-list.scss'

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
    const refSetter = useCallback(
        (scrollbarsRef) => {
            if (scrollbarsRef) {
                forwardedRef(scrollbarsRef.view);
            } else {
                forwardedRef(null);
            }
        },
        [forwardedRef]
    );

    return (
        <Scrollbars
            ref={refSetter}
            style={{ ...style, overflow: "hidden" }}
            onScroll={onScroll}
            renderTrackVertical={(props) => (
                <div className="trackVertical" {...props} />
            )}
            renderView={(props) => (
                <div className="renderView" {...props} />
            )}
            hideTracksWhenNotNeeded
            autoHide
            renderTrackHorizontal={(props) => (
                <div className="trackHorizontal" {...props} />
            )}

            renderThumbVertical={(props) => (
                <div {...props} />
            )}
        >
            {children}
        </Scrollbars>
    );
};

const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
    <CustomScrollbars {...props} forwardedRef={ref} />
));


export default CustomScrollbarsVirtualList;