import {Row} from "@prism/library";
import React from "react";
import {useTranslation} from "react-i18next";

export default function AssignVehicleListFooter({
    onShowMoreEmptyRuns,
    onShowAllEmptyRuns
}) {
    const { t } = useTranslation()

    return (
        <div className="assign-vehicle-item">
            <Row>
        <span className="d-block w-100 text-center">
            <a
                className="mx-2 p-1"
                mauto="maShowMore"
                href="javascript:void(0)"
                onClick={onShowMoreEmptyRuns}>
                    {t('LABEL.ASSIGN_INVENTORY.VEHICLE_LIST.SHOW_MORE')}
            </a>
            <a
                className="ml-2 p-1"
                mauto="maShowAll"
                href="javascript:void(0)"
                onClick={onShowAllEmptyRuns}>
                    {t('LABEL.ASSIGN_INVENTORY.VEHICLE_LIST.SHOW_ALL')}
            </a>
        </span>
            </Row>
        </div>
    );
}
