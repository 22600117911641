import React, {useEffect, useState, useCallback, useRef} from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Input, Button } from '@prism/library'
import {isNil, isEmpty, isEqual, first, toString, isNull} from 'lodash'
import classNames from 'classnames'
import { useDispatch, useSelector } from "react-redux"
import {
    LightColorConstant,
    ConstraintConstant,
    BLANK_HOLDER,
    ToggleFunction,
    EMPTY_STRING,
    VehicleDetailPattern
} from '../../../utils/constant'
import { convertDateFromAS400, handleErrorException } from '../../../utils/utils'
import { getVehicleDetail } from '../../../utils/data-source/remaining-inventory-datasource'
import { updateInventory, updateVehicle } from '../../../utils/data-source/vehicle-datasource'
import { SearchSellerPanel } from './SearchSellerPanel'

import './vehicle-detail.scss'
import { updateVehicleSuccessfulMessage } from "../../../actions/RemainingInventoryAction";
import useCustomLocation from '../../../hooks/common/UseCustomLocation';
import useVehicleNotesModal from '../../../hooks/common/UseVehicleNotesModal';
import compareVehicles from '../../assign-inventory/helper/VehicleHelper';
import { isValidNumber } from '../../../utils/validation-utils'
import {updateHasChangingFlag} from '../../../actions/VehicleDetailAction';
import {useDragDropManager} from 'react-dnd';

export function VehicleDetail({
    isShownSellerSearchButton,
    isVehicleDetailParticular,
    isVehicleDetailOpen,
    auctionCode,
    sblu,
    setGetVehicleError,
    setMessageUpdateVehicleSuccessful,
    updateVehicleData,
    isAlwaysShowUpdateParticularSuccessfulMessage = false,
    vehicle,
    setInputFocused
}) {
    const { t } = useTranslation()
    const [vehicleDetail, setVehicleDetail] = useState(vehicle.vehicleDetail)
    const [searchSellerOpen, setSearchSellerOpen] = useState(false)
    const [customers, setCustomers] = useState(null)
    const [searchData, setSearchData] = useState(null)
    const [sellerNumber, setSellerNumber] = useState(vehicle?.vehicleDetail?.sellerNumber)
    const [hasDataChanged, setHasDataChanged] = useState(vehicle?.vehicleDetail?.hasDataChanged || false)
    const [isEnableUpdateAS400Toggle, setIsEnableUpdateAS400Toggle] = useState(vehicle?.vehicleDetail?.isEnableUpdateAS400Flag || false)
    const dispatch = useDispatch()
    const toggleConfigs = useSelector(states => states.session.toggleConfigs)
    const {pathName} = useCustomLocation()
    const {openVehicleNotesModal} = useVehicleNotesModal()
    const currentInput = useRef()
    const inputRefs = useRef({})
    const currentTarget = useRef()
    const { isChanging } = useSelector(states => states.vehicleDetailContext)
    const manager= useDragDropManager()

    // Keep user focusing when component re-render while user input
    useEffect(() => {
        if (vehicleDetail?.input && isChanging) {
            currentInput.current = inputRefs.current[vehicleDetail?.input?.name]
            if (currentInput.current) {
                currentInput.current.selectionStart = vehicleDetail?.input?.selectionStart
                currentInput.current.selectionEnd = vehicleDetail?.input?.selectionStart
                currentInput.current.focus()
            }
        }
    }, []);

    useEffect(() => {
        if (!vehicleDetail) {
            const toggleConfigValue = toggleConfigs[ToggleFunction.AUCTION_LIST_ALLOWED_DB_WRITE]
            const isEnableUpdateAS400Flag = isEnableToggleFlag(toggleConfigValue)
            setIsEnableUpdateAS400Toggle(isEnableUpdateAS400Flag)

            getVehicleDetail(auctionCode, sblu).then((response) => {
                setGetVehicleError(false)
                setVehicleDetail({...response.data, isEnableUpdateAS400Flag})
                setSellerNumber(response.data.sellerNumber)
            }).catch((ex) => {
                setGetVehicleError(true)
            })
        }
    }, [isVehicleDetailOpen])

    function isEnableToggleFlag(toggleConfigValue) {
        if (isNil(toggleConfigValue)) {
            return false
        }

        if (toggleConfigValue.trim().toUpperCase() === 'ALL') {
            return true;
        }

        const auctionCodes = toggleConfigValue.split(',')
        const auctionConfig = auctionCodes.filter(p => p.trim().toUpperCase() === auctionCode.toUpperCase())
        if (!isNil(auctionConfig) && auctionConfig.length > 0) {
            return true
        }

        return false
    }
    
    function enableLightColor(light) {
        return (
            !isNil(vehicleDetail.lights) &&
            vehicleDetail.lights[light.toUpperCase()] === 'Y'
        )
    }

    // Detect current focusing input
    useEffect(() => {
        function onMouseDown(event) {
            currentTarget.current = event.target
            if (event.target.name && inputRefs.current[event.target.name]) {
                currentInput.current = event.target
                dispatch(updateHasChangingFlag(true))
            } else {
                dispatch(updateHasChangingFlag(false))
            }
        }

        document.addEventListener('mousedown', onMouseDown)

        return () => {
            document.removeEventListener('mousedown', onMouseDown)
        }
    }, [])

    const onChange = useCallback((e) => {
        updateHasDataChanged()
        const { name, value } = e.target
        updateVehicleDetail(name, value)
    }, [vehicleDetail, hasDataChanged])


    // Prevent user paste unexpected values
    const onPasteHandler = useCallback((e) => {
        const { name } = e.target
        const pastedText = e.clipboardData.getData('text/plain')
        const clearedText = pastedText.replace(/\D/g, '')
        if (isEmpty(clearedText)) {
            e.preventDefault()
        } else {
            updateHasDataChanged()
            updateVehicleDetail(name, clearedText)
        }
    }, [hasDataChanged, vehicleDetail, vehicle])

    function updateHasDataChanged() {
        if(hasDataChanged === false) {
            setHasDataChanged(true)
        }
    }

    function updateVehicleDetail(name, value) {
        if (toString(vehicleDetail[name]) !== value) {
            setVehicleDetail({
                ...vehicleDetail,
                [name]: value,
                hasDataChanged: true
            })
        }
    }

    function updateInAS400() {
        const errorMessage = validateVehicle()
        if (!isEmpty(errorMessage)) {
            updateErrorMessage(errorMessage)
            return
        }

        let payload = null
        let fn = null
        if (isVehicleDetailParticular) {
            payload = buildAS400UdatingDataParticular()
            fn = updateInventory
        } else {
            payload = buildAS400UpdatingData()
            fn = updateVehicle
        }
        fn(payload)
            .then((response) => {
                if (!isNil(response.data.errors)
                    && !isEmpty(response.data.errors)) {
                    const error = first(response.data.errors)
                    updateErrorMessage(error.errorMessage)
                    return
                }
                let regularVin = ''
                let boldVin = ''
                if (vehicleDetail.vin.length > 8) {
                    regularVin = vehicleDetail.vin.slice(0, vehicleDetail.vin.length - 8)
                    boldVin = vehicleDetail.vin.slice(-8)
                } else {
                    boldVin = vehicleDetail.vin
                }

                let updatedVehicle = {
                    auctionCode: auctionCode,
                    floor: Number(vehicleDetail.floor),
                    workOrderNumber: Number(vehicleDetail.workOrderNumber),
                    vin: vehicleDetail.vin,
                    sblu: Number(sblu),
                    announcements: vehicleDetail.announcements,
                    dispositionCode: vehicleDetail.dispositionCode?.toUpperCase(),
                    runNumber: vehicle?.runNumber,
                    laneNumber: vehicle?.laneNumber,
                    resultType: vehicle?.resultType

                }
                if (!isNil(response.data.vehicle)) {
                    updatedVehicle = {...updatedVehicle,
                        year: response.data.vehicle.year,
                        make: response.data.vehicle.make,
                        model: response.data.vehicle.model,
                        trim: response.data.vehicle.trim,
                        sellerNumber: response.data.vehicle.sellerNumber,
                        sellerName: response.data.vehicle.sellerName,
                        sellerCity: response.data.vehicle.sellerCity,
                        sellerProvince: response.data.vehicle.sellerProvince,
                    }
                }

                // Store the updated successful messages
                setMessageUpdateVehicleSuccessful(
                    (prev) => {
                        const message = {
                            vin: vehicleDetail.vin,
                            workOrderNumber: vehicleDetail.workOrderNumber,
                            vehicle: vehicle,
                            message: (isVehicleDetailParticular || isAlwaysShowUpdateParticularSuccessfulMessage)
                                ? t('MESSAGE.COMMON.SUCCESS.AS400.UPDATE_PARTICULAR_VEHICLE_SUCCESSFUL')
                                : t('MESSAGE.COMMON.SUCCESS.AS400.UPDATE_VEHICLE_SUCCESSFUL', {
                                    regularVin: regularVin,
                                    boldVin: boldVin,
                                    workOrderNumber: vehicleDetail.workOrderNumber
                                })
                        }
                        const newMessageUpdateVehicleSuccessful = [...(prev || [])]
                        const index = findCurrentMessageUpdateSuccessfulIndex(prev, vehicleDetail)
                        if (index > -1) {
                            newMessageUpdateVehicleSuccessful[index] = message
                        } else {
                            newMessageUpdateVehicleSuccessful.push(message)
                        }

                        dispatch(updateVehicleSuccessfulMessage(message))
                        return newMessageUpdateVehicleSuccessful
                    }
                )

                setHasDataChanged(false)
                updateVehicleData({...updatedVehicle, errorMessage: null, vehicleDetail: {...vehicleDetail, hasDataChanged: false}})
            }).catch((ex) => {
                const error = handleErrorException(ex)
                updateErrorMessage(error.message)
                // Remove success messages if existed
                setMessageUpdateVehicleSuccessful(
                    (prev) => {
                        const newMessageUpdateVehicleSuccessful = [...(prev || [])]
                        const index = findCurrentMessageUpdateSuccessfulIndex(prev, vehicleDetail)
                        if (index > -1) {
                            newMessageUpdateVehicleSuccessful.slice(index, 1)
                        }
                        return newMessageUpdateVehicleSuccessful
                    }
                )
            })
    }

    function findCurrentMessageUpdateSuccessfulIndex(messageUpdateSuccessful, vehicle) {
        return messageUpdateSuccessful.findIndex(message => compareVehicles(message.vehicle, vehicle))
    }

    function buildAS400UpdatingData() {
        const payload = {
            auctionCode: auctionCode,
            sblu: sblu,
            announcements: vehicleDetail.announcements || '',
            sellerNumber: vehicleDetail.sellerNumber,
            floor: vehicleDetail.floor !== '' ? vehicleDetail.floor : 0,
            dispositionCode: vehicleDetail.dispositionCode || ''
        }
        return payload
    }

    function buildAS400UdatingDataParticular() {
        const payload = {
            auctionCode: auctionCode,
            sellerNumber: vehicleDetail.sellerNumber,
            announcements: vehicleDetail.announcements || EMPTY_STRING,
            dispositionCode: vehicleDetail.dispositionCode || EMPTY_STRING,
            saleYear: vehicleDetail.saleYear,
            saleNumber: vehicleDetail.saleNumber,
            laneNumber: vehicleDetail.laneNumber,
            runNumber: vehicleDetail.runNumber,
            workOrderNumber: vehicleDetail.workOrderNumber,
            floor: !isEqual(vehicleDetail.floor, EMPTY_STRING) ? vehicleDetail.floor : 0,
            sblu: sblu
        }
        return payload
    }

    const actionOnKeyDownNumber = useCallback((e) => {
        if (e.shiftKey) {
            if (
                (e.keyCode < 37 || e.keyCode > 40) // arrow key
            ) {
                // letter
                e.preventDefault()
            }
        } else if (e.ctrlKey) {
            if (
                (e.keyCode < 37 || e.keyCode > 40) && // arrow key
                e.keyCode !== 65 && // a or A to select all
                e.keyCode !== 67 && // c or C to copy
                e.keyCode !== 86 && // v or V to paste
                e.keyCode !== 88
            ) {
                // x or X to cut
                e.preventDefault()
            }
        } else {
            if (
                e.keyCode !== 8 && // backspace
                e.keyCode !== 9 && // tab
                e.keyCode !== 13 && // enter
                (e.keyCode < 37 || e.keyCode > 40) && // arrow key
                e.keyCode !== 46 && // delete
                (e.keyCode < 48 || e.keyCode > 57) && // 0-9
                (e.keyCode < 96 || e.keyCode > 105) // num0-num9
            ) {
                // letter
                e.preventDefault()
            }
        }
    }, [])

    const actionOnKeyDownNumberAlphabet = useCallback((e) => {
        if (e.shiftKey) {
            if (
                (e.keyCode < 37 || e.keyCode > 40) && // arrow key
                (e.keyCode < 65 || e.keyCode > 90) // alphabet
            ) {
                // letter
                e.preventDefault()
            }
        } else if (e.ctrlKey) {
            if (
                (e.keyCode < 37 || e.keyCode > 40) && // arrow key
                e.keyCode !== 65 && // a or A to select all
                e.keyCode !== 67 && // c or C to copy
                e.keyCode !== 86 && // v or V to paste
                e.keyCode !== 88
            ) {
                // x or X to cut
                e.preventDefault()
            }
        } else {
            if (
                e.keyCode !== 8 && // backspace
                e.keyCode !== 9 && // tab
                e.keyCode !== 13 && // enter
                (e.keyCode < 37 || e.keyCode > 40) && // arrow key
                e.keyCode !== 46 && // delete
                (e.keyCode < 48 || e.keyCode > 57) && // 0-9
                (e.keyCode < 96 || e.keyCode > 105) && // num0-num9
                (e.keyCode < 65 || e.keyCode > 90) // alphabet
            ) {
                // letter
                e.preventDefault()
            }
        }
    }, [])

    function validateVehicle() {
        if ((!isNil(vehicleDetail.floor)) &&
            (vehicleDetail.floor != '') &&
            (!isValidNumber(vehicleDetail.floor))) {
            return t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.FLOOR_ERROR_FORMAT')
        }

        if (!isValidNumber(vehicleDetail.sellerNumber)) {
            return t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.SELLER_NUMBER_ERROR_FORMAT')
        }

        if (vehicleDetail?.dispositionCode && !(VehicleDetailPattern.DISPOSITION_CODE_PATTERN.test(vehicleDetail.dispositionCode))) {
            return t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.DISPOSITION_CODE_ERROR_FORMAT')
        }

        return EMPTY_STRING
    }

    useEffect(() => {
        if (vehicleDetail && !isEqual(vehicleDetail, vehicle?.vehicleDetail)) {
            updateVehicleData({...vehicle,
                vehicleDetail: {...vehicleDetail,
                    input: {
                        // Store current input and current position of pointer
                        name: currentInput.current?.name || vehicle.vehicleDetail?.input?.name,
                        selectionStart: currentInput.current?.selectionStart || vehicle.vehicleDetail?.input?.selectionStart
                    }
                }
            })
        }
    }, [vehicleDetail])

    const updateErrorMessage = useCallback((errMsg) => {
        updateVehicleData({...vehicle, errorMessage: errMsg, vehicleDetail: {...vehicleDetail, hasDataChanged: !isNull(errMsg)}})
    }, [vehicle, vehicleDetail, currentInput.current])

    return (
        <>
            {!!vehicleDetail && (
                <div className="remaining-vehicle-detail">
                    <div className="vehicle-detail-content">
                        <Row className="ml-1 mt-1 border-top border-top-vehicle">
                            <span
                                className="text-muted mr-1"
                                mauto="maLblCollor">
                                <p className="font-weight-bold">{t('LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.COLOR')}</p>
                                {!isEmpty(vehicleDetail.color)
                                    ? vehicleDetail.color
                                    : BLANK_HOLDER}
                            </span>
                            <span
                                className="text-muted mr-1"
                                mauto="maLblGrade">
                                <p className="font-weight-bold">{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.CR_GRADE'
                                )}</p>
                                {!isEmpty(vehicleDetail.grade)
                                    ? vehicleDetail.grade
                                    : BLANK_HOLDER}
                            </span>
                            <span
                                className="text-muted mr-1"
                                mauto="maLblPost">
                                <p className='font-weight-bold'>{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.POST_CR'
                                )}</p>
                                {!isEmpty(vehicleDetail.post)
                                    ? vehicleDetail.post
                                    : BLANK_HOLDER}
                            </span>
                            <span
                                className="text-muted mr-1"
                                mauto="maLblVehicleDate">
                                <p className='font-weight-bold'>{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.VEHICLE_REC_DATE'
                                )}</p>
                                {convertDateFromAS400(
                                    vehicleDetail.receivedDate,
                                    'MM/DD/YYYY'
                                )}
                            </span>
                            <span
                                className="text-muted mr-1"
                                mauto="maLblTitleDate">
                                <p className='font-weight-bold'>{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.TITLE_REC_DATE'
                                )}</p>
                                {convertDateFromAS400(
                                    vehicleDetail.titleReceivedDate,
                                    'MM/DD/YYYY'
                                )}
                            </span>
                            <span
                                className="text-muted mr-1"
                                mauto="maLblBU">
                                <p className='font-weight-bold'>{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.BU'
                                )}</p>
                                {!isEmpty(vehicleDetail.primeCode)
                                    ? vehicleDetail.primeCode
                                    : BLANK_HOLDER}
                            </span>
                            <span
                                className="text-muted mr-1"
                                mauto="maLblSaleType">
                                <p className='font-weight-bold'>{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.SALE_TYPE'
                                )}</p>
                                {!isEmpty(vehicleDetail.saleType)
                                    ? vehicleDetail.saleType
                                    : BLANK_HOLDER}
                            </span>
                            <span
                                className="text-muted mr-1"
                                mauto="maLblVehicleType">
                                <p className='font-weight-bold'>{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.VEHICLE_TYPE'
                                )}
                                </p>
                                {!isEmpty(vehicleDetail.vehicleType)
                                    ? vehicleDetail.vehicleType
                                    : BLANK_HOLDER}
                            </span>
                            <span
                                className="text-muted mr-1"
                                mauto="maLblDrivable">
                                <p className='font-weight-bold'>{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.DRIVABLE'
                                )}
                                </p>
                                {!isEmpty(vehicleDetail.drivable)
                                    ? vehicleDetail.drivable
                                    : BLANK_HOLDER}
                            </span>
                        </Row>
                        <Row className="ml-1 mt-1 border-top ">
                            <span
                                className="vehicle-attr-floor text-muted mr-1"
                                mauto="maTxtFloor">
                                <p className="font-weight-bold">{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.FLOOR'
                                )}</p>                               
                                <Input
                                    maxLength={
                                        ConstraintConstant.FLOOR_MAX_LENGTH
                                    }
                                    defaultValue={vehicleDetail.floor}
                                    placeholder={t(
                                        'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.FLOOR_PLACE_HOLDER'
                                    )}
                                    innerRef={(el) => (inputRefs.current['floor'] = el)}
                                    onKeyDown={actionOnKeyDownNumber}
                                    onChange={onChange}
                                    onPaste={onPasteHandler}
                                    onMouseEnter={() => {
                                        manager.getActions().beginDrag()
                                        setInputFocused(true)}
                                    }
                                    onMouseLeave={() => {setInputFocused(false)}}
                                    name='floor'
                                />
                            </span>
                            <span
                                className="announcements-attr text-muted mr-1"
                                mauto="maTxtAnnoucements">
                                    <p className="font-weight-bold">{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.ANNOUNCEMENTS'
                                )}</p>
                                <Input
                                    innerRef={(el) => (inputRefs.current['announcements'] = el)}
                                    maxLength={
                                        ConstraintConstant.ANNOUNCEMENTS_MAX_LENGTH
                                    }
                                    defaultValue={vehicleDetail.announcements}
                                    placeholder={t(
                                        'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.ANNOUNCEMENTS_PLACE_HOLDER'
                                    )}
                                    onChange={onChange}
                                    onMouseEnter={() => {
                                        manager.getActions().beginDrag()
                                        setInputFocused(true)}
                                    }
                                    onMouseLeave={() => setInputFocused(false)}
                                    name='announcements'
                                />
                            </span>
                            <span
                                className="seller-number-attr text-muted mr-1"
                                mauto="maTxtSellerNumber">
                                <p className="font-weight-bold">{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.SELLER'
                                )}</p>
                                <Row className="mx-0">
                                    <Input
                                        maxLength={
                                            ConstraintConstant.SELLER_NUMBER_MAX_LENGTH
                                        }
                                        innerRef={(el) => (inputRefs.current['sellerNumber'] = el)}
                                        value={sellerNumber}
                                        placeholder={t(
                                            'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.SELLER_PLACE_HOLDER'
                                        )}
                                        onKeyDown={actionOnKeyDownNumber}
                                        onChange={(e) => {
                                            onChange(e)
                                            setSellerNumber(e.target.value)
                                        }}
                                        onPaste={onPasteHandler}
                                        onMouseEnter={() => {
                                            manager.getActions().beginDrag()
                                            setInputFocused(true)}
                                        }
                                        onMouseLeave={() => setInputFocused(false)}
                                        name='sellerNumber'
                                    />
                                    {
                                        isShownSellerSearchButton && (
                                            <span
                                                className="icon prism-icon-search icon-search cursor-pointer"
                                                onClick={() => {
                                                    setSearchSellerOpen(true)
                                                }}
                                            />
                                        )
                                    }
                                </Row>
                            </span>
                            <span
                                className="disposition-attr text-muted mr-1"
                                mauto="maTxtDisposition">
                                <p className="font-weight-bold">{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.DISPOSITION'
                                )}</p>
                                <Input
                                    maxLength={
                                        ConstraintConstant.DISPOSITION_CODE_MAX_LENGTH
                                    }
                                    defaultValue={vehicleDetail.dispositionCode}
                                    placeholder={t(
                                        'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.DISPOSITION_PLACE_HOLDER'
                                    )}
                                    onKeyDown={actionOnKeyDownNumberAlphabet}
                                    onChange={onChange}
                                    onMouseEnter={() => {
                                        manager.getActions().beginDrag()
                                        setInputFocused(true)}
                                    }
                                    onMouseLeave={() => setInputFocused(false)}
                                    innerRef={(el) => (inputRefs.current['dispositionCode'] = el)}
                                    name='dispositionCode'
                                />
                            </span>
                            <span mauto="lightColors" className="text-muted mr-1">
                                <p className="font-weight-bold">{t(
                                    'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.LIGHTS'
                                )}</p>
                                <span
                                    className={classNames('light-indicator', {
                                        'bg-danger': enableLightColor(
                                            LightColorConstant.RED
                                        )
                                    })}></span>
                                <span
                                    className={classNames('light-indicator', {
                                        'bg-primary': enableLightColor(
                                            LightColorConstant.YELLOW
                                        )
                                    })}></span>
                                <span
                                    className={classNames('light-indicator', {
                                        'bg-success': enableLightColor(
                                            LightColorConstant.GREEN
                                        )
                                    })}></span>
                                <span
                                    className={classNames(
                                        'light-indicator light-indicator-square',
                                        {
                                            'bg-info': enableLightColor(
                                                LightColorConstant.BLUE
                                            )
                                        }
                                    )}></span>
                            </span>
                        </Row>
                        <Row className="ml-1 mt-1 border-top">
                            <Col
                                md={1}
                                mauto="maLblNotes"
                                className="text-muted font-weight-bold p-0 mr-3">
                                <a href={pathName} onClick={() => openVehicleNotesModal(auctionCode, vehicleDetail.workOrderNumber)}>                                    
                                    {t(
                                        'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.VEHICLE_NOTES'
                                    )}
                                </a>
                            </Col>
                            <Col
                                mauto="maLblRemarks"
                                className="text-muted remarks-attr p-0">
                                <p className="font-weight-bold">
                                    {t(
                                        'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.REMARKS'
                                    )}
                                </p>
                                {vehicleDetail.remarks}
                            </Col>
                            <Col className="p-0">
                                <div className="btn-update-wrapper">
                                    <Button
                                        color="primary"
                                        className="btn-update"
                                        onClick={updateInAS400}
                                        mauto="maBtnUpdateInAS400"
                                        disabled={!isEnableUpdateAS400Toggle || !hasDataChanged}>
                                        {t(
                                            'LABEL.REMAINING_INVENTORY.VEHICLE_DETAIL.UPDATE_IN_AS400'
                                        )}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
            {!!searchSellerOpen && (
                <SearchSellerPanel
                    auctionCode={auctionCode}
                    searchSellerOpen={searchSellerOpen}
                    customers={customers}
                    searchData={searchData}
                    isVehicleDetailParticular={false}
                    setSearchSellerOpen={setSearchSellerOpen}
                    setIsVehicleDetailOpen={() => {}}
                    setSellerNumber={(value) => {
                        setSellerNumber(value)
                        updateVehicleDetail("sellerNumber", value)
                    }}
                    setCustomers={setCustomers}
                    setSearchData={setSearchData}
                    setHasDataChanged={setHasDataChanged}
                />
            )}
        </>
    )
}
